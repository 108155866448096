import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const Caret = ({
  color = colors.FC2_BLACK,
  width = '14',
  height = '8',
  className = '',
  viewBox = '0 0 14 8',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    {...props}
  >
    <path fill="none" fillRule="evenodd" stroke={color} strokeWidth="1.502" d="M1 1l5.983 6L13 1" />
  </svg>
)
