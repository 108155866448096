import { apiSlice } from 'api/apiSlice'
import { ProductTemplateResponse } from 'shared/types/ProductTemplate'

export const productTemplateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductTemplate: builder.query<ProductTemplateResponse, string>({
      query: (slug) => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/product_templates/${slug}`,
      }),
    }),

    getProductOffers: builder.query<
      { response: unknown },
      { slug: string; countryCode: string; currency: string }
    >({
      query: ({ slug, countryCode, currency }) => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/product_templates/${slug}/gmc_offers?countryCode=${countryCode}&currencyCode=${currency}`,
      }),
    }),

    getRecommendations: builder.query<{ response: unknown }, number>({
      query: (id) => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/product_templates/recommended?productTemplateId=${id}&count=24`,
      }),
    }),
  }),
})

export const { useLazyGetProductTemplateQuery } = productTemplateApi
