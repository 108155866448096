import { FC, useState, ReactNode } from 'react'
import { map as _map } from 'lodash'
import styled from 'styled-components'
import { fonts, colors, media } from 'shared/lib'
import { hide } from 'shared/lib/hide'
import { RotatingCaret } from 'shared/components'
import CloseMinus from './Storytelling/Icons/CloseMinus.svg'
import OpenPlus from './Storytelling/Icons/OpenPlus.svg'

interface ICardProps {
  className?: string
  title: string
  subtitle?: string
  caretLabel?: string
  isActive?: boolean
  children: ReactNode
  isExpanded?: boolean
  qaAttr?: string
  expandable?: boolean
  plusMinusIcons?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.FC2_WHITE};
`
export const Header = styled.div<{ expandable: boolean }>`
  width: 100%;
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  ${({ expandable }) => expandable && 'cursor: pointer;'}
  align-items: flex-start;
  ${media.large`
    margin-top: 0;
    padding: 30px 0;
  `}
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`
export const Title = styled.div`
  ${fonts.SUBTITLE_2}
`

const Subtitle = styled.div<{ isActive: boolean }>`
  ${fonts.SUBTITLE_3}
  color: ${({ isActive }) => (isActive ? colors.FC2_RED : colors.FC2_GREY)};
  margin-top: 6px;
`

const OpenCloseCaret = styled(RotatingCaret)`
  margin-left: 5px;
`

const CaretLabel = styled.span`
  ${fonts.SUBTITLE_1}
  margin-right: 5px;
`

const CaretWrapper = styled.div`
  align-self: center;
`

export const Content = styled.div`
  max-width: 100%;
  ${({ isHidden }: { isHidden: boolean }) => hide({ isHidden })};
`

export const Card: FC<ICardProps> = ({
  title,
  children,
  className,
  isExpanded,
  qaAttr,
  subtitle = '',
  isActive = false,
  caretLabel = '',
  expandable = true,
  plusMinusIcons = false,
}) => {
  const [isCardOpen, setIsCardOpen] = useState(isExpanded)
  const handleHeaderClick = () => {
    if (!expandable) {
      return
    }
    setIsCardOpen(!isCardOpen)
  }

  return (
    <Wrapper data-qa={qaAttr} className={className}>
      <Header onClick={handleHeaderClick} expandable={expandable}>
        <TitleWrapper>
          <Title data-qa={`${qaAttr}SectionHeader`}>{title}</Title>
          {subtitle && (
            <Subtitle
              isActive={isActive}
              data-qa={`${qaAttr}SectionSubTitle`}
              data-testid="card-subtitle"
            >
              {subtitle}
            </Subtitle>
          )}
        </TitleWrapper>
        {expandable && (
          <CaretWrapper>
            <CaretLabel>{caretLabel}</CaretLabel>
            {!plusMinusIcons && <OpenCloseCaret isOpen={!!isCardOpen} color={colors.FC2_GREY} />}
            {plusMinusIcons && isCardOpen && <CloseMinus color={colors.FC2_GREY} />}
            {plusMinusIcons && !isCardOpen && <OpenPlus color={colors.FC2_GREY} />}
          </CaretWrapper>
        )}
      </Header>
      <Content isHidden={!isCardOpen}>{children}</Content>
    </Wrapper>
  )
}
