const styles = {
  headerHeight: '60px',
  largePageWidth: '1200px',
  mediumPageWidth: '940px',
  /**
   * The standard padding that should be between page and content on all mobile layouts
   * We need to add the padding to the content and not the page layout otherwise the padding
   * will have the greyish background color and not the content background
   */
  mobilePageLayoutPadding: '20px',
}

export default styles
