import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useBreadcrumbsQuery } from 'api/breadcrumbsApi'
import { MenuLink, UnderlinedMenuLink } from 'layout/navigation/components/MenuLink'
import getNavLinkHref from 'layout/navigation/components/getNavLink'
import { t } from 'localization'
import { Card as BaseCard, Content, Header, Title } from 'shared/components/Card'
import { defaultNavigationLinks } from 'shared/data/defaultBreadcrumbs'
import { HeaderQA } from 'shared/dataAttributes'
import { Breadcrumbs } from 'shared/enums/Breadcrumbs'
import { colors, fonts } from 'shared/lib'
import { Breadcrumb } from 'shared/types/Breadcrumb'

const TP = 'layout.navigation.components.mobileNavLinks'

type MobileNavLinksProps = {
  closeMenu: () => void
}

export const MobileNavLinks = ({ closeMenu }: MobileNavLinksProps) => {
  const [navigationLinks, setNavigationLinks] = useState(defaultNavigationLinks)

  const { data, isSuccess } = useBreadcrumbsQuery(Breadcrumbs.NavigationLinks)

  const navLinkGroups = navigationLinks?.children?.reduce<Breadcrumb[][]>(
    (groups, navLink, index) => {
      const navLinkGroupsIndex = index < 6 ? 0 : index < 9 ? 1 : 2
      groups[navLinkGroupsIndex].push(navLink)
      return groups
    },
    [[], [], []],
  )
  navLinkGroups?.[0]?.push({
    name: t(`${TP}.fcApparel`, 'FC Apparel'),
    slug: '/apparel',
  })

  useEffect(() => {
    if (isSuccess) {
      setNavigationLinks(data)
    }
  }, [isSuccess])

  return (
    <>
      {navLinkGroups?.map((linkGroup, idxLg) => (
        <List key={idxLg}>
          {linkGroup?.map((link: Breadcrumb) => {
            return (
              <li key={link.slug}>
                {link.children && (
                  <Card qaAttr={HeaderQA.MobileParent} title={link.name} plusMinusIcons>
                    <CardList length={link.children ? link.children.length + 1 : 0}>
                      <CardListItem>
                        <Link href={getNavLinkHref(link)} passHref legacyBehavior>
                          <StyledUnderlinedMenuLink
                            qaAttr={HeaderQA.MobileChildrenShopAllLink}
                            dataTestid="mobileNavShopAll"
                            onClick={closeMenu}
                            emphasis={false}
                          >
                            {`All ${link.name}`}
                          </StyledUnderlinedMenuLink>
                        </Link>
                      </CardListItem>
                      {link.children.map((childLink: Breadcrumb) => {
                        return (
                          <CardListItem key={childLink.slug}>
                            <Link href={getNavLinkHref(childLink)} passHref legacyBehavior>
                              <StyledMobileLink
                                qaAttr={HeaderQA.MobileChildrenLink}
                                dataTestid="mobileNavChildLink"
                                onClick={closeMenu}
                              >
                                {childLink.name}
                              </StyledMobileLink>
                            </Link>
                          </CardListItem>
                        )
                      })}
                    </CardList>
                  </Card>
                )}
                {!link.children && (
                  <Link href={getNavLinkHref(link)} passHref legacyBehavior>
                    <PaddedLink
                      qaAttr={HeaderQA.MobileChildrenLink}
                      dataTestid="mobileNavChildLink"
                      onClick={closeMenu}
                    >
                      {link.name}
                    </PaddedLink>
                  </Link>
                )}
              </li>
            )
          })}
        </List>
      ))}
    </>
  )
}

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
  ${fonts.SUBTITLE_3}
`

const StyledMobileLink = styled(MenuLink)`
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
`

const StyledUnderlinedMenuLink = styled(UnderlinedMenuLink)`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
`

const Card = styled(BaseCard)`
  margin-bottom: 0;
  border: none;
  ${Title} {
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    font-weight: normal;
  }
  ${Content} {
    padding: 10px 0 10px !important;
    border: none;
  }
  ${Header} {
    padding: 10px 0 !important;
    border: none;
  }
`

const CardListItem = styled.li<{ isShopAll?: boolean }>`
  width: 100%;
  display: inline-block;
  padding: 10px 0;
  color: ${({ isShopAll }) => (isShopAll ? `${colors.FC2_RED}` : 'inherit')};
`

const PaddedLink = styled(StyledMobileLink)`
  padding: 10px 0;
`

const CardList = styled.ul<{ length: number }>`
  list-style-type: none;
  padding: 0;
  columns: 2;
`
