/* tslint:disable */
import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const TwitterIcon = ({
  color = colors.FC2_GREY,
  width = '24',
  height = '20',
  className = '',
  viewBox = '0 0 24 20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M22.595 2.854a9.93 9.93 0 0 1-1.439.282A4.517 4.517 0 0 0 23.3.376c-.419.247-.916.503-1.498.73-.596.236-1.148.391-1.626.497A4.881 4.881 0 0 0 18.45.387a4.654 4.654 0 0 0-1.947-.352c-.276.005-2.355.066-3.77 1.875-1.458 1.869-.985 4.035-.921 4.291a13.342 13.342 0 0 1-5.697-1.523C3.79 3.452 2.326 1.874 1.61.93a4.996 4.996 0 0 0-.62 3.472c.374 2.02 1.853 3.05 2.119 3.226-.291-.01-.656-.05-1.07-.156A4.576 4.576 0 0 1 .902 7c-.005.482.05 1.663.803 2.84 1.065 1.652 2.73 2.085 3.11 2.17-.281.075-.646.146-1.07.16a5.028 5.028 0 0 1-1.108-.085 4.948 4.948 0 0 0 1.611 2.438 4.818 4.818 0 0 0 2.942 1.055A9.593 9.593 0 0 1 0 17.673c1.035.689 3.736 2.282 7.525 2.292 3.83.01 6.56-1.608 7.585-2.292a14.378 14.378 0 0 0 4.78-5.351 14.746 14.746 0 0 0 1.621-7.357c.434-.332.932-.759 1.434-1.307.414-.452.745-.894 1.01-1.291-.389.17-.842.342-1.36.487z"
    />
  </svg>
)

export default TwitterIcon
