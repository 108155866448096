import { Breadcrumb } from 'shared/types/Breadcrumb'

const breadcrumbSort = (a: Breadcrumb, b: Breadcrumb) => {
  if (!a?.sort || !b?.sort) {
    return 0
  }
  if (a.sort < b?.sort) {
    return -1
  }
  if (a?.sort > b?.sort) {
    return 1
  }
  return 0
}

export const sortBreadcrumbChildren = (breadcrumb: Breadcrumb) => {
  if (Array.isArray(breadcrumb?.children)) {
    breadcrumb?.children?.sort(breadcrumbSort)
    breadcrumb?.children?.forEach(child => {
      if (Array.isArray(child?.children)) {
        sortBreadcrumbChildren(child)
      }
    })
  }
  return breadcrumb
}
