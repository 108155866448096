import { FC, useState, ReactNode } from 'react'
import { map as _map } from 'lodash'
import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'
import { hide } from 'shared/lib/hide'
import { RotatingCaret } from 'shared/components'

interface ICardProps {
  className?: string
  title: string
  subtitle?: string | null
  children: ReactNode
  isExpanded?: boolean
  qaAttr?: string
  expandable?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
`
export const Header = styled.div<{ expandable: boolean }>`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  ${({ expandable }) => expandable && 'cursor: pointer;'}
  align-items: center;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`
export const Title = styled.div`
  ${fonts.SUBTITLE_2}
`

const Subtitle = styled.div`
  ${fonts.BODY_TEXT}
  margin-top: 6px;
`

const OpenCloseCaret = styled(RotatingCaret)`
  margin-left: 5px;
`

export const Content = styled.div`
  max-width: 100%;
  ${({ isHidden }: { isHidden: boolean }) => hide({ isHidden })};
  border-top: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

export const Card: FC<ICardProps> = ({
  title,
  children,
  className,
  isExpanded,
  qaAttr,
  subtitle = '',
  expandable = true,
}) => {
  const [isCardOpen, setIsCardOpen] = useState(isExpanded)
  const handleHeaderClick = () => {
    if (!expandable) {
      return
    }
    setIsCardOpen(!isCardOpen)
  }

  return (
    <Wrapper data-qa={qaAttr} className={className}>
      <Header onClick={handleHeaderClick} expandable={expandable}>
        <TitleWrapper>
          <Title data-qa={`${qaAttr}SectionHeader`}>{title}</Title>
          {subtitle && (
            <Subtitle data-qa={`${qaAttr}SectionSubTitle`} data-testid="card-subtitle">
              {subtitle}
            </Subtitle>
          )}
        </TitleWrapper>
        {expandable && <OpenCloseCaret isOpen={isCardOpen} color={colors.FC2_GREY} />}
      </Header>
      <Content data-qa={`${qaAttr}Content`} hidden={!isCardOpen} isHidden={!isCardOpen}>
        {children}
      </Content>
    </Wrapper>
  )
}
