/**
 * [executeOnNextTick]
 * Executes provided function on the next tick, for performance.
 * Uses `setTimeout` as a fallback if browser does not have a native method.
 * @param  {Function} method  The method to execute on next tick.
 */
export default (() => {
  // TODO: seems weird I have to do this since hook executes
  // when it mounts
  if (process.browser) {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      ((callback: () => void) => {
        setTimeout(callback, 1000 / 60)
      })
    )
  }
  return (callback: () => void) => {
    setTimeout(callback, 1000 / 60)
  }
})()
