import styled, { css } from 'styled-components'
import { FC, useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { mediaQueries } from 'shared/theme'

export interface ExpandedMenu {
  isOpen: boolean
  onRequestClose?: () => void
  contentLabel?: string
  qaAttr?: string
}

const ExpandedMenu: FC<ExpandedMenu> = ({ isOpen, onRequestClose, qaAttr = '', children }) => {
  const menuEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // react-modal's --after-open animation class was being applied immediately
    // this meant it would not trigger entrance animation. these classes allow
    // reliable menu animation
    setTimeout(() => {
      if (isOpen && menuEl.current) {
        disableBodyScroll(menuEl.current)
      } else if (!isOpen && menuEl.current) {
        enableBodyScroll(menuEl.current)
      }
    }, 0)
    return () => {
      if (menuEl.current) {
        enableBodyScroll(menuEl.current)
      }
    }
  }, [isOpen, menuEl.current])

  return (
    <>
      <Overlay isOpen={isOpen} onClick={onRequestClose} />
      <Menu ref={menuEl} isOpen={isOpen} data-qa={qaAttr}>
        {children}
      </Menu>
    </>
  )
}

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => (props.isOpen ? '100%' : '0')};
  height: ${(props) => (props.isOpen ? '100%' : '0')};
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 250ms ease-in-out 175ms;
  z-index: 901;
`

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${(props) => props.theme.backgroundColor};
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  padding-top: 76px;
  z-index: 905;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${mediaQueries.smallNavigation} {
    bottom: 0;
    border-bottom: 0;
    height: 100vh;
    padding-bottom: 175px;
  }
  transform: ${(props) => (props.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, -120%, 0)')};
  transition: transform 250ms ease-in-out 0ms, background-color 0ms ease-in 500ms;
  ${(props) =>
    props.isOpen &&
    css`
      transition: transform 350ms ease-in-out 175ms, background-color ease-in 500ms;
    `}
  &:focus {
    outline: 0;
  }
`

export const MenuPanel = styled.div`
  padding: 30px;
  max-height: calc(100vh - 76px);
  ${mediaQueries.smallNavigation} {
    padding: 20px;
  }
  & + & {
    border-left: 2px solid ${(props) => props.theme.borderColor};
  }
`

export default ExpandedMenu
