import { apiSlice } from 'api/apiSlice'
import { Breadcrumb } from 'shared/types/Breadcrumb'
import { sortBreadcrumbChildren } from 'shared/utils/breadcrumb-utils'

const breadcrumbsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    breadcrumbs: build.query({
      query: slug => ({
        app: 'sneakers',
        method: 'GET',
        params: { includeChildren: true },
        url: `/web-api/v1/breadcrumbs/${slug}`,
      }),
      transformResponse: (response: Breadcrumb) => {
        return sortBreadcrumbChildren(response)
      },
    }),
  }),
})

export const { useBreadcrumbsQuery } = breadcrumbsApi
