export const parse = (text, reviver?) => {
  let result
  try {
    result = JSON.parse(text, reviver)
  } catch (error) {
    return null
  }
  return result
}

export const stringify = text => {
  try {
    return JSON.stringify(text)
  } catch (error) {
    return null
  }
}
