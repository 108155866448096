const zIndex = {
  notSelectable: -1,
  radio: 1,
  modal: 100,
  justUnderNav: 900,
  header: 1000,
  justOverNav: 1100,
  pageTakeover: 2000,
  sideSheetModal: 2100,
}

export default zIndex
