import { css } from 'styled-components'
import media from './media'
import styles from './styles'

// Styled-component mixins that will automatically hide/show
// components based on the "large" size found in `shared/lib/media.ts`
// using `display: none`.  Pass in optional attrs using
// Tagged Template Literals.

// We use css.call because Typescript doesn't recognize spread operators as an argument

export const showMobile = (...attrs: Parameters<typeof css>) => css`
  display: block;
  ${css.call(null, ...attrs)}
  ${media.large`
    display: none;
  `}
`

export const showDesktop = (...attrs: Parameters<typeof css>) => css`
  display: none;
  ${media.large`
    display: block;
    ${css.call(null, ...attrs)}
  `}
`

export const pageWidth = css`
  max-width: ${styles.mediumPageWidth};
  ${media.extraLarge`
    max-width: ${styles.largePageWidth};
  `}
`
