import { useState } from 'react'

// Usage:
// const [handleTouchStart, handleTouchEnd] = useSwipe({
//   onSwipeRight,
//   onSwipeLeft,
// })
// <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} />

const useSwipe = ({ onSwipeLeft, onSwipeRight, threshold = 100 }) => {
  const [currentTouch, setCurrentTouch] = useState(null)

  const onDown = event =>
    event.touches.length === 1 ? setCurrentTouch(event.touches[0].clientX) : setCurrentTouch(null)

  const onUp = event => {
    // the only finger that hit the screen left it
    const end = event.changedTouches[0].clientX

    if (end > currentTouch + threshold) {
      onSwipeRight()
    }
    if (end < currentTouch - threshold) {
      onSwipeLeft()
    }

    // set a new currentTouch if there is another finger on screen
    event.touches.length === 1 ? setCurrentTouch(end) : setCurrentTouch(null)
  }

  return [onDown, onUp]
}

export default useSwipe
