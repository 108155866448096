import { apiSlice } from 'api/apiSlice'

const regionPreferencesApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    setRegionPreferences: build.mutation({
      query: body => ({
        app: 'sneakers',
        data: body,
        method: 'POST',
        url: '/web-api/v1/region_preferences/',
      }),
    }),
  }),
})

export const { useSetRegionPreferencesMutation } = regionPreferencesApi
