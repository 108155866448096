// tslint:disable: max-line-length
import * as React from 'react'
import KlarnaLogo from '../SVGIcons/KlarnaLogo.svg'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 26px;
  svg {
    height: 26px;
  }
`
const KlarnaIcon = () => (
  <Wrapper>
    <KlarnaLogo />
  </Wrapper>
)

export default KlarnaIcon
