import styled from 'styled-components'
import { media } from 'shared/lib'
import ErrorBanner, { IErrorBannerProps } from './ErrorBanner'
import { FC } from 'react'
import { PANEL_BOX_DESKTOP_WIDTH } from 'shared/components/Panel/PanelBox'

const ErrorContainer = styled.div`
  ${media.large`
    width: 100%;
    max-width: ${PANEL_BOX_DESKTOP_WIDTH};
  `}
`

export const PanelBoxError: FC<IErrorBannerProps> = props => {
  return (
    <ErrorContainer>
      <ErrorBanner {...props} />
    </ErrorContainer>
  )
}

export default PanelBoxError
