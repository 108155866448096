import { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import getConfig from 'next/config'

import { appHeaders } from 'shared/services/appHeaders'
import { App } from 'shared/types/App'

/**
 * [clientRequestService]
 * Creates a custom Axios RTK baseQuery to be used for making
 * client-side requests to GOAT apps
 *
 * RTK Custom Queries: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
 * Axios API Reference: https://axios-http.com/docs/api_intro
 */

type ClientRequestService = {
  app: App
  cache?: boolean
  data?: AxiosRequestConfig['data']
  method: AxiosRequestConfig['method']
  params?: AxiosRequestConfig['params']
  url: AxiosRequestConfig['url']
}

type ClientRequestServiceError = {
  status: number
  data: unknown
}

export const clientRequestService =
  (): BaseQueryFn<ClientRequestService, unknown, ClientRequestServiceError> =>
  async ({ app, cache = true, data, method, params, url }: ClientRequestService) => {
    const {
      serverRuntimeConfig: { apiUrl },
    } = getConfig()

    const defaultHeaders = {
      ...(!cache && {
        'cache-control': 'no-cache, no-store, must-revalidate',
        expires: 0,
        pragma: 'no-cache',
      }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-goat-sales-channel': '2',
    }

    try {
      const token = Cookies.get('xsrf_token')
      const result = await axios({
        baseURL: apiUrl,
        data,
        headers: {
          ...defaultHeaders,
          ...appHeaders({ app, isClient: true, token }),
        },
        method,
        params,
        url,
        withCredentials: true,
      })
      return { data: result.data }
    } catch (error) {
      return {
        error: {
          status: error?.response?.status || error?.err?.status,
          data:
            error?.response?.data?.error || error?.response?.data || error?.message || error?.msg,
        },
      }
    }
  }
