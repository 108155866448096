import { isFunction } from 'lodash'
import { logClickEventUtil } from 'mParticle/mParticleService'
import Link from 'next/link'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { UrlObject } from 'url'

import { colors } from 'shared/lib'
import fonts from 'shared/lib/fonts'
interface ILinkCTAProps {
  ariaLabel?: string
  banner?: boolean
  children: ReactNode
  className?: string
  color?: LinkColor
  dataTestId?: string
  dataTrackingEvent?(e: EventTarget): void
  href?: string | UrlObject
  isExternal?: boolean
  linkAs?: string
  newTab?: boolean
  onClick?
  qaAttr?: string
}

type LinkColor = 'black' | 'grey' | 'white' | 'inherit'

type LinkProps = { [K in LinkColor]: string }

export const LinkColors: LinkProps = {
  black: colors.FC2_BLACK,
  grey: colors.FC2_GREY,
  white: colors.FC2_WHITE,
  inherit: 'inherit',
}

const OffsetColors: LinkProps = {
  black: colors.FC2_GREY,
  // one time use color, merely meant to be a "darker" version of our white for hover
  white: '#C7C7C7',
  grey: colors.FC2_BLACK,
  inherit: 'inherit',
}

const StyledLink = styled.a<{ color: LinkColor; banner: boolean }>`
  ${({ banner }) => (banner ? fonts.BANNER_CTA_TEXT : fonts.LINK)}
  ${({ color }) =>
    color !== 'inherit'
      ? `color: ${LinkColors[color]}; &:hover { color: ${OffsetColors[color]}; }`
      : 'color: inherit; text-decoration: none;'}
  cursor: pointer;
`

/**
 * Standard way to use links as CTAs or buttons which link to another page or route
 *
 * Usage
 * <LinkCTA href="/terms-of-use">Terms of Use</LinkCTA>
 *
 */
export const LinkCTA: FC<ILinkCTAProps> = ({
  ariaLabel,
  banner = false,
  children,
  className,
  color = 'black',
  dataTestId,
  dataTrackingEvent,
  href,
  isExternal = false,
  linkAs,
  newTab = false,
  onClick,
  qaAttr,
}) => {
  const newTabProps = newTab
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {}

  return (
    <>
      {isExternal && (
        <StyledLink
          aria-label={ariaLabel}
          href={href}
          {...newTabProps}
          data-qa={qaAttr}
          color={color}
          banner={banner}
          className={className}
          data-testid={dataTestId}
        >
          {children}
        </StyledLink>
      )}
      {!isExternal && href && (
        <Link as={linkAs} href={href} passHref legacyBehavior>
          <StyledLink
            {...newTabProps}
            aria-label={ariaLabel}
            data-qa={qaAttr}
            color={color}
            banner={banner}
            className={className}
            data-testid={dataTestId}
            onClick={(e) => {
              if (isFunction(dataTrackingEvent)) {
                dataTrackingEvent(e)
              } else {
                logClickEventUtil(e)
              }
            }}
          >
            {children}
          </StyledLink>
        </Link>
      )}
      {onClick && !href && (
        <StyledLink
          {...newTabProps}
          aria-label={ariaLabel}
          data-qa={qaAttr}
          onClick={onClick}
          color={color}
          banner={banner}
          className={className}
          data-testid={dataTestId}
        >
          {children}
        </StyledLink>
      )}
    </>
  )
}

export default LinkCTA
