import styled from 'styled-components'
import Image from 'shared/components/Image'
import { IconsQA } from 'shared/dataAttributes'

const Img = styled(Image)`
  height: auto;
  width: 80px;
`

const PayPalIcon = () => {
  return <Img
    src="/static/icons/paypal.png"
    alt="paypal icon"
    data-qa={IconsQA.PaypalIcon}
    width={80}
    height={24}
  />
}

export default PayPalIcon
