// Country
const COUNTRY_US = Object.freeze({
  aliasSupported: true,
  countryCode: '1',
  currency: 'USD',
  flag: '🇺🇸',
  isoCode: 'US',
  name: 'United States',
  returnsFrom: true,
  shipsTo: true,
  sizeUnit: 'us',
  stateRequired: true,
  taxIncluded: false,
  zipRequired: true,
})

export const DEFAULT_COUNTRY_CODE = COUNTRY_US.isoCode
export const DEFAULT_COUNTRY_NAME = COUNTRY_US.name
export const DEFAULT_COUNTRY = { display: COUNTRY_US.name, value: COUNTRY_US.isoCode }

// Currency
const CURRENCY_USD = Object.freeze({
  decimalMark: '.',
  disambiguatedSymbol: 'US$',
  hasNoCents: false,
  isoCode: 'USD',
  name: 'United States Dollar',
  rate: 1,
  symbol: '$',
  symbolFirst: true,
  thousandsSeparator: ',',
})

export const DEFAULT_CURRENCY_CODE = CURRENCY_USD.isoCode
export const DEFAULT_CURRENCY = CURRENCY_USD

// Language
const LANGUAGE_ENGLISH = Object.freeze({
  value: 'en',
  name: 'English',
})

export const DEFAULT_LANGUAGE = { display: LANGUAGE_ENGLISH.name, value: LANGUAGE_ENGLISH.value }

// Cookie storage
export const STORAGE = {
  CURRENCYCODE: 'currencyCode',
  COUNTRY: 'country',
  COUNTRYNAME: 'countryName',
  CURRENCY: 'currency',
  CHECKOUT_ERROR: 'checkoutError',
  CURRENCY_OVERWRITTEN: 'currencyOverwritten',
  LOCATION_CONFIRMATION_NEEDED: 'locationConfirmationNeeded',
  LOCALE: 'locale',
}
