import { FC } from 'react'
import { Button } from 'shared/components/Button'
import { renderUntil } from 'shared/hocs/render'
import { colors } from 'shared/lib'
import SideSheet from 'shared/components/SideSheet'
import styled from 'styled-components'

import ClearRefinementsButton from '../ClearRefinementsButton'
import FiltersSection from './FiltersSection'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'search.components.SearchFilters.MobileSearchFiltersLayout'

const FilterButton = styled(Button)`
  width: 130px !important;
  height: 30px !important;
  min-height: 30px;
  line-height: 30px !important;
  background-color: ${colors.FC2_RED};
  border: none;
`

const FilterButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface MobileSearchFiltersProps {
  show: boolean
  toggle: () => void
}

const MobileSearchFilters: FC<MobileSearchFiltersProps> = ({ show, toggle }) => {
  return (
    <SideSheet.MobileOnly onClose={toggle} isVisible={show}>
      <SideSheet.Actions>
        <FilterButtonsWrapper>
          <ClearRefinementsButton />
          <FilterButton
            qaAttr={SearchQA.MobileViewResultsButton}
            buttonType="primary2"
            onClick={toggle}
          >
            {t(`${TP}.viewResults`, 'View Results')}
          </FilterButton>
        </FilterButtonsWrapper>
      </SideSheet.Actions>
      <SideSheet.Body>
        <FiltersWrapper>
          <FiltersSection />
        </FiltersWrapper>
      </SideSheet.Body>
    </SideSheet.MobileOnly>
  )
}

MobileSearchFilters.displayName = 'MobileSearchFilters'

export default renderUntil.large(MobileSearchFilters)
