import Bugsnag from '@bugsnag/js'
import { DefaultError } from 'errors/components/DefaultError'
import { Component, ErrorInfo } from 'react'

export class GenericErrorBoundary extends Component<unknown, { hasError: boolean }> {
  constructor(props: unknown) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Bugsnag.notify(error, (event) => {
      event.severity = 'error'
      event.addMetadata('Error Boundary', { error: errorInfo })
    })
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return <DefaultError />
    }

    const { children } = this.props

    return children
  }
}
