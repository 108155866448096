import Bugsnag from '@bugsnag/js'
import Script from 'next/script'

const OneTrustTag = () => {
  const dataDomainScript =
    process.env.NODE_ENV === 'production'
      ? 'be58f70f-be59-46ac-bf8e-aa62756e4867'
      : 'be58f70f-be59-46ac-bf8e-aa62756e4867-test'

  return (
    <>
      <Script
        id="onetrust-script-1"
        key="oneTrust"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={dataDomainScript}
        onError={(error) => {
          Bugsnag.notify(new Error(`OneTrust CDN Cookie Law Script Erroring: ${error.message}`))
        }}
        strategy="lazyOnload"
      />
      <Script
        id="onetrust-script-2"
        key="oneTrustWrapper"
        onError={(error) => {
          Bugsnag.notify(new Error(`OneTrust Inline Script Erroring: ${error.message}`))
        }}
        strategy="lazyOnload"
      >
        {`          
          /**
           * OptanonWrapper is a function that is called by the OneTrust script
           * on every page load or when user changes the cookie settings.
           * 
           * Ref: One Trust - Client Side Cookie Management
           * https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?topicId=0TO1Q000000ssJBWAY
           *
           */
          function OptanonWrapper() {
            // Check if active groups from OneTrust allow C0002 category cookies.
            const enableC0002 = window.OnetrustActiveGroups?.includes('C0002') || false;
            window.enableC0002Scripts(enableC0002);
          }

          /**
           * Function to enable or disable C0002 scripts. 
           * If enabled and not executed yet, trigger each script.
           * An 'OK' flag is set for each script to signal if it can be interacted with.
           */
          window.enableC0002Scripts = (isAllowed) => {
            window.C0002Scripts?.forEach(function (config) {
              if (isAllowed && !config.isAddedToPage) {
                config.script();
              }
              window[config.scriptId + '_OK'] = isAllowed;
            });
          };    
        `}
      </Script>
    </>
  )
}

export default OneTrustTag
