import { FC } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Card as BaseCard } from 'shared/components/CardDeprecated'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'

import { IRefinementListProps } from '../IRefinementListProps'
import DesktopModelFilter from './DesktopModelFilter'
import MobileModelFilter from './MobileModelFilter'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'

const Card = styled(BaseCard)<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`
const ModelFilter: FC<IRefinementListProps> = ({ items, refine, attribute, hide }) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value[value.length - 1],
    })
    refine(value)
  }
  return items.length > 0 ? (
    <Card
      title={SearchFiltersHumanizedRefinement()[attribute]}
      isExpanded
      hide={hide}
      qaAttr={SearchQA.FilterModel}
    >
      <MobileModelFilter items={items} refine={handleRefinement} />
      <DesktopModelFilter items={items} refine={handleRefinement} />
    </Card>
  ) : null
}

ModelFilter.displayName = 'ModelFilterContainer'

export default connectRefinementList(ModelFilter)
