import { connectPagination } from 'react-instantsearch-dom'
import { SVGIcon } from 'shared/components/Icons'
import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'
import { SearchQA } from 'shared/dataAttributes'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { t } from 'localization'

const TP = 'search.components.SearchPagination'

const ButtonLabel = styled.span`
  ${fonts.LINK}
  margin: 0px 5px;
`
const ClearButton = styled.button<{ disabled: boolean }>`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  ${ButtonLabel} {
    color: ${({ disabled }) => (disabled ? colors.FC2_LIGHT_GREY : colors.FC2_BLACK)};
  }
`
const PaginationList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style: none;
  padding: 40px 0;
  margin: 0;
`
const PaginationListItem = styled.li`
  display: inline-block;
`

const CurrentPageLabel = styled.span`
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_BLACK};
`

const Pagination = ({
  currentRefinement,
  nbPages,
  refine,
}: {
  currentRefinement: number
  nbPages: number
  refine: (a: number) => void
}) => {
  const currentPage = currentRefinement
  const noResults = nbPages === 0
  const isPreviousDisabled = noResults || currentPage === 1
  const isNextDisabled = noResults || currentPage === nbPages
  const getButtonColor = (isDisabled: boolean) =>
    isDisabled ? colors.FC2_LIGHT_GREY : colors.FC2_BLACK
  const previousButtonColor = getButtonColor(isPreviousDisabled)
  const nextButtonColor = getButtonColor(isNextDisabled)

  const handlePreviousClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const previousPage = currentRefinement - 1
    if (previousPage > 0) {
      sendTrackingEvent('PAGE_NUMBER_NAV_TAP', {
        page: window?.location?.href,
        page_number: previousPage,
      })
      document.body.scrollTop = 0
      refine(previousPage)
    }
  }
  const handleNextClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const nextPage = currentRefinement + 1
    if (nextPage <= nbPages) {
      sendTrackingEvent('PAGE_NUMBER_NAV_TAP', {
        page: window?.location?.href,
        page_number: nextPage,
      })
      document.body.scrollTop = 0
      refine(nextPage)
    }
  }

  return (
    <PaginationList>
      <PaginationListItem>
        <ClearButton
          data-qa={SearchQA.GridBackLeftArrow}
          disabled={isPreviousDisabled}
          onClick={handlePreviousClick}
        >
          <SVGIcon color={previousButtonColor} name="caret" direction="right" />
          <ButtonLabel>{t(`${TP}.back`, 'Back')}</ButtonLabel>
        </ClearButton>
      </PaginationListItem>
      <PaginationListItem>
        <CurrentPageLabel data-qa={SearchQA.GridPaginationCount}>
          {t(`${TP}.pageCounter`, {
            defaultValue: '{currentPage} of {nbPages}',
            currentPage,
            nbPages: nbPages === 0 ? 1 : nbPages,
          })}
        </CurrentPageLabel>
      </PaginationListItem>
      <PaginationListItem>
        <ClearButton
          data-qa={SearchQA.GridNextRightArrow}
          disabled={isNextDisabled}
          onClick={handleNextClick}
        >
          <ButtonLabel>{t(`${TP}.next`, 'Next')}</ButtonLabel>
          <SVGIcon color={nextButtonColor} name="caret" direction="left" />{' '}
        </ClearButton>
      </PaginationListItem>
    </PaginationList>
  )
}

const CustomPagination = connectPagination(Pagination)

export default CustomPagination
