/* tslint:disable:max-line-length */
enum HeaderQA {
  /// MAIN HEADER
  // DESKTOP
  DesktopSellLink = 'DesktopSellLink',
  DesktopCurrencyLink = 'DesktopCurrencyLink',
  DesktopAccountLink = 'DesktopAccountLink',
  DesktopShowAll = 'DesktopShowAll', // DO NO USE as it is, use Auto-generated names
  // DesktopShowAllAirJordansLink // Auto-generated
  // DesktopShowAllNikeLink // Auto-generated
  // DesktopShowAllAdidasLink // Auto-generated
  // DesktopShowAllYeezyLink // Auto-generated
  // DesktopShowAllMoreSneakersLink // Auto-generated
  DesktopNavigationLink = 'DesktopNavigationLink', // NODE LIST
  // DesktopAirJordans // Auto-generated - NODE LIST
  // DesktopNike // Auto-generated - NODE LIST
  // DesktopNikeChildren // Auto-generated - NODE LIST
  // DesktopAdidas // Auto-generated - NODE LIST
  // DesktopYeezy // Auto-generated - NODE LIST
  // DesktopMoreSneakers // Auto-generated - NODE LIST
  // DesktopMoreSneakersChildren // Auto-generated - NODE LIST
  // MOBILE
  MobileSearchIcon = 'MobileSearchIcon',
  MobileHamburgerMenu = 'MobileHamburgerMenu',
  MobileSellLink = 'MobileSellLink',
  MobileAccount = 'MobileAccount', // DO NO USE as it is, use Auto-generated names
  // MobileAccountSectionHeader // Auto-generated
  MobileCurrency = 'MobileCurrency', // DO NO USE as it is, use Auto-generated names
  // MobileCurrencySectionHeader // Auto-generated
  MobileParent = 'MobileParent', // DO NO USE as it is, use Auto-generated names
  // MobileParentSectionHeader // Auto-generated
  MobileChildrenLink = 'MobileChildrenLink',
  MobileChildrenShopAllLink = 'MobileChildrenShopAllLink',
  // COMMON FOR BOTH DESKTOP AND MOBILE
  HeaderFlightClubHome = 'HeaderFlightClubHome',
  AccountMyAccountLink = 'AccountMyAccountLink',
  AccountMyOrderLink = 'AccountMyOrderLink',
  AccountTrackMyOrderLink = 'AccountTrackMyOrderLink',
  AccountContactSupportLink = 'AccountContactSupportLink',
  AccountSignInRegisterLink = 'AccountSignInRegisterLink',
  AccountSignOut = 'AccountSignOut',
  AccountLoginCopyText = 'AccountLoginCopyText',
  /// CHECKOUT HEADER
  CheckoutFlightClubHome = 'CheckoutFlightClubHome',
  CheckoutHelpLink = 'CheckoutHelpLink',
  /// CURRENCY
  CurrencyDropdown = 'CurrencyDropdown',
  CurrencyTitleHeader = 'CurrencyTitleHeader',
  CurrencyCopyText = 'CurrencyCopyText',
  CurrencySaveChangesButton = 'CurrencySaveChangesButton',
}

export default HeaderQA
