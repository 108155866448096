import styled from 'styled-components'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useDeleteWantMutation } from 'api/wantsApi'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import Button from 'shared/components/Button'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { useClickOutside } from 'shared/hooks'
import { colors } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsDeleteOfferModalOpen } from 'store/offersSlice'

export const DeleteOfferModal = () => {
  const TP = 'offers.components.DeleteOfferModal'
  const dispatch = useAppDispatch()
  const ref = useClickOutside(() => dispatch(setIsDeleteOfferModalOpen(false)))
  const { offerToBeRemoved } = useAppSelector((state) => state.offers)
  const [deleteOffer, { isLoading }] = useDeleteWantMutation()

  const handleClose = () => {
    dispatch(setIsDeleteOfferModalOpen(false))
  }

  const handleDeleteClick = async () => {
    if (offerToBeRemoved) {
      sendTrackingEvent('OFFER_DELETE_CONFIRM_TAP', {
        product_template_slug: offerToBeRemoved.slug,
        size: offerToBeRemoved.size,
        want_id: offerToBeRemoved.wantId,
        location: 'global_favorite_panel',
      })

      try {
        await deleteOffer({ wantId: offerToBeRemoved.wantId })
      } catch (e) {
        Bugsnag.notify(e as NotifiableError)
      }
    }

    dispatch(setIsDeleteOfferModalOpen(false))
  }

  return (
    <ClientOnlyPortal selector=".main-page-layout" itemsAlignment="center" id="delete-size-modal">
      <LoginPanelWrapper ref={ref} onMouseUp={(e) => e.stopPropagation()}>
        <DeletePrompt>
          <DeletePromptCTA data-qa="DeleteOfferModalPromptCopy">
            {t(`${TP}.deletePromptCta`, 'Are you sure you want to delete your offer?')}
          </DeletePromptCTA>
          <CloseButton data-qa="DeleteOfferModalCloseButton" onClick={handleClose}>
            <CloseX width="15" height="15" />
          </CloseButton>
        </DeletePrompt>
        <Button
          disabled={isLoading}
          $fill
          onClick={handleDeleteClick}
          data-qa="DeleteOfferModalDeleteButton"
        >
          {t(`${TP}.deleteButtonText`, 'Delete Offer')}
        </Button>
      </LoginPanelWrapper>
    </ClientOnlyPortal>
  )
}

const LoginPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  width: 425px;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_BLACK};
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  > button {
    width: 385px;
    margin: auto 20px 20px 20px;
  }
  @media (max-width: ${sizes.large / 16}em) {
    height: 150px;
    width: 335px;
    > button {
      width: 295px;
    }
  }
`

const DeletePrompt = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`

const DeletePromptCTA = styled.div`
  @media (max-width: ${sizes.large / 16}em) {
    width: 250px;
    height: 38px;
  }
`
const CloseButton = styled.button`
  cursor: pointer;
  width: 15px;
  height: 15px;
`
