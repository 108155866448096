import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AlgoliaIndex } from 'search/enums/search'
import Image from 'shared/components/Image'
import { BodyText } from 'shared/text'
import { colors } from 'shared/theme'
import styled from 'styled-components'
import { UnstyledLink } from '../components/MenuLink'

interface SearchItemProps {
  className?: string
  href: string
  imageUrl: string
  title: string
  qaAttr?: string
  searchText: string
}

const SearchItem = ({ className, href, imageUrl, title, qaAttr, searchText }: SearchItemProps) => {
  // mParticle event params
  const router = useRouter()
  const eventContext = searchText?.length === 0 ? 'search_bar_popular' : 'search_bar_top_results'
  const slug = href.substring(1)

  const handlePdpAnalytics = () => {
    const urlParams = router.query
    const filterParams = [
      'brand',
      'model',
      'color',
      'condition',
      'size_men',
      'size_women',
      'size_youth',
      'price_cents_usd_max',
      'price_cents_usd_min',
    ]

    const isFiltered = filterParams.filter((filter) => filter in urlParams).length > 0

    const selectedSort = (() => {
      if (urlParams.sortBy === AlgoliaIndex.Relevance) return 'relevance'
      if (urlParams.sortBy === AlgoliaIndex.PriceLowHigh) return 'price_low'
      if (urlParams.sortBy === AlgoliaIndex.PriceHighLow) return 'price_high'
      if (urlParams.sortBy === AlgoliaIndex.Newest) return 'newest'
      if (urlParams.sortBy === AlgoliaIndex.Trending) return 'trending'
      return 'relevance'
    })()

    sendTrackingEvent('PDP_TAP', {
      page: window?.location?.href,
      product_template_slug: slug,
      is_filtered: isFiltered,
      sort_by: selectedSort,
      event_context: eventContext,
      search_string: searchText,
      service_name: 'algolia',
    })
  }

  return (
    <Link href={href} passHref legacyBehavior>
      <UnstyledLink className={className} onClick={() => handlePdpAnalytics()}>
        <Container data-qa={qaAttr}>
          <ImageContainer>
            <Image
             src={imageUrl}
             alt={title}
             sizes="80px"
             width={80}
             height={57}
            />
          </ImageContainer>
          <Title>{title}</Title>
        </Container>
      </UnstyledLink>
    </Link>
  )
}

const Title = styled(BodyText)`
  flex: 1;
  color: ${(props) => props.theme.textColor};
`

const Container = styled.span`
  display: flex;
  align-items: center;
  height: 80px;
  cursor: pointer;
  :hover {
    ${Title} {
      color: ${colors.darkGray};
    }
  }
`

const ImageContainer = styled.div`
  width: 80px;
  margin-right: 35px;
`

export default SearchItem
