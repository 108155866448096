import { EventAttributes } from 'mParticle/types/Event'

export const checkAttributesAgainstSchema = (
  eventAttributes: EventAttributes,
  schemaAttributes: EventAttributes[] | undefined,
) => {
  const schemaAttributeNames = new Set(
    !schemaAttributes ? [] : schemaAttributes.map(({ name }) => name),
  )
  const matchingEventAttributes = Object.keys(eventAttributes).filter(attr =>
    schemaAttributeNames.has(attr),
  )
  if (matchingEventAttributes.length === 0) {
    return false
  }
  return true
}
