import { FC } from 'react'
import { Checkbox } from 'shared/components/Checkbox'
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'
import { IRefinementListProps } from '../IRefinementListProps'
import { SearchQA } from 'shared/dataAttributes'

const ConditionsCardContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  padding: 20px;
`

const DesktopBrandFilter: FC<IRefinementListProps> = ({ items, refine }) => {
  return (
    <ConditionsCardContent>
      {items.map(({ label, value, isRefined }) => (
        <Checkbox
          key={label}
          label={label}
          onChange={() => {
            refine(value)
          }}
          checked={isRefined}
          qaAttr={SearchQA.DesktopFilterBrand}
        />
      ))}
    </ConditionsCardContent>
  )
}

DesktopBrandFilter.displayName = 'DesktopBrandFilter'

export default renderStart.large(DesktopBrandFilter)
