import { GROUP_NAME } from 'experiments/constants/Experiments'

export enum ExperimentActions {
  assignGroup = 'assignGroup',
  logExperiment = 'logExperiment',
  setError = 'setError',
  startExperiment = 'startExperiment',
}

type ExperimentStatePayload = ExperimentStateValue & {
  experimentName: string
}

type ExperimentAction = {
  type: keyof typeof ExperimentActions
  payload?: ExperimentStatePayload
}

export type ExperimentDispatch = (action: ExperimentAction) => void

export type ExperimentStateValue = {
  error?: string | null
  groupAssigned?: GROUP_NAME | null
  hasLoggedExperiment?: boolean
}

/**
 * ExperimentState is represented as a Map structure. The Key is the experiment name and
 * the Value is an object structured as ExperimentStateValue.
 * Example ExperimentState:
 * {
 *   web_fc_experiment1: {
 *     error: null,
 *     groupAssigned: 'control',
 *     hasLoggedExperiment: true,
 *   },
 *   web_fc_experiment2: {
 *     error: null,
 *     groupAssigned: 'test',
 *     hasLoggedExperiment: false,
 *   },
 * }
 */
export type ExperimentState = {
  [experiment: string]: ExperimentStateValue
}

export const ExperimentReducer = (state: ExperimentState, action: ExperimentAction) => {
  switch (action.type) {
    case ExperimentActions.assignGroup:
      if (action.payload) {
        state[action.payload.experimentName].groupAssigned = action?.payload?.groupAssigned
      }
      break
    case ExperimentActions.logExperiment:
      if (action.payload) {
        state[action.payload.experimentName].hasLoggedExperiment = true
      }
      break
    case ExperimentActions.setError:
      if (action.payload) {
        state[action.payload.experimentName].error = action?.payload?.error
      }
      break
    case ExperimentActions.startExperiment:
      if (action.payload) {
        state[action.payload.experimentName] = {
          error: null,
          groupAssigned: null,
          hasLoggedExperiment: false,
        }
      }
      break
    default:
      return state
  }
  return state
}
