/* tslint:disable:max-line-length */
enum SearchQA {
  // CARDS
  ProductItems = 'ProductItems',
  ProductItemsUrl = 'ProductItemsUrl',
  ProductItemThumbnail = 'ProductItemThumbnail',
  ProductItemBrandName = 'ProductItemBrandName',
  ProductItemTitle = 'ProductItemTitle',
  ProductItemPrice = 'ProductItemPrice',
  // GRID
  GridResultCount = 'GridResultCount',
  GridSortByFilter = 'GridSortByFilter',
  GridBackLeftArrow = 'GridBackLeftArrow',
  GridPaginationCount = 'GridPaginationCount',
  GridNextRightArrow = 'GridNextRightArrow',
  DesktopGridAddFilterLabel = 'DesktopGridAddFilterLabel',
  MobileGridAddFilterButton = 'MobileGridAddFilterButton',
  // FILTERS
  GridClearFiltersLink = 'GridClearFiltersLink',
  MobileViewResultsButton = 'MobileViewResultsButton',
  FilterModel = 'FilterModel', // DO NO USE as it is, use Auto-generated names
  // FilterModelSectionHeader // Auto-generated
  DesktopFilterModel = 'DesktopFilterModel', // DO NO USE as it is, use Auto-generated names
  // DesktopFilterModelLabel // Auto-generated [node]
  // DesktopFilterModelCheckbox // Auto-generated [node]
  MobileFilterModelLabel = 'MobileFilterModelLabel',
  FilterBrand = 'FilterBrand', // DO NO USE as it is, use Auto-generated names
  // FilterBrandSectionHeader // Auto-generated
  DesktopFilterBrand = 'DesktopFilterBrand', // DO NO USE as it is, use Auto-generated names
  // DesktopFilterBrandLabel // Auto-generated [node]
  // DesktopFilterBrandCheckbox // Auto-generated [node]
  MobileFilterBrandLabel = 'MobileFilterBrandLabel',
  Filter = 'Filter', // DO NO USE as it is, use Auto-generated names
  // FilterMen // Auto-generated
  // FilterMenSectionHeader // Auto-generated
  // FilterWomen // Auto-generated
  // FilterWomenSectionHeader // Auto-generated
  // FilterYouth // Auto-generated
  // FilterYouthSectionHeader // Auto-generated
  FilterGenderOptions = 'FilterGenderOptions', // DO NO USE as it is, use Auto-generated names
  // FilterGenderOptionsMen // Auto-generated [node]
  // FilterGenderOptionsWomen // Auto-generated [node]
  // FilterGenderOptionsYouth // Auto-generated [node]
  FilterPrice = 'FilterPrice', // DO NO USE as it is, use Auto-generated names
  // FilterPriceSectionHeader // Auto-generated
  FilterPriceMinAmount = 'FilterPriceMinAmount',
  FilterPriceMaxAmount = 'FilterPriceMaxAmount',
  FilterPriceApplyButton = 'FilterPriceApplyButton',
  FilterCondition = 'FilterCondition', // DO NO USE as it is, use Auto-generated names
  // FilterConditionSectionHeader // Auto-generated
  DesktopFilterCondition = 'DesktopFilterCondition', // DO NO USE as it is, use Auto-generated names
  // DesktopFilterConditionLabel // Auto-generated [node]
  // DesktopFilterConditionCheckbox // Auto-generated [node]
  MobileFilterConditionLabel = 'MobileFilterConditionLabel',
  FilterColor = 'FilterColor', // DO NO USE as it is, use Auto-generated names
  // FilterColorSectionHeader // Auto-generated
  FilterColorLabel = 'FilterColorLabel',
  FilterYear = 'FilterYear', // DO NO USE as it is, use Auto-generated names
  // FilterYearSectionHeader // Auto-generated
  FilterYearMinAmount = 'FilterYearMinAmount',
  FilterYearMaxAmount = 'FilterYearMaxAmount',
  FilterYearApplyButton = 'FilterYearApplyButton',
  // Breadcrumbs
  BreadcrumbLinkFullPath = 'PlpBreadcrumbLinkFullPath',
  BreadcrumbPathName = 'PlpBreadcrumbPathName',
  BreadcrumbSearchName = 'PlpBreadcrumbSearchName',
  // ERROR Pages
  GridTitleNameTrending = 'GridTitleNameTrending',
  GridShopAllTrendingLink = 'GridShopAllTrendingLink',
}

export default SearchQA
