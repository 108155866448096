import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { sha1 } from 'js-sha1'

type TrackImpactConversionProps = {
  orderNumber?: number
  customerId?: number
  customerEmail?: string
  currencyCode?: string
  promoCode?: string
  orderDiscount?: number
  productPrice?: number
  productCategory?: string
  productSku?: string
  productName?: string
}

type TrackImpactIdentifyProps = {
  id?: number
  email?: string
}

export const trackImpactConversion = ({
  currencyCode,
  customerEmail,
  customerId,
  orderDiscount,
  orderNumber,
  productCategory,
  productName,
  productPrice,
  productSku,
  promoCode,
}: TrackImpactConversionProps) => {
  if (typeof window === 'undefined' || !window?.ire) return

  try {
    const conversionData = {
      currencyCode: currencyCode || '',
      customerEmail: customerEmail ? sha1(customerEmail) : '',
      customerId: customerId || '',
      customerStatus: '',
      orderId: orderNumber || '',
      orderPromoCode: promoCode || '',
      orderDiscount: orderDiscount || '',
      items: [
        {
          category: productCategory || '',
          name: productName || '',
          quantity: 1,
          sku: productSku || '',
          subTotal: productPrice || '',
        },
      ],
    }

    return window.ire('trackConversion', 23253, conversionData, { verifySiteDefinitionMatch: true })
  } catch (error) {
    return Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'info'
    })
  }
}

export const trackImpactIdentify = (user: TrackImpactIdentifyProps) => {
  if (typeof window === 'undefined' || !window?.ire) {
    return
  }

  try {
    const sha1Email = user.email ? sha1(user.email) : ''
    window.ire('identify', {
      customerId: user.id || '',
      customerEmail: sha1Email,
    })
  } catch (error) {
    Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'warning'
    })
  }
}
