/* tslint:disable:max-line-length */

enum GlobalQA {
  SideSheetOverlay = 'SideSheetOverlay',
  SideSheetCloseIcon = 'SideSheetCloseIcon',
  SideSheetTitle = 'SideSheetTitle',
  SideSheetSubTitle = 'SideSheetSubTitle',
  SideSheetSaveButton = 'SideSheetSaveButton',
  SideSheetContent = 'SideSheetContent',
}

export default GlobalQA
