import styled from 'styled-components'
import { colors } from 'shared/theme'
import X from 'shared/svg/x.svg'

const RedX = styled(X)`
  color: ${colors.red};
  height: 10px;
  width: 10px;
`

export default RedX
