import getConfig from 'next/config'
/**
 * [includeGlobalEventAttributes]
 * Takes an event payload object, and returns a new object that includes the input items
 * plus any common attributes that should be included in ALL tracking events (such as app_version.)
 * @returns {Object}
 */
const includeGlobalEventAttributes = (attributes: any = {}): any => {
  const { publicRuntimeConfig } = getConfig()

  return {
    ...attributes,
    appVersion: publicRuntimeConfig.appVersion,
  }
}

export default includeGlobalEventAttributes
