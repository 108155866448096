import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface IHyperLinks {
  canonicalUrl: string
  currentPath: string
}

const HyperLinks: FC<IHyperLinks> = ({ canonicalUrl }) => {
  const router = useRouter()
  return (
    <Head>
      <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
      <link rel="manifest" href="/static/manifest.json" />
      <link
        rel="canonical"
        href={router?.query?.page ? `${canonicalUrl}?page=${router?.query?.page}` : canonicalUrl}
        key="canonical"
      />
    </Head>
  )
}

HyperLinks.displayName = 'HyperLinks'
export default HyperLinks
