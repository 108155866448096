import Bugsnag from '@bugsnag/js'
import getConfig from 'next/config'
import Script from 'next/script'
import { useEffect } from 'react'
import { useUser } from 'shared/hooks/useUser'

type SnapchatPixelProps = {
  isOneTrustEnabled?: boolean
}

const SnapchatPixel = ({ isOneTrustEnabled }: SnapchatPixelProps) => {
  const { currentUser } = useUser()
  const {
    publicRuntimeConfig: { SNAPCHAT_PIXEL_ID },
  } = getConfig()

  /**
   * NOTE: Reinitialize snapchat user email when user has allowed advertising
   * trackers so that we are sending the most up to date data to Snapchat.
   *
   * This is the only time we can map the user email with the tracking events
   * https://businesshelp.snapchat.com/s/article/pixel-website-install?language=en_US
   */
  useEffect(() => {
    if (!window?.snaptr || !window?.OptanonActiveGroups?.includes('C0004')) return

    window?.snaptr('init', SNAPCHAT_PIXEL_ID, { user_email: currentUser?.email })
  }, [currentUser?.email])

  return (
    <Script
      async
      className="optanon-category-C0004"
      id="snapchat-script"
      key="snapchat"
      onError={(error) => {
        Bugsnag.notify(error, (event) => {
          event.context = 'Snapchat Inline Script Erroring'
        })
      }}
      strategy="lazyOnload"
      type={isOneTrustEnabled ? 'text/plain' : 'text/javascript'}
    >
      {`
        (function(e, t, n) {
          if (e.snaptr) return;
          var a = e.snaptr = function() {
            a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
          };
          a.queue = [];
          var s = 'script';
          r = t.createElement(s);
          r.async = !0;
          r.src = n;
          var u = t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r, u);
        })(window, document, 'https://sc-static.net/scevent.min.js');

        snaptr('init', '${SNAPCHAT_PIXEL_ID as string}', { 'user_email': '${
        currentUser?.email || ''
      }'});
      `}
    </Script>
  )
}

export default SnapchatPixel
