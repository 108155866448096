import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

import { LocationPreferencesModal } from './LocationPreferencesModal'

import { STORAGE } from 'shared/enums/SitePreferences'
import useCurrency from 'shared/hooks/useCurrency'
import { colors } from 'shared/lib'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { ClientOnly } from '../ClientOnly'

/**
 * This is the stateful button that encapsulates all the necessary functionality
 * for the button and modal to work in the app.
 *
 * Use the exported StyledCurrencyButton to mock the button inside Storybook.
 */
const CurrencySelectButton = () => {
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false)

  const { countryName, currencyCode, availableCurrencies } = useShoppingRegionContext()
  const { getCurrenciesErrors } = useCurrency()

  const { locale } = useRouter()

  const sixMonthExpiration = new Date()
  sixMonthExpiration.setMonth(sixMonthExpiration.getMonth() + 6)

  const [errorMessage, setErrorMessage] = useState(
    !!getCurrenciesErrors?.graphQLErrors?.length
      ? getCurrenciesErrors.graphQLErrors[0]?.message
      : '',
  )

  const openCurrencyModal = () => {
    const currencyCookie = Cookies.get(STORAGE.CURRENCYCODE)
    const selectedCurrencySymbolFromCode = availableCurrencies?.filter(
      currency => currency.isoCode === currencyCookie,
    )[0]
    sendTrackingEvent('LOCATION_PREFERENCES_VIEW', {
      location: 'footer',
      page: window?.location?.href,
      shopping_region: Cookies.get(STORAGE.COUNTRYNAME),
      currency: `${currencyCookie}${selectedCurrencySymbolFromCode?.symbol}`,
      language: '',
    })
    setIsCurrencyModalOpen(true)
  }
  const closeCurrencyModal = () => {
    setIsCurrencyModalOpen(false)
    setErrorMessage('')
  }

  // TODO: As more locales get added change this into a reusable function
  // with a locale map
  useEffect(() => {
    // Check the cookie sent from regionPreferenceMiddleware to ensure modal shows on first render
    // but also set sessionStorage so it wont show on additional renders
    // but will once the sessionStorage is cleared
    const sessionConfirmationNeeded = Cookies.get('locationConfirmationNeeded')
    const prevCountry = Cookies.get('prevCountry')
    const sessionConfirmed = sessionStorage.getItem('locationConfirmed')
    if (
      prevCountry !== 'JP' &&
      sessionConfirmationNeeded === 'true' &&
      sessionConfirmed === null &&
      (locale?.toLowerCase() === 'ja-jp' || locale?.toLowerCase() === 'en-jp')
    ) {
      openCurrencyModal()
      sessionStorage.setItem('locationConfirmed', 'true')
      Cookies.set('locationConfirmationNeeded', 'false', { expires: sixMonthExpiration })
      Cookies.set('prevCountry', 'JP')
    }
  }, [])
  // TODO END

  return (
    <>
      <ClientOnly>
        <StyledCurrencyButton
          aria-label="Select Currency"
          title="Select Currency"
          onClick={openCurrencyModal}
          data-testid="currencySelectButton"
          data-qa="currencySelectButton"
        >
          {`${countryName} / ${currencyCode}`}
        </StyledCurrencyButton>
      </ClientOnly>
      <LocationPreferencesModal
        isOpen={isCurrencyModalOpen}
        analyticsLocation="footer"
        closeCurrencyModal={closeCurrencyModal}
        errorMessage={errorMessage}
        data-testid="currencyModal"
      />
    </>
  )
}

export const StyledCurrencyButton = styled.button`
  padding: 10px 24px;
  border-radius: 30px;
  border: 1.5px solid ${colors.FC2_DARK_GREY};
`

export default CurrencySelectButton
