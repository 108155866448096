import { reduce, get, pipe, equals } from 'lodash/fp'
import { IErrorDTO } from 'errors/types/IErrorDTO'

export enum ApolloErrorCodes {
  BAD_INPUT = 'BAD_USER_INPUT',
  VALIDATION = 'GRAPHQL_VALIDATION_FAILED',
  INTERNAL_SERVER = 'INTERNAL_SERVER_ERROR',
}

const isInternalServerError = pipe(get('extensions.code'), equals(ApolloErrorCodes.INTERNAL_SERVER))

const isUserInputError = pipe(get('extensions.code'), equals(ApolloErrorCodes.BAD_INPUT))

const isValidationError = pipe(get('extensions.code'), equals(ApolloErrorCodes.VALIDATION))

export const getErrorList = (graphQLErrors: IErrorDTO[]) => {
  return reduce(
    (acc, err) => {
      if (isInternalServerError(err)) {
        acc.push({
          code: err.errorCode,
          message: err.message,
        })
      }
      if (isUserInputError(err)) {
        acc.push({
          code: ApolloErrorCodes.BAD_INPUT,
          message: err.message,
        })
      }
      if (isValidationError(err)) {
        acc.push({
          code: ApolloErrorCodes.VALIDATION,
          message: err.message,
        })
      }
      return acc
    },
    [],
    graphQLErrors,
  )
}

export default getErrorList
