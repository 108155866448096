import { apiSlice } from 'api/apiSlice'
import { Want, WantOrder, WantResponse } from 'offers/types'

export const wantsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createWant: builder.mutation<WantResponse, Want>({
      query: (body) => ({
        app: 'sneakers',
        data: { want: body },
        url: '/web-api/v1/wants',
        method: 'POST',
      }),
    }),
    createWantOrder: builder.mutation<WantOrder, { wantId: number }>({
      query: ({ wantId }) => ({
        app: 'sneakers',
        data: { order: { wantId } },
        url: '/web-api/v1/orders',
        method: 'POST',
      }),
    }),
    activateWantOrder: builder.mutation<WantOrder, { wantId: number }>({
      query: ({ wantId }) => ({
        app: 'sneakers',
        url: `/web-api/v1/wants/${wantId}/activate`,
        method: 'POST',
      }),
    }),
    updateWant: builder.mutation<
      WantResponse,
      { wantId: string; offerAmountCents: number; offerDuration: number }
    >({
      query: (body) => ({
        app: 'sneakers',
        data: {
          want: {
            offerAmountCents: body.offerAmountCents,
            offerDuration: body.offerDuration,
            resetOfferStartTime: 1,
          },
        },
        url: `/web-api/v1/wants/${body.wantId}`,
        method: 'PUT',
      }),
    }),
    deleteWant: builder.mutation<WantResponse, { wantId: number }>({
      query: ({ wantId }) => ({
        app: 'sneakers',
        url: `/web-api/v1/wants/${wantId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateWantMutation,
  useCreateWantOrderMutation,
  useActivateWantOrderMutation,
  useUpdateWantMutation,
  useDeleteWantMutation,
} = wantsApi
