import { css } from 'styled-components'

export const fadeInStyles = css`
  animation: fadeIn ease-out 200ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
