import { apiSlice } from 'api/apiSlice'
import { OffersListResponse } from 'offers/types'
import {
  PTItemWant,
  PTListsByPTResponse,
  PTListsProductTemplate,
} from 'shared/types/ProductTemplateLists'

export const productTemplateListsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserFavoritesForPT: builder.query<
      { sizes: number[]; productTemplate: PTListsProductTemplate },
      { productTemplateId: string; currentUserId: number | undefined }
    >({
      query: ({ productTemplateId }) => ({
        app: 'product-template-lists',
        url: `/web-api/v1/product-template-lists/get-favorites/${productTemplateId}?timestamp=${new Date().getTime()}`,
        method: 'GET',
      }),
      providesTags: ['Favorites'],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.currentUserId !== previousArg?.currentUserId
      },
    }),
    saveUserFavorites: builder.mutation<
      { success: boolean },
      { sizes: number[]; productTemplateId: string }
    >({
      query: (body) => ({
        app: 'product-template-lists',
        data: body,
        method: 'POST',
        url: `/web-api/v1/product-template-lists/save-favorites`,
      }),
      invalidatesTags: ['Favorites'],
    }),
    fetchAllUserFavorites: builder.query<
      {
        listItems: PTListsByPTResponse<PTItemWant>[]
        itemsPerPage: number
        paginationToken?: string
      },
      { paginationToken?: string }
    >({
      query: ({ paginationToken = '' }) => ({
        app: 'product-template-lists',
        method: 'GET',
        url: `/web-api/v1/product-template-lists/get-all-favorites?pagination_token=${paginationToken}&timestamp=${new Date().getTime()}`,
      }),
      providesTags: ['Favorites'],
    }),
    deleteFavorite: builder.mutation<
      { success: boolean },
      { productTemplateId: string; sizes: number[] }
    >({
      query: (body) => ({
        app: 'product-template-lists',
        data: body,
        method: 'DELETE',
        url: '/web-api/v1/product-template-lists/delete-favorite',
      }),
      invalidatesTags: ['Favorites'],
    }),
    fetchUserOffersForPT: builder.query<
      { sizes: number[]; productTemplate: PTListsProductTemplate },
      { productTemplateId: string; currentUserId: number | undefined }
    >({
      query: ({ productTemplateId }) => ({
        app: 'product-template-lists',
        url: `/web-api/v1/product-template-lists/get-offers/${productTemplateId}`,
        method: 'GET',
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.currentUserId !== previousArg?.currentUserId
      },
    }),
    fetchAllUserOffers: builder.query<
      {
        listItems: OffersListResponse[]
        itemsPerPage: number
        paginationToken?: string
      },
      { paginationToken?: string }
    >({
      query: ({ paginationToken = '' }) => ({
        app: 'product-template-lists',
        method: 'GET',
        url: `/web-api/v1/product-template-lists/get-all-offers?pagination_token=${paginationToken}`,
      }),
      providesTags: ['Offers'],
    }),
  }),
})

export const {
  useDeleteFavoriteMutation,
  useFetchAllUserFavoritesQuery,
  useFetchAllUserOffersQuery,
  useFetchUserFavoritesForPTQuery,
  useFetchUserOffersForPTQuery,
  useSaveUserFavoritesMutation,
} = productTemplateListsApi
