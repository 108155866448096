import Bugsnag, { NotifiableError } from '@bugsnag/js'

interface ITrackSnapchatSignUpProps {
  interval?: number
  retries?: number
  success: number
}

interface ITrackSnapchatPurchaseProps {
  currency?: string
  interval?: number
  orderNumber?: number
  price?: string
  retries?: number
  sku?: string
}

interface ITrackSnapchatPageViewProps {
  interval?: number
  retries?: number
  sku?: string
}

interface ITrackSnapchatAddCartProps {
  interval?: number
  retries?: number
  sku?: string
}

export const trackSnapchatSignUp = ({
  interval = 1000,
  retries = 3,
  success,
}: ITrackSnapchatSignUpProps) => {
  if (typeof window === 'undefined' || retries <= 0) {
    return
  }

  if (!window?.snaptr) {
    setTimeout(() => trackSnapchatSignUp({ interval, retries: retries - 1, success }), interval)
    return
  }

  try {
    return window.snaptr('track', 'SIGN_UP', { sign_up_method: 'email', success })
  } catch (error) {
    return Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'info'
    })
  }
}

export const trackSnapchatPurchase = ({
  currency,
  interval = 1000,
  orderNumber,
  price,
  retries = 3,
  sku,
}: ITrackSnapchatPurchaseProps) => {
  if (typeof window === 'undefined' || retries <= 0) {
    return
  }

  if (!window?.snaptr) {
    setTimeout(
      () =>
        trackSnapchatPurchase({
          currency,
          interval,
          orderNumber,
          price,
          retries: retries - 1,
          sku,
        }),
      interval,
    )
    return
  }

  try {
    window.snaptr('track', 'PURCHASE', {
      currency,
      item_ids: [sku],
      price,
      transaction_id: orderNumber,
    })
  } catch (error) {
    return Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'info'
    })
  }
}

export const trackSnapchatPageView = ({
  interval = 1000,
  retries = 3,
  sku,
}: ITrackSnapchatPageViewProps = {}) => {
  if (typeof window === 'undefined' || retries <= 0) {
    return
  }

  if (!window?.snaptr) {
    setTimeout(() => trackSnapchatPageView({ interval, retries: retries - 1, sku }), interval)
    return
  }

  try {
    if (sku) {
      window.snaptr('track', 'PAGE_VIEW', { item_ids: [sku] })
    } else {
      window.snaptr('track', 'PAGE_VIEW')
    }
  } catch (error) {
    return Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'info'
    })
  }
}

export const trackSnapchatAddCart = ({
  interval = 1000,
  retries = 3,
  sku,
}: ITrackSnapchatAddCartProps = {}) => {
  if (typeof window === 'undefined' || retries <= 0) {
    return
  }
  if (!window?.snaptr) {
    setTimeout(() => trackSnapchatAddCart({ interval, retries: retries - 1 }), interval)
    return
  }
  try {
    window.snaptr('track', 'ADD_CART', { item_ids: [sku] })
  } catch (error) {
    return Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'tracking'
      event.severity = 'info'
    })
  }
}
