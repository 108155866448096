import { Breadcrumb } from 'shared/types/Breadcrumb'

const navLinkBegin = /navigation-links/g
const getNavLinkHref = (navItem: Breadcrumb) => {
  const originalLink = navItem.urlOverride || navItem.slug
  return originalLink.includes('https://')
    ? originalLink.slice(originalLink.indexOf('https://'))
    : originalLink.replace(navLinkBegin, '')
}

export default getNavLinkHref
