import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'store/store'
import { Address } from 'shared/types/Address'

type CheckoutState = {
  isFastestToYouAvailable: boolean
  shippingAddress: Address | null
}

const initialState: CheckoutState = {
  isFastestToYouAvailable: false,
  shippingAddress: null,
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setIsFastestToYouAvailable: (state, action: PayloadAction<boolean>) => {
      state.isFastestToYouAvailable = action.payload
    },
    setShippingAddress: (state, action: PayloadAction<Address | null>) => {
      const shippingAddress = action.payload
      state.shippingAddress = shippingAddress
    },
  },
})

const { actions: checkoutActions, reducer: checkoutReducer } = checkoutSlice
export const { setIsFastestToYouAvailable, setShippingAddress } = checkoutActions
export { checkoutReducer }

// Checkout Selectors
export const selectIsFastestToYouAvailable = (state: RootState) =>
  state.checkout.isFastestToYouAvailable
export const selectShippingAddress = (state: RootState) => state.checkout.shippingAddress
