import { t } from 'localization'

const TP = 'search.enums.search'

export enum AlgoliaIndex {
  Relevance = 'product_variants_v2_flight_club',
  PriceLowHigh = 'product_variants_v2_by_price_asc_flight_club',
  PriceHighLow = 'product_variants_v2_by_price_desc_flight_club',
  Newest = 'product_variants_v2_by_newest_desc_flight_club',
  Trending = 'product_variants_v2_trending_purchase_flight_club',
  Collection = 'product_variants_v2_flight_club',
}

export const SortOption = () => ({
  Relevance: t(`${TP}.relevance`, 'Relevance'),
  PriceLowHigh: t(`${TP}.priceLow`, 'Price low'),
  PriceHighLow: t(`${TP}.priceHigh`, 'Price high'),
  Newest: t(`${TP}.newest`, 'Newest'),
  Trending: t(`${TP}.trending`, 'Trending'),
})

// in the order they appear on the filters
export enum SearchFiltersRefinement {
  Brand = 'brand_name',
  Category = 'single_gender',
  Color = 'color',
  Condition = 'shoe_condition',
  LowestPrice = 'lowest_price_cents',
  // Style doesnt have a correlating face atm
  MenSizes = 'size_us_men',
  PresentationSizes = 'presentation_size',
  ProductType = 'product_type',
  Silhouette = 'silhouette',
  WomenSizes = 'size_us_women',
  Year = 'release_year',
  YouthSizes = 'size_us_youth',
}

export const SearchFiltersHumanizedRefinement = () => ({
  brand_name: t(`${TP}.brand`, 'Brand'),
  color: t(`${TP}.color`, 'Color'),
  lowest_price_cents: t(`${TP}.price`, 'Price'),
  presentation_size: t(`${TP}.size`, 'Size'),
  product_type: t(`${TP}.productType`, 'Category'),
  release_year: t(`${TP}.year`, 'Year'),
  shoe_condition: t(`${TP}.condition`, 'Condition'),
  silhouette: t(`${TP}.model`, 'Model'),
  single_gender: t(`${TP}.category`, 'Category'),
  // Style doesnt have a correlating face atm
  size_us_men: t(`${TP}.menSize`, "US Men's Sizes"),
  size_us_women: t(`${TP}.womenSizes`, "US Women's Sizes"),
  size_us_youth: t(`${TP}.youthSizes`, 'US Youth Sizes'),
})

export enum SearchCurrency {
  USD = 'USD',
}

// not being used?
export const TranslationNeeded = () => ({
  single_gender: t(`${TP}.single_gender`, 'Category'),
  shoe_condition: t(`${TP}.shoe_condition`, 'Condition'),
  size_us_men: t(`${TP}.size_us_men`, "Men's Sizes"),
  size_us_women: t(`${TP}.size_us_women`, "Women's Sizes"),
  size_us_youth: t(`${TP}.size_us_youth`, 'Youth Sizes'),
  release_year: t(`${TP}.release_year`, 'Year'),
  color: t(`${TP}.color`, 'Color'),
  silhouette: t(`${TP}.silhouette`, 'Model'),
  brand_name: t(`${TP}.brand_name`, 'Brand'),
  lowest_price_cents: t(`${TP}.lowest_price_cents`, 'Price'),
  presentation_size: t(`${TP}.presentation_size`, 'Size'),
  Relevance: t(`${TP}.Relevance`, 'Relevance'),
  PriceLowHigh: t(`${TP}.Price low`, 'Price low'),
  PriceHighLow: t(`${TP}.Price high`, 'Price high'),
  Newest: t(`${TP}.Newest`, 'Newest'),
  Trending: t(`${TP}.Trending`, 'Trending'),
})

export const conditionFilterOption = () => ({
  newNoDefects: t(`${TP}.newNoDefects`, 'New No Defects'),
  newWithDefects: t(`${TP}.newWithDefects`, 'New With Defects'),
  used: t(`${TP}.used`, 'Used'),
})

export const colorFilterOption = () => ({
  black: t(`${TP}.black`, 'Black'),
  blue: t(`${TP}.blue`, 'Blue'),
  brown: t(`${TP}.brown`, 'Brown'),
  copper: t(`${TP}.copper`, 'Copper'),
  cream: t(`${TP}.cream`, 'Cream'),
  gold: t(`${TP}.gold`, 'Gold'),
  gray: t(`${TP}.gray`, 'Gray'),
  green: t(`${TP}.green`, 'Green'),
  grey: t(`${TP}.grey`, 'Grey'),
  multiColor: t(`${TP}.multi-color`, 'Multi-Color'),
  orange: t(`${TP}.orange`, 'Orange'),
  pink: t(`${TP}.pink`, 'Pink'),
  purple: t(`${TP}.purple`, 'Purple'),
  red: t(`${TP}.red`, 'Red'),
  silver: t(`${TP}.silver`, 'Silver'),
  tan: t(`${TP}.tan`, 'Tan'),
  teal: t(`${TP}.teal`, 'Teal'),
  white: t(`${TP}.white`, 'White'),
  yellow: t(`${TP}.yellow`, 'Yellow'),
})
