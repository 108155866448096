import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'

import FiltersSection from './FiltersSection'

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DesktopSearchFilters = () => {
  return (
    <FiltersWrapper>
      <FiltersSection />
    </FiltersWrapper>
  )
}

export default renderStart.large(DesktopSearchFilters)
