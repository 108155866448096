export const setUpMParticle = (apiKey, releaseEnv) => {
  // taken from Goat implementation and mostly very similar to set up docs
  // https://docs.mparticle.com/developers/sdk/web/getting-started/

  window.mParticle = window.mParticle || {
    /**
     * Development mode - All data sent through mParticle is marked as development
     *  or production. Set this to true in your test and QA environments
     */
    config: {
      isDevelopmentMode: releaseEnv !== 'production',
    },
    isReady: false,
    EventType: {
      Unknown: 0,
      Navigation: 1,
      Location: 2,
      Search: 3,
      Transaction: 4,
      UserContent: 5,
      UserPreference: 6,
      Social: 7,
      Other: 8,
    },
  }
  window.mParticle.eCommerce = { Cart: {} }
  window.mParticle.Identity = {}
  window.mParticle.config = window.mParticle.config || {}
  window.mParticle.config.rq = []
  window.mParticle.ready = (t) => {
    window.mParticle.config.rq.push(t)
  }

  function e(e, o) {
    return function (...args) {
      if (o) {
        e = o + '.' + e
      }
      const t = Array.prototype.slice.call(args)
      t.unshift(e)
      window.mParticle.config.rq.push(t)
    }
  }
  const o = [
    'endSession',
    'logError',
    'logEvent',
    'logForm',
    'logLink',
    'logPageView',
    'setSessionAttribute',
    'setAppName',
    'setAppVersion',
    'setOptOut',
    'setPosition',
    'startNewSession',
    'startTrackingLocation',
    'stopTrackingLocation',
  ]
  const n = ['setCurrencyCode', 'logCheckout']
  const i = ['identify', 'login', 'logout', 'modify']
  o.forEach(function (t) {
    window.mParticle[t] = e(t)
  })
  n.forEach(function (t) {
    window.mParticle.eCommerce[t] = e(t, 'eCommerce')
  })
  i.forEach(function (t) {
    window.mParticle.Identity[t] = e(t, 'Identity')
  })
  const r = document.createElement('script')
  r.async = true
  r.src =
    ('https:' == document.location.protocol ? 'https://jssdkcdns' : 'http://jssdkcdn') +
    '.mparticle.com/js/v2/' +
    apiKey +
    '/mparticle.js'
  r.type = 'text/javascript'
  r.className = 'optanon-category-C0002-C0004'
  r.onload = function () {
    window.mParticle.isReady = true
  }
  const c = document.getElementsByTagName('script')[0]
  c.parentNode?.insertBefore(r, c)
}

export default setUpMParticle
