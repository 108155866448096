enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  KRW = 'KRW',
  JPY = 'JPY',
  SGD = 'SGD',
  TWD = 'TWD',
  USD = 'USD',
  MYR = 'MYR',
  MXN = 'MXN',
}

export default Currency
