/* tslint:disable */
import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const InstagramIcon = ({
  color = colors.FC2_GREY,
  width = '20',
  height = '20',
  className = '',
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M15.576 0H4.424A4.422 4.422 0 0 0 0 4.424v11.148A4.423 4.423 0 0 0 4.424 20h11.148a4.422 4.422 0 0 0 4.424-4.424V4.424A4.416 4.416 0 0 0 15.576 0z"
      />
      <circle
        cx="13.967"
        cy="6.049"
        r="1"
        fill={color === colors.FC2_WHITE ? colors.FC2_BLACK : colors.FC2_WHITE}
      />
      <g fill={color === colors.FC2_WHITE ? colors.FC2_BLACK : colors.FC2_WHITE} fillRule="nonzero">
        <path d="M13.202 2.593H6.798a4.208 4.208 0 0 0-4.205 4.205v6.404a4.208 4.208 0 0 0 4.205 4.205h6.404a4.21 4.21 0 0 0 4.205-4.205V6.798a4.208 4.208 0 0 0-4.205-4.205zm2.868 10.609a2.873 2.873 0 0 1-2.868 2.868H6.798a2.873 2.873 0 0 1-2.868-2.868V6.798A2.873 2.873 0 0 1 6.798 3.93h6.404a2.873 2.873 0 0 1 2.868 2.868v6.404z" />
        <path d="M10 6.144A3.859 3.859 0 0 0 6.144 10 3.859 3.859 0 0 0 10 13.856 3.859 3.859 0 0 0 13.856 10 3.859 3.859 0 0 0 10 6.144zm0 6.375A2.524 2.524 0 0 1 7.481 10 2.524 2.524 0 0 1 10 7.481 2.524 2.524 0 0 1 12.519 10 2.524 2.524 0 0 1 10 12.519z" />
      </g>
    </g>
  </svg>
)

export default InstagramIcon
