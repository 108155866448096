// https://support.avantlink.com/hc/en-us/articles/203148879-Tracking-Integration
import getConfig from 'next/config'
import Script from 'next/script'

interface IAvantSiteTrackingProps {
  isOneTrustEnabled: boolean
}

const AvantSiteTracking = ({ isOneTrustEnabled }: IAvantSiteTrackingProps) => {
  const { publicRuntimeConfig } = getConfig()

  return (
    <Script
      className="optanon-category-C0004"
      dangerouslySetInnerHTML={{
        __html: `
              (function() {
                var avm = document.createElement('script'); avm.type = 'text/javascript'; avm.async = true;
                avm.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.avmws.com/${publicRuntimeConfig.avantSiteID}/';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(avm, s);
              })();
            `,
      }}
      id="avant-site-tracking"
      key="avant-site-tracking"
      strategy="lazyOnload"
      type={isOneTrustEnabled ? 'text/plain' : 'text/javascript'}
    />
  )
}

export default AvantSiteTracking
