import { FC } from 'react'
import styled from 'styled-components'

import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { fonts } from 'shared/lib'
import { LinkCTA } from '../Links'
import { t } from 'localization'

const TP = 'shared.components.panel.panelHeader'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  ${fonts.SUBTITLE_2}
  margin: 0;
`

const SubTitle = styled.h3`
  ${fonts.BODY_TEXT}
  font-weight: unset;
  margin: 10px 0 0 0;
`
const CloseButton = styled.button`
  width: 15px;
  height: 15px;
`
interface IPanelHeaderProps {
  title: JSX.Element | string
  subtitle?: string
  isEditable?: boolean
  onEdit?: () => void
  qaAttr?: string
  withCloseButton?: boolean
  handleClose?: () => void
}

const PanelHeader: FC<IPanelHeaderProps> = ({
  title,
  onEdit,
  subtitle,
  isEditable = false,
  qaAttr,
  withCloseButton = false,
  handleClose,
}) => {
  return (
    <Header data-qa={`${qaAttr}SectionHeader`}>
      <Titles>
        <Title data-qa={`${qaAttr}SectionTitle`}>{title}</Title>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
      </Titles>
      {withCloseButton && (
        <CloseButton data-qa="PanelCloseButton" onClick={() => handleClose && handleClose()}>
          <CloseX width="15" height="15" />
        </CloseButton>
      )}
      {isEditable && (
        <LinkCTA qaAttr={`${qaAttr}EditLink`} onClick={onEdit} color="grey">
          {t(`${TP}.edit`, 'Edit')}
        </LinkCTA>
      )}
    </Header>
  )
}

export default PanelHeader
