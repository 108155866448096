import { apiSlice } from 'api/apiSlice'
import { Country } from 'shared/types/Country'
import { State } from 'shared/types/State'

const countriesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCountries: builder.query<{ countries: Country[] }, void>({
      query: () => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/countries`,
      }),
    }),
    getStates: builder.query<{ states: State[] }, string>({
      query: countryISOCode => ({
        app: 'sneakers',
        method: 'GET',
        url: `/web-api/v1/countries/${countryISOCode}/states`,
      }),
    }),
  }),
})

export const { useGetCountriesQuery, useGetStatesQuery } = countriesApi
