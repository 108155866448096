import { PriceLocalized } from 'shared/types/PriceLocalized'

type FormatMoneyObjectOptions = {
  /** Currency symbol prepended to the price instead of default $ */
  disambiguatedCurrencySymbol?: string
  /** Show/hide the currency symbol (default true) */
  displayCurrencySymbol?: boolean
  /** the way that number displays varies depends on different locales */
  locale?: string
  /** rounds down to dollar amt (ex: 898.99->898.00) */
  roundDownToDollarAmount?: boolean
  /** hide cents if .00 - bool values will show/hide always */
  showCents?: boolean
  /** Show/hide group symbol, typically a comma (default true) */
  useGrouping?: boolean
}

/**
 * suffixCurrencySymbolLocales
 * Locale list for those lans of which the currency symbol is a suffix
 * For example, if the locale is fr, 1 234,56 $
 */
const suffixCurrencySymbolLocales = ['fr-FR', 'it-IT', 'de-DE']

/**
 * [replaceCents] replaces the cents values to 00 so we get the nearest whole dollar amount
 * ex: $898.75 -> $898: replaceCents(89875) -> 89800
 *
 * @param amount: number
 */
const replaceCents = (amount: number) => Number(`${amount}`.replace(/\d{2}$/, '00'))

/**
 * formatMoneyObject
 * Formats the standard currency object returned by sneakers into a localized price string
 *
 * @param {object} money
 * @param {number} money.amount - The price amount in cents, potentially in non USD currency
 * @param {number} money.amountUsdCents - The price amount in USD cents
 * @param {string} money.currency - The currency of price.amount (default USD)
 * @returns {string} a formatted price string based on the price object's currency
 */
export const formatMoneyObject = (
  money: PriceLocalized | undefined,
  options: FormatMoneyObjectOptions = {},
): string => {
  if (!money || typeof Number(money.amount) !== 'number' || isNaN(Number(money.amount))) {
    return ''
  }
  const { amount, currency = 'USD' } = money
  const {
    displayCurrencySymbol = true,
    roundDownToDollarAmount = false,
    useGrouping = true,
    showCents,
    locale = 'en-US',
    disambiguatedCurrencySymbol,
  } = options
  const amountValue = roundDownToDollarAmount ? replaceCents(Number(amount)) : Number(amount)

  const fractionDigits = (() => {
    if (currency === 'JPY' || currency === 'KRW') {
      return 0
    }
    if (typeof showCents === 'undefined' || showCents === null) {
      const hasCents = amountValue % 100 !== 0
      return hasCents ? 2 : 0
    }
    return showCents ? 2 : 0
  })()

  const formattedParts = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: 'currency',
    useGrouping,
  }).formatToParts(amountValue / 100)

  const formattedPrice = formattedParts.reduce((price, parts) => {
    if (parts?.type === 'currency') {
      const symbol = parts?.value?.replace(/[a-zA-Z]/g, '')
      if (currency === 'USD') {
        return price + symbol
      } else {
        return price + `${currency} ${symbol}`
      }
    }
    if (parts?.type === 'literal') {
      return price
    }
    return price + parts?.value
  }, '')

  // A special case added for the locale: it-IT
  // for example, originally, 123456 will be formatted as 1.234,56 USD
  // but we want it to be 1.234,56 $
  if (suffixCurrencySymbolLocales.includes(locale)) {
    if (locale === 'it-IT') {
      return `${formattedPrice.trim()}${
        displayCurrencySymbol ? disambiguatedCurrencySymbol || ' $' : ''
      }`
    }
    return displayCurrencySymbol
      ? formattedPrice
      : formattedPrice.replace(/[\D]*$/, disambiguatedCurrencySymbol)
  }
  // Override for USD to prevent US$ from showing up all over the place
  if (disambiguatedCurrencySymbol === 'US$') {
    return formattedPrice
  }
  return disambiguatedCurrencySymbol || !displayCurrencySymbol
    ? formattedPrice.replace(/^[\D]*/, disambiguatedCurrencySymbol)
    : formattedPrice
}
