/* tslint:disable:max-line-length */
enum HomepageQA {
  Hero = 'Hero',
  HeroImage = 'HeroImage',
  HeroImageDesktop = 'HeroImageDesktop',
  HeroImageMobile = 'HeroImageMobile',
  HeroSectionAllStarTitle = 'HeroSectionAllStarTitle',
  HeroImageLink = 'HeroImageLink',
  LinkOverlay = 'LinkOverlay',
  /// SHOP MEN, WOMEN AND KIDS
  ShopMen = 'ShopMen', // DO NO USE as it is, use Auto-generated names
  // ShopMenLink // Auto-generated
  // ShopMenImage // Auto-generated
  ShopWomen = 'ShopWomen', // DO NO USE as it is, use Auto-generated names
  // ShopWomenLink // Auto-generated
  // ShopWomenImage // Auto-generated
  ShopKids = 'ShopKids', // DO NO USE as it is, use Auto-generated names
  // ShopKidsLink // Auto-generated
  // ShopKidsImage // Auto-generated
  /// OUR STORES AND HISTORY
  StoresAndHistoryColumn = 'StoresAndHistoryColumn',
  OurStoresImage = 'OurStoresImage',
  OurStoresSectionTitle = 'OurStoresSectionTitle',
  OurStoresDescriptionText = 'OurStoresDescriptionText',
  OurStoresLearnMoreLink = 'OurStoresLearnMoreLink',
  OurStoresNewYorkLink = 'OurStoresNewYorkLink',
  OurStoresLosAngelesLink = 'OurStoresLosAngelesLink',
  OurStoresMiamiLink = 'OurStoresMiamiLink',
  OurStoresChicagoLink = 'OurStoresChicagoLink',
  OurHistoryImage = 'OurHistoryImage',
  OurHistorySectionTitle = 'OurHistorySectionTitle',
  OurHistoryDescriptionText = 'OurHistoryDescriptionText',
  OurHistoryLearnMoreLink = 'OurHistoryLearnMoreLink',
  OurHistoryAirJordansLink = 'OurHistoryAirJordansLink',
  OurHistoryNikeLink = 'OurHistoryNikeLink',
  OurHistoryAdidasLink = 'OurHistoryAdidasLink',
  VideoHero = 'VideoHero',
}

export default HomepageQA
