import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const CardNumber = ({
  color = colors.FC2_BLACK,
  width = '24',
  height = '3',
  className = '',
  viewBox = '0 0 24 3',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0ZM8.5 0C7.67157 0 7 0.671573 7 1.5C7 2.32843 7.67157 3 8.5 3C9.32843 3 10 2.32843 10 1.5C10 0.671573 9.32843 0 8.5 0ZM14 1.5C14 0.671573 14.6716 0 15.5 0C16.3284 0 17 0.671573 17 1.5C17 2.32843 16.3284 3 15.5 3C14.6716 3 14 2.32843 14 1.5ZM22.5 0C21.6716 0 21 0.671573 21 1.5C21 2.32843 21.6716 3 22.5 3C23.3284 3 24 2.32843 24 1.5C24 0.671573 23.3284 0 22.5 0Z" fill={color}/>
  </svg>
)
                    