import React, { FC, ReactNode, useState, useEffect } from 'react'
import { SVGIcon } from 'shared/components/Icons'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'
import { t } from 'localization'

const TP = 'shared.components.Storytelling.LanguageDropdown'

type LanguageDropdownProps = {
  disabled?: boolean
  name: string
  onChange: (language: string) => void
  qaAttr?: string
  title: string | ReactNode
  defaultLanguage: string
}

const LanguageDropdownField: FC<LanguageDropdownProps> = ({
  disabled,
  name,
  onChange,
  qaAttr,
  title,
  defaultLanguage,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)

  const handleChangeAndBlur = e => {
    const value = e.currentTarget.value
    setSelectedLanguage(value)
    onChange(value)
  }

  return (
    <FieldBox>
      <FormLabel>{title}</FormLabel>
      <SelectBox>
        <StyledSelect
          onBlur={handleChangeAndBlur}
          onChange={handleChangeAndBlur}
          data-qa={qaAttr}
          disabled={disabled}
          autoComplete="language"
          name={name}
          value={selectedLanguage}
        >
          {/* do not translate */}
          <option value="ja-jp">日本語</option>
          <option value="en-jp">English</option>
        </StyledSelect>
        <StyledCaret name="caret" color={disabled ? colors.FC2_GREY : colors.FC2_BLACK} />
      </SelectBox>
    </FieldBox>
  )
}

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const FormLabel = styled.label`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
  display: flex;
  justify-content: space-between;
`

const StyledCaret = styled(SVGIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 14px;
  pointer-events: none;
`

const SelectBox = styled.div`
  position: relative;
`

const StyledSelect = styled.select`
  min-height: 40px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  border-radius: 0;
  margin-top: 5px;
  font-size: 14px;
  padding: 0 15px;
  color: ${({ disabled }) => (disabled ? colors.FC2_GREY : colors.FC2_BLACK)};
  background-color: ${colors.FC2_WHITE};
  min-width: 100%;
  height: 40px;
  appearance: none;
`

export default LanguageDropdownField
