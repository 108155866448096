import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend/cjs'
import ICU from 'i18next-icu'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

import { i18n } from '../../../next-i18next.config'

const isBrowser = typeof window !== 'undefined'
const isDev = process.env.NODE_ENV === 'development'
const cacheDuration = 3 * 60 * 60 * 1000 // 3 hours

export default {
  i18n,
  fallbackLng: {
    default: ['en'],
    'ja-jp': ['ja'],
    jp: ['ja'],
  },
  backend: {
    backendOptions: [
      {
        expirationTime: isDev ? 0 : cacheDuration,
      },
      {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
    ],
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  use: isBrowser ? [ICU, initReactI18next, ChainedBackend] : [ICU],
  nonExplicitSupportedLngs: true,
  defaultNS: 'common',
  ns: ['common'],
  serializeConfig: false,
  lowerCaseLng: true,
  reloadOnPrerender: isDev,
  debug: false,
}
