/* tslint:disable */
import React from 'react'
import { IconsQA } from 'shared/dataAttributes'

export default function Discover({ height = 16, width = 24, className }) {
  return (
    <svg
      data-qa={IconsQA.DiscoverCardIcon}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ASSETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="cc_payment_discover">
          <path
            d="M23.9016393,14 C23.9016393,15.1 23.0163934,16 21.9344262,16 L2.26229508,16 C1.18032787,16 0.295081967,15.1 0.295081967,14 L0.295081967,2 C0.295081967,0.9 1.18032787,0 2.26229508,0 L21.9344262,0 C23.0163934,0 23.9016393,0.9 23.9016393,2 L23.9016393,14 L23.9016393,14 Z"
            id="Shape"
            fill="#FFF9F0"
          />
          <g id="Group" transform="translate(3.934426, 6.400000)">
            <path
              d="M2.31540984,1.5772 C2.31540984,1.978 2.20327869,2.2848 1.97940984,2.498 C1.7547541,2.7108 1.43055738,2.8176 1.00681967,2.8176 L0.32852459,2.8176 L0.32852459,0.3836 L1.08,0.3836 C1.47108197,0.3836 1.77442623,0.4888 1.99003279,0.6984 C2.20721311,0.908 2.31540984,1.2012 2.31540984,1.5772 L2.31540984,1.5772 Z M1.78819672,1.5908 C1.78819672,1.068 1.56078689,0.8068 1.10754098,0.8068 L0.836852459,0.8068 L0.836852459,2.3912 L1.05481967,2.3912 C1.54386885,2.3916 1.78819672,2.1244 1.78819672,1.5908 L1.78819672,1.5908 Z"
              id="Shape"
              fill="#414042"
            />
            <polygon
              id="Shape"
              fill="#414042"
              points="2.74465574 2.8176 2.74465574 0.3836 3.25219672 0.3836 3.25219672 2.8176"
            />
            <path
              d="M5.26859016,2.1416 C5.26859016,2.362 5.19108197,2.5344 5.03488525,2.6612 C4.87908197,2.7876 4.66308197,2.8516 4.38570492,2.8516 C4.1307541,2.8516 3.90413115,2.8024 3.70740984,2.7052 L3.70740984,2.2256 C3.86911475,2.2996 4.00603279,2.3504 4.11777049,2.3812 C4.2295082,2.4108 4.33219672,2.4256 4.42504918,2.4256 C4.536,2.4256 4.62177049,2.4036 4.68118033,2.3608 C4.73940984,2.318 4.7704918,2.2528 4.7704918,2.168 C4.7704918,2.1204 4.7575082,2.078 4.7307541,2.0408 C4.70518033,2.0032 4.66622951,1.9672 4.61508197,1.9332 C4.56432787,1.8992 4.46085246,1.8436 4.30504918,1.7684 C4.15908197,1.6984 4.04970492,1.6308 3.9757377,1.5668 C3.90216393,1.5028 3.84432787,1.428 3.80065574,1.342 C3.75698361,1.256 3.73534426,1.156 3.73534426,1.042 C3.73534426,0.8268 3.80695082,0.6576 3.95055738,0.5344 C4.09455738,0.4108 4.29245902,0.3496 4.54622951,0.3496 C4.67055738,0.3496 4.78937705,0.3644 4.90229508,0.3944 C5.01521311,0.424 5.13363934,0.4668 5.25678689,0.5208 L5.09311475,0.922 C4.9652459,0.8688 4.85901639,0.8308 4.77560656,0.8104 C4.69180328,0.7896 4.60957377,0.7788 4.52931148,0.7788 C4.43291803,0.7788 4.35895082,0.8012 4.30819672,0.8472 C4.25665574,0.8928 4.23108197,0.952 4.23108197,1.0252 C4.23108197,1.0708 4.24131148,1.11 4.26216393,1.1444 C4.28301639,1.1784 4.31606557,1.2108 4.36131148,1.2424 C4.40655738,1.274 4.51436066,1.3308 4.68314754,1.4132 C4.90662295,1.522 5.06006557,1.6312 5.14347541,1.7404 C5.22688525,1.8488 5.26859016,1.9828 5.26859016,2.1416 L5.26859016,2.1416 Z"
              id="Shape"
              fill="#414042"
            />
            <path
              d="M6.71921311,0.778 C6.528,0.778 6.38045902,0.8516 6.27540984,0.9968 C6.16996721,1.1428 6.11842623,1.3468 6.11842623,1.6072 C6.11842623,2.1496 6.31868852,2.4212 6.71921311,2.4212 C6.88760656,2.4212 7.09022951,2.3796 7.33022951,2.2932 L7.33022951,2.726 C7.1335082,2.8096 6.91396721,2.8508 6.67160656,2.8508 C6.32340984,2.8508 6.05704918,2.7428 5.87252459,2.528 C5.688,2.3136 5.59593443,2.0052 5.59593443,1.6032 C5.59593443,1.35 5.64157377,1.1284 5.73245902,0.9384 C5.82255738,0.7472 5.95318033,0.602 6.1227541,0.5004 C6.29311475,0.3988 6.49180328,0.348 6.72,0.348 C6.95252459,0.348 7.18662295,0.4052 7.42072131,0.5196 L7.25704918,0.9392 C7.16695082,0.896 7.07763934,0.8584 6.98636066,0.826 C6.89547541,0.7936 6.80655738,0.778 6.71921311,0.778 L6.71921311,0.778 Z"
              id="Shape"
              fill="#414042"
            />
            <path
              d="M11.3937049,0.3836 L11.9063607,0.3836 L11.0931148,2.8176 L10.539541,2.8176 L9.72747541,0.3836 L10.2405246,0.3836 L10.6910164,1.832 C10.7158033,1.918 10.7425574,2.0168 10.768918,2.1312 C10.7948852,2.2452 10.8118033,2.3232 10.8188852,2.368 C10.8306885,2.266 10.8716066,2.0884 10.9416393,1.832 L11.3937049,0.3836 L11.3937049,0.3836 Z"
              id="Shape"
              fill="#414042"
            />
            <polygon
              id="Shape"
              fill="#414042"
              points="13.5863607 2.8176 12.2073443 2.8176 12.2073443 0.3836 13.5863607 0.3836 13.5863607 0.8068 12.7148852 0.8068 12.7148852 1.3412 13.5261639 1.3412 13.5261639 1.764 12.7148852 1.764 12.7148852 2.3912 13.5863607 2.3912"
            />
            <path
              d="M14.5935738,1.8836 L14.5935738,2.8176 L14.0860328,2.8176 L14.0860328,0.3836 L14.7836066,0.3836 C15.1089836,0.3836 15.349377,0.4436 15.5059672,0.5644 C15.6617705,0.6848 15.7400656,0.8672 15.7400656,1.1128 C15.7400656,1.2556 15.7011148,1.3836 15.624,1.4948 C15.5464918,1.6064 15.4367213,1.694 15.295082,1.7572 C15.655082,2.3036 15.8895738,2.6576 15.9989508,2.8172 L15.435541,2.8172 L14.8638689,1.8832 L14.5935738,1.8836 L14.5935738,1.8836 Z M14.5935738,1.464 L14.7576393,1.464 C14.9177705,1.464 15.0365902,1.4368 15.112918,1.3828 C15.1888525,1.328 15.2274098,1.2424 15.2274098,1.126 C15.2274098,1.0104 15.1880656,0.9288 15.1101639,0.8796 C15.0322623,0.8304 14.9114754,0.8064 14.7478033,0.8064 L14.5927869,0.8064 L14.5935738,1.464 L14.5935738,1.464 Z"
              id="Shape"
              fill="#414042"
            />
            <ellipse
              id="Oval"
              fill="#E6A124"
              cx="8.58334426"
              cy="1.6008"
              rx="1.1972459"
              ry="1.2172"
            />
          </g>
          <path
            d="M23.9016393,11 L23.9016393,14 C23.9016393,14.2752 23.8461639,14.5376 23.7462295,14.7764 C23.6459016,15.0152 23.5019016,15.2312 23.3236721,15.4124 C23.1454426,15.5936 22.933377,15.74 22.6980984,15.842 C22.4624262,15.9436 22.2047213,16 21.9344262,16 L13.0988852,16 L4.26334426,16 L23.9016393,11 L23.9016393,11 Z"
            id="Shape"
            fill="#E6A124"
          />
          <path
            d="M23.3236721,0.5876 C23.6801311,0.95 23.9016393,1.45 23.9016393,2 L23.9016393,8 L23.9016393,14 C23.9016393,14.55 23.6805246,15.05 23.3236721,15.4124 C22.9668197,15.7748 22.4754098,16 21.9344262,16 L12.0983607,16 L2.26229508,16 C1.72131148,16 1.2295082,15.7752 0.87304918,15.4124 L23.3236721,0.5876 L23.3236721,0.5876 Z"
            id="Shape"
            fill="#F7F9FB"
            opacity="0.203192631"
          />
          <path
            d="M21.9344262,0.4 C22.8023607,0.4 23.5081967,1.1176 23.5081967,2 L23.5081967,14 C23.5081967,14.8824 22.8023607,15.6 21.9344262,15.6 L2.26229508,15.6 C1.39436066,15.6 0.68852459,14.8824 0.68852459,14 L0.68852459,2 C0.68852459,1.1176 1.39436066,0.4 2.26229508,0.4 L21.9344262,0.4 L21.9344262,0.4 Z M21.9344262,0 L2.26229508,0 C1.18032787,0 0.295081967,0.9 0.295081967,2 L0.295081967,14 C0.295081967,15.1 1.18032787,16 2.26229508,16 L21.9344262,16 C23.0163934,16 23.9016393,15.1 23.9016393,14 L23.9016393,2 C23.9016393,0.9 23.0163934,0 21.9344262,0 L21.9344262,0 L21.9344262,0 Z"
            id="Shape"
            fill="#F7F9FB"
          />
        </g>
      </g>
    </svg>
  )
}
