import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useLogoutMutation } from 'api/usersApi'
import { mParticleLogoutUser } from 'mParticle/mParticleService'

export const useAuth = () => {
  const { asPath, reload } = useRouter()
  const [logout, { isSuccess }] = useLogoutMutation()

  const logoutUser = async () => {
    await logout()
    mParticleLogoutUser()
  }

  useEffect(() => {
    if (isSuccess) {
      if (asPath.startsWith('/my-account')) {
        reload()
      }
    }
  }, [isSuccess])

  return {
    logoutUser,
  }
}
