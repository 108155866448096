import ContextErrorBanner from 'errors/components/ContextErrorBanner'
import ErrorContextProvider, { useErrorContext } from 'errors/components/ErrorContextProvider'
import { t } from 'localization'
import { isEmpty, toFinite, toInteger } from 'lodash/fp'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { FC, useState } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Button } from 'shared/components/Button'
import { Card } from 'shared/components/CardDeprecated'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'
import { currencyLocaleMap } from 'shared/lib/formatCurrency'
import styled from 'styled-components'

const TP = 'search.enums.search'
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const NumberInput = styled.input.attrs({ type: 'number' })`
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  padding: 15px 13px;
  margin-bottom: 15px;
`

const Label = styled.label`
  text-transform: uppercase;
  color: ${colors.FC2_GREY};
  margin-bottom: 5px;
  font-size: 10px;
`

interface PriceFilterProps {
  currentRefinement
  refine
  min: number
  max: number
  attribute: string
  baseAttribute: string
  currency: string
}

const PriceFilter: FC<PriceFilterProps> = ({
  currentRefinement,
  refine,
  min,
  max,
  baseAttribute,
  currency,
}) => {
  const convertToCents = (value) => (value != null ? value * 100 : value)
  const convertToDollars = (value) => (value != null ? value / 100 : value)
  const currencyOptions = new Intl.NumberFormat(currencyLocaleMap[currency.toUpperCase()], {
    style: 'currency',
    currency,
  }).resolvedOptions()

  const shouldConvert =
    !!currencyOptions.minimumFractionDigits || currencyOptions.currency !== 'USD'
  const initialMinPrice = shouldConvert
    ? convertToDollars(currentRefinement.min)
    : currentRefinement.min
  const initialMaxPrice = shouldConvert
    ? convertToDollars(currentRefinement.max)
    : currentRefinement.max

  const { setErrors } = useErrorContext()
  const [minPrice, setMinPrice] = useState(initialMinPrice)
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice)

  const onPriceChange = (e, set) => {
    let value = e.target.value
    const number = toInteger(value)

    if (!isEmpty(value)) {
      if (number < 0) {
        value = Math.abs(number).toString() // For negative inputs
      } else {
        value = number.toString()
      }
    }

    set(value)
  }

  const isPriceRangeInvalid = () =>
    !!minPrice && !!maxPrice && toFinite(minPrice) > toFinite(maxPrice)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isPriceRangeInvalid()) {
      setErrors([
        {
          message: t(`${TP}.priceRangeInvalid`, "'From' price should be lower than 'To' price"),
        },
      ])
      return
    } else {
      setErrors([])
    }

    const isChanged = currentRefinement.min !== minPrice || currentRefinement.max !== maxPrice
    const isFormEmpty = isEmpty(minPrice) && isEmpty(maxPrice)
    const filterName = SearchFiltersHumanizedRefinement()[baseAttribute] || ''
    if (isFormEmpty) {
      sendTrackingEvent('FILTER_APPLY_TAP', {
        page: window?.location?.href,
        filter_name: filterName.toLowerCase(),
        filter_selection: `${minPrice}-${maxPrice}`,
      })
      refine({
        ...currentRefinement,
        min,
        max,
      })
      return
    }
    if (isChanged) {
      sendTrackingEvent('FILTER_APPLY_TAP', {
        page: window?.location?.href,
        filter_name: filterName.toLowerCase(),
        filter_selection: `${minPrice}-${maxPrice}`,
      })
      refine({
        ...currentRefinement,
        min: shouldConvert && !!minPrice ? convertToCents(minPrice) : minPrice,
        max: shouldConvert && !!maxPrice ? convertToCents(maxPrice) : maxPrice,
      })
    }
  }

  const hasValues = !!minPrice || !!maxPrice

  return (
    <Card
      qaAttr={SearchQA.FilterPrice}
      title={SearchFiltersHumanizedRefinement()[baseAttribute]}
      isExpanded={hasValues}
    >
      <CardContent>
        <form onSubmit={handleSubmit}>
          <ContextErrorBanner />
          <Label>{t(`${TP}.from`, 'From')}</Label>
          <NumberInput
            data-qa={SearchQA.FilterPriceMinAmount}
            min={min}
            max={max}
            value={minPrice || ''}
            onChange={(e) => onPriceChange(e, setMinPrice)}
            data-testid="min-price-input"
          />
          <Label>{t(`${TP}.to`, 'To')}</Label>
          <NumberInput
            data-qa={SearchQA.FilterPriceMaxAmount}
            min={min}
            max={max}
            value={maxPrice || ''}
            onChange={(e) => onPriceChange(e, setMaxPrice)}
            data-testid="max-price-input"
          />
          <Button
            qaAttr={SearchQA.FilterPriceApplyButton}
            $fill
            buttonType="secondary"
            type="submit"
            uniqueLogEvent
          >
            {t(`${TP}.apply`, 'Apply')}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

const PriceFilterProvider = (props) => (
  <ErrorContextProvider>
    <PriceFilter {...props} />
  </ErrorContextProvider>
)

PriceFilterProvider.displayName = 'PriceFilterContainer'

const PriceFilterContainer = connectRange(PriceFilterProvider)

export default PriceFilterContainer
