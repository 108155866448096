import { isEmpty, toFinite } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import styled from 'styled-components'

import ContextErrorBanner from 'errors/components/ContextErrorBanner'
import ErrorContextProvider, { useErrorContext } from 'errors/components/ErrorContextProvider'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Button } from 'shared/components/Button'
import { Card } from 'shared/components/CardDeprecated'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'

const TP = 'search.enums.search'

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const NumberInput = styled.input.attrs({ type: 'number' })`
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  padding: 15px 13px;
  margin-bottom: 15px;
`

const Label = styled.label`
  text-transform: uppercase;
  color: ${colors.FC2_GREY};
  margin-bottom: 5px;
  font-size: 10px;
`

const YearFilter = ({ currentRefinement, min, max, refine, attribute }) => {
  const { setErrors } = useErrorContext()
  const [minYear, setMinYear] = useState(currentRefinement.min)
  const [maxYear, setMaxYear] = useState(currentRefinement.max)

  useEffect(() => {
    setMinYear(currentRefinement.min)
    setMaxYear(currentRefinement.max)
  }, [currentRefinement])

  const isYearRangeInvalid = () => !!minYear && !!maxYear && toFinite(minYear) > toFinite(maxYear)

  const onYearChange = (e, set) => {
    const value = e.target.value
    const number = toFinite(value)
    const badInput = e.target.validity ? e.target.validity.badInput : false

    if ((!isEmpty(value) && number <= 0) || value.length > 4 || badInput) {
      e.preventDefault()
      return
    }

    set(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isYearRangeInvalid()) {
      setErrors([
        {
          message: "'From' year should be lower than 'To' year",
        },
      ])
      return
    } else {
      setErrors([])
    }

    if (currentRefinement.min !== minYear || currentRefinement.max !== maxYear) {
      sendTrackingEvent('FILTER_APPLY_TAP', {
        page: window?.location?.href,
        filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
        filter_selection: `${minYear}-${maxYear}`,
      })
      refine({ ...currentRefinement, min: minYear, max: maxYear })
    }
  }

  return (
    <Card title={SearchFiltersHumanizedRefinement()[attribute]} qaAttr={SearchQA.FilterYear}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <ContextErrorBanner />
          <Label>{t(`${TP}.yearFrom`, 'From')}</Label>
          <NumberInput
            data-qa={SearchQA.FilterYearMinAmount}
            type="number"
            min={min}
            max={max}
            value={minYear || ''}
            onChange={(e) => onYearChange(e, setMinYear)}
          />
          <Label>{t(`${TP}.yearTo`, 'To')}</Label>
          <NumberInput
            data-qa={SearchQA.FilterYearMaxAmount}
            type="number"
            min={min}
            max={max}
            value={maxYear || ''}
            onChange={(e) => onYearChange(e, setMaxYear)}
          />
          <Button
            qaAttr={SearchQA.FilterYearApplyButton}
            $fill
            buttonType="secondary"
            type="submit"
            uniqueLogEvent
          >
            {t(`${TP}.apply`, 'Apply')}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

const YearFilterProvider = (props) => (
  <ErrorContextProvider>
    <YearFilter {...props} />
  </ErrorContextProvider>
)

export default connectRange(YearFilterProvider)
