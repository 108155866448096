import { DEFAULT_LANGUAGE } from 'localization/enums/LocalizationEnums'

// if our locale code does not match the standard locale code,
// we can exchange here
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// https://stripe.com/docs/js/appendix/supported_locales
export const getStandardLocaleCode = (locale?: string): string => {
  switch (locale) {
    case 'jp':
    case 'ja-jp':
      return 'ja'
    case 'en-jp':
    case null:
    case undefined:
    case '':
      return 'en'
    default:
      return locale
  }
}

export const setLocalizedHTMLAnchorLinks = (html: string, locale?: string): string => {
  if (!html) {
    return html
  }
  const re = new RegExp('href=("|\')?((http(s)?://)?(www.)?flightclub.com(/)?|/)', 'g')
  if (!locale || locale === DEFAULT_LANGUAGE) {
    return html.replace(re, 'href=$1/')
  }
  return html.replace(re, `href=$1/${locale}/`)
}
