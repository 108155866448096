import formatCurrency from 'shared/lib/formatCurrency'
import { t } from 'localization'

const TP = 'shared.ProductGrid.utils.formatPrice'

/**
 * Formats the price to be displayed (i.e. in a grid item).
 *
 * - If value is null or undefined the item price is not displayed and empty string is returned.
 * - If value is 0 'Out of stock' is returned.
 * - If value is a number > 0 the value is formatted and returned.
 * @param value Value to format.
 * @param isoCode Currency iso code
 * @param symbol Currency symbol to use as prefix in the formatted value.
 * @param priceSuffix Optional suffix to place after a valid formatted price.
 */
export const formatPrice = (
  value?: number | null,
  isoCode?: string,
  symbol?: string,
  priceSuffix?: string,
): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return ''
  }
  if (value === 0) {
    return t(`${TP}.outOfStock`, 'Out of stock')
  }
  return `${formatCurrency(isoCode, {
    useGrouping: true,
    hideCents: true,
    symbol: symbol || '',
  })(value)}${priceSuffix || ''}`
}
