import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { Children, FC, RefObject, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useRef } from 'react'
import { Transition } from 'react-transition-group'
import { GlobalQA } from 'shared/dataAttributes'
import { useClickOutside } from 'shared/hooks'
import { colors, fonts, media, zIndex } from 'shared/lib'
import { SVGIcon } from '../Icons'

const PageTakeover = styled.div<{ isVisible: boolean, withoutBackdrop: boolean }>`
  ${({ isVisible, withoutBackdrop }) =>
    isVisible
      ? css`
          bottom: 0;
          height: 100%;
          left: 0;
          overflow: hidden;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: ${zIndex.pageTakeover};
          ${withoutBackdrop ? '' : 'background-color: rgba(0, 0, 0, 0.4);'}
        `
      : ''}
`

const XIcon = styled(SVGIcon).attrs({
  size: 'small',
  name: 'closeX',
})``

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited'
const SideSheetContent = styled.div<{
  isBackgroundOffWhiteMobile: boolean
  state: TransitionState
  $isHeightFitContent: boolean
}>`
  background-color: ${({ isBackgroundOffWhiteMobile }) =>
    isBackgroundOffWhiteMobile ? colors.FC2_OFF_WHITE : colors.FC2_WHITE};
  position: absolute;
  max-height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.pageTakeover};
  ${media.large`
    background-color: ${colors.FC2_WHITE};
    height: 100%;
    top: 0;
    width: 545px;
  `}
  transition: 0.3s;
  transform: ${({ state }) =>
    state === 'entered' ? 'translate(0px, 0px);' : 'translate(0px, 1000px)'};
  ${media.large`
    transform: ${({ state }: { state: TransitionState }) =>
      state === 'entered' ? 'translate(0px);' : 'translate(545px)'};
  `}
`

const Header = styled.div`
  display: flex;
  background-color: ${colors.FC2_WHITE};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const Title = styled.div`
  ${fonts.SUBTITLE_1}
`

const TitleCopy = styled.div`
  overflow: hidden;
`

const Body = styled.div`
  flex: 1;
  overflow: auto;
`

const Footer = styled.div<{ isFooterBoxShadowVisible: boolean }>`
  ${({ isFooterBoxShadowVisible }) =>
    isFooterBoxShadowVisible
      ? `box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); z-index: ${zIndex.header};`
      : ''};
`

const ModalOverlay = styled.div``

export interface IResponsiveSideSheet {
  children?: string | JSX.Element | JSX.Element[]
  isBackgroundOffWhiteMobile?: boolean
  isFooterBoxShadowVisible?: boolean
  isHeightFitContent?: boolean
  isVisible?: boolean
  withoutBackdrop?: boolean
  onClose: () => void
  onActionClick?: () => void
  subtitle?: string
  title: string
}

const ResponsiveSideSheet: FC<IResponsiveSideSheet> = ({
  children,
  isBackgroundOffWhiteMobile = false,
  isFooterBoxShadowVisible = false,
  isHeightFitContent = false,
  isVisible = false,
  withoutBackdrop = false,
  onClose,
  subtitle,
  title,
}) => {
  const contentEl = useClickOutside(onClose) as RefObject<HTMLDivElement>
  const bodyEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isVisible && bodyEl.current) {
      disableBodyScroll(bodyEl.current)
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isVisible, bodyEl.current])

  return (
    <PageTakeover
      data-qa={GlobalQA.SideSheetOverlay}
      data-testid="responsiveSideSheetOverlay"
      isVisible={isVisible}
      withoutBackdrop={withoutBackdrop}
    >
      <Transition
        mountOnEnter
        unmountOnExit
        in={isVisible}
        timeout={300}
        // necessary to reflow browser at the right time
        // see https://github.com/reactjs/react-transition-group/issues/299
        onEnter={(node: any) => node.offsetHeight}
      >
        {(state: TransitionState) => (
          <SideSheetContent
            ref={contentEl}
            isBackgroundOffWhiteMobile={isBackgroundOffWhiteMobile}
            state={state}
            data-testid="responsiveSideSheetContent"
            data-qa={GlobalQA.SideSheetContent}
            $isHeightFitContent={isHeightFitContent}
          >
            <ModalOverlay data-testid="responsiveSideSheetModal">
              {Children.map(
                children,
                child => child?.type?.displayName === 'SideSheet.ModalOverlay>' && child,
              )}
            </ModalOverlay>
            <Header>
              <TitleCopy>
                <Title data-qa={GlobalQA.SideSheetTitle} data-testid="responsiveSideSheetTitle">
                  {title}
                </Title>
                {subtitle && (
                  <div
                    data-qa={GlobalQA.SideSheetSubTitle}
                    data-testid="responsiveSideSheetSubTitle"
                  >
                    {subtitle}
                  </div>
                )}
              </TitleCopy>
              <button type="button" onClick={onClose} data-qa={GlobalQA.SideSheetCloseIcon}>
                <XIcon data-testid="responsiveSideSheetCloseIcon" />
              </button>
            </Header>
            <Body data-testid="responsiveSideSheetBody" ref={bodyEl}>
              {Children.map(
                children,
                child => child?.type?.displayName === 'SideSheet.Body' && child,
              )}
            </Body>
            <Footer
              isFooterBoxShadowVisible={isFooterBoxShadowVisible}
              data-testid="responsiveSideSheetFooter"
            >
              {Children.map(
                children,
                child => child?.type?.displayName === 'SideSheet.Footer' && child,
              )}
            </Footer>
          </SideSheetContent>
        )}
      </Transition>
    </PageTakeover>
  )
}

export default ResponsiveSideSheet
