/* tslint:disable:max-line-length */
enum CheckoutQA {
  // GLOBAL ERRORS
  // CheckoutErrorLoginCreateAccountTopForm // Auto-generated
  // CheckoutErrorExpressTopForm? // Auto-generated
  // CheckoutErrorShippingAddressTopForm // Auto-generated
  // CheckoutErrorShippingMethodTopForm // Auto-generated
  // CheckoutErrorPaymentMethodTopForm // Auto-generated
  // CheckoutErrorPromotionTopForm // Auto-generated
  // LOGIN - CREATE AN ACCOUNT HEADERS SECTION USED IN CHECKOUT ONLY
  LoginCreateAccount = 'LoginCreateAccount', // DO NO USE as it is, use Auto-generated names
  // LoginCreateAccountPanel // Auto-generated
  // LoginCreateAccountSectionHeader // Auto-generated
  // LoginCreateAccountOpenedContent // Auto-generated
  // LoginCreateAccountClosedContent // Auto-generated
  CheckoutAsGuestButton = 'CheckoutAsGuestButton',
  DesktopCheckoutWithFlightClub = 'DesktopCheckoutWithFlightClub',
  MobileCheckoutWithFlightClub = 'MobileCheckoutWithFlightClub',
  AccountGuestClosedContentTitle = 'AccountGuestClosedContentTitle',
  AccountGuestClosedContentDescription = 'AccountGuestClosedContentDescription',
  LoggedInUserName = 'LoggedInUserName',
  LoggedInUserEmail = 'LoggedInUserEmail',
  // ERRORS
  // LoginEmailBoxErrorInLine, // Auto-generated
  // LoginPassBoxErrorInLine, // Auto-generated
  // CreateAccountNameBoxErrorInLine, // Auto-generated
  // CreateAccountEmailBoxErrorInLine, // Auto-generated
  // CreateAccountNewPassBoxErrorInLine, // Auto-generated
  // CreateAccountConfirmPassBoxErrorInLine, // Auto-generated
  // EXPRESS CHECKOUT SECTION
  ExpressCheckout = 'ExpressCheckout', // DO NO USE as it is, use Auto-generated names
  // ExpressCheckoutSectionHeader // Auto-generated
  // ExpressCheckoutPanel // Auto-generated
  // ExpressCheckoutOpenedContent // Auto-generated
  // ExpressCheckoutClosedContent // Auto-generated
  ExpressDisclaimerText = 'ExpressDisclaimerText',
  ExpressTermsConditionsLink = 'ExpressTermsConditionsLink',
  ExpressPrivacyPolicyLink = 'ExpressPrivacyPolicyLink',
  ContinueCheckoutTextDivider = 'ContinueCheckoutTextDivider',
  // OrderSummaryTotalAmountExpressCheckout // Auto-generated
  // SHIPPING ADDRESS SECTION
  GuestEmailBox = 'GuestEmailBox',
  ShippingAddress = 'ShippingAddress', // DO NO USE as it is, use Auto-generated names
  // ShippingAddressPanel // Auto-generated
  // ShippingAddressSectionHeader // Auto-generated
  // ShippingAddressOpenedContent // Auto-generated
  // ShippingAddressClosedContent // Auto-generated
  // ShippingAddressEditLink // Auto-generated
  NewShippingNameBox = 'NewShippingNameBox',
  // NewShippingNameBoxName // Auto-generated
  NewShippingAddress1Box = 'NewShippingAddress1Box',
  // NewShippingAddress1BoxName // Auto-generated
  NewShippingAddress2Box = 'NewShippingAddress2Box',
  // NewShippingAddress2BoxName // Auto-generated
  NewShippingCityBox = 'NewShippingCityBox',
  // NewShippingCityBoxName // Auto-generated
  NewShippingZipCodeBox = 'NewShippingZipCodeBox',
  // NewShippingZipCodeBoxName // Auto-generated
  NewShippingCountryBox = 'NewShippingCountryBox',
  // NewShippingCountryBoxName // Auto-generated
  NewShippingStateBox = 'NewShippingStateBox',
  // NewShippingStateBoxName // Auto-generated
  NewShippingPhoneBox = 'NewShippingPhoneBox',
  // NewShippingPhoneBoxName // Auto-generated
  NewShippingContinueButton = 'NewShippingContinueButton',
  NewShippingCancelButton = 'NewShippingCancelButton',
  ShippingAddressRadioGroup = 'ShippingAddressRadioGroup', // Children
  // ShippingAddressRadioLabel // Auto-generated - NodeList
  ShippingContiuneButton = 'ShippingContiuneButton',
  ShippingAddNewAddressButton = 'ShippingAddNewAddressButton',
  // ERRORS
  // NewShippingNameBoxErrorInLine // Auto-generated
  // NewShippingAddress1BoxErrorInLine // Auto-generated
  // NewShippingCityBoxErrorInLine // Auto-generated
  // NewShippingZipCodeBoxErrorInLine // Auto-generated
  // NewShippingCountryBoxErrorInLine // Auto-generated
  // NewShippingStateBoxErrorInLine // Auto-generated
  // NewShippingPhoneBoxErrorInLine // Auto-generated
  // SHIPPING METHOD SECTION
  ShippingMethod = 'ShippingMethod', // DO NO USE as it is, use Auto-generated names
  // ShippingMethodPanel // Auto-generated
  // ShippingMethodSectionHeader // Auto-generated
  // ShippingMethodSectionClosedContent // Auto-generated
  // ShippingMethodSectionEditLink // Auto-generated
  ShippingMethodNoticeText = 'ShippingMethodNoticeText',
  ShippingMethodContinueButton = 'ShippingMethodContinueButton',
  // ShippingMethodRadioLabel<index>', // Auto-generated
  ShippingMethodPriceText = 'ShippingMethodPriceText', // DO NO USE as it is, use Auto-generated names
  // ShippingMethodPriceText<index>', // Auto-generated
  ShippingMethodSummaryPriceText = 'ShippingMethodSummaryPriceText',
  // PAYMENT METHOD SECTION
  PaymentMethod = 'PaymentMethod', // DO NO USE as it is, use Auto-generated names
  // PaymentMethodPanel // Auto-generated
  // PaymentMethodSectionHeader // Auto-generated
  // PaymentMethodSectionClosedContent // Auto-generated
  // PaymentMethodSectionEditLink // Auto-generated
  NewCardFullNameBox = 'NewCardFullNameBox',
  // NewCardFullNameBoxErrorinLine // Auto-generated
  NewCreditCardBox = 'NewCreditCardBox',
  // NewCreditCardBoxErrorinLine // Auto-generated
  NewCardContinueButton = 'NewCardContinueButton',
  NewCardCancelButton = 'NewCardCancelButton',
  PaymentMethodContinueButton = 'PaymentMethodContinueButton',
  PaymentMethodAddNewCardButton = 'PaymentMethodAddNewCardButton',
  PaymentMethodRadioGroup = 'PaymentMethodRadioGroup',
  PaymentRedirectNotice = 'PaymentRedirectNotice',
  CreditCardOption = 'CreditCardOption', // DO NO USE as it is, use Auto-generated names
  // CreditCardOptionRadioLabel<[index]>', // Auto-generated
  // CreditCardOptionRadioLabelTitle, // Auto-generated
  AddNewCardOption = 'AddNewCardOption', // DO NO USE as it is, use Auto-generated names
  // AddNewCardOptionRadioLabel // Auto-generated
  AfterPayOption = 'AfterPayOption', // DO NO USE as it is, use Auto-generated names
  // AfterPayOptionRadioLabel // Auto-generated
  // AfterPayOptionRadioLabelTitle // Auto-generated
  // AfterPayOptionRadioLabelSubTitle // Auto-generated
  AffirmOption = 'AffirmOption', // DO NO USE as it is, use Auto-generated names
  // AffirmOptionRadioLabel // Auto-generated
  // AffirmOptionRadioLabelTitle // Auto-generated
  // AffirmOptionRadioLabelSubTitle // Auto-generated
  PayPalOption = 'PayPalOption',
  PayPalv2Option = 'PayPalv2Option',
  KlarnaOption = 'KlarnaOption',
  // BILLING ADDRESS SECTION
  BillingAddress = 'BillingAddress', // DO NO USE as it is, use Auto-generated names
  // BillingAddressSectionHeader // Auto-generated
  // BillingAddressSectionClosedContent // Auto-generated
  // BillingAddressSectionEditLink // Auto-generated
  BillingSameAsShippingCheckbox = 'BillingSameAsShippingCheckbox',
  BillingNameBox = 'BillingNameBox',
  // BillingNameBoxName // Auto-generated
  BillingAddress1Box = 'BillingAddress1Box',
  // BillingAddress1BoxName // Auto-generated
  BillingAddress2Box = 'BillingAddress2Box',
  // BillingAddress2BoxName // Auto-generated
  BillingCityBox = 'BillingCityBox',
  // BillingCityBoxName // Auto-generated
  BillingZipCodeBox = 'BillingZipCodeBox',
  // BillingZipCodeBoxName // Auto-generated
  BillingCountryBox = 'BillingCountryBox',
  // BillingCountryBoxName // Auto-generated
  BillingStateBox = 'BillingStateBox',
  // BillingStateBoxName // Auto-generated
  BillingPhoneBox = 'BillingPhoneBox',
  // BillingPhoneBoxName // Auto-generated
  BillingContinueButton = 'BillingContinueButton',
  // ERRORS
  // BillingNameBoxErrorInLine // Auto-generated
  // BillingAddress1BoxErrorInLine // Auto-generated
  // BillingCityBoxErrorInLine // Auto-generated
  // BillingZipCodeBoxErrorInLine // Auto-generated
  // BillingCountryBoxErrorInLine // Auto-generated
  // BillingPhoneBoxErrorInLine // Auto-generated
  // ORDER SUMMARY SECTION
  OrderSummary = 'OrderSummary', // DO NO USE as it is, use Auto-generated names
  // OrderSummarySectionHeader // Auto-generated
  OrderSummaryBreakdownToggle = 'OrderSummaryBreakdownToggle',
  OrderSummaryShoeImage = 'OrderSummaryShoeImage',
  OrderSummaryShoeName = 'OrderSummaryShoeName',
  OrderSummaryShoeColorway = 'OrderSummaryShoeColorway',
  OrderSummaryShoeSize = 'OrderSummaryShoeSize',
  OrderSummaryShoeQuantity = 'OrderSummaryShoeQuantity',
  OrderSummaryShoePrice = 'OrderSummaryShoePrice',
  OrderSummaryListPriceTag = 'OrderSummaryListPriceTag',
  OrderSummaryListPriceAmount = 'OrderSummaryListPriceAmount',
  OrderSummaryPriorityProcessingFeeTag = 'OrderSummaryPriorityProcessingFeeTag',
  OrderSummaryPriorityProcessingFeeAmount = 'OrderSummaryPriorityProcessingFeeAmount',
  OrderSummaryTotalPriceTag = 'OrderSummaryTotalPriceTag',
  OrderSummaryTotalPriceAmount = 'OrderSummaryTotalPriceAmount',
  OrderSummarySubtotalTag = 'OrderSummarySubtotalTag',
  OrderSummarySubtotalAmount = 'OrderSummarySubtotalAmount',
  OrderSummaryShippingTag = 'OrderSummaryShippingTag',
  OrderSummaryShippingAmount = 'OrderSummaryShippingAmount',
  OrderSummaryTaxTag = 'OrderSummaryTaxTag',
  OrderSummaryTaxAmount = 'OrderSummaryTaxAmount',
  OrderSummaryApplicableCostsTag = 'OrderSummaryApplicableCostsTag',
  OrderSummaryApplicableCostsAmount = 'OrderSummaryApplicableCostsAmount',
  OrderSummaryCreditTag = 'OrderSummaryCreditTag',
  OrderSummaryCreditAmount = 'OrderSummaryCreditAmount',
  OrderSummaryOrderTotalTag = 'OrderSummaryOrderTotalTag',
  OrderSummaryTaxInfoIcon = 'OrderSummaryTaxInfoIcon',
  FinalCheckout = 'FinalCheckout', // DO NO USE as it is, use Auto-generated names
  // OrderSummaryTotalAmountFinalCheckout // Auto-generated
  PlaceOrderTermsAndConditionsText = 'PlaceOrderTermsAndConditionsText',
  PlaceOrderTermsConditionsLink = 'PlaceOrderTermsConditionsLink',
  PlaceOrderPrivacyPolicyLink = 'PlaceOrderPrivacyPolicyLink',
  PlaceOrderButton = 'PlaceOrderButton',
  // OFFER SUCCESSFUL SECTION
  DesktopOfferSuccessHeroImage = 'DesktopOfferSuccessHeroImage',
  MobileOfferSuccessHeroImage = 'MobileOfferSuccessHeroImage',
  OfferSuccessThankYouTitle = 'OfferSuccessThankYouTitle',
  // ORDER SUCCESSFUL SECTION
  DesktopOrderSuccessHeroImage = 'DesktopOrderSuccessHeroImage',
  MobileOrderSuccessHeroImage = 'MobileOrderSuccessHeroImage',
  OrderSuccessThankYouTitle = 'OrderSuccessThankYouTitle',
  OrderSuccessConfirmationEmailText = 'OrderSuccessConfirmationEmailText',
  OrderSuccessOrderNumberLink = 'OrderSuccessOrderNumberLink',
  OrderSuccessHaveAnyQuestionsTitle = 'OrderSuccessHaveAnyQuestionsTitle',
  OrderSuccessHaveAnyQuestionsText = 'OrderSuccessHaveAnyQuestionsText',
  OrderSuccessCustomerSupportLink = 'OrderSuccessCustomerSupportLink',
  OrderSuccessAllSalesFinalText = 'OrderSuccessAllSalesFinalText',
  OrderSuccessPolicyhereLink = 'OrderSuccessPolicyhereLink',
  OrderSuccessThreeImagesAdidasYeezy = 'OrderSuccessThreeImagesAdidasYeezy',
  OrderSuccessThreeImagesOffWhite = 'OrderSuccessThreeImagesOffWhite',
  OrderSuccessThreeImagesAirJordan = 'OrderSuccessThreeImagesAirJordan',
  // TRACK AND MANAGE YOUR ORDERS
  TrackManageYourOrdersTitle = 'TrackManageYourOrdersTitle',
  TrackManageYourOrdersDescription = 'TrackManageYourOrdersDescription',
  ViewMyAccountButton = 'ViewMyAccountButton',
  AlreadyHaveAnAccountLink = 'AlreadyHaveAnAccountLink',
  WelcomeToFlightClubTitle = 'WelcomeToFlightClubTitle',
  WelcomeToFlightClubDescription = 'WelcomeToFlightClubDescription',
  // Deprecated
  OrderSuccessSectionHeader = 'OrderSuccessSectionHeader',
  OrderSuccessOrderNumber = 'OrderSuccessOrderNumber',
  OrderSuccessShoeImage = 'OrderSuccessShoeImage',
  OrderSuccessThankYou = 'OrderSuccessThankYou',
  OrderSuccessPolicyText = 'OrderSuccessPolicyText',
  OrderSuccessPolicyLink = 'OrderSuccessPolicyLink',
  OrderSuccessCustomerText = 'OrderSuccessCustomerText',
  OrderSuccessSupportEmailLink = 'OrderSuccessSupportEmailLink',
  OrderSuccessContinueShoppingButton = 'OrderSuccessContinueShoppingButton',
  OrderSuccessViewMyOrderButton = 'OrderSuccessViewMyOrderButton',
  // PROMO CODES
  Promotion = 'Promotion', // DO NO USE as it is, use Auto-generated names
  // PromotionPanel // Auto-generated
  // PromotionSectionHeader // Auto-generated
  PromotionBox = 'PromotionBox', // USED for Auto-generated names and as default name
  // PromotionBoxName // Auto-generated
  PromotionDisclaimerText = 'PromotionDisclaimerText',
  PromotionSubmitButton = 'PromotionFormSubmitButton',
  // CVV Check
  CvvTitleHeader = 'CvvTitleHeader',
  CvvDescriptionText = 'CvvDescriptionText',
  CvvSubmitButton = 'CvvSubmitButton',
  CvvInputBoxName = 'CvvInputBoxName',
  // DEPRECATED - TO DELETE AFTER NEW CHECKOUT IS RELEASED
  CheckoutErrorLoginTopForm = 'CheckoutErrorLoginTopForm',
  CheckoutErrorExpressTopForm = 'CheckoutErrorExpressTopForm',
  CheckoutErrorShippingAddressTopForm = 'CheckoutErrorShippingAddressTopForm',
  CheckoutErrorShippingMethodTopForm = 'CheckoutErrorShippingMethodTopForm',
  CheckoutErrorPaymentTopForm = 'CheckoutErrorPaymentTopForm',
  CheckoutErrorBillingTopForm = 'CheckoutErrorBillingTopForm',
  PriceUpdateModalOkButton = 'PriceUpdateModalOkButton',
}

export default CheckoutQA
