import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const Lock = ({
  color = colors.FC2_GREEN,
  width = '12',
  height = '15',
  className = '',
  viewBox = '0 0 12 15',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    <path
      fill={color}
      fillRule="nonzero"
      // tslint:disable-next-line:max-line-length
      d="M11.667 6.625h-1v-2.25C10.667 1.963 8.573 0 6 0S1.333 1.963 1.333 4.375v2.25h-1c-.184 0-.333.14-.333.313v6.812C0 14.44.598 15 1.333 15h9.334c.735 0 1.333-.56 1.333-1.25V6.938c0-.173-.15-.313-.333-.313zm-8.334 0v-1.25c0-1.378 0-3.5 2.667-3.5s2.667 2.122 2.667 3.5v1.25H3.333z"
    />
  </svg>
)
