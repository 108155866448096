export { default as ProductQA } from './ProductQA'
export { default as CheckoutQA } from './CheckoutQA'
export { default as UserAuthQA } from './UserAuthQA'
export { default as SearchQA } from './SearchQA'
export { default as HeaderQA } from './HeaderQA'
export { default as AccountQA } from './AccountQA'
export { default as HomepageQA } from './HomepageQA'
export { default as FooterQA } from './FooterQA'
export { default as PagesQA } from './PagesQA'
export { default as FraudQA } from './FraudQA'
export { default as IconsQA } from './IconsQA'
export { default as ErrorPagesQA } from './ErrorPagesQA'
export { default as GlobalQA } from './GlobalQA'
