/* tslint:disable */
import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const FacebookIcon = ({
  color = colors.FC2_GREY,
  width = '20',
  height = '20',
  className = '',
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M18.774 0H1.226C.547 0 0 .547 0 1.226v17.548C0 19.449.547 20 1.226 20h17.548c.675 0 1.226-.547 1.226-1.226V1.226C20 .547 19.453 0 18.774 0z"
      />
      <path
        fill={color === colors.FC2_WHITE ? colors.FC2_BLACK : colors.FC2_WHITE}
        d="M14.156 6.214c.004 0 .004-.004.009-.004.296-.23.617-.247.72-.247.662.016 1.325.029 1.987.045V3.037h-2.674c-.486 0-.968.086-1.412.272a3.414 3.414 0 0 0-1.333.975 3.516 3.516 0 0 0-.786 2.4v2.575H8.066v3.078h2.605V20h3.103v-7.663h2.56l.473-3.078h-3.033V7.033a1.032 1.032 0 0 1 .382-.819z"
      />
    </g>
  </svg>
)

export default FacebookIcon
