import styled from 'styled-components'

const H4 = styled.h4`
  font-family: ${props => props.theme.fonts.ivar};
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 24px;
  margin: 0;
`

export default H4
