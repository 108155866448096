import styled from 'styled-components'

const H2 = styled.h2`
  font-family: ${props => props.theme.fonts.ivar};
  font-size: 45px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 54px;
  margin: 0;
`

export default H2
