import styled from 'styled-components'
import { Button } from 'shared/components/Button'

const ActionButton = styled(Button).attrs({
  buttonType: 'primary2',
})`
  width: 130px;
  height: 30px;
  line-height: 30px;
  min-height: 30px;
  border: none;
`

export default ActionButton
