import React, { FC } from 'react'
import MobileSideSheet, { IMobileSideSheet } from './MobileSideSheet'
import ResponsiveSideSheet, { IResponsiveSideSheet } from './ResponsiveSideSheet'
import ActionButton from './SideSheetActionButton'

interface ISideSheet {
  Actions: FC
  Body: FC
  Footer: FC
  ModalOverlay: FC
  ActionButton: FC
  MobileOnly: FC<IMobileSideSheet>
  Responsive: FC<IResponsiveSideSheet>
}

type SideSheetComponents = Partial<ISideSheet>

const SideSheet: SideSheetComponents = {
  MobileOnly: MobileSideSheet,
  Responsive: ResponsiveSideSheet,
}

SideSheet.Actions = ({ children }) => <>{children}</>
SideSheet.Actions.displayName = 'SideSheet.Actions'

SideSheet.ModalOverlay = ({ children }) => <>{children}</>
SideSheet.ModalOverlay.displayName = 'SideSheet.ModalOverlay>'

SideSheet.Body = ({ children }) => <>{children}</>
SideSheet.Body.displayName = 'SideSheet.Body'

SideSheet.Footer = ({ children }) => <>{children}</>
SideSheet.Footer.displayName = 'SideSheet.Footer'

SideSheet.ActionButton = ActionButton

export default SideSheet
