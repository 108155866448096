import { FC } from 'react'
import { cond, constant, isEqual } from 'lodash/fp'
import FormikInput from 'shared/components/Input/FormikInput'
import styled from 'styled-components'

export enum FieldType {
  FullName,
  Email,
  CurrentPassword,
  NewPassword,
  ConfirmPassword,
}

interface IAuthFormField {
  title: string
  name: string
  type: FieldType
  qaAttr?: string
  isDarkMode?: boolean
  disabled?: boolean
}

const StyledInput = styled(FormikInput)`
  margin-bottom: 15px;
`

const AuthFormField: FC<IAuthFormField> = ({
  title,
  name,
  type,
  qaAttr,
  isDarkMode,
  disabled = false,
}) => {
  const inputProps = cond([
    [
      isEqual(FieldType.Email),
      constant({
        autoComplete: 'email',
        type: 'text',
        disabled,
      }),
    ],
    [
      isEqual(FieldType.CurrentPassword),
      constant({
        autoComplete: 'current-password',
        type: 'password',
      }),
    ],
    [
      isEqual(FieldType.FullName),
      constant({
        autoComplete: 'name',
        type: 'text',
      }),
    ],
    [
      isEqual(FieldType.NewPassword),
      constant({
        autoComplete: 'new-password',
        type: 'password',
      }),
    ],
    [
      isEqual(FieldType.ConfirmPassword),
      constant({
        type: 'password',
      }),
    ],
  ])(type)

  return (
    <StyledInput
      title={title}
      name={name}
      qaAttr={qaAttr}
      isDarkMode={isDarkMode}
      {...inputProps}
    />
  )
}

export default AuthFormField
