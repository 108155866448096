/* tslint:disable:max-line-length */
enum UserAuthQA {
  // LOGIN CREATE ACCOUNT HEADER JOIN/SIGN IN SECTION ONLY
  // LoginPanel, // Auto-generated
  // LoginHeader, // Auto-generated
  // LoginTitle, // Auto-generated
  // CreateAccountPanel, // Auto-generated
  // CreateAccountHeader, // Auto-generated
  // CreateAccountTitle, // Auto-generated
  // LOGIN CREATE ACCOUNT FORMS USED IN JOIN/SIGN IN AND CHECKOUT
  Login = 'Login',
  CreateAccount = 'CreateAccount',
  LoginEmailBox = 'LoginEmailBox',
  // LoginEmailBoxName // Auto-generated
  LoginPassBox = 'LoginPassBox',
  // LoginPassBoxName // Auto-generated
  LoginForgotPasswordLink = 'LoginForgotPasswordLink',
  NewToFlightClubText = 'NewToFlightClubText',
  PrimaryLoginButton = 'PrimaryLoginButton',
  SecondaryCreateAccountButton = 'SecondaryCreateAccountButton',
  CreateAccountNameBox = 'CreateAccountNameBox',
  // CreateAccountNameBoxName // Auto-generated
  CreateAccountEmailBox = 'CreateAccountEmailBox',
  // CreateAccountEmailBoxName // Auto-generated
  CreateAccountNewPassBox = 'CreateAccountNewPassBox',
  // CreateAccountNewPassBoxName // Auto-generated
  CreateAccountConfirmPassBox = 'CreateAccountConfirmPassBox',
  // CreateAccountConfirmPassBoxName // Auto-generated
  AlreadyHaveAnAccountText = 'AlreadyHaveAnAccountText',
  PrimaryCreateAccountButton = 'PrimaryCreateAccountButton',
  SecondaryLoginButton = 'SecondaryLoginButton',
  // FORGOT PASSWORD SECTION
  ForgotPassword = 'ForgotPassword', // DO NO USE as it is, use Auto-generated names
  // ForgotPasswordHeader, // Auto-generated
  ForgotPasswordEmailBox = 'ForgotPasswordEmailBox',
  // ForgotPasswordEmailBoxName // Auto-generated
  ForgotPasswordSubmitButton = 'ForgotPasswordSubmitButton',
  ForgotPasswordBackToLoginButton = 'ForgotPasswordBackToLoginButton',
  // EMAIL SENT SECTION
  EmailSentHeader = 'EmailSentHeader',
  EmailSentEmailText = 'EmailSentEmailText',
  EmailSentLoginButton = 'EmailSentLoginButton',
  EmailSentBackToResetPasswordButton = 'EmailSentBackToResetPasswordButton',
  /// ERROR VALIDATION
  // LOGIN
  // LoginEmailBoxErrorInLine, // Auto-generated
  // LoginPassBoxErrorInLine, // Auto-generated
  // LoginErrorTopPage, // Auto-generated
  // ACCOUNT CREATION
  // CreateAccountNameBoxErrorInLine, // Auto-generated
  // CreateAccountEmailBoxErrorInLine, // Auto-generated
  // CreateAccountNewPassBoxErrorInLine, // Auto-generated
  // CreateAccountConfirmPassBoxErrorInLine, // Auto-generated
}

export default UserAuthQA
