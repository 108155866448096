import mapValues from 'lodash/mapValues'
import { ComponentType, PropsWithChildren } from 'react'
import MediaQuery from 'react-responsive'
import { sizes } from 'shared/lib/media'

/**
 * Prevents component from being rendered to the DOM given a
 * `size` from `media.ts up to a optional maxSize.  For simple
 * hiding using "display: none" use the helpers found in
 * `shared/lib/responsiveness.
 *
 *  Example: const DesktopHeader = renderStart.large(Header)
 */
export const renderStart = mapValues(
  sizes,
  (size: number) =>
    (WrappedComponent: ComponentType<any>, maxSize?: number) =>
    (props: PropsWithChildren<any>) =>
      (
        <>
          <MediaQuery minWidth={size} maxWidth={maxSize}>
            <WrappedComponent {...props} />
          </MediaQuery>
        </>
      ),
)

/**
 * Prevents component from being rendered to the DOM given a
 * `size` from `media.ts up to a optional maxSize.  For simple
 * hiding using "display: none" use the helpers found in
 * `shared/lib/responsiveness.
 *
 * Example: const MobileHeader = renderUntil.large(Header)
 */
export const renderUntil = mapValues(
  sizes,
  (maxSize: number) => (WrappedComponent: ComponentType<any>) => (props: PropsWithChildren<any>) =>
    (
      <>
        <MediaQuery minWidth={sizes.base} maxWidth={maxSize}>
          <WrappedComponent {...props} />
        </MediaQuery>
      </>
    ),
)
