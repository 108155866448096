import { HttpLink } from '@apollo/client'

const createGraphQLHttpLink = () => {
  return new HttpLink({
    uri: `/graphql`,
    credentials: 'same-origin',
  })
}

export default createGraphQLHttpLink
