import { FC } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Card as BaseCard } from 'shared/components/CardDeprecated'
import { IRefinementListProps } from '../IRefinementListProps'
import DesktopConditionFilter from './DesktopConditionFilter'
import MobileConditionFilter from './MobileConditionFilter'
import { SearchQA } from 'shared/dataAttributes'
import styled from 'styled-components'
import { hide as hideUtil } from 'shared/lib'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'

const Card = styled(BaseCard)<{ isHidden: boolean }>`
  ${({ isHidden }) => hideUtil({ isHidden })};
`

const ConditionFilter: FC<IRefinementListProps> = ({ items, refine, attribute, hide }) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value[value.length - 1],
    })
    refine(value)
  }
  return items.length > 0 ? (
    <Card
      isHidden={hide}
      title={SearchFiltersHumanizedRefinement()[attribute]}
      qaAttr={SearchQA.FilterCondition}
    >
      <MobileConditionFilter items={items} refine={handleRefinement} />
      <DesktopConditionFilter items={items} refine={handleRefinement} />
    </Card>
  ) : null
}

ConditionFilter.displayName = 'ConditionFilterContainer'

export default connectRefinementList(ConditionFilter)
