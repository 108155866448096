import { ErrorMessage, Field } from 'formik'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.header<{ isDarkMode?: boolean }>`
  ${fonts.SUBTITLE_3};
  color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_WHITE : colors.FC2_GREY)};
  display: flex;
  justify-content: space-between;
`

const DarkModeWebkitAutofillOverrideStyles = `
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 30px black inset !important;
`

const InputFieldComponent = ({ as, children, ...props }) => {
  const Component = as || Field
  // omit success and isDarkmode props to fix warnings
  const { isDarkMode, success, ..._props } = props
  return <Component {..._props}>{children}</Component>
}

export const InputField = styled(InputFieldComponent)<{ isDarkMode: boolean; error: boolean }>`
  min-height: 40px;
  border: 1px solid ${({ error }) => (error ? `${colors.FC2_RED}` : `${colors.FC2_LIGHTEST_GREY}`)};
  margin-top: 5px;
  font-size: 16px;
  padding: 0 15px;
  color: ${({ disabled, isDarkMode }) =>
    disabled ? colors.FC2_GREY : isDarkMode ? colors.FC2_WHITE : colors.FC2_BLACK};
  &:focus {
    border-color: ${colors.FC2_BLACK};
  }
  ${({ isDarkMode }) =>
    isDarkMode && `&:focus { color: ${colors.FC2_WHITE}; border-color: ${colors.FC2_WHITE};}`};
  ${({ isDarkMode }) =>
    isDarkMode &&
    `background-color: ${colors.FC2_BLACK};
      &:-webkit-autofill { ${DarkModeWebkitAutofillOverrideStyles}};
      &:-webkit-autofill:focus { ${DarkModeWebkitAutofillOverrideStyles}};`}
`

const ErrorComponent = ({ as, children, ...props }) => {
  const Component = as || ErrorMessage
  const errorMessageProps = !as ? { component: 'span' } : {}
  return (
    <Component {...errorMessageProps} {...props}>
      {children}
    </Component>
  )
}

export const Error = styled(ErrorComponent)`
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_RED};
`
