import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const InfoIcon = ({
  color = colors.FC2_RED,
  width = '12',
  height = '12',
  className = '',
  viewBox = '0 0 12 12',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <circle cx="6" cy="6" r="5.5" stroke={color} fill="none" />
    <path d="M5.504 3.288H6.416V4.152H5.504V3.288ZM5.504 4.864H6.416V9H5.504V4.864Z" fill={color} />
  </svg>
)
