import cookie from 'cookie';
import { get, pipe } from 'lodash/fp';
import { parse } from 'shared/lib/safeJSON';

import { gql } from '@apollo/client';

const parseCookies = cookies => cookie.parse(cookies)

const getCurrencyCookie = pipe(parseCookies, get('currency'), parse)

export const typeDef = gql`
  extend type Currency {
    isSelected: Boolean
    isoCode: String
    displaySymbol: String
    disambiguatedSymbol: String
  }

  extend type Query {
    selectedCurrency: Currency
  }
`

const DEFAULT_SELECTED_CURRENCY_ISO = 'USD'
const DEFAULT_SELECTED_CURRENCY_SYMBOL = '$'

const getIsoCode = get('isoCode')
const getCurrencySymbol = currency => get('symbol')(currency)
const getDisambiguatedSymbol = currency => get('disambiguatedSymbol')(currency)

export const defaultState = context => {
  const currency = get('currency')(context)
  const isoCode = getIsoCode(currency) || DEFAULT_SELECTED_CURRENCY_ISO
  const displaySymbol = getCurrencySymbol(currency) || DEFAULT_SELECTED_CURRENCY_SYMBOL
  const disambiguatedSymbol = getDisambiguatedSymbol(currency) || ''
  return {
    selectedCurrency: {
      __typename: 'Currency',
      isoCode,
      displaySymbol,
      disambiguatedSymbol,
    },
  }
}

export const resolvers = {
  Currency: {
    isSelected: ({ isoCode }, _args, _context) => {
      const currencyCookie = typeof window !== 'undefined' && getCurrencyCookie(document.cookie)
      const cookieISO = getIsoCode(currencyCookie)
      return cookieISO === isoCode
    },
  },
  Query: {
    selectedCurrency: (_currency, _args, context) => {
      // To use Apollo Context in local resolvers
      // https://github.com/apollographql/apollo-client/issues/4533
      const currency = get('client.context.currency')(context)
      const isoCode =
        (typeof window === 'undefined'
          ? getIsoCode(currency)
          : getIsoCode(getCurrencyCookie(document.cookie))) || DEFAULT_SELECTED_CURRENCY_ISO
      const displaySymbol =
        (typeof window === 'undefined'
          ? getCurrencySymbol(currency)
          : getCurrencySymbol(getCurrencyCookie(document.cookie))) ||
        DEFAULT_SELECTED_CURRENCY_SYMBOL

      const disambiguatedSymbol = 
        (typeof window === 'undefined'
          ? getDisambiguatedSymbol(currency)
          : getDisambiguatedSymbol(getCurrencyCookie(document.cookie)) || ''
        )
      return {
        __typename: 'Currency',
        isoCode,
        displaySymbol,
        disambiguatedSymbol,
      }
    },
  },
}
