import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const CloseX = ({
  color = colors.FC2_GREY,
  width = '12',
  height = '12',
  className = '',
  viewBox = '0 0 12 12',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      // tslint:disable-next-line:max-line-length
      d="M7.059 6l4.412 4.412.529.53L10.941 12l-.53-.53L6 7.06 1.588 11.47l-.53.529L0 10.941l.53-.53L4.94 6 .53 1.588 0 1.058 1.059 0l.53.53L6 4.94 10.412.53l.53-.529L12 1.059l-.53.53L7.06 6z"
    />
  </svg>
)
