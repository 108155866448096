import { ReactNode, useEffect, useRef, useState } from 'react'
import { colors, fonts, zIndex } from 'shared/lib'
import styled from 'styled-components'

const TheSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: ${zIndex.justOverNav};
`
export const SpinnerIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 3px solid ${colors.FC2_OFF_WHITE};
  border-right-color: ${colors.FC2_RED};
  animation: rotate 700ms infinite linear;
  transform: translateZ(0px);

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const Text = styled.p`
  margin-top: 40px;
  margin-bottom: 10px;
  ${fonts.HEADER_2}
`
const SubText = styled.p`
  margin: 0;
  ${fonts.BODY_TEXT}
`

// 125ms is practically "instant" - we pass in 1000ms for Spinners that represent page loads
// Best practices for page loading screens - show them if load for more than 1 second
// https://theblog.adobe.com/xd-essentials-best-practices-for-animated-progress-indicators/
const DEFAULT_THRESHOLD = 125

interface SpinnerProps {
  showSpinner: boolean
  text?: string
  subText?: string
  threshold?: number
  children?: ReactNode
}

// TODO: Move spinner into PageLayout when refactor PageLayout
// to wrap everything except Checkout
const Spinner = ({
  children,
  showSpinner,
  subText,
  text,
  threshold = DEFAULT_THRESHOLD,
}: SpinnerProps) => {
  const beginWithSpinner = typeof threshold === 'number' && threshold === 0
  const [display, setDisplay] = useState(beginWithSpinner)
  const intervalRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (showSpinner) {
      intervalRef.current = setTimeout(() => {
        setDisplay(true)
      }, threshold)
    } else {
      clearTimeout(intervalRef.current!)
      setDisplay(false)
    }
    return () => clearTimeout(intervalRef.current!)
  }, [showSpinner])

  return (
    <>
      {children}
      {display && (
        <TheSpinnerWrapper data-testid="spinner">
          <SpinnerIcon />
          <Text>{text}</Text>
          <SubText>{subText}</SubText>
        </TheSpinnerWrapper>
      )}
    </>
  )
}

export default Spinner
