import { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useFetchAllUserOffersQuery } from 'api/productTemplateListsApi'
import { t } from 'localization'
import { DeleteOfferModal } from 'offers/DeleteOfferModal'
import { OffersList } from 'offers/OffersList'
import { SpinnerIcon } from 'shared/components/Spinner'
import { useUser } from 'shared/hooks/useUser'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import { useAppSelector } from 'store/hooks'
import { getActiveOffers } from 'store/offersSlice'

type OffersListContentsProps = {
  isDeleteOfferModalOpen: boolean
  handleClose: () => void
}

export const OffersListContents = ({
  isDeleteOfferModalOpen,
  handleClose,
}: OffersListContentsProps) => {
  const TP = 'offers.components.OffersListContents'
  const { isMobile } = useWindowSize()
  const [mobileSlideOutHeight, setMobileSlideoutHeight] = useState(0)
  const { isAuthenticated } = useUser()
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const { currentPaginationToken, nextPageQueue } = useAppSelector((state) => state.offers)
  const activeOffers = useAppSelector((state) => getActiveOffers(state))

  const { isLoading, isFetching } = useFetchAllUserOffersQuery(
    {
      paginationToken: currentPaginationToken === 'start' ? '' : currentPaginationToken,
    },
    {
      skip: !isAuthenticated || currentPaginationToken === undefined,
      refetchOnMountOrArgChange: 10,
    },
  )

  // Resize slideout height on mobile
  useEffect(() => {
    if (isMobile && ref.current) {
      setMobileSlideoutHeight(ref.current.offsetHeight)
    }
  }, [isMobile, ref.current?.offsetHeight])

  if ((isLoading || isFetching) && nextPageQueue?.[0] === 'start') {
    return (
      <SpinnerWrapper data-qa="SpinnerWrapper" $mobileSlideOutHeight={mobileSlideOutHeight}>
        <SpinnerIcon />
      </SpinnerWrapper>
    )
  }

  if (!activeOffers.length) {
    return (
      <EmptyState>
        <span data-qa="OffersListContentsEmptyState">
          {t(`${TP}.emptyStateCta`, 'Place an offer and track its status here')}
        </span>
      </EmptyState>
    )
  }

  return (
    <Wrapper ref={ref}>
      <OffersList listItems={activeOffers} handleSlideOutClose={handleClose} />
      {isDeleteOfferModalOpen && <DeleteOfferModal />}
    </Wrapper>
  )
}

const EmptyState = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 20px;
  font-size: 16px;
  > span {
    width: 323px;
  }
`

const SpinnerWrapper = styled.div<{ $mobileSlideOutHeight: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $mobileSlideOutHeight }) =>
    $mobileSlideOutHeight ? `${$mobileSlideOutHeight}px` : '100%'};
  min-height: 350px;
`
const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior-y: none;
`
