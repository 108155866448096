import { SVGIcon } from 'shared/components/Icons'

interface IRotatingCaretProps {
  isOpen: boolean
  color?: string
  className?: string
}

export const RotatingCaret = ({ isOpen, color, className }: IRotatingCaretProps) => (
  <SVGIcon name="caret" color={color} className={className} direction={isOpen ? 'down' : 'up'} />
)
