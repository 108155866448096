import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const Caret2 = ({
  color = colors.FC2_BLACK,
  width = '13',
  height = '22',
  className = '',
  viewBox = '0 0 13 22',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <path id="R Arrow" d="M1 21L11 11.029L1 1" fill="none" stroke={color} strokeWidth="1.5" />
  </svg>
)
