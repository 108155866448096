import React, { FC, ReactNode, useState, useEffect } from 'react'
import { SVGIcon } from 'shared/components/Icons'
import { colors, fonts } from 'shared/lib'
import { ICurrency } from 'shared/types/ICurrency'
import styled from 'styled-components'

type CurrencyDropdownProps = {
  availableCurrencies: ICurrency[]
  disabled?: boolean
  name: string
  /** Callback that handles state update when a country is selected. Receives country ISO code. */
  onChange: (countryCode: string) => void
  qaAttr?: string
  title: string | ReactNode
  defaultCurrency: string
}

const CurrencyDropdownField: FC<CurrencyDropdownProps> = ({
  availableCurrencies,
  disabled,
  name,
  onChange,
  qaAttr,
  title,
  defaultCurrency,
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency)

  // Update the selected currency when the selected region changes
  useEffect(() => {
    if (defaultCurrency) {
      setSelectedCurrency(defaultCurrency)
    }
  }, [defaultCurrency])

  const handleChangeAndBlur = e => {
    const value = e.currentTarget.value
    setSelectedCurrency(value)
    onChange(value)
  }

  return (
    <FieldBox>
      <FormLabel>{title}</FormLabel>
      <SelectBox>
        <StyledSelect
          onBlur={handleChangeAndBlur}
          onChange={handleChangeAndBlur}
          data-qa={qaAttr}
          disabled={disabled}
          autoComplete="country"
          name={name}
          availableCurrencies={availableCurrencies}
          value={selectedCurrency}
        >
          {availableCurrencies?.map(item => (
            <option key={item.isoCode} value={item.isoCode}>
              {item.symbol} {item.isoCode}
            </option>
          ))}
        </StyledSelect>
        <StyledCaret name="caret" color={disabled ? colors.FC2_GREY : colors.FC2_BLACK} />
      </SelectBox>
    </FieldBox>
  )
}

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
`

const FormLabel = styled.label`
  ${fonts.SUBTITLE_3}
  color: ${colors.FC2_GREY};
  display: flex;
  justify-content: space-between;
`

const StyledCaret = styled(SVGIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 14px;
  pointer-events: none;
`

const SelectBox = styled.div`
  position: relative;
`

const StyledSelect = styled.select`
  min-height: 40px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  border-radius: 0;
  margin-top: 5px;
  font-size: 14px;
  padding: 0 15px;
  color: ${({ disabled }) => (disabled ? colors.FC2_GREY : colors.FC2_BLACK)};
  background-color: ${colors.FC2_WHITE};
  min-width: 100%;
  height: 40px;
  appearance: none;
`

export default CurrencyDropdownField
