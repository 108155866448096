import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const Search = ({
  color = colors.FC2_BLACK,
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}: IconProps) => (
  <svg
    className={`svg-icon ${className || ''}`}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="nonzero" stroke={color} strokeWidth="1.5" transform="translate(1 1)">
      <ellipse cx="5.727" cy="5.625" rx="5.727" ry="5.625" />
      <path strokeLinecap="round" d="M9.757 9.853l4.21 4.136" />
    </g>
  </svg>
)
