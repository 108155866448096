import styled from 'styled-components'

import Button from 'shared/components/Button'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { colors } from 'shared/lib'
import { setIsDeleteSizeModalOpen } from 'store/favoritesSlice'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { sizes } from 'shared/lib/media'
import { t } from 'localization'
import { useAppDispatch } from 'store/hooks'
import { useAppSelector } from 'store/hooks'
import { useClickOutside } from 'shared/hooks'
import { useDeleteFavoriteMutation } from 'api/productTemplateListsApi'

export const DeleteSizeModal = () => {
  const TP = 'favorites.components.DeleteSizeModal'
  const dispatch = useAppDispatch()
  const ref = useClickOutside(() => dispatch(setIsDeleteSizeModalOpen(false)))
  const { favoriteToBeRemoved } = useAppSelector((state) => state.favorites)
  const [deleteFavorite, { isLoading }] = useDeleteFavoriteMutation()

  const handleClose = () => {
    dispatch(setIsDeleteSizeModalOpen(false))
  }

  const handleRemoveClick = async () => {
    if (favoriteToBeRemoved) {
      const { productTemplateId, sizes, productTemplateSlug } = favoriteToBeRemoved

      sendTrackingEvent('FAVORITE_REMOVE_CONFIRM_TAP', {
        product_template_slug: productTemplateSlug,
        size: `${sizes[0]}`,
      })

      await deleteFavorite({ productTemplateId, sizes })
    }

    dispatch(setIsDeleteSizeModalOpen(false))
  }

  return (
    <ClientOnlyPortal selector=".main-page-layout" itemsAlignment="center" id="delete-size-modal">
      <LoginPanelWrapper ref={ref}>
        <DeletePrompt>
          <DeletePromptCTA data-qa="DeleteSizeModalPromptCopy">
            {t(
              `${TP}.deletePromptCta`,
              'Are you sure you want to remove this item from your Favorites?',
            )}
          </DeletePromptCTA>
          <CloseButton data-qa="DeleteSizeModalCloseButton" onClick={handleClose}>
            <CloseX width="15" height="15" />
          </CloseButton>
        </DeletePrompt>
        <Button
          disabled={isLoading}
          $fill
          onClick={handleRemoveClick}
          data-qa="DeleteSizeModalDeleteButton"
        >
          {t(`${TP}.removeButtonText`, 'Remove Favorite')}
        </Button>
      </LoginPanelWrapper>
    </ClientOnlyPortal>
  )
}

const LoginPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  width: 425px;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_BLACK};
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  > button {
    width: 385px;
    margin: auto 20px 20px 20px;
  }
  @media (max-width: ${sizes.large / 16}em) {
    height: 150px;
    width: 335px;
    > button {
      width: 295px;
    }
  }
`

const DeletePrompt = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`

const DeletePromptCTA = styled.div`
  @media (max-width: ${sizes.large / 16}em) {
    width: 250px;
    height: 38px;
  }
`
const CloseButton = styled.button`
  cursor: pointer;
  width: 15px;
  height: 15px;
`
