import styled from 'styled-components'

export const InputGroup = styled.div`
  display: flex;
  & + & {
    margin-top: 20px;
  }
  &&& > * {
    flex: 1;
    margin-left: 20px;
    margin-top: 0;
  }
  &&& > *:first-child {
    margin-left: 0;
  }
`
