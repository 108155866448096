import Bugsnag from '@bugsnag/js'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'

import { trackImpactIdentify } from 'tracking/impactService'
import { useUser } from 'shared/hooks/useUser'

type ImpactTagProps = {
  isOneTrustEnabled?: boolean
}

const ImpactTag = ({ isOneTrustEnabled }: ImpactTagProps) => {
  const router = useRouter()
  const { currentUser } = useUser()
  const [initialPageView, setInitialPageView] = useState(false)
  const scriptId = 'impact_script'

  /**
   * Checks for **window.impact_script_OK** flag (indicating Impact ire object has been instantiated)
   * to track the initial page view.
   *
   * Exponential backoff is used to retry due to added delay from OneTrust while enabling the script.
   */
  const trackInitialPageView = (retry = 3) => {
    if (initialPageView) return

    if (!window?.impact_script_OK) {
      if (retry > 0) {
        setTimeout(() => {
          trackInitialPageView(retry - 1)
        }, 1000 * 2 ** (4 - retry))
      }

      return
    }

    setInitialPageView(true)
    trackImpactIdentify({ id: currentUser?.id, email: currentUser?.email })
  }

  // Track page view on route change
  useEffect(() => {
    const handleRouteChangeComplete = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow && window?.impact_script_OK) {
        trackImpactIdentify({ id: currentUser?.id, email: currentUser?.email })
      }
    }

    //add listener
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    //clean up listener
    return () => router.events.off('routeChangeComplete', handleRouteChangeComplete)
  }, [router.events, currentUser?.id, currentUser?.email])

  return (
    <Script
      className="optanon-category-C0002"
      id={scriptId}
      key="impact"
      strategy="lazyOnload"
      type={isOneTrustEnabled ? 'text/plain' : 'text/javascript'}
      onReady={() => trackInitialPageView()}
      onError={(e: Error) => {
        Bugsnag.notify(e, (event) => {
          event.context = 'Impact script failed to load.'
          event.severity = 'warning'
        })
      }}
    >
      {`
      /**********************************************************************************
      * Since Impact script is linked to performance cookies (OneTrust C0002 category)
      * we add its configuration to the C0002Scripts Map to be enabled/disabled by the OptanonWrapper callback.
      ***********************************************************************************/
      window.C0002Scripts = window.C0002Scripts || new Map();
      
      const config = {  
        script: function impactScript() {
          /* BEGIN ImpactTag script as provided by Impact */

          (function(a, b, c, d, e, f, g) {
            e['ire_o'] = c;
            e[c] = e[c] || function() {
              (e[c].a = e[c].a || []).push(arguments)
            };
            f = d.createElement(b);
            g = d.getElementsByTagName(b)[0];
            f.async = 1;
            f.src = a;
            g.parentNode.insertBefore(f, g);
          })('https://d.impactradius-event.com/A2549307-b7ca-4ce7-891b-eaac645bb85f1.js', 'script', 'ire', document, window);

          /* END ImpactTag script as provided by Impact */
        },
        scriptId: '${scriptId}',
        scriptAddedToPage: false
      }

      window.C0002Scripts.set('${scriptId}', config);
      
      /**********************************************************************************
      * If OneTrust is disabled,
      * OR if OneTrust already executed OptanonWrapper and C0002 scripts are allowed
      * then run the script immediately.
      **********************************************************************************/
      const isScriptAllowed = window.OnetrustActiveGroups?.includes('C0002') && !window.C0002Scripts?.get('${scriptId}')?.scriptAddedToPage;
  
      if (!${isOneTrustEnabled} || isScriptAllowed) {
        config.script();
        config.scriptAddedToPage = true;
        window[config.scriptId + '_OK'] = true;
      }
      `}
    </Script>
  )
}

export default ImpactTag
