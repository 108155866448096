import styled from 'styled-components'

import { IconsQA } from 'shared/dataAttributes'

type VisaCardProps = {
  size?: 'small' | ''
}

export const VisaCard = ({ size = '' }: VisaCardProps) => (
  <CustomSvg
    $size={size}
    data-qa={IconsQA.VisaCardIcon}
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="35" height="23" rx="1.5" fill="white" stroke="#E7E7E7" />
    <path
      d="M14.1178 8.14586L10.9726 15.8763H8.90137L7.37808 9.75029C7.35305 9.4551 7.17823 9.19515 6.91781 9.06588C6.30822 8.76366 5.66388 8.54126 5 8.40391V8.17952H8.28767C8.73513 8.17938 9.11622 8.51245 9.1863 8.96491L10.0082 13.4528L12.0247 8.19074L14.1178 8.14586ZM22.1507 13.3519C22.1507 11.3098 19.411 11.1977 19.4329 10.2888C19.4329 10.0083 19.6959 9.71663 20.2548 9.63809C20.9094 9.57551 21.5684 9.69571 22.1616 9.98591L22.5014 8.34781C21.9203 8.12086 21.3041 8.00303 20.6822 8C18.7644 8 17.3945 9.05466 17.3945 10.5581C17.3945 11.6801 18.3589 12.2972 19.0932 12.6674C19.8274 13.0377 20.1014 13.2845 20.1014 13.6324C20.1014 14.1485 19.4986 14.3841 18.9397 14.3841C18.2444 14.3983 17.5573 14.2283 16.9452 13.8904L16.5726 15.5846C17.2703 15.866 18.0145 16.007 18.7644 15.9997C20.8027 15.9997 22.1288 14.9675 22.1397 13.3519H22.1507ZM27.2027 15.8763H29L27.4329 8.14586H25.789C25.4267 8.14696 25.1013 8.37345 24.9671 8.71807L22.0521 15.8763H24.0904L24.4959 14.7543H26.9836L27.2027 15.8763ZM25.011 13.1387L26.0301 10.2327L26.6548 13.1162L25.011 13.1387ZM16.8575 8.14586L15.2575 15.8763H13.3507L14.9507 8.14586H16.8575Z"
      fill="#1A1F71"
    />
  </CustomSvg>
)

const CustomSvg = styled.svg<{ $size: VisaCardProps['size'] }>`
  height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
  width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
`
