import styled from 'styled-components'
import { colors } from 'shared/theme'
import FlightClubLogoSVG from 'shared/svg/flightclub_logo.svg'

const FlightClubLogo = styled(FlightClubLogoSVG)`
  color: ${colors.red};
  height: 24px;
  width: 127px;
`

export default FlightClubLogo
