import { FC, Fragment } from 'react'
import HiddenCheckbox from './HiddenCheckbox'
import Label from './Label'

interface CheckBoxProps {
  onClick?()
  onChange?()
  checked: boolean
  id?: string
  label: string
  noMargin?: boolean
  qaAttr?: string
}
const Checkbox: FC<CheckBoxProps> = ({
  onClick,
  onChange,
  checked,
  id,
  label,
  qaAttr,
  noMargin = false,
}) => {
  return (
    <Fragment>
      <HiddenCheckbox
        data-qa={`${qaAttr}Checkbox`}
        id={id || label}
        checked={checked}
        onChange={onChange}
      />
      <Label data-qa={`${qaAttr}Label`} htmlFor={id || label} onClick={onClick} noMargin={noMargin}>
        {label}
      </Label>
    </Fragment>
  )
}

export default Checkbox
