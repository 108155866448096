import { Children, FC, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { get } from 'lodash/fp'
import { colors, zIndex } from 'shared/lib'
import { renderUntil } from 'shared/hocs/render'
import ActionButton from './SideSheetActionButton'
import { SVGIcon } from '../Icons'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

const Wrapper = styled.div<{ isVisible: boolean; isBackgroundWhite: boolean }>`
  background-color: ${({ isBackgroundWhite }) =>
    isBackgroundWhite ? colors.FC2_WHITE : colors.FC2_OFF_WHITE};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: ${zIndex.pageTakeover};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

const CloseButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 20px;
`

const ActionBar = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const VerticalBar = styled.div`
  height: 26px;
  border-left: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const ActionBarButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 15px;
  flex-basis: 100%;
`

const ActionsWrapper = styled.div`
  width: 100%;
`

const FilterButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const XIcon = styled(SVGIcon).attrs({
  size: 'small',
  name: 'closeX',
})``

export interface IMobileSideSheet {
  isVisible: boolean
  isBackgroundWhite: boolean
  onClose: () => void
  onActionClick?: () => void
  actionText?: string
  children?: string | JSX.Element | JSX.Element[]
}
const MobileSideSheet: FC<IMobileSideSheet> = ({
  isVisible,
  isBackgroundWhite,
  onClose,
  onActionClick,
  actionText,
  children,
}) => {
  const actions = Children.map(
    children,
    child => child.type.displayName === 'SideSheet.Actions' && child,
  )

  const wrapperEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (isVisible && wrapperEl.current) {
        disableBodyScroll(wrapperEl.current)
      } else {
        clearAllBodyScrollLocks()
      }
    }, 0)

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isVisible, wrapperEl?.current])

  return (
    <>
      <Wrapper
        ref={wrapperEl}
        data-testid="mobileSideSheetOverlay"
        isVisible={isVisible}
        isBackgroundWhite={isBackgroundWhite}
      >
        <ActionBar>
          <CloseButton>
            <div data-testid="mobileSideSheetCloseIcon" onClick={onClose}>
              <XIcon />
            </div>
          </CloseButton>
          <VerticalBar />
          <ActionBarButtonsWrapper>
            {get('length')(actions) ? (
              <ActionsWrapper data-testid="mobileSideSheetActions">{actions}</ActionsWrapper>
            ) : (
              <FilterButtonsWrapper>
                <ActionButton onClick={onActionClick} data-testid="mobileSideSheetActionText">
                  {actionText}
                </ActionButton>
              </FilterButtonsWrapper>
            )}
          </ActionBarButtonsWrapper>
        </ActionBar>
        <div data-testid="mobileSideSheetBody">
          {Children.map(children, child => child.type.displayName === 'SideSheet.Body' && child)}
        </div>
        <div data-testid="mobileSideSheetFooter">
          {Children.map(children, child => child.type.displayName === 'SideSheet.Footer' && child)}
        </div>
      </Wrapper>
    </>
  )
}

export default renderUntil.large(MobileSideSheet)
