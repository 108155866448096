import { CardNumber } from 'shared/components/Icons/SVGIcons/CardNumber'
import { Caret } from 'shared/components/Icons/SVGIcons/Caret'
import { Caret2 } from 'shared/components/Icons/SVGIcons/Caret2'
import { CircleCheck } from 'shared/components/Icons/SVGIcons/CircleCheck'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { ErrorIcon } from 'shared/components/Icons/SVGIcons/ErrorIcon'
import { HamburgerMenu } from 'shared/components/Icons/SVGIcons/HamburgerMenu'
import { InfoIcon } from 'shared/components/Icons/SVGIcons/InfoIcon'
import { Lock } from 'shared/components/Icons/SVGIcons/Lock'
import { Search } from 'shared/components/Icons/SVGIcons/Search'
import FacebookIcon from 'shared/components/Icons/SVGIcons/SocialMediaIcons/FacebookIcon'
import InstagramIcon from 'shared/components/Icons/SVGIcons/SocialMediaIcons/InstagramIcon'
import TwitterIcon from 'shared/components/Icons/SVGIcons/SocialMediaIcons/TwitterIcon'
import styled from 'styled-components'

type IconDirection = 'left' | 'right' | 'up' | 'down'

type IconSize = 'small' | 'large' | number

export type IconType =
  | 'cardNumber'
  | 'caret'
  | 'caret2'
  | 'closeX'
  | 'circleCheck'
  | 'errorIcon'
  | 'facebook'
  | 'hamburgerMenu'
  | 'infoIcon'
  | 'instagram'
  | 'lock'
  | 'search'
  | 'twitter'

type SVGs = { [K in IconType]: any }

export const SVGComponent: SVGs = {
  cardNumber: CardNumber,
  caret: Caret,
  caret2: Caret2,
  circleCheck: CircleCheck,
  closeX: CloseX,
  errorIcon: ErrorIcon,
  facebook: FacebookIcon,
  hamburgerMenu: HamburgerMenu,
  infoIcon: InfoIcon,
  instagram: InstagramIcon,
  lock: Lock,
  search: Search,
  twitter: TwitterIcon,
}

interface ISVGIconProps {
  name: IconType
  direction?: IconDirection
  color?: string
  size?: IconSize
  className?: string
  onClick?(): void
  fill?: string
}

const getTransformDegree = (direction?: IconDirection): string => {
  switch (direction) {
    case 'right':
      return '90deg'
    case 'down':
      return '180deg'
    case 'left':
      return '270deg'
    case 'up':
      return '0deg'
    default:
      return '0deg'
  }
}

const getSize = (size?: IconSize): string => {
  switch (size || 'small') {
    case 'small':
      return '14'
    case 'large':
      return '30'
    default:
      return `${size}`
  }
}

export const SVGIcon = (props: ISVGIconProps) => {
  const { name, direction, size, ...iconProps } = props
  const IconComponent = SVGComponent[name]

  return (
    <IconWrapper direction={direction}>
      <IconComponent width={getSize(size)} height={getSize(size)} {...iconProps} />
    </IconWrapper>
  )
}

const IconWrapper = styled.span<{ direction?: IconDirection }>`
  svg {
    transform: rotateZ(${({ direction }) => getTransformDegree(direction)});
    cursor: pointer;
    pointer-events: none;
  }
`
