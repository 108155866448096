import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { connectRefinementList } from 'react-instantsearch-dom'
import styled from 'styled-components'

import { SearchFiltersHumanizedRefinement } from 'search'
import { IRefinementListProps } from 'search/components/SearchFilters/Filters/IRefinementListProps'
import { Button } from 'shared/components/Button'
import { Card } from 'shared/components/CardDeprecated'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'

const CategoryFilter = ({ items, attribute, refine }: IRefinementListProps) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value[value.length - 1],
    })
    refine(value)
  }

  return items.length > 0 ? (
    // TODO: Work with QA to add qaAttr SearchQA.FilterCategory
    <Card title={SearchFiltersHumanizedRefinement()[attribute]} qaAttr={'SearchQA.FilterCategory'}>
      <CategoryCardContent>
        {items.map(({ label, value, isRefined }) => (
          <CategoryOptionButton
            buttonType="secondary"
            $fill
            key={label}
            onClick={() => {
              handleRefinement(value)
            }}
            isSelected={isRefined}
            qaAttr={SearchQA.FilterColorLabel}
          >
            {label.toUpperCase()}
          </CategoryOptionButton>
        ))}
      </CategoryCardContent>
    </Card>
  ) : null
}

const CategoryCardContent = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  padding: 20px;
`

const CategoryOptionButton = styled(Button)<{ isSelected: boolean }>`
  align-items: center;
  border-color: ${({ isSelected }) =>
    isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY} !important;
  display: flex;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: normal !important;
  padding-left: 15px;
  text-align: left;
  text-transform: capitalize !important;
`

export default connectRefinementList(CategoryFilter)
