import { createContext, ReactNode } from 'react'
import { useImmerReducer } from 'use-immer'

import {
  ExperimentDispatch,
  ExperimentReducer,
  ExperimentState,
} from 'experiments/state/ExperimentReducer'

type ExperimentProviderProps = {
  children: ReactNode
}

export const ExperimentStateContext = createContext<ExperimentState>(null)
export const ExperimentDispatchContext = createContext<ExperimentDispatch>(null)

const ExperimentProvider = ({ children }: ExperimentProviderProps) => {
  const initialValues = {}

  const [state, dispatch] = useImmerReducer(ExperimentReducer, initialValues)

  return (
    <ExperimentDispatchContext.Provider value={dispatch}>
      <ExperimentStateContext.Provider value={state}>{children}</ExperimentStateContext.Provider>
    </ExperimentDispatchContext.Provider>
  )
}

export default ExperimentProvider
