import { useEffect, useRef } from 'react'

/**
 * Creates a ref and calls a handler when clicking outside that ref
 *
 * Usage
 * const modalEl = useClickOutside(isModalVisible(false))
 * return <Modal ref={modalEl} />
 */

const useClickOutside = onClickOutside => {
  const element = useRef(null)
  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (element.current && !element.current.contains(target)) {
        onClickOutside()
      }
    }
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  })

  return element
}

export default useClickOutside
