import * as Yup from 'yup'
import { Formik, Form, FormikHelpers } from 'formik'
import { useMutation, gql } from '@apollo/client'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'
import { ComboBox } from 'shared/inputs'
import { IError } from 'errors/types/IError'
import { emailValidation } from 'shared/addressValidations'
import { getErrorList } from 'errors/utils/getErrorList'
import { logEmailSignUp } from 'mParticle/mParticleService'
import { t } from 'localization'
import { FCLink } from 'shared/components/Links'

const TP = 'layout.footer.emailSignUp'

interface EmailSignUpValues {
  email: string
}
const EmailSignUp = () => {
  const [registerEmail] = useMutation(REGISTER_EMAIL)
  const [successMessage, setSuccessMessage] = useState('')
  const tv = useTranslation()
  const EmailSchema = Yup.object().shape({
    email: emailValidation(),
  })
  const handleSubmit = async (
    { email }: EmailSignUpValues,
    { setErrors, setSubmitting }: FormikHelpers<EmailSignUpValues>,
  ) => {
    setSuccessMessage('')
    try {
      const { data } = await registerEmail({ variables: { email } })
      setSuccessMessage(data?.registerEmail?.message)
      logEmailSignUp()
    } catch (error) {
      const errors: IError[] = getErrorList(error.graphQLErrors)
      if (errors.length > 0) {
        setErrors({
          email: errors[0].message,
        })
        logEmailSignUp(errors[0].message)
        return
      }
      setErrors({
        email: t(
          `${TP}.issueWithEmail`,
          'There was an issue registering your email, please try again.',
        ),
      })
    }
    setSubmitting(false)
  }

  const handleChange = () => setSuccessMessage('')

  // Try to add a helper for translation cause Trans component
  // will cause some issuess when directly passing two level components
  const descriptionText = (
    <>
      <Trans
        i18nKey={`${TP}.bySigningUp`}
        t={tv.t}
        defaults="By signing up, you confirm you are over 16 years of age and you want to receive Flight Club emails. Please see our <0>Terms & Conditions</0> and <1>Privacy Policy</1> for more details."
        components={[
          <FCLink href="/terms-conditions" qaAttr="footer-email-signup-terms-link" />,
          <FCLink href="/privacy" qaAttr="footer-email-signup-privacy-link" />,
        ]}
      />
    </>
  )

  return (
    <Formik initialValues={{ email: '' }} onSubmit={handleSubmit} validationSchema={EmailSchema}>
      {({ isSubmitting }) => (
        <Form data-qa="FooterEmailSignup">
          <ComboBox
            description={descriptionText}
            isLoading={isSubmitting}
            label={t(`${TP}.emailAddress`, 'Email Address')}
            name="email"
            onChange={handleChange}
            successMessage={successMessage}
            title={t(`${TP}.signUp`, 'Sign Up For News & Promotions')}
            type="email"
          />
        </Form>
      )}
    </Formik>
  )
}

export const REGISTER_EMAIL = gql`
  mutation registerEmail($email: String!) {
    registerEmail(email: $email) {
      message
    }
  }
`

export default EmailSignUp
