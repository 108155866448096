import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { SVGIcon } from 'shared/components/Icons'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

const TP = 'errors.components.errorBanner'

export interface IErrorBannerProps {
  message: string | ReactNode
  qaAttr?: string
  className?: string
}

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_RED};
  ${fonts.BODY_TEXT}
  color: ${colors.FC2_RED};
  margin-bottom: 20px;
  padding: 20px;
`

const ErrorX = styled(SVGIcon).attrs({
  name: 'errorIcon',
})`
  margin-right: 10px;
`

export const ErrorBanner = ({ message, qaAttr, className }: IErrorBannerProps) => {
  const tv = useTranslation()
  const errorMessage = message || (
    <span>
      <Trans
        i18nKey={`${TP}.somethingWentWrong`}
        t={tv.t}
        defaults="Something went wrong. Please try again or reach out to <0>support@flightclub.com</0>"
        components={[<a key="supportMail" href="mailto:support@flightclub.com" />]}
      />
    </span>
  )
  return (
    <ErrorBox className={className}>
      <ErrorX />
      <span data-testid="message" data-qa={qaAttr}>
        {errorMessage}
      </span>
    </ErrorBox>
  )
}

export default ErrorBanner
