/* tslint:disable:max-line-length */

enum FraudQA {
  // COLUMN NAMES
  FraudErrorTopForm = 'FraudErrorTopForm',
  FraudFaqEmail = 'FraudFaqEmail',
  FraudUserMessage = 'FraudUserMessage',
  FraudSubmitButton = 'FraudSubmitButton',
  FraudStatementCodeBox = 'FraudStatementCodeBox',
  // FraudStatementCodeBoxName // Auto-generated
  FraudPageTitle = 'FraudPageTitle',
}

export default FraudQA
