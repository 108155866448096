import { OffersModalDesktop } from './OffersModalDesktop'
import { OffersModalMobile } from './OffersModalMobile'
import { useWindowSize } from 'shared/hooks/useWindowSize'

type OffersModalProps = {
  handleCloseOffersModal: () => void
  handleCloseOffersLoginPrompt: () => void
  promptLogin?: boolean
}
export const OffersModal = (props: OffersModalProps) => {
  const { isMobile } = useWindowSize()
  if (isMobile) {
    return <OffersModalMobile {...props} />
  }

  return <OffersModalDesktop {...props} />
}
