import { FC } from 'react'
import { camelCase } from 'lodash'
import { Checkbox } from 'shared/components/Checkbox'
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'
import { IRefinementListProps } from '../IRefinementListProps'
import { SearchQA } from 'shared/dataAttributes'
import { conditionFilterOption } from 'search/enums/search'

const ConditionsCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const DesktopConditionFilter: FC<IRefinementListProps> = ({ items, refine }) => {
  return (
    <ConditionsCardContent>
      {items.map(({ label, value, isRefined }) => (
        <Checkbox
          key={label}
          label={conditionFilterOption()[camelCase(label)]}
          onChange={() => {
            refine(value)
          }}
          onClick={() => {}}
          checked={isRefined}
          qaAttr={SearchQA.DesktopFilterCondition}
        />
      ))}
    </ConditionsCardContent>
  )
}

DesktopConditionFilter.displayName = 'DesktopConditionFilter'

export default renderStart.large(DesktopConditionFilter)
