import { FC, useRef, useState, MouseEvent as ReactMouseEvent } from 'react'
import { throttle } from 'lodash'

interface HoverIntentProps {
  exitTimeout?: number
  onEnter?: () => void
  onExit?: () => void
  cancellableTimeoutCallback?: (timeout: number) => void
  onUpdate?: (active: boolean) => void
  sensitivity?: number
}

interface MousePosition {
  x: number
  y: number
}

export const COMPARE_INTERVAL = 40
export const DEFAULT_EXIT_TIMEOUT = 250

const HoverIntent: FC<HoverIntentProps> = ({
  children,
  exitTimeout = DEFAULT_EXIT_TIMEOUT,
  onEnter = () => {},
  onExit = () => {},
  onUpdate = () => {},
  sensitivity = 6,
  cancellableTimeoutCallback = () => {},
}) => {
  const mousePosition = useRef<MousePosition>({ x: 0, y: 0 })
  const [active, setActive] = useState(false)

  const handleMouseMove = (event: ReactMouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.persist() // https://reactjs.org/docs/legacy-event-pooling.html
    compareMouseMove(event)
  }
  const compareMouseMove = throttle(
    ({ clientX, clientY }: ReactMouseEvent<HTMLSpanElement, MouseEvent>) => {
      const { x, y } = mousePosition.current
      if (Math.sqrt((x - clientX) * (x - clientX) + (y - clientY) * (y - clientY)) < sensitivity) {
        onEnter()
        onUpdate(true)
        setActive(true)
      }
      mousePosition.current = { x: clientX, y: clientY }
    },
    COMPARE_INTERVAL,
  )

  const handleMouseLeave = () => {
    if (active) {
      // the reference to the setTimeout is passed back to the parent component
      // when a timeout is triggered so it could be cancelled if and when necessary
      // to prevent the hover behavior from resetting on mouse leave
      cancellableTimeoutCallback(
        window.setTimeout(() => {
          onExit()
          onUpdate(false)
          setActive(false)
        }, exitTimeout),
      )
    }
  }

  return (
    <span onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      {children}
    </span>
  )
}

export default HoverIntent
