import { ReactNode, MouseEvent as ReactMouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { isFunction } from 'lodash/fp'
import { logClickEventUtil } from 'mParticle/mParticleService'
import { H6 } from 'shared/text'
import colors from 'shared/theme/colors'
import LoaderSVG from 'shared/icons/Loader'

type ColorType = 'red' | 'black' | 'white' | 'mediumGray'

interface IButtonProps {
  children: string | ReactNode
  className?: string
  colorTheme?: ColorType
  dataTestId?: string
  dataTrackingEvent?(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>): void
  disabled?: boolean
  isLoading?: boolean
  isSelected?: boolean
  onClick?: () => void
  qaAttr?: string
  isNewModal?: boolean
}

export const Button = ({
  children,
  className,
  colorTheme,
  dataTestId,
  dataTrackingEvent,
  disabled = false,
  isLoading = false,
  isSelected = false,
  onClick = () => {},
  qaAttr,
  isNewModal,
}: IButtonProps) => {
  const onClickWithTracking = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isFunction(dataTrackingEvent)) {
      dataTrackingEvent(e)
    } else {
      logClickEventUtil(e)
    }

    if (isFunction(onClick)) {
      onClick()
    }
  }

  const isChildString = typeof children === 'string' || children instanceof String

  const loaderColorOverride = () => {
    if (colorTheme !== 'black' && typeof colorTheme !== 'undefined') {
      return colors.black
    }
  }

  return (
    <BaseButton
      data-qa={qaAttr}
      data-qa-disabled={disabled || isLoading}
      data-qa-loading={isLoading}
      data-qa-checked={isSelected}
      data-testid={dataTestId}
      onClick={onClickWithTracking}
      className={className}
      disabled={disabled || isLoading}
      colorTheme={colorTheme}
      isLoading={isLoading}
      isSelected={isSelected}
      isNewModal={isNewModal}
    >
      {isLoading ? (
        <Loader color={loaderColorOverride()} />
      ) : isChildString ? (
        <H6>{children}</H6>
      ) : (
        children
      )}
    </BaseButton>
  )
}
const Loader = styled(LoaderSVG)`
  transform: translate3d(1px, -3px, 0);
`
const blackTheme = css`
  background-color: ${colors.black};
  color: ${colors.white};
  border: 2px solid ${colors.mediumGray};
  &:disabled {
    border: none;
  }
  @media (hover: hover) {
    &:hover:enabled {
      border: 2px solid ${colors.white};
    }
  }
`
const mediumGrayTheme = css`
  background-color: ${colors.mediumGray};
  color: ${colors.black};
  @media (hover: hover) {
    &:hover:enabled {
      box-shadow: 0px 0px 12px ${colors.mediumGray};
    }
  }
`
const redTheme = css`
  background-color: ${colors.red};
  color: ${colors.black};
  @media (hover: hover) {
    &:hover:enabled {
      box-shadow: 0px 0px 12px ${colors.red};
    }
  }
`
const whiteTheme = css`
  background-color: ${colors.white};
  color: ${colors.black};
  @media (hover: hover) {
    &:hover:enabled {
      box-shadow: 0px 0px 12px ${colors.white};
    }
  }
`
const themeColors = {
  black: blackTheme,
  mediumGray: mediumGrayTheme,
  red: redTheme,
  white: whiteTheme,
}

const BaseButton = styled.button<{
  colorTheme?: ColorType
  isLoading: boolean
  isSelected?: boolean
  isNewModal?: boolean
}>`
  width: 100%;
  border-radius: ${({ isNewModal }) => (isNewModal ? 'none' : '4px')};
  padding: ${({ isNewModal }) => (isNewModal ? '10px' : '20px')};
  &:disabled {
    color: ${colors.white};
    ${({ isLoading }) => !isLoading && `background-color: ${colors.darkGray};`}
  }
  /* if colorTheme is undefined, only default button styles above will be applied*/
  ${({ colorTheme }) => (colorTheme ? themeColors[colorTheme] : '')}
  ${({ isSelected }) =>
    isSelected &&
    `border: 2px solid ${colors.red}; &:hover:enabled {
    border: 2px solid ${colors.red};
    box-shadow: 0px 0px 12px ${colors.red};
  }`}
  border: ${({ isNewModal }) => isNewModal && 'none !important'};
`

export default Button
