import mapValues from 'lodash/mapValues'
import { css } from 'styled-components'

// Sourcing breakpoints from bootstrap
// https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
export const sizes = {
  base: 0,
  extraSmall: 360,
  small: 576,
  medium: 768,
  large: 992,
  larger: 1100,
  extraLarge: 1280,

  // device specific
  iPhoneXPortrait: 414,
}

// NOTE: this maps all the `sizes` to mobile-first media-queries
const media = mapValues(
  sizes,
  // We use css.call because Typescript doesn't recognize spread operators as an argument
  (size: number) =>
    (...args: Parameters<typeof css>) =>
      css`
        @media (min-width: ${size / 16}em) {
          ${css.call(null, ...args)}
        }
      `,
)

export default media
