// tslint:disable: max-line-length
import * as React from 'react'
import AfterPayLogo from '../SVGIcons/AfterpayLogo.svg'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 74px;
  height: 22px;
  svg {
    width: 100%;
    height: 100%;
  }
`
const AfterPayIcon = props => (
  <Wrapper {...props}>
    <AfterPayLogo />
  </Wrapper>
)

export default AfterPayIcon
