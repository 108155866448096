import { get, includes, isFunction, toString } from 'lodash/fp'
import Router from 'next/router'
import qs, { ParsedQs } from 'qs'
import { isomorphicLog } from 'shared/utils/logging-utils'

type ActionType = 'login' | 'register' | 'identify' | 'logout'

interface IIdentityCallbackProps {
  result: any
  user: IUser | any
  actionType: ActionType
  retries: number
  callback: any
}

/**
 * [identityCallback]
 *
 * A callback function called after an mParticle Identity API request is made. On success,
 * user attributes are set for lean plum integration. On failure, it will retry the original
 * call to login/logout/identify or console.warn the result body.
 * @callback retryCallback
 *
 * @param  {Object} result            Result returned from mParticle Identity API request
 * @param  {Object} user              The user object passed in from mParticleIdentifyUser or
 *                                    mParticleLogoutUser
 * @param  {string} actionType        e.g., 'logout', 'login'
 * @param  {number} retries           Number of mParticle API request retries. We will retry
 *                                    up to 2 times.
 * @param  {retryCallback} callback}  e.g., mParticleIdentifyUser or mParticleLogoutUser
 */
export function identityCallback(props: IIdentityCallbackProps) {
  const { result, user, actionType, retries, callback } = props
  let mParticleUser

  if (isFunction(result.getUser)) {
    mParticleUser = result.getUser()
  }

  if (mParticleUser) {
    // set these attributes for Leanplum integration through mParticle
    mParticleUser.setUserAttribute('email', toString(user.email))
    mParticleUser.setUserAttribute('email_lp', 'TRUE')
    return
  }

  /**
   * https://docs.mparticle.com/developers/sdk/web/idsync/#error-handling
   * The code block below handles errors if there is a failure in the mParticle IDSync API call.
   * The IDSync API call sends events to mParticle on user login or identification.
   *
   * https://docs.mparticle.com/developers/sdk/web/idsync/#status-codes
   * As of June 2019, the codes we are using are:
   *  activeIdentityRequest: -2;
   *  nativeIdentityRequest: -5;
   *  noHttpCoverage: -1;
   *  tooManyRequests: 429;
   *  validationIssue: -4;
   */

  const codes = get('mParticle.Identity.HTTPCodes', window)

  if (!codes || !result.httpCode || retries >= 2) {
    return
  }

  switch (result.httpCode) {
    case codes.noHttpCoverage:
    case codes.tooManyRequests:
    case codes.activeIdentityRequest:
      callback(user, actionType, retries + 1)
      break
    case codes.validationIssue:
    case 400:
      // tslint:disable-next-line:no-console
      console.warn('Validation issue. Inspect result body:', result.body)
      break
    default:
      // tslint:disable-next-line:no-console
      console.warn('Result Body:', result.body)
  }
}

export function getMParticle() {
  if (typeof window === 'undefined') {
    return
  }
  return window.mParticle
}

/** prefix for the mParticle key in local storage */
export const MPARTICLE_LOCAL_STORAGE_NAMESPACE = 'mprtcl-v4'
/** key for device id in the mParticle object stored in local storage  */
export const MPARTICLE_DEVICE_ID_KEY = 'das'
/** key for session id in the mParticle object stored in local storage  */
export const MPARTICLE_SESSION_ID_KEY = 'sid'

export function getMParticleLocalStorage(item?: string) {
  const getItem = (key: string | number | boolean) => {
    return localStorage.getItem(encodeURIComponent(key))
  }

  try {
    const mParticleLocalStorageKey = Object.keys(localStorage).find((key) =>
      key.startsWith(MPARTICLE_LOCAL_STORAGE_NAMESPACE),
    )

    if (mParticleLocalStorageKey) {
      const dataString = getItem(mParticleLocalStorageKey)
      if (!dataString) return null

      const mParticleData = JSON.parse(dataString.replace(/\|/g, ',').replace(/'/g, '"'))

      return item ? mParticleData?.gs?.[item] : mParticleData
    }
  } catch {
    return null
  }
}

export const getMParticleDeviceId = () => {
  const mParticle = getMParticle()

  if (mParticle?.getDeviceId) {
    return mParticle.getDeviceId()
  } else {
    return getMParticleLocalStorage(MPARTICLE_DEVICE_ID_KEY) || null
  }
}

export const getMParticleSessionId = () => {
  const mParticle = getMParticle()

  if (mParticle?.sessionManager?.getSession) {
    return mParticle.sessionManager.getSession()
  } else {
    return getMParticleLocalStorage(MPARTICLE_SESSION_ID_KEY) || null
  }
}

export function isMParticleIdentityAvailable() {
  if (typeof window === 'undefined') {
    return false
  }
  return !!get('mParticle.Identity')(window)
}

/**
 * [mParticleIdentifyUser]
 * Receives user and action and registers the change with mParticle:
 *    Login and register correlate to a 'login' event on mParticle.
 *    All other actions (including fetch current user) correlate to an 'identify' event
 *    on mParticle
 * @param  {Object} [user={}]               The user object
 * @param  {string} [actionType='identify'] e.g., login', 'register'
 * @param  {number} [retries=0]             Number of mParticle API request retries. We will
 *                                          retry up to 2 times.
 * @returns {Promise | undefined}
 */

interface IUser {
  id: number
  fullName: string
  email: string
}

export function mParticleIdentifyUser(
  user: IUser | any = {},
  actionType: ActionType = 'identify',
  retries = 0,
  loginRegisterCallback?: () => void,
) {
  const identityRequest = {
    userIdentities: {
      email: toString(user.email),
      customerid: toString(user.id),
    },
  }

  if (!isMParticleIdentityAvailable()) {
    return
  }

  const mParticleIdentityAPI = window.mParticle.Identity

  try {
    if (actionType === 'login' || actionType === 'register') {
      mParticleIdentityAPI.login(identityRequest, (result) => {
        identityCallback({
          result,
          user,
          actionType,
          retries,
          callback: mParticleIdentifyUser,
        })
        if (loginRegisterCallback) {
          loginRegisterCallback()
        }
      })
    }
    mParticleIdentityAPI.identify(identityRequest, (result) =>
      identityCallback({
        result,
        user,
        actionType,
        retries,
        callback: mParticleIdentifyUser,
      }),
    )
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.warn(error)
  }
}

/**
 * [mParticleLogoutUser]
 * Registers the user logout event with mParticle.
 * @param  {Object} [user={}]             The user object necessary for the identityCallback, but
 *                                        an empty object is all that's needed for logout
 * @param  {string} [actionType='logout'] The action type necessary for the identityCallback
 * @param  {number} [retries=0]           Number of mParticle API request retries. We will retry
 *                                        up to 2 times.
 * @returns {Promise}
 */

export function mParticleLogoutUser(
  user: IUser | any = {},
  actionType: ActionType = 'logout',
  retries: number = 0,
) {
  const logoutRequest = {
    userIdentities: {},
  }

  if (!isMParticleIdentityAvailable()) {
    return
  }

  try {
    window.mParticle.Identity.logout(logoutRequest, (result) =>
      identityCallback({
        result,
        user,
        actionType,
        retries,
        callback: mParticleLogoutUser,
      }),
    )
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.warn(error)
  }
}

type UTMParams = 'utm_source' | 'utm_content' | 'utm_term' | 'utm_medium' | 'utm_campaign'
const ACCEPTED_UTM_QUERY_PARAMS: UTMParams[] = [
  'utm_source',
  'utm_content',
  'utm_term',
  'utm_medium',
  'utm_campaign',
]
export const getQueryParamSubset = (subsetKeys: string[]): ParsedQs => {
  const url = Router.asPath || ''
  const queryParams = qs.parse(url.split('?')[1])
  return subsetKeys.reduce<ParsedQs>((queryParamSubset, subsetKey) => {
    if (subsetKey in queryParams) {
      queryParamSubset[subsetKey] = queryParams[subsetKey]
    }
    return queryParamSubset
  }, {})
}
export const getUtmQueryParams = () =>
  getQueryParamSubset(ACCEPTED_UTM_QUERY_PARAMS) as { [key in UTMParams]?: string }

// Pages using this should be tracked here: /shared/enums/SpecificPageTrackingEvents.ts
// This will ensure we don't duplicate general and specific page views per Data Team's request
export function logSpecificPageView({ slug = '', pageName = '', slugType = '' }) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    const viewType = `${pageName}_view`
    const viewEventUtms =
      viewType === 'pdp_view' && getQueryParamSubset(['utm_source', 'utm_medium', 'utm_campaign'])
    mparticle.logEvent(viewType, mparticle.EventType.Navigation, {
      page: document.referrer,
      slug,
      slug_type: slugType,
      ...viewEventUtms,
    })
    const utmQueryParams = getUtmQueryParams()
    if (viewType === 'pdp_view' && utmQueryParams.utm_source === 'retail') {
      // this relies on utm_source from retail being like fcmia-123456789
      const [retail_location, associate_id] = utmQueryParams.utm_campaign?.split('-')!
      mparticle.logEvent('salesfloor_pdp_view', mparticle.EventType.Navigation, {
        product_template_slug: slug,
        associate_id,
        retail_location,
      })
    }
    /**
     * This data is needed to track the cart_purchase and the URL params are stripped
     * after navigating to checkout
     */
    window.sessionStorage.setItem('utm_source', utmQueryParams.utm_source || '')
    window.sessionStorage.setItem('utm_medium', utmQueryParams.utm_medium || '')
    window.sessionStorage.setItem('utm_campaign', utmQueryParams.utm_campaign || '')
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function logPageView(url: string) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    // tslint:disable-next-line:max-line-length
    // This is a custom event. See: https://github.com/goatapp/analytics-event-schema/blob/master/flight_club/inputs/page_view.json
    mparticle.logEvent('page_view', mparticle.EventType.Navigation, {
      page: url,
      hostname: window?.location.hostname,
      title: document?.title,
    })

    // This is aimed to GA logging.
    mparticle.logPageView(
      'PageView',
      {
        hostname: window?.location.hostname,
        title: document?.title,
      },
      {
        'Google.Label': window?.location.pathname.toString(),
        'Google.Page': window?.location.pathname.toString(),
        'Google.Title': document?.title,
      },
    )
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

interface ILogClickEventProps {
  page: string
  name: string
  componentClass: string
}

export function logClickEvent({ page, name, componentClass }: ILogClickEventProps) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    mparticle.logEvent('object_click', mparticle.EventType.Navigation, {
      page,
      object_name: name,
      object_class: componentClass,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function logClickEventUtil(event) {
  let page = ''
  if (typeof window !== 'undefined') {
    page = get('location.href', window)
  }
  const targetClass = get('target.className', event)
  const innerText = get('target.innerText', event)

  logClickEvent({ page, name: innerText, componentClass: targetClass })
}

export type LoginOrCreateAccountEventType = 'login' | 'account_creation'
export type LoginOrCreateAccountLocation =
  | 'during_checkout'
  | 'after_checkout'
  | 'account_page'
  | 'view_order_link'
  | 'pdp_favorite'
  | 'global_favorite_panel'
  | 'pdp_offer'
  | 'global_offer_panel'
type LogLoginOrCreateAccountClickParams = {
  email?: string
  eventType: LoginOrCreateAccountEventType
  location: LoginOrCreateAccountLocation
  newsletterSignUp?: boolean
}
export function logLoginOrCreateAccountClick({
  eventType,
  newsletterSignUp = false,
  email = '',
  location,
}: LogLoginOrCreateAccountClickParams) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  const pathname = get('location.pathname', window)

  try {
    mparticle.logEvent(`${eventType}_click`, mparticle.EventType.Navigation, {
      account_creation_newsletter_sign_up: newsletterSignUp,
      email, // collecting for data team - async mParticle Identity call may not return user in time
      ...(eventType === 'account_creation' ? { location } : {}),
      page: pathname,
      location,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function logUserLoginOrCreation({ eventType, email = '', isGuest = false }) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  const pathname = get('location.pathname', window)

  try {
    mparticle.logEvent(eventType, mparticle.EventType.Navigation, {
      page: pathname,
      email, // collecting for data team - async mParticle Identity call may not return user in time
      is_guest: isGuest,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function logEmailSignUp(errorMessage = '') {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    mparticle.logEvent('footer_newsletter_email_sign_up_tap', mparticle.EventType.Other, {
      error_message: errorMessage,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function logHomePageHeroTap(
  url: string,
  moduleName: string,
  modulePosition: number,
  draftId: number | string,
  sectionId: number | string,
  productTemplateSlug: string,
  segmentId?: string,
) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    mparticle.logEvent('homepage_hero_unit_tap', mparticle.EventType.Navigation, {
      url,
      module_name: moduleName,
      module_position: modulePosition,
      draft_id: draftId,
      section_id: sectionId,
      product_template_slug: productTemplateSlug,
      segment_id: segmentId,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function productGridComponentTap(url: string) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  try {
    mparticle.logEvent('product_grid_component_tap', mparticle.EventType.Navigation, {
      url,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export function collectionPdpTap(slug: string) {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }

  const url = get('location.pathname', window)

  try {
    mparticle.logEvent('collection_pdp_tap', mparticle.EventType.Navigation, {
      url,
      product_template_slug: slug,
    })
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e)
  }
}

export const getReferrerInfo = () => {
  const response: { utm_source: string; utm_medium: string } = {
    utm_source: '',
    utm_medium: '',
  }
  if (document?.referrer) {
    response.utm_source = document?.referrer
    const medium =
      includes('google.com')(document?.referrer) ||
      includes('google.co.jp')(document?.referrer) ||
      includes('yahoo.com')(document?.referrer) ||
      includes('bing.com')(document?.referrer)
        ? 'organic'
        : 'referral'
    response.utm_medium = medium
  } else {
    response.utm_source = 'direct'
  }

  return response
}

export function setReferrerInfo() {
  const mparticle = getMParticle()

  if (!mparticle) {
    return
  }
  if (document?.referrer) {
    window.mParticle.setSessionAttribute('utm_source', document?.referrer)
    const medium =
      includes('google.com')(document?.referrer) ||
      includes('google.co.jp')(document?.referrer) ||
      includes('yahoo.com')(document?.referrer) ||
      includes('bing.com')(document?.referrer)
        ? 'organic'
        : 'referral'
    window.mParticle.setSessionAttribute('utm_medium', medium)
  } else {
    window.mParticle.setSessionAttribute('utm_source', 'direct')
  }
}

export function logUtmIfPresent() {
  const mparticle = getMParticle()

  if (!mparticle) return

  try {
    const attributes = getUtmQueryParams()

    if (Object.keys(attributes).length > 0) {
      setReferrerInfo()

      Object.entries(attributes).forEach(([utmKey, utmValue]) => {
        window.mParticle.setSessionAttribute(utmKey, utmValue)
      })
    }
  } catch (e) {
    isomorphicLog(e)
  }
}
