import styled from 'styled-components'
import { colors } from 'shared/theme'
import SearchSVG from 'shared/svg/search_glass.svg'

const Search = styled(SearchSVG)`
  color: ${colors.white};
  height: 10px;
  width: 10px;
`

export default Search
