import styled, { createGlobalStyle, css } from 'styled-components'
import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import CheckoutNavigation from 'checkout/components/CheckoutNavigation/CheckoutNavigation'
import colors from 'shared/lib/colors'
import { Breadcrumb } from 'shared/types/Breadcrumb'
import { FeatureFlagActions } from 'featureFlags/state/featureFlagReducer'
import { Footer, Navigation } from 'layout'
import { setUser } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { useFeatureFlagDispatch, useFeatureFlagState } from 'featureFlags/hooks/useFeatureFlag'
import { useGetUserQuery } from 'api/usersApi'

interface IPageLayoutProps {
  className?: string
  footerData: Breadcrumb
  isAuthenticated: boolean
}

const PageLayout: FC<IPageLayoutProps> = ({ children, className, footerData, isAuthenticated }) => {
  const featureFlagDispatch = useFeatureFlagDispatch()
  const { featureFlags } = useFeatureFlagState()
  const storeDispatch = useAppDispatch()

  const { asPath } = useRouter()
  const [isCypressFfApplied, setIsCypressFfApplied] = useState(false)
  const [isCheckout, setIsCheckout] = useState(asPath.startsWith('/checkout'))
  const { data: user, isSuccess } = useGetUserQuery(null, { skip: !isAuthenticated })

  useEffect(() => {
    // Cypress sets Cypress and cypressFeatures window values
    if (window?.Cypress && window?.cypressFeatures && !isCypressFfApplied) {
      featureFlagDispatch({
        type: FeatureFlagActions.setFeatureFlags,
        payload: window.cypressFeatures,
      })
      setIsCypressFfApplied(true)
    }
  }, [featureFlags])

  useEffect(() => {
    setIsCheckout(asPath.startsWith('/checkout'))
  }, [asPath])

  useEffect(() => {
    if (isSuccess) {
      storeDispatch(setUser(user))
    }
  }, [isSuccess])

  return isCheckout ? (
    <>
      <GlobalStyle />
      <CheckoutNavigation />
      <CheckoutPage className={className}>
        <main className="main-page-layout">{children}</main>
      </CheckoutPage>
    </>
  ) : (
    <div className="main-page-layout">
      <Navigation />
      <Page className={className}>{children}</Page>
      <Footer footerData={footerData} />
    </div>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.FC2_OFF_WHITE};
  }
`

const pageCss = css`
  flex: 1;
  background-color: ${colors.FC2_OFF_WHITE};
  min-height: 400px;
`

const Page = styled.div`
  ${pageCss}
`

const CheckoutPage = styled.div`
  ${pageCss}
  flex: 1 0 auto;
`

export default PageLayout
