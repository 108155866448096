/* tslint:disable:max-line-length */
enum ProductQA {
  /// DESKTOP SPECIFIC
  DesktopSizePicker = 'DesktopSizePicker',
  /// MOBILE SPECIFIC
  MobileMainCarouselAllImages = 'MobileMainCarouselAllImages',
  MobileBuyNewCloseButton = 'MobileBuyNewCloseButton',
  MobileSelectSizeButton = 'MobileSelectSizeButton',
  MobileBuyFromButton = 'MobileBuyFromButton',
  MobileOverlayShoeTitle = 'MobileOverlayShoeTitle',
  MobileOverlayGenderTitle = 'MobileOverlayGenderTitle',
  MobileSizePicker = 'MobileSizePicker',
  /// DESKTOP AND MOBILE SPECIFIC
  BuyNewSectionLabel = 'BuyNewSectionLabel',
  BuyNewSizingLabel = 'BuyNewSizingLabel',
  BuyUsedSizingLabel = 'BuyUsedSizingLabel',
  InStoreAvailability = 'InStoreAvailability', // DO NO USE as it is, use Auto-generated names
  LeftArrowPictureCarousel = 'LeftArrowPictureCarousel',
  MainCarouselProductImage = 'MainCarouselProductImage',
  MainCarouselProductThumb = 'MainCarouselProductThumb',
  ProductDescriptionText = 'ProductDescriptionText',
  ProductDescriptionTitle = 'ProductDescriptionTitle',
  RightArrowPictureCarousel = 'RightArrowPictureCarousel',
  // InStoreAvailabilitySectionHeader, // Auto-generated
  // InStoreAvailabilitySectionSubTitle, // Auto-generated
  StoreDetails = 'StoreDetails', // DO NO USE as it is, use Auto-generated names
  // StoreDetailsStoreMaps, // Auto-generated
  // StoreDetailsStoreAddress, // Auto-generated
  // StoreDetailsStorePricing, // Auto-generated
  // BUY NEW SECTION
  BuyNewSizingTitle = 'BuyNewSizingTitle',
  BuyNew = 'BuyNew', // DO NO USE as it is, use Auto-generated names
  BuyNewCheckoutButton = 'BuyNewCheckoutButton',
  DeliveryAndPickup = 'DeliveryAndPickup', // DO NO USE as it is, use Auto-generated names
  // DeliveryAndPickupPanel // Auto-generated
  BuyNewToggle = 'BuyNewToggle', // DO NO USE as it is, use Auto-generated names
  // BuyNewTogglePanel // Auto-generated
  BuyNewBuyNowButton = 'BuyNewBuyNowButton',
  ShipIt = 'ShipIt', // DO NO USE as it is, use Auto-generated names
  // ShipItRadioLabel // Auto-generated
  ShipBuyNewLearnAboutPricingLink = 'ShipBuyNewLearnAboutPricingLink',
  ShipBuyNewSectionLabel = 'ShipBuyNewSectionLabel',
  ShipBuyNewLowestPriceAmount = 'ShipBuyNewLowestPriceAmount',
  ShipBuyNewLowestPriceTag = 'ShipBuyNewLowestPriceTag',
  ShipBuyNewInstantShipTag = 'ShipBuyNewInstantShipTag',
  ShipNewCheckoutButton = 'ShipNewCheckoutButton',
  PopupFlightClubPricingtitle = 'PopupFlightClubPricingtitle',
  PopupFlightClubPricingCloseButton = 'PopupFlightClubPricingCloseButton',
  PopupLowestPrice = 'PopupLowestPrice', // DO NO USE as it is, use Auto-generated names
  // PopupLowestPricePanel // Auto-generated
  PopupLowestPriceBadge = 'PopupLowestPriceBadge',
  PopupLowestPriceText = 'PopupLowestPriceText',
  PopupFastestToYou = 'PopupFastestToYou', // DO NO USE as it is, use Auto-generated names
  // PopupFastestTooYouPanel // Auto-generated
  PopupFastestToYouBadge = 'PopupFastestToYouBadge',
  PopupFastestToYouText = 'PopupFastestToYouText',
  PopupListPrice = 'PopupListPrice', // DO NO USE as it is, use Auto-generated names
  // PopupListPriceTag // Auto-generated
  // PopupListPriceValue // Auto-generated
  PopupPriorityProcessing = 'PopupPriorityProcessing', // DO NO USE as it is, use Auto-generated names
  // PopupPriorityProcessingTag // Auto-generated
  // PopupPriorityProcessingValue // Auto-generated
  PopupTotalPrice = 'PopupTotalPrice', // DO NO USE as it is, use Auto-generated names
  // PopupTotalPriceTag // Auto-generated
  // PopupTotalPriceValue // Auto-generated
  FastestToYouSpinner = 'FastestToYouSpinner',
  PickUp = 'PickUp', // DO NO USE as it is, use Auto-generated names
  // PickUpRadioLabel // Auto-generated
  InStoreAvailabilityTitle = 'InStoreAvailabilityTitle',
  InStoreCopyText = 'InStoreCopyText',
  // FcnySectionHeader // Auto-generated
  // FcnySectionSubTitle // Auto-generated
  // FcnyInStoreAddress // Auto-generated
  // FcnyInStoreDirectionsLink // Auto-generated
  // FcnyInStoreBusinessHours // Auto-generated
  // FclaSectionHeader // Auto-generated
  // FclaSectionSubTitle // Auto-generated
  // FclaInStoreAddress // Auto-generated
  // FclaInStoreDirectionsLink // Auto-generated
  // FclaInStoreBusinessHours // Auto-generated
  // FcmiaSectionHeader // Auto-generated
  // FcmiaSectionSubTitle // Auto-generated
  // FcmiaInStoreAddress // Auto-generated
  // FcmiaInStoreDirectionsLink // Auto-generated
  // FcmiaInStoreBusinessHours // Auto-generated
  // OVERALL PDP
  AftepayPromotionLink = 'AftepayPromotionLink',
  AftepayPromotionCloseIcon = 'AftepayPromotionCloseIcon',
  BreadCrumbLinkFullPath = 'BreadCrumbLinkFullPath',
  BreadcrumbPathName = 'BreadcrumbPathName',
  BreadcrumbShoeName = 'BreadcrumbShoeName',
  BreadcrumbFlightClub = 'BreadcrumbFlightClub',
  ProductTitle = 'ProductTitle',
  ProductBrandName = 'ProductBrandName',
  ProductInformationDetails = 'ProductInformationDetails',
  ProductSku = 'ProductSku',
  ProductColorway = 'ProductColorway',
  ProductReleaseDate = 'ProductReleaseDate',
  SizeGuideLink = 'SizeGuideLink',
  SizeGuideClose = 'SizeGuideClose',
  SizeGuideTitle = 'SizeGuideTitle',
  SizeGuideDescription = 'SizeGuideDescription',
  SizeGuideSupportEmail = 'SizeGuideSupportEmail',
  // DELIVERY AND RETURN SECTION
  DeliveryAndReturns = 'DeliveryAndReturns', // DO NO USE as it is, use Auto-generated names
  // DeliveryAndReturnsSectionHeader, // Auto-generated
  DeliveryAndReturnsDeliveryTitle = 'DeliveryAndReturnsDeliveryTitle',
  DeliveryAndReturnsDeliveryTerms = 'DeliveryAndReturnsDeliveryTerms',
  DeliveryAndReturnsReturnsTitle = 'DeliveryAndReturnsReturnsTitle',
  DeliveryAndReturnsReturnsTerms = 'DeliveryAndReturnsReturnsTerms',
  DeliveryAndReturnsCancellationTitle = 'DeliveryAndReturnsCancellationTitle',
  DeliveryAndReturnsCancellationTerms = 'DeliveryAndReturnsCancellationTerms',
  // RECOMMENDATIONS SECTION
  RecommendedForYouTitle = 'RecommendedForYouTitle',
  DesktopRecommendedShopAllLink = 'DesktopRecommendedShopAllLink',
  MobileRecommendedShopAllLink = 'MobileRecommendedShopAllLink',
  // BUY NEW SECTION
  SideSheetShoeName = 'SideSheetShoeName',
  SideSheetSizingLabel = 'SideSheetSizingLabel',
  BuyNewInstantShipOption = 'BuyNewInstantShipOption', // DO NO USE as it is, use Auto-generated names
  // BuyNewInstantShipOptionRadioLabel, // Auto-generated
  // BuyNewInstantShipOptionRadioLabelTitle, // Auto-generated
  // BuyNewInstantShipOptionRadioLabelSubTitle, // Auto-generated
  BuyNewLowestPriceOption = 'BuyNewLowestPriceOption', // DO NO USE as it is, use Auto-generated names
  // BuyNewLowestPriceOptionRadioLabel, // Auto-generated
  // BuyNewLowestPriceOptionRadioLabelTitle, // Auto-generated
  // BuyNewLowestPriceOptionRadioLabelSubTitle, // Auto-generated
  BuyNewShippingNote = 'BuyNewShippingNote',
  ShipBuyWithDefectSectionLabel = 'ShipBuyWithDefectSectionLabel',
  BuyWithDefectEstimation = 'BuyWithDefectEstimation',
  BuyWithDefectDescription = 'BuyWithDefectDescription',
  BuyWithDefectShippingNote = 'BuyWithDefectShippingNote',
  HideF2YLearnAboutPricingLink = 'HideF2YLearnAboutPricingLink',
  HideF2YLearnAboutPricingCopy = 'HideF2YLearnAboutPricingCopy',
  // BUY USED SECTION
  BuyConditional = 'BuyConditional', // DO NO USE as it is, use Auto-generated names
  BuyUsed = 'BuyUsed', // DO NO USE as it is, use Auto-generated names
  BuyUsedCheckoutButton = 'BuyUsedCheckoutButton',
  BuyUsedRowTitle = 'BuyUsedRowTitle', // - NodeList
  BuyUsedRowShipingEstimate = 'BuyUsedRowShipingEstimate', // - NodeList
  BuyUsedCarouselProductImage = 'BuyUsedCarouselProductImage', // - NodeList
  BuyUsedLeftArrowPictureCarousel = 'BuyUsedLeftArrowPictureCarousel', // - NodeList
  BuyUsedRightArrowPictureCarousel = 'BuyUsedRightArrowPictureCarousel', // - NodeList
  BuyUsedRowNotes = 'BuyUsedRowNotes', // - NodeList
  KlarnaPromotionCloseIcon = 'KlarnaPromotionCloseIcon',
  OutOfStock = 'OutOfStock',
}

export default ProductQA
