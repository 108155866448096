import styled from 'styled-components'

import { colors, media } from 'shared/lib'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import LoginPanel from 'login/components/LoginPanel'
import { useClickOutside } from 'shared/hooks'

type LoginPanelModalProps = {
  handleCloseLoginPanelModal: () => void
  withBackdrop?: boolean
  location: 'pdp_favorite' | 'global_favorite_panel' | 'pdp_offer' | 'global_offer_panel'
  flow: 'viewOffers' | 'viewFavorites' | 'placeOffer'
}

export const LoginPanelModal = ({
  handleCloseLoginPanelModal,
  withBackdrop = true,
  location,
  flow,
}: LoginPanelModalProps) => {
  const ref = useClickOutside(() => handleCloseLoginPanelModal())

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop={withBackdrop}
      itemsAlignment="center"
      id="login-panel-modal"
    >
      <LoginPanelWrapper ref={ref}>
        <LoginPanel
          withCloseButton
          handleClose={handleCloseLoginPanelModal}
          location={location}
          flow={flow}
        />
      </LoginPanelWrapper>
    </ClientOnlyPortal>
  )
}

const LoginPanelWrapper = styled.div`
  ${media.large`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  padding: 0;
  `}

  .Login, .CreateAccount {
    border: 1px solid ${colors.FC2_BLACK};
    max-width: 450px;
  }
  width: 100%;
  max-height: 100%;
  padding: 0px 20px;
  overflow-y: scroll;
  overscroll-behavior-y: none;
`
