import { MutableRefObject, useState } from 'react'
import styled from 'styled-components'

import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { LoginPanelModal } from 'favorites/LoginPanelModal'
import { OffersModalContents } from 'offers/OffersModalContents'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'
import { useClickOutside } from 'shared/hooks'

type OffersModalMobileProps = {
  handleCloseOffersModal: () => void
  handleCloseOffersLoginPrompt: () => void
  promptLogin?: boolean
}

export const OffersModalMobile = ({
  handleCloseOffersModal,
  promptLogin,
  handleCloseOffersLoginPrompt,
}: OffersModalMobileProps) => {
  const [renderPortal, setRenderPortal] = useState(true)
  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    if (promptLogin) return
    handleCloseOffersModal()
  })

  const handleCloseAnimation = () => {
    setRenderPortal(false)
    setTimeout(() => handleCloseOffersModal(), 300)
  }

  const onAnimationStart = () => {
    if (!renderPortal) {
      setTimeout(() => handleCloseOffersModal(), 300)
    }
  }

  return (
    <>
      <ClientOnlyPortal
        selector=".main-page-layout"
        withBackdrop
        itemsAlignment="end"
        id="offers-modal-mobile"
      >
        <StyledSlideOutPanel
          ref={ref}
          className={renderPortal ? 'enter' : 'exit'}
          onAnimationStart={onAnimationStart}
        >
          <OffersModalContents handleCloseOffersModal={handleCloseAnimation} />
        </StyledSlideOutPanel>
      </ClientOnlyPortal>
      {promptLogin && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseOffersLoginPrompt}
          location="pdp_offer"
          flow="placeOffer"
        />
      )}
    </>
  )
}

const StyledSlideOutPanel = styled(SlideOutPanel)`
  display: flex;
  justify-content: center;
  align-items: center;
`
