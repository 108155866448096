import { ApolloError, gql, useMutation, useQuery, FetchResult } from '@apollo/client'
import { ICurrency } from 'shared/types/ICurrency'

export const GET_SELECTED_CURRENCY = gql`
  query getSelectedCurrency {
    selectedCurrency @client {
      isoCode
      displaySymbol
      disambiguatedSymbol
    }
  }
`

export const SET_CURRENCY = gql`
  mutation setCurrency($isoCode: String!) {
    setCurrency(isoCode: $isoCode)
  }
`

export const GET_CURRENCIES = gql`
  query getCurrencies {
    getCurrencies {
      isSelected @client
      isoCode
      symbol
      disambiguatedSymbol
    }
  }
`

export interface SelectedCurrency {
  isoCode: string
  displaySymbol: string
  disambiguatedSymbol: string
}

export interface ICurrencyHookInfo {
  availableCurrencies?: ICurrency[]
  selectedCurrency?: SelectedCurrency
  error?: ApolloError
  getCurrenciesErrors?: ApolloError
  getCurrenciesLoading?: boolean
  loading: boolean
  setCurrency: (isoCode: string) => Promise<FetchResult<ISetCurrencyInput>>
}

interface ISetCurrencyInput {
  isoCode: string
}

const useCurrency = (): ICurrencyHookInfo => {
  const {
    data: currencyData,
    loading: loadingGetSelectedCurrency,
    error: getSelectedCurrencyError,
  } = useQuery(GET_SELECTED_CURRENCY)

  const {
    data: getCurrenciesData,
    loading: getCurrenciesLoading,
    error: getCurrenciesErrors,
  } = useQuery(GET_CURRENCIES)

  const [setCurrencyMutation, { loading: loadingSetCurrency }] = useMutation<ISetCurrencyInput>(
    SET_CURRENCY,
    { refetchQueries: () => [{ query: GET_SELECTED_CURRENCY }] },
  )

  const setCurrency = async (isoCode: string) =>
    await setCurrencyMutation({
      variables: {
        isoCode,
      },
    })

  return {
    availableCurrencies: getCurrenciesData?.getCurrencies,
    selectedCurrency: currencyData?.selectedCurrency,
    setCurrency,
    error: getSelectedCurrencyError,
    getCurrenciesErrors,
    loading: loadingGetSelectedCurrency || loadingSetCurrency,
    getCurrenciesLoading,
  }
}

export default useCurrency
