import AmexCardIcon from 'shared/components/Icons/PaymentIcons/CardIcon/AmexCardIcon'
import DiscoverCardIcon from 'shared/components/Icons/PaymentIcons/CardIcon/DiscoverCardIcon'
import MasterCardIcon from 'shared/components/Icons/PaymentIcons/CardIcon/MasterCardIcon'
import VisaCardIcon from 'shared/components/Icons/PaymentIcons/CardIcon/VisaCardIcon'
import { AmexCard } from 'shared/icons/PaymentMethods/AmexCard'
import { DefaultCard } from 'shared/icons/PaymentMethods/DefaultCard'
import { MasterCard } from 'shared/icons/PaymentMethods/MasterCard'
import { VisaCard } from 'shared/icons/PaymentMethods/VisaCard'

export enum CardBrand {
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
}

type CardIconProps = {
  brand: string
  className?: string
  width?: number
  height?: number
  updatedIcon?: boolean
}

const CardIcon = ({ brand, className, height, updatedIcon = false, width }: CardIconProps) => {
  switch (brand) {
    case CardBrand.AmericanExpress:
      return updatedIcon ? (
        <AmexCard />
      ) : (
        <AmexCardIcon height={height} width={width} className={className} />
      )
    case CardBrand.Discover:
      return <DiscoverCardIcon height={height} width={width} className={className} />
    case CardBrand.MasterCard:
      return updatedIcon ? (
        <MasterCard />
      ) : (
        <MasterCardIcon height={height} width={width} className={className} />
      )
    case CardBrand.Visa:
      return updatedIcon ? (
        <VisaCard />
      ) : (
        <VisaCardIcon height={height} width={width} className={className} />
      )
    default:
      return <DefaultCard />
  }
}

export default CardIcon
