import 'intersection-observer'

// We need to polyfill because Safari does not support formatToParts
import IntlPolyfill from 'intl'
import 'intl/locale-data/jsonp/en.js'
import 'intl/locale-data/jsonp/zh.js'
import 'intl/locale-data/jsonp/ko.js'
import 'intl/locale-data/jsonp/ja.js'

if (Intl.NumberFormat.prototype.formatToParts == null) {
  Intl = IntlPolyfill
}
