import { FC, useContext, useState, createContext, ReactNode } from 'react'
import { IError } from 'errors/types/IError'

interface IErrorState {
  errors: IError[]
  setErrors(errors: IError[]): void
}

const ErrorContext = createContext<IErrorState>({
  errors: [],
  setErrors: () => null,
})

export const useErrorContext = () => useContext(ErrorContext)

interface IErrorContextProvider {
  children: ReactNode
}

const ErrorContextProvider: FC<IErrorContextProvider> = ({ children }) => {
  const initialValue = []
  const [errors, setErrors] = useState(initialValue)
  const state = {
    errors,
    setErrors,
  }
  return <ErrorContext.Provider value={state}>{children}</ErrorContext.Provider>
}

export default ErrorContextProvider
