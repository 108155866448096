import styled from 'styled-components'

const H3 = styled.h3`
  font-family: ${props => props.theme.fonts.ivar};
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 43px;
  margin: 0;
`

export default H3
