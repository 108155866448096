import { camelCase } from 'lodash'
import { FC } from 'react'
import { Button } from 'shared/components/Button'
import { SearchQA } from 'shared/dataAttributes'
import { renderUntil } from 'shared/hocs/render'
import { colors } from 'shared/lib'
import styled from 'styled-components'

import { conditionFilterOption } from 'search/enums/search'
import { IRefinementListProps } from '../IRefinementListProps'

const ConditionsCardContent = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
`

const ConditionOptionButton = styled(Button)<{ isSelected: boolean }>`
  border-color: ${({ isSelected }) =>
    isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY} !important;
`

const MobileConditionsFilter: FC<IRefinementListProps> = ({ items, refine }) => {
  return (
    <ConditionsCardContent>
      {items.map(({ label, value, isRefined }) => (
        <ConditionOptionButton
          $fill
          buttonType="secondary"
          key={label}
          onClick={() => {
            refine(value)
          }}
          isSelected={isRefined}
          qaAttr={SearchQA.MobileFilterConditionLabel}
        >
          {conditionFilterOption()[camelCase(label)]}
        </ConditionOptionButton>
      ))}
    </ConditionsCardContent>
  )
}

MobileConditionsFilter.displayName = 'MobileConditionsFilter'

export default renderUntil.large(MobileConditionsFilter)
