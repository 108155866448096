import { isAxiosError } from 'axios'
import getConfig from 'next/config'
import { requestService } from 'shared/utils/requestService'

export const isomorphicLog = async (...args: any[]) => {
  if (typeof window === 'undefined') {
    // Load logger module only on the server side
    const { logger } = await import('server/utils/logger')
    if (logger) {
      logger.info(...args)
      return
    }
  }

  // For the client-side, or if import failed on the server side
  const { publicRuntimeConfig } = getConfig()

  if (publicRuntimeConfig.releaseEnv !== 'production') {
    // Log to console for non-production environments
    console.log(...args)
  }
}

/**
 *  Friendly log is a way to log messages to the server for debugging purposes.
 */
export const friendlyLog = async (msg: string, ...args: any[]) => {
  return requestService({
    url: `/friendly-log`,
    data: { msg, args },
    method: 'POST',
  }).catch((error) => {
    if (isAxiosError(error)) {
      console.error('friendlyLog error', error.message)
    } else {
      console.error('friendlyLog error', error)
    }
  })
}
