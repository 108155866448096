import { FC } from 'react'
import { connect, getIn } from 'formik'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'
import { InputBox, Header, Error, InputField } from './InputStyles'
import styled from 'styled-components'
import { fonts, colors } from 'shared/lib'
import { SVGIcon } from '../Icons'
import { FCLink } from '../Links'

const TP = 'shared.Input.FormikInput'

interface IInputProps {
  title?: string
  name: string
  type?: string
  success?: boolean
  placeholder?: string
  autoComplete?: string
  className?: string
  qaAttr?: string
  disabled?: boolean
  ariaLabel?: string
  formik?: any
  isDarkMode?: boolean
}

const StyledInput = styled(InputField)<{ isDarkMode: boolean }>`
  width: 100%;
  &:focus {
    color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_WHITE : colors.FC2_BLACK)};
  }
  ${({ success }) => (success ? `border-right: none;` : '')}
`

const Label = styled.label`
  white-space: nowrap;
  margin-right: 5px;
`

const ErrorMessageStyle = styled.span`
  ${fonts.BODY_TEXT};
  color: ${colors.FC2_RED};

  a {
    text-decoration: underline;
    color: ${colors.FC2_RED};
  }
`

const InputIconContainer = styled(InputBox)`
  display: flex;
  flex-direction: row;
`

const IconContainer = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  align-items: center;
  padding-right: 5px;
  margin-top: 5px;
  border: ${colors.FC2_LIGHTEST_GREY} 1px solid;
  border-left: none;
`

/**
 * Formik input based on FC2 designs
 * @param title - Optional: Text that appears above the input field
 * @param name - Name of input that matches up with key value in Formik
 *
 * <Formik input={{ username: '' }}>
 *   <Input title="User" name="username" />
 * </Formik>
 */
const Input: FC<IInputProps> = ({
  title,
  name,
  type,
  success = false,
  placeholder,
  autoComplete,
  className,
  ariaLabel,
  formik,
  qaAttr,
  disabled,
  isDarkMode = false,
}) => {
  const { t } = useTranslation()
  const hasError = getIn(formik.errors, name) && getIn(formik.touched, name)
  const error = getIn(formik.errors, name)
  const hasPromoLoginError = hasError && error.includes('log in') && error.includes('promo')

  return (
    <InputBox className={className}>
      <Header isDarkMode={isDarkMode}>
        {!!title && (
          <Label data-qa={`${qaAttr}Name`} htmlFor={name}>
            {title}
          </Label>
        )}

        {hasPromoLoginError ? (
          <ErrorMessageStyle>
            <Trans
              t={t}
              i18nKey={`${TP}.Error`}
              defaults="<0>Log in</0> to apply promo"
              components={[<FCLink href="/customer/account/login" />]}
            />
          </ErrorMessageStyle>
        ) : (
          <Error name={name} data-qa={`${qaAttr}ErrorInLine`} />
        )}
      </Header>
      <InputIconContainer>
        <StyledInput
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          data-qa={qaAttr}
          disabled={disabled}
          error={hasError ? 'true' : hasError}
          id={name}
          isDarkMode={isDarkMode}
          name={name}
          placeholder={placeholder}
          success={success}
          type={type}
        />
        {!!success && (
          <IconContainer data-qa={`${qaAttr}SuccessIcon`}>
            <SVGIcon name="circleCheck" color={colors.FC2_GREEN} />
          </IconContainer>
        )}
      </InputIconContainer>
    </InputBox>
  )
}

export default connect(Input)
