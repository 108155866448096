import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie'
import getConfig from 'next/config'

import createGraphQLHttpLink from 'shared/apollo/links/createGraphQLHttpLink'

let createGraphQLServerLink

if (typeof window === 'undefined') {
  const createSchemaLink = require('server/utils/createSchemaLink').default
  createGraphQLServerLink = createSchemaLink
} else {
  createGraphQLServerLink = createGraphQLHttpLink
}

type CreateApolloLinksProps = {
  getToken(): string
  getCsrf(): string
  req: any
  res: any
}

const createApolloLinks = (apolloContext: CreateApolloLinksProps): ApolloLink => {
  const { getToken, getCsrf, req, res } = apolloContext
  const isBrowser = typeof window !== 'undefined'
  const { serverRuntimeConfig } = getConfig()

  // from https://www.apollographql.com/docs/react/recipes/authentication/
  const csrfLink = setContext(async (_request, { headers, ...context }) => {
    /**
     * the request is called every time execute is run on the link chain the
     * following is useful logging when debugging schema/data source requests data
     * source requests will log to the terminal and schema client schema requests will
     * log to the console
     */
    // console.log('operation name:', _request.operationName)
    // console.log('variables:', _request.variables)

    if (isBrowser) {
      if (!Cookies.get('_csrf')) {
        await fetch('/session', { credentials: 'same-origin' })
      }
    }

    const csrfToken = isBrowser ? Cookies.get('xsrf_token') : getCsrf()

    return {
      headers: {
        ...headers,
        'x-csrf-token': csrfToken,
      },
      ...context,
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
    if (!serverRuntimeConfig.disableGqlLogs) {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          )
        })
        console.log(
          `Operation: ${operation.operationName} Vars: ${JSON.stringify(operation.variables)}`,
          'Errors:',
          JSON.stringify(response?.errors, null, 2),
        )
      }
    }
    if (networkError) {
      console.log(
        `[Network error]: ${networkError}`,
        `Operation: ${operation.operationName} Vars: ${JSON.stringify(operation.variables)}`,
        'Errors:',
        JSON.stringify(response?.errors, null, 2),
      )
    }
  })

  return ApolloLink.from([csrfLink, errorLink, createGraphQLServerLink({ getToken, req, res })])
}

export default createApolloLinks
