import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import styled, { ThemeProvider, css } from 'styled-components'

import { FavoritesNavLink } from 'favorites/FavoritesNavLink'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import SearchMenu from 'layout/navigation/search/SearchMenu'
import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import { AlgoliaIndex } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useDebouncedValue } from 'shared/hooks/useDebouncedValue'
import FlightClubLogoSVG from 'shared/icons/FlightClubLogo'
import { BodyText } from 'shared/text'
import { mediaQueries } from 'shared/theme'
import colors, { ThemeColor } from 'shared/theme/colors'
import AccountMenu from './AccountMenu'
import MobileMenu from './MobileMenu'
import { SneakersMenu } from './SneakersMenu'
import HoverIntent from './components/HoverIntent'
import NavLink from './components/NavLink'
import Search from './search/Search'
import { useWindowSize } from 'shared/hooks/useWindowSize'

const TP = 'layout.navigation.search.navigation'
// NAVIGATION_THEMES
// Themes allow the navigation to respond to page scroll, routing, and menu events
// by changing and animating its style properties. All sub-components - no matter
// how deeply nested - have access to theme colors and properties.
export type NavThemeNames = 'default' | 'overlayed' | 'search' | 'sneakers' | 'checkout' | 'mobile'
export interface NavigationTheme {
  backgroundColor: ThemeColor
  borderColor: ThemeColor
  textColor: ThemeColor
  searchBorderColor: ThemeColor
  hideLogo?: boolean
  hideLinks?: boolean
  hideSearch?: boolean
  menuOpen?: boolean
}
type NavigationThemes = {
  [theme in NavThemeNames]: NavigationTheme
}
const NAVIGATION_THEMES: NavigationThemes = {
  default: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    searchBorderColor: colors.offWhite,
    textColor: colors.black,
  },
  overlayed: {
    backgroundColor: colors.transparent,
    borderColor: colors.transparent,
    searchBorderColor: colors.glass,
    textColor: colors.white,
  },
  search: {
    // for search and search results
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: true,
    hideLogo: true,
    menuOpen: true,
    searchBorderColor: colors.transparent,
    textColor: colors.black,
  },
  sneakers: {
    // for sneakers menu
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: false,
    hideLogo: false,
    menuOpen: false,
    searchBorderColor: colors.transparent,
    textColor: colors.black,
  },
  checkout: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    hideLinks: true,
    hideSearch: true,
    searchBorderColor: colors.offWhite,
    textColor: colors.black,
  },
  mobile: {
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: false,
    hideSearch: true,
    textColor: colors.black,
    searchBorderColor: colors.transparent,
    menuOpen: true,
  },
}

const Navigation = () => {
  const { asPath: pathWithQueries, events: routerEvents } = useRouter()
  const displayFlightClubFridays = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_FLIGHT_CLUB_FRIDAYS)
  const displayFlightClubInvitationalNav = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_FLIGHT_CLUB_INVITATIONAL_NAV)
  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)
  // query strings and hashes need to be stripped out
  const asPath = pathWithQueries.split(/[?#]/)[0]
  const [navTheme, setNavTheme] = useState<NavThemeNames>('overlayed')
  const [isOverlayed, setIsOverlayed] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebouncedValue(searchText, 300)
  // this is a reference to the timeout that triggers the closing of the sneakers menu, it can
  // be cancelled when switching to search to prevent nav from resetting for a smoother transition
  const [sneakersCancellableHoverTimeout, setSneakersCancellableHoverTimeout] = useState<number>()
  const intersectionObserver = useRef<IntersectionObserver>()
  const { isMobile } = useWindowSize()

  const getUnopenedNavTheme = (): NavThemeNames => {
    if (asPath.startsWith('/checkout')) {
      return 'checkout'
    }
    if (isOverlayed) {
      return 'overlayed'
    }
    return 'default'
  }
  const closeNav = () => setNavTheme(getUnopenedNavTheme())

  useEffect(() => {
    routerEvents.on('routeChangeComplete', closeNav)
  }, [])

  useEffect(() => {
    if (navTheme !== 'search' && navTheme !== 'sneakers' && navTheme !== 'mobile') {
      closeNav()
    }
  }, [isOverlayed, navTheme])

  // route transition effects
  // anything that needs to run on every page happens here
  useEffect(() => {
    // init the intersectionObserver if it doesn't exist
    if (!intersectionObserver.current) {
      intersectionObserver.current = new IntersectionObserver(
        (intersections: IntersectionObserverEntry[]) => {
          intersections.forEach((intersection) => {
            if (intersection.target.classList.contains('transparent-nav-overlay')) {
              setIsOverlayed(intersection.isIntersecting)
            }
          })
        },
      )
    }
    // disconnect and reconnect observation on route change to catch
    // new .transparent-nav-overlay elements
    intersectionObserver.current.disconnect()
    setTimeout(() => {
      const overlays = document.getElementsByClassName('transparent-nav-overlay')
      for (const overlay of overlays) {
        intersectionObserver.current?.observe(overlay)
      }
    }, 1000)

    // reset overlay status
    // page transitions can leave isOverlayed === true
    // when transitioning to a page without any overlay items
    setIsOverlayed(false)
  }, [asPath])

  return (
    <ThemeProvider theme={NAVIGATION_THEMES[navTheme]}>
      <ScrollContainer>
        <InstantSearch searchClient={AlgoliaSearchClient} indexName={AlgoliaIndex.Relevance}>
          <Configure hitsPerPage={10} distinct />
          <NavContainer role="navigation">
            <LeftContainer>
              {navTheme !== 'mobile' && (
                <Search
                  getUnopenedNavTheme={getUnopenedNavTheme}
                  navTheme={navTheme}
                  searchText={searchText}
                  setNavTheme={setNavTheme}
                  setSearchText={setSearchText}
                  sneakersCancellableHoverTimeout={sneakersCancellableHoverTimeout}
                />
              )}
              {/* Needs isMobile check to prevent double rendering of the component */}
              {isFavoritesEnabled && isMobile && (
                <FavoritesNavLink closeNav={closeNav} navTheme={navTheme} id="mobile" />
              )}
            </LeftContainer>
            <Link href="/" onClick={(e) => headerNavigationTrackEvent(e)}>
              <LogoContainer data-qa="navigation-link-home">
                <FlightClubLogo />
              </LogoContainer>
            </Link>
            <LinkContainer>
              <DesktopButtons>
                <Link href="/sneakers" onClick={(e) => headerNavigationTrackEvent(e)}>
                  <HoverIntent onEnter={() => setNavTheme('sneakers')}>
                    <NavLink data-qa="NavigationSneakersLink">
                      {t(`${TP}.sneakers`, 'Sneakers')}
                    </NavLink>
                  </HoverIntent>
                </Link>
                <Link href="/apparel" onClick={(e) => headerNavigationTrackEvent(e)}>
                  <NavLink data-qa="NavigationApparelLink" disabled={asPath === '/apparel'}>
                    {t(`${TP}.fcApparel`, 'FC Apparel')}
                  </NavLink>
                </Link>
                {displayFlightClubFridays && (
                  <a
                    href="https://www.youtube.com/playlist?list=PL9jt6WcuokxZA9n-fCgwLJXZ3UFU6T6WX"
                    onClick={(e) => headerNavigationTrackEvent(e)}
                    target="_blank"
                  >
                    <NavLink data-qa="flightclub_fridays_youtube">
                      {t(`${TP}.fcFridays`, 'FC Fridays')}
                    </NavLink>
                  </a>
                )}
                {displayFlightClubInvitationalNav && (
                  <a
                    href="https://bit.ly/rsvp-fci"
                    onClick={(e) => headerNavigationTrackEvent(e)}
                    target="_blank"
                  >
                    <NavLink data-qa="flightclub_invitational_nav">
                      {t(`${TP}.fcInvitational24`, 'FCI \'24')}
                    </NavLink>
                  </a>
                )}
                <Link href="/storelocator" onClick={(e) => headerNavigationTrackEvent(e)}>
                  <NavLink data-qa="NavigationStoresLink" disabled={asPath === '/storelocator'}>
                    {t(`${TP}.stores`, 'Stores')}
                  </NavLink>
                </Link>
                {/* Needs !isMobile check to prevent double rendering of the component */}
                {isFavoritesEnabled && !isMobile && <FavoritesNavLink id="desktop" />}
                <AccountMenu navTheme={navTheme} closeMenu={closeNav} />
              </DesktopButtons>
              <MobileButtons>
                {navTheme !== 'mobile' && (
                  <MobileOpen
                    data-qa="MobileNavigationMenu"
                    onClick={(e) => {
                      setNavTheme('mobile')
                      headerNavigationTrackEvent(e)
                    }}
                  >
                    {t(`${TP}.menu`, 'Menu')}
                  </MobileOpen>
                )}
                {navTheme === 'mobile' && (
                  <MobileClose
                    data-qa="MobileNavigationMenuCloseBtn"
                    onClick={(e) => {
                      closeNav()
                      headerNavigationTrackEvent(e)
                    }}
                  >
                    {t(`${TP}.close`, 'Close')}
                  </MobileClose>
                )}
              </MobileButtons>
            </LinkContainer>
          </NavContainer>
          <SearchMenu
            isOpen={navTheme === 'search'}
            onRequestClose={closeNav}
            searchText={debouncedSearch}
          />
          <SneakersMenu
            isOpen={navTheme === 'sneakers'}
            closeMenu={closeNav}
            setSneakersCancellableHoverTimeout={setSneakersCancellableHoverTimeout}
          />
          <MobileMenu isOpen={navTheme === 'mobile'} closeMenu={closeNav} />
        </InstantSearch>
      </ScrollContainer>
    </ThemeProvider>
  )
}

const MobileClose = styled(BodyText)`
  padding: 15px 0px;
  cursor: pointer;
`

const MobileOpen = styled(NavLink)`
  padding: 15px 0px;
`

const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
`

export const NavContainer = styled.nav`
  position: relative;
  z-index: 999;
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.backgroundColor};
  display: flex;
  height: 76px;
  width: 100%;
  transition: background-color 200ms ease-out, border-color 200ms ease-out;
  padding: 0px 20px;
`

const LeftContainer = styled.div`
  flex: 1;
  flex-basis: 45%;
  height: 100%;
  display: flex;
  align-items: center;
`

const LogoContainer = styled.div`
  flex-shrink: 0;
  text-align: center;
  opacity: 1;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideLogo &&
    css`
      pointer-events: none;
      transition: opacity 200ms ease-out;
      opacity: 0;
    `}
`

export const FlightClubLogo = styled(FlightClubLogoSVG)`
  nav & {
    cursor: pointer;
    height: 44px;
    padding: 10px;
    width: 147px;
  }
`

const LinkContainer = styled.div`
  flex: 1;
  flex-basis: 45%;
  text-align: right;
  opacity: 1;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideLinks &&
    css`
      pointer-events: none;
      transition: opacity 200ms ease-out;
      opacity: 0;
    `}
`

const MobileButtons = styled.div`
  display: none;
  ${mediaQueries.smallNavigation} {
    display: block;
  }
`

const DesktopButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mediaQueries.smallNavigation} {
    display: none;
  }
`

export default Navigation
