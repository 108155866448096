import { colors, fonts, media } from 'shared/lib'
import styled from 'styled-components'

export const PANEL_BOX_DESKTOP_WIDTH = '515px'

const PanelBox = styled.div<{ $fill?: boolean }>`
  width: 100%;
  position: relative;
  ${fonts.BODY_TEXT};
  padding: 20px;
  background-color: ${colors.FC2_WHITE};
  ${media.large`
    padding: 30px;
    width: ${p => (p.$fill ? '100%' : PANEL_BOX_DESKTOP_WIDTH)};
  `}
`
export default PanelBox
