import styled from 'styled-components'

const small = styled.small`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
`

export default small
