import { t } from 'localization'
import Link from 'next/link'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const TP = 'errors.components.DefaultError'

export const DefaultError = () => {
  return (
    <DefaultError.Wrapper>
      <p>{t(`${TP}.title`, 'Sorry, something went wrong.')}</p>
      <p>
        <Trans
          i18nKey={`${TP}.body`}
          defaults="Please try again or reach out to <0>support@flightclub.com</0>"
          components={[<Link key="supportLink" href="mailto:support@flightclub.com" />]}
        />
      </p>
    </DefaultError.Wrapper>
  )
}

DefaultError.Wrapper = styled.div`
  text-align: center;
`
