import algoliasearch from 'algoliasearch/lite'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const AlgoliaSearchClient = algoliasearch(
  publicRuntimeConfig.algoliaAppID,
  publicRuntimeConfig.algoliaAPIKey,
)

export default AlgoliaSearchClient
