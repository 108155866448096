import { FC } from 'react'
import { Field } from 'formik'
import HiddenCheckbox from './HiddenCheckbox'
import CircleCheckbox from './CircleCheckbox'
import Label from './Label'

interface IFormikCheckboxProps {
  name: string
  label: string
  text: string
  checked: boolean
  noMargin?: boolean
  className?: string
  isDarkMode?: boolean
}

const FormikCheckbox: FC<IFormikCheckboxProps> = ({
  name,
  label,
  text,
  checked,
  noMargin,
  className,
  isDarkMode = false,
}) => (
  <div className={className}>
    <Field name={name}>
      {({ field }) => (
        <>
          {isDarkMode ? (
            <CircleCheckbox {...field} type="checkbox" id={label} checked={checked} />
          ) : (
            <HiddenCheckbox {...field} type="checkbox" id={label} checked={checked} />
          )}
          <Label htmlFor={label} noMargin={noMargin}>
            {text}
          </Label>
        </>
      )}
    </Field>
  </div>
)
export default FormikCheckbox
