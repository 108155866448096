import styled from 'styled-components'
import { colors } from 'shared/theme'
import InputArrowSVG from 'shared/svg/input_arrow.svg'

const InputArrow = styled(InputArrowSVG)`
  color: ${colors.white};
  height: 10px;
  width: 18px;
`

export default InputArrow
