export enum FeatureFlagActions {
  setFeatureFlags = 'setFeatureFlags',
}

export interface IFeatureFlagAction {
  type: FeatureFlagActions
  payload: { [key: string]: boolean } | null
}

type IFeatureFlagStateAction = {
  type: FeatureFlagActions.setFeatureFlags
  payload: { [key: string]: boolean } | null
}

export type IFeatureFlagDispatch = (action: IFeatureFlagAction) => void

export interface IFeatureFlagState {
  featureFlags: { [key: string]: boolean } | null
}

export const featureFlagReducer = (state: IFeatureFlagState, action: IFeatureFlagStateAction) => {
  switch (action.type) {
    case FeatureFlagActions.setFeatureFlags:
      if (action.payload) {
        state.featureFlags = { ...state.featureFlags, ...action.payload }
      }
      break
    default:
      return state
  }
  return state
}
