import i18n, { StringMap, TOptions } from 'i18next'
import ICU from 'i18next-icu'
import nextI18nextConfiguration from 'localization/enums/nextI18nextConfiguration'
import { i18n as nextI18next } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { initReactI18next } from 'react-i18next'

import { ServerResponse } from 'http'
import { DEFAULT_LANGUAGE, LANGUAGES } from 'localization/enums/LocalizationEnums'

// for all options read: https://www.i18next.com/overview/configuration-options
export const i18NextConfig = {
  resources: LANGUAGES,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: {
    default: ['en'],
    'ja-jp': ['ja'],
    jp: ['ja'],
  },
  debug: false,
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
}

// client side translation
// usage
export const t = (
  key: string,
  optionsOne: string | TOptions<StringMap> | undefined,
  optionsTwo?: TOptions,
): string => {
  if (nextI18next) {
    return nextI18next.t(key, optionsOne, optionsTwo)
  }
  if (typeof optionsOne === 'string') {
    return optionsOne
  }
  return optionsOne?.defaultValue || ''
}

export const serverSideConfigs = async (_: ServerResponse, locale?: string) => {
  const code = locale || DEFAULT_LANGUAGE
  // return i18n data
  return await serverSideTranslations(code, ['common'], nextI18nextConfiguration)
}

// temp - turn /ja/sneakers into /sneakers
// Remove after we have breadcrumb data availabile for /ja/
export const getDefaultLocalePath = (path: string) => {
  if (i18n?.language) {
    return path.replace(`/${i18n.language}`, '')
  }
  return path
}

export const getLocalePath = (pathWithoutLocale: string, locale?: string) => {
  if (!locale || locale === 'en') {
    return pathWithoutLocale
  }
  return `/${locale}${pathWithoutLocale}`
}

// init i18n
i18n
  .use(ICU)
  .use(initReactI18next)
  .init(i18NextConfig)
  .catch((error) => error)

export default i18n
