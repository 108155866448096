/**
 * [isValidEventSchema]
 * Returns false if provided schema has incorrect shape or missing attributes.
 * @param   {Object}  schema An enumerated event, in object format.
 */
const isValidEventSchema = (schema: any): boolean => {
  return (
    schema instanceof Object &&
    typeof schema.name === 'string' &&
    typeof schema.type === 'string' &&
    schema.attributes instanceof Array
  )
}
export default isValidEventSchema
