import { FC } from 'react'
import { Button } from 'shared/components/Button'
import { SearchQA } from 'shared/dataAttributes'
import { renderUntil } from 'shared/hocs/render'
import { colors } from 'shared/lib'
import styled from 'styled-components'

import { IRefinementListProps } from '../IRefinementListProps'

const ConditionsCardContent = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  max-height: 200px;
  overflow-y: auto;
  padding: 20px;
`

const ConditionOptionButton = styled(Button)<{ isSelected: boolean }>`
  border-color: ${({ isSelected }) =>
    isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY} !important;
`

const MobileModelFilter: FC<IRefinementListProps> = ({ items, refine }) => {
  return (
    <ConditionsCardContent>
      {items.map(({ label, value, isRefined }) => (
        <ConditionOptionButton
          $fill
          buttonType="secondary"
          key={label}
          onClick={() => {
            refine(value)
          }}
          isSelected={isRefined}
          qaAttr={SearchQA.MobileFilterModelLabel}
        >
          {label}
        </ConditionOptionButton>
      ))}
    </ConditionsCardContent>
  )
}

MobileModelFilter.displayName = 'MobileModelFilter'

export default renderUntil.large(MobileModelFilter)
