import axios, { AxiosRequestConfig } from 'axios'
import Cookie from 'js-cookie'
import isString from 'lodash/isString'

/**
 * Returns a fresh crsf token
 */
export const refreshCrsf = async (): Promise<string> => {
  if (!Cookie.get('xsrf_token')) {
    await axios.get('/session', { withCredentials: true })
  }
  const csrfResponse = await axios.get('/token', { withCredentials: true })

  return await csrfResponse.data
}

export const setHeaderWithCSRF = () => {
  return {
    'X-CSRF-TOKEN': Cookie.get('xsrf_token'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

export const requestService = (options: AxiosRequestConfig) => {
  if (!options.url || !isString(options.url)) {
    throw new Error('Required: url')
  }

  const requestOptions = {
    ...options,
    headers: { ...setHeaderWithCSRF(), ...options.headers },
    method: options.method || 'GET',
  }

  return axios
    .request(requestOptions)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
