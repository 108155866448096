import { forwardRef, Ref } from 'react'
import styled from 'styled-components'
import { get } from 'lodash/fp'
import { colors, fonts } from 'shared/lib'
import { SVGIcon } from 'shared/components/Icons'
import { useErrorContext } from 'errors/components/ErrorContextProvider'

const ErrorBox = styled.div<{ error: boolean; isDarkMode: boolean }>`
  display: ${({ error }) => (error ? 'flex' : 'none')};
  align-items: center;
  width: 100%;
  ${fonts.BODY_TEXT}
  color: ${colors.FC2_RED};
  border: 1px solid ${colors.FC2_RED};
  background-color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_BLACK : colors.FC2_WHITE)};
  margin-bottom: 20px;
  padding: 16px 20px;
`

const ErrorX = styled(SVGIcon).attrs(({ fill }) => ({
  name: 'errorIcon',
  fill,
}))`
  margin-right: 10px;
`

interface IContextErrorBannerProps {
  qaAttr?: string
  isDarkMode?: boolean
}
const ContextErrorBanner = (
  { qaAttr, isDarkMode = false }: IContextErrorBannerProps,
  ref?: Ref<HTMLInputElement>,
) => {
  const errorContext = useErrorContext()
  const errors = get('errors')(errorContext)
  const error = get('0.message')(errors) || errors?.[0]

  return (
    <ErrorBox ref={ref} error={!!error} isDarkMode={isDarkMode}>
      <ErrorX fill={isDarkMode ? colors.FC2_BLACK : colors.FC2_WHITE} />
      <span data-qa={qaAttr}>{error}</span>
    </ErrorBox>
  )
}

export default forwardRef(ContextErrorBanner)
