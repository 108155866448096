import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const CircleCheck = ({
  color = colors.FC2_GREEN,
  width = '20',
  height = '20',
  className = '',
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <g fill="none" fillRule="nonzero" stroke={color} transform="translate(1 1)">
      <circle cx="9" cy="9" r="9" fill="#FFF" />
      <path strokeWidth="1.5" d="M5.063 8.814L7.8 11.812l5.138-5.624" />
    </g>
  </svg>
)
