import setUpMParticle from 'head/utils/setUpMParticle'
import { logPageView, logUtmIfPresent } from 'mParticle/mParticleService'
import getConfig from 'next/config'
import Router from 'next/router'
import { Component } from 'react'
import { trackSnapchatPageView } from 'tracking/snapchatService'

interface WithTrackingState {
  initialPageLoad: boolean
}

function withTracking(App) {
  return class extends Component<{}, WithTrackingState> {
    constructor(props) {
      super(props)
      this.state = {
        initialPageLoad: true,
      }
    }

    shouldTrackPageView(pathName = '') {
      // Router.pathname returns things like /collection/[slug] and /[...slug]
      // for collections and plp/pdp respectively
      if (
        pathName.includes('[...slug]') ||
        pathName.includes('[slug]') ||
        pathName.includes('catalogsearch')
      ) {
        return false
      }
      return true
    }

    trackRouteChange = (url: string) => {
      // Modified shouldTrackPageView to accommodate removal of duplicate
      // snapchat pixel events
      logPageView(url)
      if (!this.state.initialPageLoad && this.shouldTrackPageView(Router.pathname)) {
        trackSnapchatPageView()
      }
    }

    componentDidMount() {
      if (this.state.initialPageLoad) {
        this.setState({ initialPageLoad: false })
        this.trackRouteChange(Router.asPath)
      }
      Router.events.on('routeChangeComplete', this.trackRouteChange)

      const {
        publicRuntimeConfig: { mParticleApiKey, releaseEnv },
      } = getConfig()

      try {
        setUpMParticle(mParticleApiKey, releaseEnv)
        window.mParticle.ready(() => {
          this.trackRouteChange(Router.asPath)
          logUtmIfPresent()
        })
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(`There was an issue setting up mParticle: ${e}`)
      }
    }

    componentWillUnmount() {
      Router.events.off('routeChangeComplete', this.trackRouteChange)
    }

    render() {
      return <App {...this.props} />
    }
  }
}

export default withTracking
