import colors from './colors'

const fontFamily = {
  STANDARD: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
}

const fonts = {
  STANDARD: `font-family: ${fontFamily.STANDARD};`,
  BANNER: `
    font-family: ${fontFamily.STANDARD};
    font-size: 40px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${colors.FC2_WHITE};
  `,
  HEADER_1: `
    font-family: ${fontFamily.STANDARD};
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${colors.FC2_BLACK};
  `,
  HEADER_2: `
    font-family: ${fontFamily.STANDARD};
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    color: ${colors.FC2_BLACK};
    `,
  SUBTITLE_1: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_BLACK};
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  `,
  SUBTITLE_2: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_BLACK};
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  `,
  SUBTITLE_3: `
    font-family: ${fontFamily.STANDARD};
    font-weight: 500;
    font-size: 12px;
    color: ${colors.FC2_BLACK};
  `,
  SUBTITLE_4: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_GREY};
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    font-size: 10px;
  `,
  CTA_TEXT_1: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_WHITE};
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    `,
  CTA_TEXT_2: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_BLACK};
    font-weight: bold;
    font-size: 14px;
  `,
  BANNER_CTA_TEXT: `
    font-family: ${fontFamily.STANDARD};
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
    color: ${colors.FC2_WHITE};
    text-transform: uppercase;
    text-decoration: none;
  `,
  SUB_TEXT: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_GREY};
    font-size: 10px;
  `,
  LINK: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_BLACK};
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
  `,
  LINK_ACTION: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_GREY};
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  `,
  BODY_TEXT: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_GREY};
    font-size: 12px;
    line-height: 16px;
  `,
  CAPTION: `
    font-family: ${fontFamily.STANDARD};
    color: ${colors.FC2_RED};
    text-transform: uppercase;
    font-size: 10px;
  `,
}

export default fonts
