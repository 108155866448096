import styled from 'styled-components'
import { colors, fonts } from 'shared/lib'

const SizeTile = styled.button<{ isSelected: boolean }>`
  width: 100%;
  height: 40px;
  background: none;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};
  background-color: ${colors.FC2_WHITE};
  ${fonts.SUBTITLE_2};
`

export default SizeTile
