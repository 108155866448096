/* tslint:disable:no-console */
import { Component } from 'react'
import createApolloClient from './createApolloClient'

const withApolloClient = (App) =>
  class Apollo extends Component {
    static displayName = 'withApollo(App)'
    apolloClient = {}

    constructor(props) {
      super(props)
      const getCsrf = () => props.pageProps.csrf
      const getToken = () => null
      this.apolloClient = createApolloClient(props.pageProps.apolloState, {
        getCsrf,
        getToken,
        currency: props.pageProps.currency,
      })
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />
    }
  }

export default withApolloClient
