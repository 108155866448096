import Bugsnag, { BrowserConfig } from '@bugsnag/js'
import BugsnagPluginExpress from '@bugsnag/plugin-express'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'

import { logger } from 'server/utils/logger'

type BugsnagConfig = BrowserConfig

export const startBugsnag = () => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
  const isServer = typeof window === 'undefined'
  const apiKey = isServer ? serverRuntimeConfig.bugsnagAPIKey : publicRuntimeConfig.bugsnagAPIKey

  if (!apiKey) return

  const appType = isServer ? 'server' : 'browser'
  const plugins = [new BugsnagPluginReact(), ...(isServer ? [BugsnagPluginExpress] : [])]
  const config: BugsnagConfig = {
    apiKey,
    appType,
    appVersion: publicRuntimeConfig.appVersion,
    enabledReleaseStages: ['staging', 'production'],
    plugins,
    releaseStage: publicRuntimeConfig.releaseEnv,
    logger: isServer ? logger : console,
  }

  if (!Bugsnag.isStarted()) {
    Bugsnag.start(config)
  }
}
