import { isFunction, omit } from 'lodash/fp'
import { logClickEventUtil } from 'mParticle/mParticleService'
import Link from 'next/link'
import { FC, ReactNode, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'shared/lib'
interface IButtonProps {
  as?: string
  buttonType?: ButtonType
  className?: string
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  children?: ReactNode
  dataTrackingEvent?(e: EventTarget): void
  disabled?: boolean
  dataTestid?: string
  $fill?: boolean
  href?: string
  isDarkMode?: boolean
  onClick?
  qaAttr?: string
  type?: string
  uniqueLogEvent?: boolean
}

type ButtonType = 'primary1' | 'primary2' | 'secondary' | 'tertiary' | 'link'

const appendStyles = (buttonType?: ButtonType) => {
  switch (buttonType) {
    case 'primary2':
      return PrimaryCTA2
    case 'secondary':
      return SecondaryCTA
    case 'tertiary':
      return TertiaryCTA
    case 'link':
      return LinkCTA
    default:
      return PrimaryCTA1
  }
}

const FCButton = forwardRef(
  ({ buttonType, className, component, $fill, ...props }: IButtonProps, ref) => {
    return (
      <StyledButton
        as={component || 'button'}
        buttonType={buttonType}
        className={className}
        $fill={$fill}
        {...props}
      />
    )
  },
)

export const Button: FC<IButtonProps> = (props) => {
  const {
    buttonType = 'primary1',
    className,
    qaAttr,
    dataTestid,
    dataTrackingEvent,
    href,
    onClick,
    children,
    uniqueLogEvent,
  } = props
  const passedProps = omit([
    'qaAttr',
    'dataTestid',
    'dataTrackingEvent',
    'buttonType',
    'href',
    'isDarkMode',
    'onClick',
  ])(props)
  const onClickWithLogEvent = (e) => {
    if (!uniqueLogEvent) {
      if (isFunction(dataTrackingEvent)) {
        dataTrackingEvent(e)
      } else {
        logClickEventUtil(e)
      }

      if (isFunction(onClick)) {
        onClick()
      }
    }
  }

  return (
    <>
      {href && (
        <Link href={href} passHref legacyBehavior>
          <FCButton
            buttonType={buttonType}
            className={className}
            $fill
            data-qa={qaAttr}
            data-testid={dataTestid}
            onClick={onClickWithLogEvent}
            {...passedProps}
          >
            {children}
          </FCButton>
        </Link>
      )}
      {!href && (
        <FCButton
          buttonType={buttonType}
          className={className}
          $fill
          data-qa={qaAttr}
          data-testid={dataTestid}
          onClick={onClickWithLogEvent}
          {...passedProps}
        >
          {children}
        </FCButton>
      )}
    </>
  )
}

const disabledButtonStyles = css`
  background-color: ${colors.FC2_LIGHTEST_GREY};
  border-color: ${colors.FC2_LIGHTEST_GREY};
  color: ${colors.FC2_DARK_GREY};
  cursor: default;
  &:hover {
    background-color: ${colors.FC2_LIGHTEST_GREY};
  }
`

const PrimaryCTA1 = css`
  background-color: ${colors.FC2_BLACK};
  border: 1px solid ${colors.FC2_BLACK};
  color: ${colors.FC2_WHITE};
  &:hover {
    background-color: ${colors.FC2_DARK_GREY};
    border-color: ${colors.FC2_DARK_GREY};
  }
  &:disabled {
    ${disabledButtonStyles}
  }
`

const PrimaryCTA2 = css<IButtonProps>`
  background-color: ${colors.FC2_RED};
  border: 1px solid ${colors.FC2_RED};
  color: ${colors.FC2_WHITE};
  ${({ as }) =>
    as === 'a' &&
    css`
      display: block;
    `};
  &:hover {
    background-color: #d91a21;
  }
  &:disabled {
    ${disabledButtonStyles}
  }
`

const SecondaryCTA = css`
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_BLACK};
  color: ${colors.FC2_BLACK};
  &:hover {
    background-color: ${colors.FC2_OFF_WHITE};
  }
  &:disabled {
    ${disabledButtonStyles}
  }
`

const TertiaryCTA = css`
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_BLACK};
  color: ${colors.FC2_BLACK};
  height: 35px;
  line-height: 35px;
  &:hover {
    background-color: ${colors.FC2_OFF_WHITE};
  }
  &:disabled {
    ${disabledButtonStyles}
  }
`

const LinkCTA = css`
  background-color: transparent;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  height: auto;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
  width: auto;
`

const StyledButton = styled.button<IButtonProps>`
  font-size: 14px;
  font-weight: bold;
  height: 45px;
  line-height: 45px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  ${({ $fill }) => ($fill ? 'width: 100%;' : 'width: 325px;')};
  ${({ buttonType }) => appendStyles(buttonType)}
`

export default Button
