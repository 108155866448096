import styled from 'styled-components'

import { IconsQA } from 'shared/dataAttributes'

type AmexCardProps = {
  size?: 'small' | ''
}

export const AmexCard = ({ size = '' }: AmexCardProps) => (
  <CustomSvg
    $size={size}
    data-qa={IconsQA.AmexCardIcon}
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="35" height="23" rx="1.5" fill="white" stroke="#E7E7E7" />
    <path
      d="M17.592 9H16.8L15 12.828L13.2 9H10.8V14.064L8.49598 9H6.21598L3.59998 15H5.38798L5.92798 13.668H8.73598L9.29998 15H12.3V10.68L14.304 15H15.756L17.592 11.04V15H19.2V9H17.64H17.592ZM6.49198 12.3L7.30798 10.308L8.15998 12.3H6.49198Z"
      fill="#0072AD"
    />
    <path
      d="M32.3999 9H30.2879L28.5839 10.8L26.9159 9H20.3039V15H26.7839L28.5479 13.116L30.2759 15H32.3999L29.6279 12L32.3999 9ZM25.1999 14.4V13.62H21.8399V12.672H25.1999V11.316H21.8399V10.368H25.1999V9.456L27.5159 12L25.1999 14.4Z"
      fill="#0072AD"
    />
  </CustomSvg>
)

const CustomSvg = styled.svg<{ $size: AmexCardProps['size'] }>`
  height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
  width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
`
