import { createContext, FC, ReactNode } from 'react'
import { useImmerReducer } from 'use-immer'

import {
  IFeatureFlagDispatch,
  IFeatureFlagState,
  featureFlagReducer,
} from 'featureFlags/state/featureFlagReducer'

interface IFeatureFlags {
  [key: string]: boolean
}

interface IFeatureFlagContextProvider {
  children: ReactNode
  featureFlags: IFeatureFlags
}

export const FeatureFlagContext = createContext<IFeatureFlagState>(null)
export const FeatureFlagDispatchContext = createContext<IFeatureFlagDispatch>(null)

const FeatureFlagContextProvider: FC<IFeatureFlagContextProvider> = ({
  children,
  featureFlags,
}) => {
  const initialValues = {
    featureFlags,
  }

  const [state, dispatch] = useImmerReducer(featureFlagReducer, initialValues)

  return (
    <FeatureFlagDispatchContext.Provider value={dispatch}>
      <FeatureFlagContext.Provider value={state}>{children}</FeatureFlagContext.Provider>
    </FeatureFlagDispatchContext.Provider>
  )
}

export default FeatureFlagContextProvider
