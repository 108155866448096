import Link from 'next/link'
import { MutableRefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { OfferSizes } from 'offers/OfferSizes'
import { OffersListResponse } from 'offers/types'
import Image from 'shared/components/Image'
import { colors } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { useAppDispatch } from 'store/hooks'
import { setCurrentPaginationToken } from 'store/offersSlice'

type OffersListProps = {
  handleSlideOutClose: () => void
  listItems: OffersListResponse[]
}

export const OffersList = ({ listItems, handleSlideOutClose }: OffersListProps) => {
  const observerRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const dispatch = useAppDispatch()

  // Infinite scroll
  // when element is in view it dispatches an action to the redux store to get the
  // next pagination token in the stack if theres is one.
  useEffect(() => {
    if (observerRef?.current) {
      const cachedRef = observerRef.current
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            dispatch(setCurrentPaginationToken())
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        },
      )

      observer.observe(cachedRef)

      return () => {
        observer.unobserve(cachedRef)
      }
    }
  }, [observerRef.current])

  return (
    <OffersListWrapper data-qa="OffersListWrapper">
      {listItems.map((ptOffers, primaryIndex) => {
        const PT = ptOffers.productTemplate
        return (
          <OfferCard key={PT.id}>
            <Link href={`/${PT.slug}`}>
              <OfferDescription onClick={handleSlideOutClose}>
                <OfferImage>
                  <Image
                    data-qa={`OffersListPtImage${primaryIndex}`}
                    alt={PT?.name}
                    src={PT?.picture?.gridPhotoUrl}
                    width={128}
                    height={96}
                  />
                </OfferImage>
                <OfferName data-qa={`OffersListPtName${primaryIndex}`}>
                  <span>{PT.name}</span>
                </OfferName>
              </OfferDescription>
            </Link>

            <OfferSizes primaryIndex={primaryIndex} ptOffers={ptOffers} />
          </OfferCard>
        )
      })}
      <OffersListObserver ref={observerRef} />
    </OffersListWrapper>
  )
}

const OfferCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: ${colors.FC2_WHITE};

  > a {
    text-decoration: none;
  }

  @media (max-width: ${sizes.large / 16}em) {
    margin-top: 10px;
  }
`

const OfferDescription = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 130px;
  width: 100%;
  @media (max-width: ${sizes.large / 16}em) {
    height: 150px;
  }
`

const OfferImage = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0px 20px;
  > img {
    width: 150px;
    max-width: 150px;
    max-height: 130px;
    min-height: 80px;
    object-fit: scale-down;
  }
`

const OffersListObserver = styled.div`
  height: 50px;
`

const OffersListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 20px;
  @media (max-width: ${sizes.large / 16}em) {
    padding: 0px 10px;
  }
`

const OfferName = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  padding-right: 40px;
  width: 50%;
  max-height: 30px;

  > span {
    display: block;
    color: ${colors.FC2_BLACK};
  }
`
