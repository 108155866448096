import { useState, useEffect } from 'react'

import { sizes } from 'shared/lib/media'

interface IWindowSize {
  width: number | undefined
  height: number | undefined
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.documentElement.clientWidth || window.innerWidth,
        height: document.documentElement.clientHeight || window.innerHeight,
      })
    }

    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Add event listener
      window.addEventListener('resize', () => {
        handleResize()
      })
      // Call handler right away so state gets updated with initial window size
      handleResize()
      // Remove event listener on cleanup
      return () =>
        window.removeEventListener('resize', () => {
          handleResize()
        })
    }
  }, [])

  const isMobile = windowSize.width < sizes.medium

  return {
    isMobile,
    windowSize,
  }
}
