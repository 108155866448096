import translationEN from '../../../public/locales/en/common.json'
import translationENJP from '../../../public/locales/en-jp/common.json'
import translationJA from '../../../public/locales/ja/common.json'

export const LANGUAGE_COOKIE_NAME = 'locale'

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_LOCALE = 'en'

export interface ILanguageNavConfig {
  [language: string]: {
    label: string
    sneakerCode: string
    localeCode: string
    translation: object
    dateFormat?: Intl.DateTimeFormatOptions
    dateTimeFormat?: Intl.DateTimeFormatOptions
  }
}

export const LANGUAGES: ILanguageNavConfig = {
  en: {
    label: 'English',
    sneakerCode: 'en',
    localeCode: 'en-US',
    translation: translationEN,
    dateFormat: {
      timeZone: 'America/New_York',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    },
    dateTimeFormat: {
      timeZone: 'America/New_York',
      timeZoneName: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-jp': {
    label: 'English',
    sneakerCode: 'en',
    localeCode: 'en-US',
    translation: translationENJP,
    dateFormat: {
      timeZone: 'America/New_York',
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    },
    dateTimeFormat: {
      timeZone: 'America/New_York',
      timeZoneName: 'short',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ja: {
    label: '日文',
    sneakerCode: 'ja',
    localeCode: 'ja-JP',
    translation: translationJA,
    dateFormat: {
      timeZone: 'Asia/Tokyo',
      dateStyle: 'long',
    },
    dateTimeFormat: {
      timeZone: 'Asia/Tokyo',
      timeZoneName: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
}
