import { MutableRefObject } from 'react'

import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { LoginPanelModal } from 'favorites/LoginPanelModal'
import { OffersModalContents } from 'offers/OffersModalContents'
import { useClickOutside } from 'shared/hooks'

type OffersModalDesktopProps = {
  handleCloseOffersModal: () => void
  handleCloseOffersLoginPrompt: () => void
  promptLogin?: boolean
}

export const OffersModalDesktop = ({
  handleCloseOffersModal,
  promptLogin,
  handleCloseOffersLoginPrompt,
}: OffersModalDesktopProps) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    if (promptLogin) return
    handleCloseOffersModal()
  })

  return (
    <>
      <ClientOnlyPortal
        withBackdrop
        selector=".main-page-layout"
        itemsAlignment="center"
        id="offers-modal-desktop"
      >
        <OffersModalContents ref={ref} handleCloseOffersModal={handleCloseOffersModal} />
      </ClientOnlyPortal>

      {promptLogin && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseOffersLoginPrompt}
          location="pdp_offer"
          flow="placeOffer"
        />
      )}
    </>
  )
}
