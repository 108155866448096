import { useContext } from 'react'

import {
  FeatureFlagContext,
  FeatureFlagDispatchContext,
} from 'featureFlags/providers/FeatureFlagProvider'

export const useFeatureFlag = (name: string) => {
  const featureFlags = useContext(FeatureFlagContext)
  return !!featureFlags?.featureFlags?.[name]
}

export const useFeatureFlagState = () => useContext(FeatureFlagContext)
export const useFeatureFlagDispatch = () => useContext(FeatureFlagDispatchContext)
