import { Dispatch, RefObject, SetStateAction, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-dom'

import SearchIcon from 'shared/icons/Search'
import { colors, mediaQueries } from 'shared/theme'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { t } from 'localization'

const TP = 'layout.navigation.search.searchInput'

export interface SearchInputProps extends SearchBoxProvided {
  openSearch: () => void
  searchInputEl: RefObject<HTMLInputElement>
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
}

const SearchInput = ({
  openSearch,
  refine,
  searchInputEl,
  searchText,
  setSearchText,
}: SearchInputProps) => {
  useEffect(() => refine(searchText), [searchText])
  return (
    <InputContainer>
      <StyledSearchIcon />
      <Input
        aria-label={t(`${TP}.searchInput`, 'Search input')}
        autoComplete="off"
        data-qa="NavigationSearchInput"
        id="NavigationSearchInput"
        onChange={({ target }) => setSearchText(target.value)}
        onFocus={e => {
          // BUY1-304 OneTrust accept button bubbles the click event upward
          // so we need to check if the focus target here is actually this input
          if (e?.currentTarget === e?.target) {
            openSearch()
            sendTrackingEvent('SEARCH_BAR_TAP', {
              page: window?.location?.href,
            })
          }
        }}
        placeholder={t(`${TP}.search`, 'Search')}
        ref={searchInputEl}
        role="search"
        type="text"
        value={searchText}
      />
    </InputContainer>
  )
}

const InputContainer = styled.span`
  border-radius: 3px;
  border: 2px solid ${props => props.theme.searchBorderColor};
  display: flex;
  position: relative;
  transition: border-color 200ms, width 100ms;
  width: ${props => (props.theme.menuOpen ? '100%' : '240px')};
  pointer-events: auto;
  ${mediaQueries.smallNavigation} {
    border-color: ${colors.transparent};
    width: ${props => (props.theme.menuOpen ? '100%' : '90px')};
  }
  ${props =>
    props.theme.menuOpen &&
    css`
      transition: border-color 200ms, width 100ms 200ms;
    `}
`

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 5px;
  left: 7px;
  width: 20px;
  height: 20px;
  fill: ${props => props.theme.textColor};
  ${mediaQueries.smallNavigation} {
    display: none;
  }
`

/* stylelint-disable */
const Input = styled.input`
  background-color: transparent;
  border: 0;
  color: ${props => props.theme.textColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  outline: 0;
  padding: 0 20px 0 32px;
  width: 100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.backgroundColor};
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.backgroundColor} inset;
    background-color: ${props => props.theme.backgroundColor} !important;
    background-clip: content-box !important;
  }
  &::placeholder {
    color: ${props => props.theme.textColor};
    opacity: 1;
  }

  :focus::placeholder {
    color: ${colors.mediumGray};
  }

  ${mediaQueries.smallNavigation} {
    padding-left: 0;
  }
`
/* stylelint-enable */

export default connectSearchBox(SearchInput)
