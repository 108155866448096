export const newSizes = [
  '4',
  '4.5',
  '6',
  '6.5',
  '8',
  '8.5',
  '10',
  '10.5',
  '12',
  '12.5',
  '14',
  '14.5',
]

export const usedSizes = ['5', '5.5', '7', '7.5', '9', '9.5', '11', '11.5', '13', '13.5']

const sizes = [...newSizes, ...usedSizes]

const men = [
  ['US', 'UK', 'EUROPE', 'CM'],
  ['6', '5.5', '38.5', '24'],
  ['6', '5.5', '38.5', '24'],
]

const women = [
  ['US', 'UK', 'EUROPE', 'CM'],
  ['6', '5.5', '38.5', '24'],
  ['6', '5.5', '38.5', '24'],
]

export const sizeConversions = {
  men,
  women,
}

// export for test purposes
export const US_WOMEN_SKIP_SIZES = [14.5, 15.5]
export const EU_WOMEN_SKIP_SIZES = [37, 39.5, 41.5, 43.5, 46.5]
export function getAllWomenSizes() {
  const TOTAL_SIZES = 24

  const womenSizes = [[4, 1.5, 34.5, 21]]
  let [US, UK, EU, CM] = [4, 1.5, 34.5, 21]

  for (let i = 0; i < TOTAL_SIZES; i++) {
    US += US_WOMEN_SKIP_SIZES.includes(US + 0.5) ? 1 : 0.5
    UK += 0.5
    EU += EU_WOMEN_SKIP_SIZES.includes(EU + 0.5) ? 1 : 0.5
    CM += 0.5
    womenSizes.push([US, UK, EU, CM])
  }

  return womenSizes
}

// export for test purposes
export const US_MEN_SKIP_SIZES = [14.5, 15.5]
export const UK_MEN_SKIP_SIZES = [5.5, 6.5, 11.5, 12.5, 13.5, 14.5]
export const EU_MEN_SKIP_SIZES = [39.5, 41.5, 43.5, 46.5]
export const CM_MEN_SKIP_SIZES = [32.5, 33.5]
export function getAllMenSizes() {
  const TOTAL_SIZES = 20

  const menSizes = [[6, 5, 38.5, 24]]
  let [US, UK, EU, CM] = [6, 5, 38.5, 24]

  for (let i = 0; i < TOTAL_SIZES; i++) {
    US += US_MEN_SKIP_SIZES.includes(US + 0.5) ? 1 : 0.5
    UK += UK_MEN_SKIP_SIZES.includes(UK + 0.5) ? 1 : 0.5
    EU += EU_MEN_SKIP_SIZES.includes(EU + 0.5) ? 1 : 0.5
    CM += CM_MEN_SKIP_SIZES.includes(CM + 0.5) ? 1 : 0.5
    menSizes.push([US, UK, EU, CM])
  }

  return menSizes
}
export default sizes
