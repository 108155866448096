import styled from 'styled-components'

const TileGrid = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 20px;
`

export default TileGrid
