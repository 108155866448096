import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const HamburgerMenu = ({
  color = colors.FC2_BLACK,
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}: IconProps) => (
  <svg
    className={`svg-icon ${className || ''}`}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="nonzero" stroke={color} strokeLinecap="square" strokeWidth="2">
      <path d="M1 1h18M1 7h18M1 13h18" />
    </g>
  </svg>
)
