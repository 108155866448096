import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { isFunction } from 'lodash/fp'
import { FC, MouseEvent, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { media, zIndex } from 'shared/lib'
import { BlurredBackDrop } from './BlurredBackDrop'

interface IModalProps {
  isOpen: boolean
  onClose?(event: MouseEvent<HTMLElement>): void
  children?: ReactNode
  widthOverride?: string
}

export const Modal: FC<IModalProps> = ({ isOpen, onClose, children, widthOverride }) => {
  const contentEl = useRef<HTMLDivElement>()

  // Use data attribute to ensure correct target for click to dismiss
  const shouldElementDismissDialog = (e: React.MouseEvent<HTMLElement>) => {
    try {
      return (e.target as HTMLElement).getAttribute('data-dismiss-on-click')
    } catch (error) {
      // tslint:disable-next-line
      console.error(error)
      return false
    }
  }

  useEffect(() => {
    if (isOpen && contentEl.current) {
      disableBodyScroll(contentEl.current)
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen, contentEl.current])

  // a11y: We need to add a keyboard Escape event here to close also probably
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (shouldElementDismissDialog(event) && isFunction(onClose)) {
      onClose(event)
    }
  }

  return isOpen ? (
    <ModalBlurredBackDrop data-dismiss-on-click isActive={isOpen} onClick={handleClick}>
      <Content ref={contentEl} widthOverride={widthOverride}>
        {children}
      </Content>
    </ModalBlurredBackDrop>
  ) : null
}

const ModalBlurredBackDrop = styled(BlurredBackDrop)`
  width: 100vw;
  height: 100vh;
  ${media.large`
    z-index: ${zIndex.justOverNav};
  `}
`

const Content = styled.div<{ widthOverride?: string }>`
  z-index: ${zIndex.modal};
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px;
  max-height: 100%;
  max-width: 500px;
  overflow-y: auto;
  ${({ widthOverride }) =>
    media.large`
      width: ${widthOverride ? widthOverride : '450px'};
    `}
`

export default Modal
