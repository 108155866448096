import styled from 'styled-components'
import { colors, zIndex, pageWidth, media, styles } from 'shared/lib'
import { LinkCTA } from 'shared/components/Links'
import { HeaderQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'checkout.components.CheckoutNavigation'

const Navigation = styled.nav`
  position: sticky;
  top: 0;
  background-color: ${colors.FC2_WHITE};
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
  height: 60px;
  width: 100%;
  padding: 18px ${styles.mobilePageLayoutPadding};
  z-index: ${zIndex.header};
  ${media.medium`
    padding: 18px 48px;
  `}
`

const NavContent = styled.div`
  ${pageWidth}
  display: flex;
  align-items: center;
  ${media.medium`
    margin: 0 auto;
  `}
`

const LogoAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  margin-left: 62px;
  ${media.medium`
    flex-grow: 1;
    margin-left: 0;
    justify-content: unset;
  `}
`

const LogoLink = styled(LinkCTA)`
  ${media.medium`
    margin-right: 30px;
  `}
`

const FlightClubLogo = styled.img.attrs({
  src: '/static/flightclub_logo.svg',
  alt: t(`${TP}.flightClubLogo`, 'Flight Club Logo'),
})`
  max-width: 125px;
  max-height: 25px;
`

const CheckoutNavigation = () => {
  return (
    <Navigation>
      <NavContent>
        <LogoAndTitleWrapper>
          <LogoLink
            color="inherit"
            href="/"
            qaAttr={HeaderQA.CheckoutFlightClubHome}
            data-testid="checkoutNavLogo"
          >
            <FlightClubLogo />
          </LogoLink>
        </LogoAndTitleWrapper>
        <LinkCTA
          qaAttr={HeaderQA.CheckoutHelpLink}
          data-testid="checkoutNavHelp"
          href="https://support.flightclub.com/hc/en-us"
          newTab
          color="grey"
          isExternal
        >
          {t(`${TP}.needHelp`, 'Need Help?')}
        </LinkCTA>
      </NavContent>
    </Navigation>
  )
}

export default CheckoutNavigation
