import styled from 'styled-components'

import { IconsQA } from 'shared/dataAttributes'

type MasterCardProps = {
  size?: 'small' | ''
}

export const MasterCard = ({ size = '' }: MasterCardProps) => (
  <CustomSvg
    $size={size}
    data-qa={IconsQA.MasterCardIcon}
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="35" height="23" rx="1.5" fill="white" stroke="#E7E7E7" />
    <path
      d="M26.509 11.9946C26.507 14.0724 25.2564 15.9323 23.3674 16.6667C21.4785 17.4011 19.3451 16.8569 18.0082 15.2997C19.6288 13.4158 19.6288 10.5839 18.0082 8.70001C19.3438 7.14421 21.4748 6.59942 23.3627 7.33112C25.2506 8.06281 26.5027 9.91877 26.509 11.9946Z"
      fill="#F79E1B"
    />
    <path
      d="M19.2255 11.9948C19.2243 13.2122 18.7915 14.3874 18.0081 15.2999C16.3876 13.416 16.3876 10.5841 18.0081 8.7002C18.7891 9.60993 19.2218 10.7809 19.2255 11.9948Z"
      fill="#FF5F00"
    />
    <path
      d="M16.7911 11.9947C16.7922 13.2122 17.2251 14.3874 18.0084 15.2999C16.6735 16.8633 14.537 17.4125 12.6444 16.6786C10.7518 15.9446 9.49896 14.0811 9.49896 12C9.49896 9.91887 10.7518 8.05537 12.6444 7.32145C14.537 6.58752 16.6735 7.13666 18.0084 8.70015C17.2274 9.60988 16.7947 10.7809 16.7911 11.9947Z"
      fill="#EB001B"
    />
  </CustomSvg>
)

const CustomSvg = styled.svg<{ $size: MasterCardProps['size'] }>`
  height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-height: ${({ $size }) => ($size === 'small' ? '24px' : '32px')};
  min-width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
  width: ${({ $size }) => ($size === 'small' ? '36px' : '48px')};
`
