import styled from 'styled-components'

const Label = styled.label<{ noMargin: boolean }>`
  position: relative;
  display: inline-block;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '15px')};
  padding-left: 20px;
  font-size: 12px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }
  &:before {
    top: 2px;
    left: 0;
    width: 10px;
    height: 10px;
    border: 1px solid #222;
  }
  &:after {
    top: 4px;
    left: 2px;
    width: 6px;
    height: 6px;
  }
`

export default Label
