import { SingletonRouter, useRouter, withRouter } from 'next/router'
import qs from 'qs'
import { FC, useState } from 'react'
import styled from 'styled-components'

import PanelBoxError from 'errors/components/PanelBoxError'
import { IError } from 'errors/types/IError'
import { t } from 'localization'
import CreateAccountForm from 'login/components/CreateAccountForm'
import LoginForm from 'login/components/LoginForm'
import { Panel } from 'shared/components/Panel'
import { UserAuthQA } from 'shared/dataAttributes'
import { setLoggedInToViewFavorites } from 'store/favoritesSlice'
import { setPlaceOfferAfterLogin, setLoggedInToViewOffers } from 'store/offersSlice'
import { useAppDispatch } from 'store/hooks'

const TP = 'login.components.LoginPanel'

enum View {
  Login,
  CreateAccount,
}

interface ILoginPanelProps {
  flow: 'viewOffers' | 'viewFavorites' | 'placeOffer'
  handleClose?: () => void
  router: SingletonRouter
  redirectUrl?: {
    [key: string]: undefined | string | string[] | qs.ParsedQs | qs.ParsedQs[]
  }
  withCloseButton?: boolean
  location: 'pdp_favorite' | 'global_favorite_panel' | 'pdp_offer' | 'global_offer_panel'
}

export const LoginPanel: FC<ILoginPanelProps> = ({
  router,
  redirectUrl = {},
  withCloseButton,
  handleClose,
  flow,
  location,
}) => {
  const [currentView, setCurrentView] = useState(View.Login)
  const [pageErrors, setPageErrors] = useState<IError[]>([])

  const { locale } = useRouter()
  const dispatch = useAppDispatch()

  const handleCreateAccountClick = () => {
    setPageErrors([])
    setCurrentView(View.CreateAccount)
  }
  const handleLoginClick = () => {
    setPageErrors([])
    setCurrentView(View.Login)
  }

  const handleComplete = () => {
    setPageErrors([])

    switch (flow) {
      case 'viewFavorites':
        return dispatch(setLoggedInToViewFavorites(true))
      case 'viewOffers':
        return dispatch(setLoggedInToViewOffers(true))
      case 'placeOffer':
        return dispatch(setPlaceOfferAfterLogin(true))
      default:
        break
    }

    if (redirectUrl && redirectUrl.redirectToMyAccount === 'true') {
      const formattedQueryString = qs.stringify(redirectUrl, {
        addQueryPrefix: true,
        filter: (prefix, value) => {
          if (prefix === 'redirectToMyAccount') {
            return
          }
          return value
        },
      })
      // Prevent malicious url redirection, since my account is currently
      // the only redirect we use in this scenario
      // Could try and log events where this is something else
      // as a honeypot type thing
      router.push(`/my-account${formattedQueryString}`, `/my-account${formattedQueryString}`, {
        locale,
      })
    } else {
      router.push(`/`, '/', { locale })
    }
  }

  const handleError = (errors: IError[]) => {
    setPageErrors(errors)
    window.scrollTo(0, 0)
  }

  const title =
    currentView === View.Login
      ? t(`${TP}.logIn`, 'Log in')
      : t(`${TP}.createAnAccount`, 'Create A Flight Club Account')
  const qaAttr = currentView === View.Login ? UserAuthQA.Login : UserAuthQA.CreateAccount

  return (
    <>
      <Panel
        qaAttr={qaAttr}
        title={title}
        withCloseButton={withCloseButton}
        handleClose={handleClose}
        className={qaAttr}
        withDivider={false}
      >
        <PanelBoxErrorWrapper>
          {pageErrors?.map((error, i) => (
            <PanelBoxError key={i} qaAttr={`${qaAttr}ErrorTopPage`} message={error} />
          ))}
        </PanelBoxErrorWrapper>
        {currentView === View.Login ? (
          <LoginForm
            onCreateAccountClick={handleCreateAccountClick}
            onLogin={handleComplete}
            onLoginError={handleError}
            location={location || 'account_page'}
            flow={flow}
            handleCloseLoginModal={handleClose}
          />
        ) : (
          <CreateAccountForm
            onLoginClick={handleLoginClick}
            onCreateAccount={handleComplete}
            onCreateAccountError={handleError}
            location={location || 'account_page'}
            loginInsteadStyle="button"
          />
        )}
      </Panel>
    </>
  )
}

export default withRouter(LoginPanel)

const PanelBoxErrorWrapper = styled.div`
  margin: 25px 0px;
`
