import { logClickEventUtil } from 'mParticle/mParticleService'
import Link from 'next/link'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { colors } from 'shared/lib'
import { isFunction, isUndefined } from 'util'

interface IFCLinkProps {
  href: string
  linkAs?: string
  className?: string
  newTab?: boolean
  isExternal?: boolean
  dataTestid?: string
  qaAttr?: string
  children?: ReactNode
  lightenOnHover?: boolean
  emphasis?: boolean
  isActive?: boolean
  onClick?(e: any): void
}

interface IStyledLinkProps {
  emphasis: boolean
  isActive: boolean
  lightenOnHover: boolean
}
const StyledLink = styled.a<Partial<IStyledLinkProps>>`
  color: inherit;
  font: inherit;
  font-weight: 500;
  ${({ emphasis }) => (emphasis ? 'text-decoration: underline;' : 'text-decoration: none;')}
  ${({ isActive }) => {
    if (!isUndefined(isActive)) {
      return isActive
        ? `text-decoration: none; border-bottom: 1px solid ${colors.FC2_DARK_GREY};`
        : `text-decoration: none; border: none;`
    }
  }}
  ${({ lightenOnHover }) =>
    lightenOnHover
      ? `&:hover {
      color: ${colors.FC2_GREY};
    }`
      : `&:hover {
    filter: brightness(60%);
  }`};
`
/**
 * Standard way use links in or as text which link to another page or route
 *
 * Usage
 *
 * Default bold link
 * <FCLink href="/terms-of-use">Terms of Use</FCLink>
 *
 * Link text with normal font-weight
 * <FCLink emphasis={false} href="/terms-of-use">Terms of Use</FCLink>
 */

export const FCLink: FC<IFCLinkProps> = ({
  href,
  linkAs,
  className,
  newTab = false,
  isExternal = false,
  qaAttr,
  dataTestid,
  children,
  emphasis = true,
  lightenOnHover,
  isActive,
  onClick,
}) => {
  const newTabProps = newTab
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {}

  return (
    <>
      {isExternal ? (
        <StyledLink
          {...newTabProps}
          href={href}
          data-testid={dataTestid}
          data-qa={qaAttr}
          emphasis={emphasis}
          className={className}
          isActive={isActive}
          lightenOnHover={lightenOnHover}
          onClick={(e) => {
            if (isFunction(onClick)) {
              onClick(e)
            } else {
              logClickEventUtil(e)
            }
          }}
        >
          {children}
        </StyledLink>
      ) : (
        <Link as={linkAs} href={href} passHref legacyBehavior>
          <StyledLink
            {...newTabProps}
            className={className}
            data-qa={qaAttr}
            data-testid={dataTestid}
            emphasis={emphasis}
            isActive={isActive}
            lightenOnHover={lightenOnHover}
            onClick={(e) => {
              if (isFunction(onClick)) {
                onClick(e)
              } else {
                logClickEventUtil(e)
              }
            }}
          >
            {children}
          </StyledLink>
        </Link>
      )}
    </>
  )
}

export default FCLink
