import { useEffect } from 'react'
import { doesEventMatchKey, executeOnNextTick } from './utils'

/**
 * [useKeyDownEvent]
 * Listens for 'keydown' events that match the 'desiredKey', and if it matches,
 * executes the provided method on next tick.
 * @param  {String}       desiredKey The key to match. See usage below.
 * @param  {Function}     handlerFn  The method to execute when key is pressed.
 *
 * Usage:
 * Accepts either a code (String) or key (String)  https://keycode.info/
 *
 *   useKeyDownEvent('a', handlerFn)
 */
function useKeyDownEvent(desiredKey: string, handlerFn: () => void): void {
  const handleKeypress = e => {
    if (doesEventMatchKey(e, desiredKey)) {
      executeOnNextTick(handlerFn)
    }
  }

  useEffect(() => {
    window.document.addEventListener('keydown', handleKeypress)

    return () => {
      window.document.removeEventListener('keydown', handleKeypress)
    }
  })
}

export default useKeyDownEvent
