import { hide, media, zIndex, colors } from 'shared/lib'
import styled from 'styled-components'

interface IBlurredBackDropProps {
  isActive: boolean
}

// FOR: https://goatapp.atlassian.net/browse/BUY1-1971
// PR: https://github.com/goatapp/flightclub.com/pull/1759
// The !important on background and border/color are temporary styles
// for 1.67.0 deploy.
// Monitor for hIGegT class(Button.tsx line 57 PrimaryCTA1) on modal div
// and if not present assume issue was environment related and revert these changes
export const BlurredBackDrop = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.45) !important;
  border: none !important;
  color: ${colors.FC2_DARK_GREY} !important;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: ${zIndex.pageTakeover};
  ${({ isActive }: IBlurredBackDropProps) => hide({ isHidden: !isActive })}
`
export const BlurredBackDropSideSheet = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: ${zIndex.pageTakeover};
  ${({ isActive }: IBlurredBackDropProps) => hide({ isHidden: !isActive })}
`
