// tslint:disable:max-line-length
import React from 'react'
import { IconsQA } from 'shared/dataAttributes'

const VisaCardIcon = ({ width = 32, height = 20, ...props }) => (
  <svg data-qa={IconsQA.VisaCardIcon} viewBox="0 0 32 20" width={width} height={height} {...props}>
    <g fill="none">
      <path fill="#FFF" d="M0 .496v18.936h31.983V.496H0z" />
      <path
        fill="#1A1F71"
        d="M5.298 7.021l1.865 7.026h2.165l3.37-8.137H10.5l-2.122 5.525-.873-4.323-2.208-.09zm8.219-1.141l-1.293 8.167h2.046l1.322-8.167h-2.075zm8.092 1.952l.3-1.71a5.97 5.97 0 0 0-1.985-.36c-1.083 0-3.43.69-3.43 2.7 0 2.01 2.917 2.102 2.917 3.212 0 1.11-2.616.992-3.399.272l-.329 1.77s1.173.451 2.225.451c1.053 0 3.672-.24 3.672-2.851 0-2.283-2.919-1.832-2.919-3.213 0 0 .3-1.38 2.948-.271zm-.151 6.215h2.256l.391-1.171h2.767l.23 1.16h1.956l-1.794-8.064h-1.805s-.66.075-.962.87c-.286.755-3.04 7.205-3.04 7.205z"
      />
      <path fill="#FFF" d="M26.542 11.195h-1.836l1.144-3.002.692 3.002z" />
      <path
        fill="#1A1F71"
        d="M8.15 10.304l-.644-3.192s-.087-1.199-.943-1.199H3.068v.166s3.456.573 5.08 4.225h.001z"
      />
      <path fill="#F7B600" d="M0 15.239h31.983v4.733H0z" />
      <path fill="#1A1F71" d="M0 0h31.983v4.732H0z" />
    </g>
  </svg>
)

export default VisaCardIcon
