import { gql } from '@apollo/client'
import { get as _get } from 'lodash'

export const typeDef = gql`
  type CheckoutPage {
    orderNumber: Int
    isSameAsShipping: Boolean
    isPayPalOpen: Boolean
    isPlacingOrder: Boolean
  }

  extend type Query {
    checkoutPage: CheckoutPage
  }
`

export const defaultState = {
  checkoutPage: {
    __typename: 'CheckoutPage',
    orderNumber: null,
    isSameAsShipping: false,
    isPayPalOpen: false,
    isPlacingOrder: false,
  },
}

export const resolvers = {
  Mutation: {},
}
