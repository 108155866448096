import styled from 'styled-components'
import { colors } from 'shared/theme'

const NavLink = styled.button`
  color: ${props => props.theme.textColor};
  font-size: 16px;
  font-weight: 500;
  padding: 12px;

  &:disabled {
    color: ${colors.mediumGray};
  }
`

export default NavLink
