import i18n, { t, serverSideConfigs, getDefaultLocalePath, getLocalePath } from './utils/i18n'
import { getStandardLocaleCode } from './utils/locale-utils'
import { getLocale } from './utils/getLocale'
import { LANGUAGES, LANGUAGE_COOKIE_NAME, DEFAULT_LANGUAGE } from './enums/LocalizationEnums'

export {
  i18n,
  t,
  serverSideConfigs,
  getLocale,
  getLocalePath,
  getDefaultLocalePath,
  getStandardLocaleCode,
  LANGUAGES,
  LANGUAGE_COOKIE_NAME,
  DEFAULT_LANGUAGE,
}
