import { ReactNode } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { useTranslation } from 'next-i18next'
import { SVGIcon } from 'shared/components/Icons'
import colors from 'shared/theme/colors'
import { Small } from 'shared/text'

const TP = 'shared.components.Storytelling.ErrorBanner'

export interface IErrorBannerProps {
  message: string | ReactNode
  qaAttr?: string
  className?: string
}

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.black};
  border: 1px solid ${colors.red};
  color: ${colors.red};
  margin-bottom: 20px;
  padding: 20px;
`

const ErrorX = styled(SVGIcon).attrs({
  name: 'errorIcon',
  fill: colors.black,
})`
  margin-right: 10px;
`

export const ErrorBanner = ({ message, qaAttr, className }: IErrorBannerProps) => {
  const { t } = useTranslation()
  const errorMessage = message || (
    <span>
      <Trans t={t} i18nKey={`${TP}.errorMessage`}>
        Something went wrong. Please try again or reach out to{' '}
        <a href="mailto:support@flightclub.com">support@flightclub.com</a>
      </Trans>
    </span>
  )
  return (
    <ErrorBox className={className}>
      <ErrorX />
      <Small data-testid="message" data-qa={qaAttr}>
        {errorMessage}
      </Small>
    </ErrorBox>
  )
}

export default ErrorBanner
