import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import { useEffect, useState } from 'react'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors } from 'shared/theme'
import styled, { css } from 'styled-components'

const TP = 'layout.footer.OneTrustLink'

// When isOneTrustEnabled / ONE_TRUST FF is gone
// We can remove the deprecatedFooterLinkStyle forking

const OneTrustLink = ({ deprecatedFooterLinkStyle = false }) => {
  const shouldForceHideOnetrust = useFeatureFlag(FeatureFlag.FORCE_HIDE_ONETRUST)
  const [cookieLinkText, setCookieLinkText] = useState('Cookie Settings')
  const getTranslatedButtonText = (text: string) => {
    switch (text.toLowerCase()) {
      case 'cookie settings':
        return t(`${TP}.cookieSettings`, 'Cookie Settings')
      case 'do not sell my info':
        return t(`${TP}.doNotSellMyInfo`, 'Do Not Sell My Info')
      default:
        return text
    }
  }

  /**
   * BUY1-91
   * When OneTrust renders the link into the component we provide,
   * it can get lost in between server side and client side rerenders.
   *
   * Instead of relying on OneTrust, we manually get the label text through the exposed
   * APIs and create our own link to open the OneTrust panel.
   *
   * As of June 2021, the label should say `Do Not Sell My Info` for California and
   * `Cookie Settings` elsewhere.
   *
   * https://community.cookiepro.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643
   * https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
   */
  useEffect(() => {
    // we have to wait for OneTrust to load, there aren't any exposed onload callbacks
    const timeout = setTimeout(() => {
      if (window.OneTrust) {
        try {
          setCookieLinkText(window.OneTrust.GetDomainData().CookieSettingButtonText)
        } catch (e) {
          Bugsnag.notify(e as NotifiableError)
        }
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])

  /**
   * BUY1-222
   * There's a bug in OneTrust where closing the cookie management modal after triggering it
   * via the link does not apply the `display: none` styles correctly. This should be fixed
   * on OneTrust's end, but they have been unresponsive so this is a hack to provide the
   * users with a workaround.
   */
  const hideBuggyOneTrustOverlayHack = () => {
    setTimeout(() => {
      const overlay = window.document.body.querySelector('.onetrust-pc-dark-filter')
      if (overlay) {
        const forceHideOverlay = () => {
          window?.OneTrust?.Close?.()
          overlay.setAttribute('style', `${overlay.getAttribute('style')} display: none;`)
          overlay.removeEventListener('click', forceHideOverlay)
        }

        overlay.addEventListener('click', forceHideOverlay)
      }
    }, 500)

    setTimeout(() => {
      const closeBtn = window.document.body.querySelector('#close-pc-btn-handler')
      if (closeBtn) {
        const forceCloseModal = () => {
          window?.OneTrust?.Close?.()
          const overlay = window.document.body.querySelector('.onetrust-pc-dark-filter')
          if (overlay) {
            overlay.setAttribute('style', `${overlay.getAttribute('style')} display: none;`)
          }
          closeBtn.removeEventListener('click', forceCloseModal)
        }

        closeBtn.addEventListener('click', forceCloseModal)
      }
    }, 500)
  }

  return (
    <Container deprecatedFooterLinkStyle={deprecatedFooterLinkStyle}>
      <a
        id="one-trust-footer-link"
        data-qa="FooterOneTrustLink"
        onClick={() => {
          window.OneTrust?.ToggleInfoDisplay?.()
          if (shouldForceHideOnetrust) {
            hideBuggyOneTrustOverlayHack()
          }
        }}
      >
        {getTranslatedButtonText(cookieLinkText)}
      </a>
    </Container>
  )
}

const footerLinkStyles = css`
  a {
    cursor: pointer;
  }
`

const oldFooterLinkStyles = css`
  a {
    color: ${colors.white};
    cursor: pointer;
    text-decoration: none;
  }
  a:hover {
    color: ${colors.mediumGray};
  }
`

const Container = styled.span<{ deprecatedFooterLinkStyle: boolean }>`
  ${(props) => (props.deprecatedFooterLinkStyle ? oldFooterLinkStyles : footerLinkStyles)}
`

export default OneTrustLink
