/* tslint:disable:max-line-length */
enum AccountQA {
  // MANAGE YOUR ORDERS
  ManageYourOrdersTitle = 'ManageYourOrdersTitle',
  ManageYourOrdersDescription = 'ManageYourOrdersDescription',
  OrderSearchOrderNumberBox = 'OrderSearchOrderNumberBox',
  // OrderSearchOrderNumberBoxName // Auto-generated
  // OrderSearchOrderNumberBoxErrorInLine // Auto-generated
  OrderSearchEmailBox = 'OrderSearchEmailBox',
  // OrderSearchEmailBoxName // Auto-generated
  // OrderSearchEmailBoxErrorInLine // Auto-generated
  LoginToViewOrderHistoryTitle = 'LoginToViewOrderHistoryTitle',
  LoginToViewOrderHistoryDescription = 'LoginToViewOrderHistoryDescription',
  FindOrderButton = 'FindOrderButton',
  LoginButton = 'LoginButton',
  // MY ACCOUNT
  MyAccountHeader = 'MyAccountHeader',
  // ACCOUNT SECTION
  Account = 'Account', // DO NO USE as it is, use Auto-generated names
  // 'AccountPanel', // Auto-generated
  // 'AccountSectionHeader', // Auto-generated
  // 'AccountSectionTitle', // Auto-generated
  // 'AccountEditLink', // Auto-generated
  AccountFullname = 'AccountFullname',
  AccountEmail = 'AccountEmail',
  // EDIT SECTION
  EditUsernameSection = 'EditUsernameSection', // DO NO USE as it is, use Auto-generated names
  // 'EditUsernameSectionHeader', // Auto-generated
  EditFullnameBox = 'EditFullnameBox',
  // EditFullnameBoxName // Auto-generated
  EditPasswordSection = 'EditPasswordSection', // DO NO USE as it is, use Auto-generated names
  // 'EditPasswordSectionHeader', // Auto-generated
  EditChangePasswordButton = 'EditChangePasswordButton',
  EditErrorTopForm = 'EditErrorTopForm',
  // SHIPPING ADDRESS SECTION
  ShippingAddress = 'ShippingAddress', // DO NO USE as it is, use Auto-generated names
  // 'ShippingAddressPanel', // Auto-generated
  // 'ShippingAddressSectionHeader', // Auto-generated
  // ShippingAddressSectionTitle, // Auto-generated
  ShippingAddressEmptySection = 'ShippingAddressEmptySection',
  ShippingAddressMainContent = 'ShippingAddressMainContent',
  ShippingAddressSecondaryContent = 'ShippingAddressSecondaryContent',
  // PAYMENT SECTION
  Payment = 'Payment', // DO NO USE as it is, use Auto-generated names
  // 'PaymentPanel', // Auto-generated
  // 'PaymentSectionTitle', // Auto-generated
  // 'PaymentSectionHeader', // Auto-generated
  PaymentEmptySection = 'PaymentEmptySection',
  PaymentContentList = 'PaymentContentList',
  // BILLING ADDRESS SECTION
  BillingAddress = 'BillingAddress', // DO NO USE as it is, use Auto-generated names
  // 'BillingAddressPanel', // Auto-generated
  // 'BillingAddressSectionHeader', // Auto-generated
  // BillingAddressSectionTitle // Auto-generated
  BillingAddressEmptySection = 'BillingAddressEmptySection',
  BillingAddressMainContent = 'BillingAddressMainContent',
  BillingAddressSecondaryContent = 'BillingAddressSecondaryContent',
  // ORDERS SECTION
  CancelOrderButton = 'CancelOrderButton',
  MyOrdersHeader = 'MyOrdersHeader',
  MyOrdersEmptySection = 'MyOrdersEmptySection',
  OrderNumber = 'OrderNumber',
  OrderDate = 'OrderDate',
  OrderTotalTag = 'OrderTotalTag',
  OrderTotalAmount = 'OrderTotalAmount',
  OrderPaymentMethodTag = 'OrderPaymentMethodTag',
  OrderPaymentMethodValue = 'OrderPaymentMethodValue',
  OrderFlightClubCredit = 'OrderFlightClubCredit',
  OrderShoeThumbnail = 'OrderShoeThumbnail',
  OrderShoeName = 'OrderShoeName',
  OrderShoeDetails = 'OrderShoeDetails',
  OrderShoeSizeTag = 'OrderShoeSizeTag',
  OrderShoeSizeValue = 'OrderShoeSizeValue',
  OrderShoePrice = 'OrderShoePrice',
  OrderShippingStatusTag = 'OrderShippingStatusTag',
  OrderShippingStatusValue = 'OrderShippingStatusValue',
  OrderTrackingNumber = 'OrderTrackingNumber',
  OrderLoadMoreButton = 'OrderLoadMoreButton',
  OrderEndOfListTextDivider = 'OrderEndOfListTextDivider',
}

export default AccountQA
