import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import CurrencyDropdownField from './CurrencyDropdown'
import LanguageDropdownField from './LanguageDropdown'
import RegionDropdownField from './RegionDropdown'

import { gql, useMutation } from '@apollo/client'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { LANGUAGE_COOKIE_NAME, getLocalePath, t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { SVGIcon } from 'shared/components/Icons'
import Modal from 'shared/components/Modal'
import Button from 'shared/components/Storytelling/Button'
import ErrorBanner from 'shared/components/Storytelling/ErrorBanner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { STORAGE } from 'shared/enums/SitePreferences'
import useCurrency from 'shared/hooks/useCurrency'
import { useUser } from 'shared/hooks/useUser'
import { colors, fonts } from 'shared/lib'
import { useAppDispatch } from 'store/hooks'
import { setRefetchOnCurrencyChange } from 'store/favoritesSlice'

const TP = 'shared.components.Storytelling.LocationPreferencesModal'

const SET_REGION_PREFERENCE = gql`
  mutation SetRegionPreferenceSneakers($input: RegionPreferenceInput!) {
    setRegionPreference(input: $input) {
      userId
      country {
        country
        isoCode
        name
        flag
        countryCode
        currency
        sizeUnit
        shipsTo
      }
      locale
    }
  }
`

type LocationPreferencesModalProps = {
  analyticsLocation: string
  isOpen: boolean
  closeCurrencyModal: () => void
  errorMessage: string
}

export const LocationPreferencesModal: FC<LocationPreferencesModalProps> = ({
  isOpen,
  analyticsLocation,
  closeCurrencyModal,
  errorMessage,
}) => {
  const router = useRouter()
  const { asPath, locale: activeLanguage } = router
  const isLocalizationEnabled = useFeatureFlag(FeatureFlag.LOCALIZATION)
  const { setCurrency } = useCurrency()
  const {
    country,
    setCountryContext,
    countryName,
    setCountryNameContext,
    currencyCode,
    setCurrencyCodeContext,
    availableCountries,
    availableCurrencies,
  } = useShoppingRegionContext()

  const checkIfShippable = () => {
    const defaultCountry = availableCountries.find(
      (_country) => _country.isoCode === country && _country.shipsTo,
    )
    return defaultCountry?.isoCode || ''
  }
  const [selectedCountry, setSelectedCountry] = useState(checkIfShippable())
  const [selectedCountryName, setSelectedCountryName] = useState(countryName)
  const [selectedCurrencyState, setSelectedCurrency] = useState(currencyCode)
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState(activeLanguage)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isAuthenticated } = useUser()
  const [setRegionPreference] = useMutation(SET_REGION_PREFERENCE)
  const dispatch = useAppDispatch()

  const handleCurrencyChange = (isoCode: string, changeFromField: string) => {
    const selectedCurrencySymbolFromCode = availableCurrencies?.filter(
      (currency) => currency.isoCode === isoCode,
    )[0]
    setSelectedCurrency(isoCode)
    setSelectedCurrencySymbol(selectedCurrencySymbolFromCode.symbol)
    sessionStorage.setItem('currencyOverwrittenFromField', changeFromField)
  }

  const handleCountryChange = (countryNameInput: string) => {
    const selectedCountryFromName = availableCountries.filter(
      (_country) => _country.name === countryNameInput,
    )[0]
    setSelectedCountry(selectedCountryFromName.isoCode)
    setSelectedCountryName(countryNameInput)
    handleCurrencyChange(selectedCountryFromName.currency, 'false')
    if (isLocalizationEnabled && countryNameInput === 'Japan') {
      setSelectedLanguage('ja-jp')
      return
    } else {
      setSelectedLanguage('en')
    }
  }

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language)
  }

  // Reset local state to default in the event input is changed but not saved
  // to maintain consistency between footer and pdp modal
  const handleCloseModal = () => {
    setSelectedCountryName(countryName)
    setSelectedCurrency(currencyCode)
    setSelectedLanguage(activeLanguage)
    closeCurrencyModal()
  }

  const handleConfirm = async () => {
    // SelectedCurrencySymbol is being undefined sometimes when no changes are made
    // This will cancel out of the modal without updating cookies when
    // confirm is pressed but no input is changed
    if (
      selectedCountry === country &&
      selectedCurrencyState === currencyCode &&
      selectedLanguage === activeLanguage
    ) {
      handleCloseModal()
    } else {
      setIsSubmitting(true)

      try {
        sendTrackingEvent('LOCATION_PREFERENCES_SAVE', {
          location: analyticsLocation,
          page: window?.location?.href,
          shopping_region: selectedCountryName || countryName,
          currency: `${selectedCurrencyState}${selectedCurrencySymbol}`,
          language: selectedLanguage,
        })
        await setCurrency(selectedCurrencyState)
        // Only set this in event user changes currency explicitly and not just country
        // to ensure regionPreferenceMiddleware works properly
        if (sessionStorage.getItem('currencyOverwrittenFromField') === 'true') {
          sessionStorage.setItem('currencyOverwritten', 'true')
          const currencyOverwritten = sessionStorage.getItem('currencyOverwritten')
          Cookies.set('currencyOverwritten', currencyOverwritten)
        }

        const variables = {
          input: {
            locale: selectedLanguage,
            country: selectedCountry,
          },
        }

        if (isAuthenticated) {
          await setRegionPreference({ variables })
        }

        Cookies.set(STORAGE.COUNTRYNAME, selectedCountryName, { expires: 365 })
        Cookies.set(STORAGE.COUNTRY, selectedCountry, { expires: 365 })
        Cookies.set('prevCountry', selectedCountry, { expires: 365 })
        Cookies.set(STORAGE.CURRENCYCODE, selectedCurrencyState, { expires: 365 })
        setCountryContext(selectedCountry)
        setCountryNameContext(selectedCountryName)
        setCurrencyCodeContext(selectedCurrencyState)

        // redirection
        // show loader and modal until the redirection is completed
        if (selectedLanguage?.length > 0 && selectedLanguage !== activeLanguage) {
          Cookies.set(LANGUAGE_COOKIE_NAME, selectedLanguage, { path: '/' })
          // reload the page here to get breadcrumb in correct translation
          window.location.href = getLocalePath(asPath, selectedLanguage)
          return
        }
        setIsSubmitting(false)
        dispatch(setRefetchOnCurrencyChange(true))
        closeCurrencyModal()
      } catch (e) {
        setIsSubmitting(false)
        closeCurrencyModal()
      }
    }
  }

  useEffect(() => {
    setSelectedCountry(country)
    setSelectedCountryName(countryName)
    setSelectedCurrency(currencyCode)
    setSelectedLanguage(activeLanguage)
  }, [countryName, country, currencyCode, activeLanguage])

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalContent data-testid="currencyModal">
        <HeaderWrapper>
          <Header data-qa="currencyModalHeader">
            {t(`${TP}.locationPreferences`, 'LOCATION PREFERENCES')}
          </Header>
          <CloseButton type="button" data-qa="currencyModalCloseIcon" onClick={handleCloseModal}>
            <XIcon />
          </CloseButton>
        </HeaderWrapper>
        {!!errorMessage?.length && <ErrorBanner data-testid="errors" message={errorMessage} />}
        <Divider />
        <Description>
          <StyledSubtitle data-qa="currencyModalTopText">
            {t(`${TP}.currencyModalTopText`, 'Please confirm your current shopping region')}
          </StyledSubtitle>
          <RegionDropdownField
            availableCountries={availableCountries}
            title={t(`${TP}.shoppingRegion`, 'SHOPPING REGION')}
            name="countryCode"
            qaAttr="RegionSelect"
            defaultCountry={selectedCountryName}
            onChange={(countryNameInput: string) => handleCountryChange(countryNameInput)}
          />
          <CurrencyDropdownField
            availableCurrencies={availableCurrencies}
            title={t(`${TP}.currency`, 'CURRENCY')}
            name="currency"
            qaAttr="CurrencySelect"
            defaultCurrency={selectedCurrencyState}
            onChange={(isoCode: string) => handleCurrencyChange(isoCode, 'true')}
          />
          {/* Languages dropdown only for Japan region */}
          {isLocalizationEnabled && selectedCountryName === 'Japan' && (
            <LanguageDropdownField
              title="Language"
              title={t(`${TP}.language`, 'Language')}
              name="language"
              qaAttr="LangaugeSelect"
              onChange={(language: string) => handleLanguageChange(language)}
              defaultLanguage={selectedLanguage}
            />
          )}
        </Description>
        <StyledText data-qa="currencyModalBottomText">
          {t(
            `${TP}.currencyModalBottomText`,
            'All transactions will process in your selected currency',
          )}
        </StyledText>
        <ConfirmButtonWrapper>
          <Button
            colorTheme="black"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleConfirm}
            dataTestId="saveButton"
            qaAttr="currency-modal-save-btn"
            isNewModal
          >
            {t(`${TP}.confirm`, 'CONFIRM')}
          </Button>
        </ConfirmButtonWrapper>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  height: fit-content;
  width: 100%;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`

const Header = styled.h1`
  ${fonts.SUBTITLE_1}
  margin: 0 0;
`

const Divider = styled.div`
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`
const Description = styled.div`
  ${fonts.BODY_TEXT}
  color: ${colors.FC2_BLACK};
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 20px;
  text-align: left;
`

const CloseButton = styled.button`
  line-height: 15px;
`

const XIcon = styled(SVGIcon).attrs({
  size: 15,
  name: 'closeX',
  color: colors.FC2_GREY,
})``

const StyledText = styled.p`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  color: ${colors.FC2_GREY};
`

const ConfirmButtonWrapper = styled.div`
  padding: 20px;
  > button > h6 {
    font-size: 14px;
  }
`

const StyledSubtitle = styled.div`
  ${fonts.BODY_TEXT}
  color: ${colors.FC2_DARK_GREY};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding-bottom: 30px;
`
