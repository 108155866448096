import { kebabCase } from 'lodash'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { memo } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import EmailSignUp from 'layout/footer/EmailSignUp'
import OneTrustLink from 'layout/footer/OneTrustLink'
import CurrencySelectButton from 'shared/components/Storytelling/CurrencySelectButton'
import { defaultFooter } from 'shared/data/defaultBreadcrumbs'
import { useUser } from 'shared/hooks/useUser'
import FlightClubLogo from 'shared/icons/FlightClubLogo'
import { H6 } from 'shared/text'
import { colors, mediaQueries } from 'shared/theme'
import { Breadcrumb } from 'shared/types/Breadcrumb'

type FooterProps = {
  footerData: Breadcrumb
}

const TP = 'layout.footer.Footer'

const Footer = ({ footerData }: FooterProps) => {
  const tv = useTranslation()
  const { isAuthenticated } = useUser()
  const footer = footerData?.children?.length ? footerData : defaultFooter
  const sectionLinks = footer?.children?.find((crumb) => crumb.slug === 'footer/sectionLinks')
  const legalLinks = footer?.children?.find((crumb) => crumb.slug === 'footer/legalLinks')

  return (
    <Container role="contentinfo">
      <LogoSection>
        <Link href="/">
          <FlightClubLogo />
        </Link>
      </LogoSection>
      <LinkSection>
        {sectionLinks?.children?.map((section) => (
          <LinkGroup
            className={section.slug !== 'footer/sectionLinks/new-releases' ? 'folding' : ''}
            data-qa={createSectionQaAttribute(section.name)}
            key={section.slug}
          >
            <H6>
              {
                <Link
                  href={getFooterLinkUrl(section, section)}
                  data-qa={createSectionHeaderQaAttribute(section.name)}
                >
                  {section.name}
                </Link>
              }
            </H6>
            <ul>
              {section.children?.map((link) => (
                <li key={link.slug}>
                  <Link
                    href={getFooterLinkUrl(section, link, isAuthenticated)}
                    data-qa={createLinkQaAttribute(section.name)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </LinkGroup>
        ))}
        <EmailSignUpContainer>
          <EmailSignUp />
        </EmailSignUpContainer>
      </LinkSection>
      <BottomLinks>
        <CopyrightSection className="mobile">
          &copy;{' '}
          <Trans
            i18nKey={`${TP}.copyrightMobile`}
            t={tv.t}
            defaults="{date} Flight Club New York LLC. <0></0> All Rights Reserved"
            values={{ date: new Date().getFullYear() }}
            components={[<br />]}
          />
        </CopyrightSection>
        <LegalLinks data-qa="FooterLegalLinksSection">
          {legalLinks?.children?.map((link) => (
            <li key={link.slug}>
              <Link
                href={getFooterLinkUrl(legalLinks, link)}
                data-qa={createLinkQaAttribute('legalLinks')}
              >
                {link.name}
              </Link>
            </li>
          ))}
          <li>
            <OneTrustLink />
          </li>
        </LegalLinks>
        <CopyrightSection>
          &copy;{' '}
          <Trans
            i18nKey={`${TP}.copyright`}
            t={tv.t}
            defaults="{date} Flight Club New York LLC. All Rights Reserved"
            values={{ date: new Date().getFullYear() }}
          />
        </CopyrightSection>
        <CurrencySection>
          <CurrencySelectButton />
        </CurrencySection>
      </BottomLinks>
    </Container>
  )
}

// TODO: This functionality is provisional until the footer can be fully authored in the CMS.
// Currently the code has to be aware of the CMS structure and slugs in order to comply with
// new requirements | BUY1-2042
const getFooterLinkUrl = (
  parentBreadcrumb: Breadcrumb,
  linkBreadcrumb: Breadcrumb,
  isAuthenticated?: boolean,
) => {
  // in the CMS the footer section headers slugs start with 'footer/sectionLinks'
  const slugStart = 'footer/sectionLinks'
  const isSectionHeader = parentBreadcrumb.slug === linkBreadcrumb.slug

  if (linkBreadcrumb.urlOverride) return linkBreadcrumb.urlOverride

  if (isSectionHeader) {
    const sectionLink = linkBreadcrumb.slug.slice(slugStart.length + 1)
    return /http[s]?:/.test(sectionLink) ? sectionLink : `/${sectionLink}`
  } else {
    const relativeSlug = linkBreadcrumb.slug.replace(parentBreadcrumb.slug + '/', '/')

    if (/http[s]?:/.test(relativeSlug)) {
      return relativeSlug.slice(relativeSlug.indexOf('http'))
    }

    // the "Track My Order" link from the breadcrumbs needs to go to
    // the My Accounts page when logged in and there's no way to do that
    // via the breadcrumbs so we just override it here BUY1-287
    if (isAuthenticated && linkBreadcrumb.slug.indexOf('order-search')) {
      return linkBreadcrumb.slug
        .replace(parentBreadcrumb.slug + '/', '/')
        .replace('order-search', 'my-account')
    }

    return relativeSlug
  }
}

const createSectionHeaderQaAttribute = (sectionName: string) =>
  `footer-section-header-${kebabCase(sectionName)}`
const createSectionQaAttribute = (sectionName: string) => `footer-section-${kebabCase(sectionName)}`
const createLinkQaAttribute = (sectionName: string) =>
  `footer-section-${kebabCase(sectionName)}-link`

/* stylelint-disable no-descending-specificity */
const Container = styled.footer`
  background-color: ${colors.black};
  color: ${colors.mediumGray};

  font-size: 16px;
  font-style: normal;
  line-height: 22px;

  a {
    color: ${colors.mediumGray};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  ${H6} {
    color: ${colors.white};

    a {
      color: ${colors.white};
    }
  }
`
const LogoSection = styled.section`
  padding: 50px 25px;
`

const LinkSection = styled.nav`
  padding: 0 15px 65px 15px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  ${mediaQueries.mobile} {
    padding-bottom: 0;
  }
`

const LinkGroup = styled.section`
  flex: 1;
  margin-bottom: 60px;
  padding: 0 10px;

  ${H6} {
    margin-bottom: 25px;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
  }
  li {
    flex-basis: 100%;
    list-style-type: none;
    margin-bottom: 20px;
  }

  ${mediaQueries.tablet} {
    flex-basis: 50%;
    &.folding li {
      flex-basis: 50%;
    }
  }

  ${mediaQueries.mobile} {
    flex-basis: 100%;
    &.folding li {
      flex-basis: 50%;
    }
  }
`

const EmailSignUpContainer = styled.section`
  flex: 1;
  margin-bottom: 60px;
  padding: 0 10px;

  ${mediaQueries.tablet} {
    max-width: 50%;
  }
`

const BottomLinks = styled.nav`
  border-top: 1px solid ${colors.darkGray};
  display: flex;
  padding: 0 25px;
  width: 100%;
  ${mediaQueries.mobile} {
    display: block;
    padding: 35px 25px;
  }
`
const LegalLinks = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    line-height: 60px;
    padding-right: 20px;
  }
`
const CopyrightSection = styled.div`
  flex: 1;
  text-align: center;
  line-height: 60px;
  &.mobile {
    display: none;
  }
  ${mediaQueries.mobile} {
    display: none;
    text-align: left;
    line-height: 1.3;
    &.mobile {
      display: block;
    }
  }
`
const CurrencySection = styled.div`
  flex-basis: 20%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mediaQueries.mobile} {
    flex-basis: auto;
    text-align: left;
    justify-content: left;
  }
`

// OneTrust creates a link based on a className
// we memo the footer because re-renders will
// clobber the link content
export default memo(Footer)
