import getConfig from 'next/config'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { checkAttributesAgainstSchema } from 'mParticle/checkAttributesSgainstSchema'
import flightClubEvents from 'mParticle/flightClubEvents'
import { getMparticleEventType } from 'mParticle/getMparticleEventType'
import includeGlobalEventAttributes from 'mParticle/includeGlobalEventAttributes'
import isValidEventSchema from 'mParticle/isValidEventSchema'
import { getMParticle } from 'mParticle/mParticleService'
import { EventAttributes, EventSchema } from 'mParticle/types/Event'

type EventKeys = keyof typeof flightClubEvents

/**
 * [sendTrackingEvent]
 * Accepts an event name + payload, and sends a tracking event to mParticle.
 * The event must be defined in 'goatapp/analytics-event-schema'.
 *
 * Usage:
 *   import { sendTrackingEvent } from 'common/tracking';
 *   ...
 *   sendTrackingEvent('EVENT_SCHEMA_NAME', { ... })
 *
 * @param   eventSchemaName      The schema definition for the event.
 * @param   [eventAttributes={}] Attributes to include in the event payload.
 */
const sendTrackingEvent = (
  eventSchemaName: EventKeys,
  eventAttributes?: EventAttributes,
): boolean => {
  const {
    publicRuntimeConfig: { releaseEnv },
  } = getConfig()

  try {
    const mParticle = getMParticle()
    const eventSchema = flightClubEvents[eventSchemaName.toUpperCase() as EventKeys] as EventSchema

    if (!eventSchema || !isValidEventSchema(eventSchema)) {
      if (releaseEnv !== 'production') {
        // tslint:disable-next-line:no-console
        console.error(
          [
            `Missing tracking event: ${eventSchemaName} does not exist.`,
            "Make sure it's enabled for web in 'analytics-event-schema'.",
          ].join(' '),
        )

        return false
      }

      Bugsnag.notify(
        new Error(
          `sendTrackingEvent: ${eventSchemaName} is not present in the event schema object.`,
        ),
        (event) => {
          event.addMetadata('Tracking Event', {
            'requested event': eventSchemaName,
            'valid event names': Object.keys(flightClubEvents),
          })
        },
      )

      return false
    }

    const { attributes: schemaAttributes, name: schemaName, type: schemaType } = eventSchema

    if (eventAttributes && !checkAttributesAgainstSchema(eventAttributes, schemaAttributes)) {
      Bugsnag.notify(
        new Error('vent attributes do not match expected attributes defined in schema'),
        (event) => {
          event.context = 'sendTrackingEvent'
          event.addMetadata('Tracking Event', {
            name: schemaName,
            payload: Object.keys(eventAttributes),
          })
        },
      )
    }

    const eventPayload = includeGlobalEventAttributes(eventAttributes)

    // Uncomment below for easy event tracking logs
    // console.log(`$$$ EVENT ${eventSchemaName.toUpperCase()}:`, eventPayload)
    mParticle.logEvent(eventSchema.name, getMparticleEventType(schemaType, mParticle), eventPayload)

    return true
  } catch (error) {
    Bugsnag.notify(error as NotifiableError, (event) => {
      event.context = 'sendTrackingEvent'
    })
    return false
  }
}

export default sendTrackingEvent
