import { gql } from '@apollo/client'
import formatCurrency from 'shared/lib/formatCurrency'

export const typeDef = gql`
  extend type Price {
    display: String
  }
`

export const defaultState = {}

export const resolvers = {
  Price: {
    display: (
      { localizedValue },
      { currency, useGrouping, hideCents, hideEmptyCents },
      _context,
    ) => {
      const formatPrice = formatCurrency(currency, {
        useGrouping,
        hideCents,
        hideEmptyCents,
      })

      return formatPrice(localizedValue)
    },
  },
}
