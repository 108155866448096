import Link from 'next/link'
import { Hit } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import styled from 'styled-components'

import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { H6 } from 'shared/text'
import { colors, mediaQueries } from 'shared/theme'
import ExpandedMenuModal, { MenuPanel } from '../components/ExpandedMenuModal'
import { MenuLink, UnderlinedMenuLink } from '../components/MenuLink'
import SearchItem from './SearchItem'

const TP = 'layout.navigation.search.searchMenu'

export interface SearchHit {
  name: string
  url: string
  slug: string
  grid_picture_url: string
  lowest_price_cents: number
}
export interface SearchMenuProps {
  isOpen: boolean
  onRequestClose: () => void
  searchText: string
  hits: Hit<SearchHit>[]
}

const SearchMenu = ({ hits, isOpen, onRequestClose, searchText }: SearchMenuProps) => {
  const categoryLinks = [
    {
      href: '/air-jordans',
      label: 'Air Jordan',
    },
    {
      href: '/nike',
      label: 'Nike',
    },
    {
      href: '/adidas/adidas-yeezy',
      label: 'Yeezy',
    },
    {
      href: '/adidas',
      label: 'Adidas',
    },
    {
      href: '/off-white',
      label: 'Off-White',
    },
    {
      href: '/sneakers',
      label: t(`${TP}.moreBrands`, 'More Brands'),
    },
    {
      href: '/new-releases',
      label: t(`${TP}.newReleases`, 'New Releases'),
    },
    {
      href: '/price-drops',
      label: t(`${TP}.priceDrops`, 'Price Drops'),
    },
    {
      href: '/men',
      label: t(`${TP}.men`, 'Men'),
    },
    {
      href: '/women',
      label: t(`${TP}.women`, 'Women'),
    },
    {
      href: '/youth',
      label: t(`${TP}.kids`, 'Kids'),
    },
    {
      href: '/apparel',
      label: t(`${TP}.apparel`, 'Apparel'),
    },
  ]
  const getSearchMenuTitle = () => {
    if (searchText?.length === 0) {
      return t(`${TP}.popular`, 'Popular')
    }
    if (hits.length === 0) {
      return t(`${TP}.noResults`, 'No Results')
    }
    return t(`${TP}.topResults`, 'Top Results')
  }
  return (
    <ExpandedMenuModal
      contentLabel={t(`${TP}.searchMenu`, 'Search Menu')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      qaAttr="NavigationSearchMenu"
    >
      <MenusContainer>
        <SearchResultsPanel className="scrollable-menu">
          <Title data-qa="NavigationSearchMenuTitle">{getSearchMenuTitle()}</Title>
          <ItemWrapper>
            {hits.map(({ name, slug, grid_picture_url }, index) => (
              <StyledSearchItem
                href={`/${slug}`}
                imageUrl={grid_picture_url}
                key={slug}
                title={name}
                qaAttr={`navigation-search-item-${index}`}
                searchText={searchText}
              />
            ))}
          </ItemWrapper>
        </SearchResultsPanel>
        {searchText?.length > 0 && hits?.length > 0 && (
          <AllResultsContainer>
            <Link href={`/catalogsearch/result?query=${searchText}`} passHref legacyBehavior>
              <UnderlinedMenuLink onClick={(e) => headerNavigationTrackEvent(e)}>
                {t(`${TP}.viewAllResults`, 'View All Results')}
              </UnderlinedMenuLink>
            </Link>
          </AllResultsContainer>
        )}
        {searchText?.length === 0 && (
          <CategoryPanel>
            <Title>{t(`${TP}.categories`, 'Categories')}</Title>
            <CategoriesContainer>
              {categoryLinks.map((link, index) => (
                <Link href={link.href} key={link.href} passHref legacyBehavior>
                  <StyledMenuLink
                    onClick={() =>
                      sendTrackingEvent('SEARCH_BAR_CATEGORY_TAP', {
                        page: window?.location?.href,
                        slug: link.href,
                        slug_type: 'product_list',
                        category_name: link.label,
                      })
                    }
                    data-qa={`navigation-category-link-${index}`}
                  >
                    {link.label}
                  </StyledMenuLink>
                </Link>
              ))}
            </CategoriesContainer>
          </CategoryPanel>
        )}
      </MenusContainer>
    </ExpandedMenuModal>
  )
}

const StyledMenuLink = styled(MenuLink)`
  margin-bottom: 16px !important;
`

const AllResultsContainer = styled.div`
  padding: 30px;
  padding-left: 32px;
  flex: 1;
  ${mediaQueries.smallNavigation} {
    padding-bottom: 100px;
  }
  ${mediaQueries.tabletOrLarger} {
    padding-top: 85px;
  }
`

const MenusContainer = styled.div`
  display: flex;
  ${mediaQueries.smallNavigation} {
    max-height: none;
    flex-direction: column;
  }
`

const SearchResultsPanel = styled(MenuPanel)`
  flex: 1;
  ${mediaQueries.smallNavigation} {
    max-height: none;
  }
  ${mediaQueries.notNavigation} {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

export const StyledSearchItem = styled(SearchItem)`
  flex: 1;
  flex-basis: 50%;
  min-width: 280px;
  flex-flow: row wrap;
  padding: 15px 15px 0 15px;
`

const CategoryPanel = styled(MenuPanel)`
  flex: 1;
  ${mediaQueries.mobile} {
    display: none;
  }
`

const Title = styled(H6)`
  color: ${colors.darkGray};
`

const CategoriesContainer = styled.div`
  columns: 2;
  margin-top: 25px;
`

export default connectHits<SearchMenuProps, SearchHit>(SearchMenu)
