import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const ErrorIcon = ({
  color = colors.FC2_RED,
  width = '20',
  height = '20',
  className = '',
  viewBox = '0 0 20 20',
  fill = '#FFF',
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke={colors.FC2_RED} transform="translate(1 1)">
      <circle cx="9" cy="9" r="9" fill={fill} fillRule="nonzero" />
      <path strokeWidth="1.5" d="M9 9L6 6l3 3-3 3 3-3zm0 0l3 3-3-3 3-3-3 3z" />
    </g>
  </svg>
)
