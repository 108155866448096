import { colors } from 'shared/theme'
import { t } from 'localization'

const TP = 'shared.icons.Loader'

interface ILoaderSVGProp {
  color?: string
  width?: number
  spaceBetween?: number
}

const LoaderSVG = ({ color = colors.white, width = 6, spaceBetween = 8 }: ILoaderSVGProp) => {
  const viewBoxWidth = width * 3 + spaceBetween * 2
  return (
    <svg
      width={viewBoxWidth}
      height={width}
      viewBox={`0 0 ${viewBoxWidth} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t(`${TP}.loading`, 'Loading Animation')}</title>
      <rect width={width} height={width} rx="1" fill={color}>
        <animate
          attributeName="opacity"
          calcMode="discrete"
          values="1;0.5;0.5"
          dur="900ms"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={width + spaceBetween} opacity="0.5" width={width} height={width} rx="1" fill={color}>
        <animate
          attributeName="opacity"
          calcMode="discrete"
          values="0.5;1;0.5"
          dur="900ms"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={2 * width + 2 * spaceBetween}
        opacity="0.5"
        width={width}
        height={width}
        rx="1"
        fill={color}
      >
        <animate
          attributeName="opacity"
          calcMode="discrete"
          values="0.5;0.5;1"
          dur="900ms"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  )
}

export default LoaderSVG
