import styled from 'styled-components'
import { colors } from 'shared/lib'

const CircleCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  &:not(:checked) + label:after {
    border: 1.5px solid ${colors.FC2_WHITE};
    border-radius: 50%;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.FC2_BLACK};
  }
  &:checked + label:after {
    content: '✓';
    position: absolute;
    color: ${colors.FC2_WHITE};
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    text-align: center;
    border: 1.5px solid ${colors.FC2_WHITE};
    border-radius: 50%;
    background: ${colors.FC2_BLACK};
  }
`

export default CircleCheckbox
