/* tslint:disable:no-var-requires no-console */
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  NormalizedCacheObject,
} from '@apollo/client'
import { merge } from 'lodash'
import possibleTypes from 'shared/apollo/possibleFragmentTypes.json'
import createApolloLinks from './links/createApolloLinks'
import { defaults } from './defaults'
import { resolvers } from './resolvers'
import { typeDefs } from './typeDefs'

declare global {
  interface Window {
    apolloClient: any
  }
}

// https://www.apollographql.com/docs/react/advanced/caching/#normalization
const dataIdFromObject = (object: any) => {
  const { __typename } = object
  switch (__typename) {
    case 'Order':
      return `${__typename}:${object.orderNumber}` || defaultDataIdFromObject(object)
    case 'ShippingServiceLevelOption':
    case 'Price':
      return null
    default:
      return defaultDataIdFromObject(object)
  }
}

function create(initialState, apolloContext): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache({
    ...possibleTypes,
    typePolicies: {
      Order: {
        fields: {
          // when an order with multiple options
          // updated to an address with only one
          // Apollo merged the old + new options
          // We want to return only the new opts
          shippingServiceLevelOptions: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
    },
    dataIdFromObject,
  }).restore(merge({}, initialState, defaults(apolloContext)))

  const isBrowser = typeof window !== 'undefined'
  const link = createApolloLinks(apolloContext)

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link,
    cache,
    resolvers,
    typeDefs,
  })
  client.context = apolloContext

  return client
}

const createNodeApolloClient = create

const createBrowserApolloClient = (_, context) => {
  // Reuse client on the client-side
  if (!window.apolloClient) {
    window.apolloClient = create(
      {}, // Initialize with empty state to force queries to run
      context,
    )
  }

  return window.apolloClient
}

export default function createApolloClient(initialState, context) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return createNodeApolloClient(initialState, context)
  }

  return createBrowserApolloClient(initialState, context)
}
