/* tslint:disable:max-line-length */

enum FooterQA {
  // COLUMN NAMES
  FooterMyAccountCategory = 'FooterMyAccountCategory',
  FooterFlightClubCategory = 'FooterFlightClubCategory',
  FooterSupportCategory = 'FooterSupportCategory',
  FooterTopCategoriesCategory = 'FooterTopCategoriesCategory',
  FooterFollowUsCategory = 'FooterFollowUsCategory',
  // LINK NAMES
  FooterMyAccountLink = 'FooterMyAccountLink',
  FooterMyOrderLink = 'FooterMyOrderLink',
  FooterTrackMyOrderLink = 'FooterTrackMyOrderLink',
  FooterSellLink = 'FooterSellLink',
  FooterOurHistoryLink = 'FooterOurHistoryLink',
  FooterStoreLink = 'FooterStoreLink',
  FooterConsignmentLink = 'FooterConsignmentLink',
  FooterMediaRelationsLink = 'FooterMediaRelationsLink',
  FooterJobsLink = 'FooterJobsLink',
  FooterContactSupportLink = 'FooterContactSupportLink',
  FooterFaqLink = 'FooterFaqLink',
  FooterShippingReturnLink = 'FooterShippingReturnLink',
  FooterSizeGuideLink = 'FooterSizeGuideLink',
  FooterTermsOfUseLink = 'FooterTermsOfUseLink',
  FooterPrivacyPolicyLink = 'FooterPrivacyPolicyLink',
  FooterOneTrustLink = 'FooterOneTrustLink',
  FooterAdidasYeezyLink = 'FooterAdidasYeezyLink',
  FooterOffWhiteLink = 'FooterOffWhiteLink',
  FooterPharrellLink = 'FooterPharrellLink',
  FooterAirJordanLink = 'FooterAirJordanLink',
  FooterNikeAirForce1Link = 'FooterNikeAirForce1Link',
  FooterTravisScottLink = 'FooterTravisScottLink',
  FooterFacebookLink = 'FooterFacebookLink',
  FooterTwitterLink = 'FooterTwitterLink',
  FooterInstagramLink = 'FooterInstagramLink',
  // NEWSLETTER SECTION
  NewsletterEmailSuccessText = 'NewsletterEmailSuccessText',
  NewsletterAgreementText = 'NewsletterAgreementText',
  NewsletterEmailBox = 'NewsletterEmailBox',
  NewsletterSignUpButton = 'NewsletterSignUpButton',
  // NewsletterEmailBoxErrorInLine // Auto-generated
  // NewsletterEmailBoxSuccessIcon // Auto-generated
  NewsletterTermsConditionLink = 'NewsletterTermsConditionLink',
  NewsletterPrivacyPolicyLink = 'NewsletterPrivacyPolicyLink',
}

export default FooterQA
