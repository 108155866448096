import styled from 'styled-components'
import { fadeInStyles } from './FadeInStyles'

export const UnderlinedMenuLink = styled.a`
  color: ${props => props.theme.textColor};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
  font-weight: 500;
  ${fadeInStyles}
`

export const MenuLink = styled(UnderlinedMenuLink)`
  text-decoration: none;
  font-weight: unset;
  margin-bottom: unset;
`

export const UnstyledLink = styled.a`
  color: unset;
  text-decoration: none;
`
