import { lowerCase, startCase } from 'lodash'
import { get, orderBy, pipe, toLower } from 'lodash/fp'
import { Fragment, useContext } from 'react'

import { useRouter } from 'next/router'
import { SearchFiltersRefinement } from 'search'
import AlgoliaSearchGridContext from 'search/components/AlgoliaSearchGridContext'
import CategoryFilter from 'search/components/SearchFilters/Filters/CategoryFilter'
import useCurrency from 'shared/hooks/useCurrency'
import BrandFilterContainer from './Filters/Brand/BrandFilterContainer'
import ColorFilter from './Filters/ColorFilter'
import ConditionFilterContainer from './Filters/Condition/ConditionFilterContainer'
import ModelFilterContainer from './Filters/Model/ModelFilterContainer'
import PriceFilterContainer from './Filters/Price/PriceFilterContainer'
import PresentationSizeFilter from './Filters/Size/PresentationSizeFilter'
import { sortByShirtSizeOrder } from './Filters/Size/ShirtSizeOrder'
import SizeSelectionFilter from './Filters/Size/SizeSelectionFilter'
import YearFilter from './Filters/YearFilter'

/**
 * 40 will cover all valid sizes and on the off chance there are more than 40 that
 * we'd want to display in the current UI design at one time, more than 40 would be an
 * unreasonable amount for the eye to search through
 */
const SizeFacetMax = 40
/**
 * WARNING:
 * `limit` will set the `maxValuesPerFacet` global limit, and thus those facets are
 * requested anyway, for all facets.
 * https://github.com/algolia/react-instantsearch/issues/2895
 */
const ModelFacetMax = 10
const BrandFacetMax = 10
const ColorFacetMax = 20
const CategoryFacetMax = 20
const ColorDisplayMax = 10

const FiltersSection = () => {
  const sortNumericLabel = items =>
    items.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))

  const sortAlphanumericLabel = items => {
    const topItems = items.slice(0, ModelFacetMax)
    return topItems.sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
    )
  }

  // Get top 10 and sort them alphabetically
  const sortColorByCountThenAlpha = items =>
    items
      .slice(0, ColorDisplayMax)
      .sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
      )

  const { hiddenFilters } = useContext(AlgoliaSearchGridContext)
  const { selectedCurrency, loading, error } = useCurrency()
  const currencyIsoCode = pipe(get('isoCode'), toLower)(selectedCurrency)
  const { query } = useRouter()
  const isApparel = query.slug && query.slug.includes('apparel')

  return (
    <Fragment>
      <ModelFilterContainer
        hide={
          isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.Silhouette))
        }
        attribute={SearchFiltersRefinement.Silhouette}
        transformItems={sortAlphanumericLabel}
        limit={ModelFacetMax}
      />
      <BrandFilterContainer
        hide={isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.Brand))}
        attribute={SearchFiltersRefinement.Brand}
        transformItems={items => orderBy([({ label }) => lowerCase(label)], ['asc'], items)}
        transformItems={items => {
          const topItems = items.slice(0, BrandFacetMax)
          return orderBy([({ label }) => lowerCase(label)], ['asc'], topItems)
        }}
        limit={BrandFacetMax}
      />

      <PresentationSizeFilter
        hide={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.PresentationSizes)}
        attribute={SearchFiltersRefinement.PresentationSizes}
        defaultRefinement={undefined}
        limit={SizeFacetMax}
        transformItems={sortByShirtSizeOrder}
        isExpanded
      />
      <SizeSelectionFilter
        hide={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.MenSizes)}
        attribute={SearchFiltersRefinement.MenSizes}
        defaultRefinement={undefined}
        transformItems={sortNumericLabel}
        limit={SizeFacetMax}
        isExpanded
      />
      <SizeSelectionFilter
        hide={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.WomenSizes)}
        attribute={SearchFiltersRefinement.WomenSizes}
        defaultRefinement={undefined}
        transformItems={sortNumericLabel}
        limit={SizeFacetMax}
        isExpanded={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.MenSizes)}
      />
      <SizeSelectionFilter
        hide={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.YouthSizes)}
        attribute={SearchFiltersRefinement.YouthSizes}
        defaultRefinement={undefined}
        transformItems={sortNumericLabel}
        limit={SizeFacetMax}
        isExpanded={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.MenSizes)}
      />

      {!isApparel && !loading && !error && (
        <PriceFilterContainer
          currency={currencyIsoCode}
          attribute={
            !!currencyIsoCode
              ? `${SearchFiltersRefinement.LowestPrice}_${currencyIsoCode}`
              : SearchFiltersRefinement.LowestPrice
          }
          baseAttribute={SearchFiltersRefinement.LowestPrice}
        />
      )}

      <ConditionFilterContainer
        hide={isApparel || hiddenFilters.has(SearchFiltersRefinement.Condition)}
        attribute={SearchFiltersRefinement.Condition}
        transformItems={items => {
          return items.map(item => ({ ...item, label: startCase(item.label.replace(/_/g, ' ')) }))
        }}
      />

      {isApparel && (
        <CategoryFilter
          attribute={SearchFiltersRefinement.ProductType}
          defaultRefinement={undefined}
          limit={CategoryFacetMax}
        />
      )}

      <ColorFilter
        attribute={SearchFiltersRefinement.Color}
        defaultRefinement={undefined}
        transformItems={sortColorByCountThenAlpha}
        limit={ColorFacetMax}
      />

      {!isApparel && <YearFilter attribute={SearchFiltersRefinement.Year} />}
    </Fragment>
  )
}
FiltersSection.displayName = 'FiltersSection'
export default FiltersSection
