import camelCase from 'lodash/camelCase'
import flow from 'lodash/flow'
import upperFirst from 'lodash/upperFirst'

/**
 * See docs on event types
 * https://docs.mparticle.com/developers/sdk/web/event-tracking/#event-types
 * also see specified Event Types in setUpMParticle.ts line 19 - 29
 */
//
const DEFAULT_EVENT_TYPE = 'Unknown'

const pascalCase = flow([camelCase, upperFirst])

/**
 * [getMParticleEventType]
 * Attempts to match incoming event type with the enumerated number defined in mParticle's
 * client SDK. Our event schema defines type's in lowercase, so we capitalize the first
 * letter before matching.
 * @param   {String} eventTypeString e.g. 'navigation'
 * @returns {Number}                 e.g. 1
 */
export const getMparticleEventType = (eventTypeString: string, mParticle: any): number => {
  const eventType =
    typeof eventTypeString !== 'string' ? DEFAULT_EVENT_TYPE : pascalCase(eventTypeString)

  return mParticle?.EventType[eventType] || 0
}
