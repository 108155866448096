import React, { useContext } from 'react'
import { AlgoliaIndex } from 'search/enums/search'
export interface AlgoliaSearchGridContextProps {
  hiddenFilters: Set<string> // controls which filters to visually hide
  filters?: string
  index?: string
  hasNewProductOnly: boolean
  ruleContexts?: string[]
}
const AlgoliaSearchGridContext = React.createContext<Partial<AlgoliaSearchGridContextProps>>({
  hiddenFilters: new Set(),
  filters: '',
  index: AlgoliaIndex.Relevance,
})

export const useGridContext = () => useContext(AlgoliaSearchGridContext)
AlgoliaSearchGridContext.displayName = 'AlgoliaSearchGridContext'
export default AlgoliaSearchGridContext
