import { pipe, map, reduce, find, get } from 'lodash/fp'
import { Currency } from 'shared/enums'

export const currencyLocaleMap = {
  [Currency.AUD]: 'en-AU',
  [Currency.CAD]: 'en-CA',
  [Currency.CNY]: 'zh-CN',
  [Currency.EUR]: 'en-GB',
  [Currency.GBP]: 'en-GB',
  [Currency.HKD]: 'en-HK',
  [Currency.KRW]: 'ko-KR',
  [Currency.JPY]: 'ja-JP',
  [Currency.SGD]: 'zh-SG',
  [Currency.TWD]: 'zh-TW',
  [Currency.USD]: 'en-US',
  [Currency.MYR]: 'ms-MY',
  [Currency.MXN]: 'es-MX',
}

interface IFormatOptions {
  symbol: string
  useGrouping?: boolean
  hideCents?: boolean
  hideEmptyCents?: boolean
}

export const convertToCents = (amount?: number) => {
  if (typeof amount === 'number') {
    return amount * 100
  }
}

// The amount of currency in cents. A cent is 1/100 of a full currency even if the currency
// doesn't have the traditional notion of cents.
export const convertToDollars = (amount?: number) => {
  if (typeof amount === 'number') {
    return amount / 100
  }
}
const convertToDollarsWithRemainder = amount => Math.round(amount / 100)
const currencyHasCents = currency => {
  const locale = getLocale(currency)
  const { maximumFractionDigits } = Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).resolvedOptions()

  return maximumFractionDigits == null ? false : maximumFractionDigits !== 0
}
const getLocale = currency => currencyLocaleMap[currency] || currencyLocaleMap[Currency.USD]
const replaceSymbol = currency => symbol =>
  map(({ type, value }) =>
    type === 'currency'
      ? currency === 'USD'
        ? { type, value: symbol }
        : { type, value: `${currency} ${symbol}` }
      : { type, value },
  )
const removeSpaceBetweenSymbolAndValue = map(({ type, value }) =>
  type === 'literal' ? { type, value: '' } : { type, value },
)
const removeEmptyCents = map(({ type, value }) =>
  type === 'fraction' && value === '00' ? { type, value: '' } : { type, value },
)
const getFractionValue = pipe(find(['type', 'fraction']), get('value'))
const removeDecimal = parts =>
  map(({ type, value }) =>
    type === 'decimal' && getFractionValue(parts) === '00' ? { type, value: '' } : { type, value },
  )(parts)

// Would like to eventually change the implementation to support masking like in Dinero.js
// so that we can deprecate passing in these options and instead provide a format string
// https://sarahdayan.github.io/dinero.js/module-Dinero.html#~toFormat
const formatCurrency = (currency, formatOptions?: IFormatOptions) => (price?: number) => {
  if (currency == null || typeof price !== 'number') {
    return ''
  }

  const { symbol, useGrouping = true, hideCents = false, hideEmptyCents = false } =
    formatOptions || {}
  const locale = getLocale(currency)
  const options = {
    style: 'currency',
    currency,
    useGrouping,
    currencyDisplay: 'code',
  }
  const dollarPrice = currencyHasCents(currency)
    ? convertToDollars(price)
    : convertToDollarsWithRemainder(price)

  if (hideCents) {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }

  const formattedParts = new Intl.NumberFormat(locale, options).formatToParts(dollarPrice)
  const formatToDisplay = pipe(
    replaceSymbol(currency)(symbol),
    parts => (hideEmptyCents ? removeDecimal(parts) : parts),
    parts => (hideEmptyCents ? removeEmptyCents(parts) : parts),
    removeSpaceBetweenSymbolAndValue,
    reduce((string, { value }) => string + value, ''),
  )

  return formatToDisplay(formattedParts)
}

export default formatCurrency
