import { camelCase } from 'lodash'
import { ReactNode } from 'react'
import styled, { StyledComponent } from 'styled-components'

import InputArrow from 'shared/icons/InputArrow'
import { labelStyles, TextInput, TextInputProps } from 'shared/inputs/TextInput'
import LoaderSVG from 'shared/svg/loader.svg'
import SuccessCheckSVG from 'shared/svg/success_check.svg'
import { H6 } from 'shared/text'
import { colors } from 'shared/theme'
import { t } from 'localization'

const TP = 'shared.inputs.ComboBox'

export interface ComboBoxProps extends TextInputProps {
  description?: string | ReactNode
  icon?: StyledComponent<any, { className?: string }>
  isLoading?: boolean
  successMessage?: string
  title?: string
}

const ComboBox = ({
  className,
  dataQa,
  description,
  icon: IconFromProps,
  isLoading = false,
  label,
  name,
  onChange,
  successMessage,
  title,
  type = 'text',
}: ComboBoxProps) => {
  const baseDataQa = dataQa || `${camelCase(label)}ComboBox`
  const getComboBoxDataQa = (postFix = '') => `${baseDataQa}${postFix}`
  return (
    <Container
      role="form"
      className={className}
      data-qa-loading={isLoading}
      data-qa={getComboBoxDataQa()}
    >
      {title && <Title data-qa={getComboBoxDataQa('Title')}>{title}</Title>}
      <InputWrapper>
        <ComboInput
          dataQa={getComboBoxDataQa('TextInput')}
          label={label}
          name={name}
          onChange={onChange}
          type={type}
        />
        <ComboSubmit
          aria-label={t(`${TP}.submit`, 'Submit')}
          data-qa={getComboBoxDataQa('SubmitButton')}
          disabled={isLoading}
          type="submit"
        >
          {successMessage ? (
            <SuccessCheck />
          ) : isLoading ? (
            <Loader />
          ) : IconFromProps ? (
            <IconFromProps />
          ) : (
            <Arrow />
          )}
        </ComboSubmit>
      </InputWrapper>
      {successMessage && (
        <SuccessMessage data-qa={getComboBoxDataQa('SuccessMessage')}>
          {successMessage}
        </SuccessMessage>
      )}
      {description && (
        <Description data-qa={getComboBoxDataQa('Description')}>{description}</Description>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Title = styled(H6)`
  margin-bottom: 25px;
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`

/* stylelint-disable */
const ComboInput = styled(TextInput)`
  flex: 1;
  margin-top: 0;
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${InputWrapper}:focus-within & input {
    border-color: ${colors.white};
  }
  ${InputWrapper} &.hasError input {
    border-color: ${colors.red};
  }
`
/* stylelint-enable */

const SuccessMessage = styled.div`
  ${labelStyles}
  color: ${colors.white};
  margin-top: 10px;
`

const Arrow = styled(InputArrow)`
  transition: transform 125ms ease-out;
  transform: translate3d(0, 0, 0);
`
const Loader = styled(LoaderSVG)`
  transform: translate3d(1px, -3px, 0);
`
const SuccessCheck = styled(SuccessCheckSVG)`
  transform: translate3d(0, 3px, 0);
`

/* stylelint-disable */
const ComboSubmit = styled.button`
  background-color: ${colors.offBlack};
  border: 1px solid ${colors.lighterGray};
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-left: 0;
  height: 50px;
  margin-left: 0;
  margin-top: 28px;
  min-width: 50px;
  padding: 0 20px;
  transition: border-color 250ms;
  ${InputWrapper}:focus-within & {
    border-color: ${colors.white};
  }
  ${InputWrapper} .hasError + & {
    border-color: ${colors.red};
  }
  &:focus > ${Arrow}, &:hover > ${Arrow} {
    transform: translate3d(18%, 0, 0);
  }
`
/* stylelint-enable */

// this is a bad code smell
// anchors should be globally styled
// FIX ME
const Description = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${colors.mediumGray};
  &&& a {
    color: ${colors.mediumGray};
    text-decoration: underline;
  }
  &&& a:hover {
    color: ${colors.lighterGray};
    text-decoration: underline;
  }
`

export default ComboBox
