const SizeOrder = [
  'xxxs',
  'xxxs/xxs',
  'xxs',
  'xxs/xs',
  'xs',
  'xs/s',
  's',
  's/m',
  'm',
  'm/l',
  'l',
  'l/xl',
  'xl',
  'xl/xxl',
  'xxl',
  'xxl/xxxl',
  'xxxl',
]

interface SizeDTO {
  label: string
  value: string[]
  count: number
  isRefined: boolean
}

export const sortByShirtSizeOrder = (sizeArray: SizeDTO[]) => {
  sizeArray.sort((a, b) => {
    if (SizeOrder.indexOf(a.label) < SizeOrder.indexOf(b.label)) {
      return -1
    }
    if (SizeOrder.indexOf(b.label) < SizeOrder.indexOf(a.label)) {
      return 1
    }
    return 0
  })
  return sizeArray
}
