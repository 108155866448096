import pino from 'pino'
import PinoHttp, { Options } from 'pino-http'

export const logger: pino.Logger = pino({
  formatters: {
    level: (label) => {
      return { level: label.toUpperCase() }
    },
  },
  redact: {
    // Remove CSP headers from logging output (to reduce noise)
    paths: ['res.headers["content-security-policy"]'],
    censor: '**removed**',
  },
  timestamp: pino.stdTimeFunctions.isoTime,
})

const loggerOptions: Options = {
  logger,
  serializers: {
    req: function customReqSerializer(req) {
      return {
        id: req.id,
        method: req.method,
        params: req.params,
        url: req.url,
        user: req.user,
      }
    },
    res: function customResSerializer(res) {
      return {
        statusCode: res.statusCode,
      }
    },
  },
}

export const expressLogger = PinoHttp(loggerOptions)
