import sendTrackingEvent from 'mParticle/sendTrackingEvent'

export function headerNavigationTrackEvent(event) {
  const page = window?.location?.href || ''
  const url = event?.target?.href || event?.target?.parentNode?.href
  const urlName = event?.target?.innerText

  sendTrackingEvent('HEADER_NAVIGATION_LINK_TAP', {
    page,
    url,
    url_name: urlName,
  })
}
