import { Breadcrumb } from 'shared/types/Breadcrumb'

// defaultBreadcrumbs JSON data are saved in this file to use as fallback data
// If the request to retrieve breadcrumbs fails, we should still populate the
// navigation menu, footer, etc. so the user can still have navigation options
// Please keep breadcrumb data up to date if you are aware of any changes

export const defaultFooter: Breadcrumb = {
  name: 'Footer',
  slug: 'footer',
  sort: 8,
  children: [
    {
      name: 'Footer Sections',
      slug: 'footer/sectionLinks',
      sort: 1,
      children: [
        {
          name: 'Trending',
          slug: 'footer/sectionLinks/collections/top-picks',
          sort: 1,
          children: [
            {
              name: 'Air Jordan',
              slug: 'footer/sectionLinks/collections/top-picks/air-jordans',
              sort: 1,
            },
            {
              name: 'Yeezy',
              slug: 'footer/sectionLinks/collections/top-picks/yeezy',
              sort: 2,
            },
            {
              name: 'Nike',
              slug: 'footer/sectionLinks/collections/top-picks/nike',
              sort: 3,
            },
            {
              name: 'Adidas',
              slug: 'footer/sectionLinks/collections/top-picks/adidas',
              sort: 4,
            },
            {
              name: 'Travis Scott',
              slug: 'footer/sectionLinks/collections/top-picks/travis-scott',
              sort: 5,
            },
            {
              name: 'Off-White',
              slug: 'footer/sectionLinks/collections/top-picks/off-white',
              sort: 6,
            },
          ],
        },
        {
          name: 'New Releases',
          slug: 'footer/sectionLinks/collections/new-releases',
          sort: 2,
          children: [
            {
              name: 'Air Max 97 OG QS',
              slug:
                'footer/sectionLinks/collections/new-releases/https://fc2-staging.fclabs.com/air-max-97-og-qs-metallic-silver-varsity-red-053222',
              sort: 1,
            },
            {
              name: "AIR JORDAN 1 RETRO HIGH OG ALL STAR 'CHAMELEON'",
              slug:
                'footer/sectionLinks/collections/new-releases/https://fc2-staging.fclabs.com/air-jordan-1-retro-high-og-all-star-chameleon-907958-015',
              sort: 2,
            },
          ],
        },
        {
          name: 'Support',
          slug: 'footer/sectionLinks/https://support.flightclub.com/hc/en-us',
          sort: 3,
          children: [
            {
              name: 'My Account',
              slug: 'footer/sectionLinks/https://support.flightclub.com/hc/en-us/my-account',
              sort: 1,
            },
            {
              name: 'Track My Order',
              slug: 'footer/sectionLinks/https://support.flightclub.com/hc/en-us/order-search',
              sort: 2,
            },
            {
              name: 'Shipping \u0026 Returns',
              slug: 'footer/sectionLinks/https://support.flightclub.com/hc/en-us/shipping',
              sort: 3,
            },
            {
              name: 'Sell Sneakers',
              slug: 'footer/sectionLinks/https://support.flightclub.com/hc/en-us/consign',
              sort: 4,
            },
          ],
        },
        {
          name: 'About Us',
          slug: 'footer/sectionLinks/about-us',
          sort: 4,
          children: [
            {
              name: 'Stores',
              slug: 'footer/sectionLinks/about-us/storelocator',
              sort: 1,
            },
            {
              name: 'Jobs',
              slug: 'footer/sectionLinks/about-us/jobs',
              sort: 2,
            },
            {
              name: 'Press',
              slug: 'footer/sectionLinks/about-us/press',
              sort: 3,
            },
            {
              name: 'Instagram',
              slug: 'footer/sectionLinks/about-us/https://www.instagram.com/flightclub/',
              sort: 4,
            },
            {
              name: 'Facebook',
              slug: 'footer/sectionLinks/about-us/https://www.facebook.com/FlightClub',
              sort: 5,
            },
            {
              name: 'Twitter',
              slug: 'footer/sectionLinks/about-us/https://twitter.com/flightclub',
              sort: 6,
            },
          ],
        },
      ],
    },
    {
      name: 'Legal Links',
      slug: 'footer/legalLinks',
      sort: 2,
      children: [
        {
          name: 'Terms',
          slug: 'footer/legalLinks/terms-conditions',
          sort: 1,
        },
        { name: 'Privacy', slug: 'footer/legalLinks/privacy', sort: 2 },
      ],
    },
  ],
}

export const defaultNavigationLinks: Breadcrumb = {
  name: 'Navigation Links',
  slug: 'navigation-links',
  sort: 9,
  children: [
    {
      name: 'Air Jordan',
      slug: 'navigation-links/air-jordans',
      sort: 1,
      children: [
        {
          name: 'Air Jordan 1',
          slug: 'navigation-links/air-jordans/air-jordan-1',
          sort: 1,
        },
        {
          name: 'Air Jordan 2',
          slug: 'navigation-links/air-jordans/air-jordan-2',
          sort: 2,
        },
        {
          name: 'Air Jordan 3',
          slug: 'navigation-links/air-jordans/air-jordan-3',
          sort: 3,
        },
        {
          name: 'Air Jordan 4',
          slug: 'navigation-links/air-jordans/air-jordan-4',
          sort: 4,
        },
        {
          name: 'Air Jordan 5',
          slug: 'navigation-links/air-jordans/air-jordan-5',
          sort: 5,
        },
        {
          name: 'Air Jordan 6',
          slug: 'navigation-links/air-jordans/air-jordan-6',
          sort: 6,
        },
        {
          name: 'Air Jordan 7',
          slug: 'navigation-links/air-jordans/air-jordan-7',
          sort: 7,
        },
        {
          name: 'Air Jordan 8',
          slug: 'navigation-links/air-jordans/air-jordan-8',
          sort: 8,
        },
        {
          name: 'Air Jordan 9',
          slug: 'navigation-links/air-jordans/air-jordan-9',
          sort: 9,
        },
        {
          name: 'Air Jordan 10',
          slug: 'navigation-links/air-jordans/air-jordan-10',
          sort: 10,
        },
        {
          name: 'Air Jordan 11',
          slug: 'navigation-links/air-jordans/air-jordan-11',
          sort: 11,
        },
        {
          name: 'Air Jordan 12',
          slug: 'navigation-links/air-jordans/air-jordan-12',
          sort: 12,
        },
        {
          name: 'Air Jordan 13',
          slug: 'navigation-links/air-jordans/air-jordan-13',
          sort: 13,
        },
        {
          name: 'Air Jordan 14',
          slug: 'navigation-links/air-jordans/air-jordan-14',
          sort: 14,
        },
        {
          name: 'Air Jordan New Releases',
          slug: 'navigation-links/air-jordans/new-releases',
          sort: 15,
        },
      ],
    },
    {
      name: 'Yeezy',
      slug: 'navigation-links/yeezy',
      sort: 2,
      urlOverride: '/adidas/adidas-yeezy',
      children: [
        {
          name: 'Yeezy Boost 350',
          slug: 'navigation-links/yeezy/boost-350',
          sort: 1,
        },
        {
          name: 'Yeezy Boost 380',
          slug: 'navigation-links/yeezy/boost-380',
          sort: 2,
        },
        {
          name: 'Yeezy Boost 500',
          slug: 'navigation-links/yeezy/boost-500',
          sort: 3,
        },
        {
          name: 'Yeezy Boost 700',
          slug: 'navigation-links/yeezy/boost-700',
          sort: 4,
        },
        {
          name: 'Yeezy Boost 750',
          slug: 'navigation-links/yeezy/boost-750',
          sort: 5,
        },
        {
          name: 'Adidas Yeezy',
          slug: 'navigation-links/yeezy/adidas-yeezy',
          sort: 6,
          urlOverride: 'adidas/adidas-yeezy',
        },
        {
          name: 'Nike Yeezy',
          slug: 'navigation-links/yeezy/nike-yeezy',
          sort: 7,
        },
        {
          name: 'Yeezy Foam Runner',
          slug: 'navigation-links/yeezy/foam-runner',
          sort: 8,
        },
        {
          name: 'Yeezy Slides',
          slug: 'navigation-links/yeezy/slides',
          sort: 9,
        },
      ],
    },
    {
      name: 'Nike',
      slug: 'navigation-links/nike',
      sort: 3,
      children: [
        {
          name: 'Off-White',
          slug: 'navigation-links/nike/off-white',
          sort: 1,
          urlOverride: '/collections/nike-off-white',
        },
        {
          name: 'Dunk',
          slug: 'navigation-links/nike/nike-dunks',
          sort: 2,
        },
        {
          name: 'Air Max',
          slug: 'navigation-links/nike/nike-running/air-max',
          sort: 3,
        },
        {
          name: 'Air Force 1',
          slug: 'navigation-links/nike/nike-air-force',
          sort: 4,
        },
        {
          name: 'Blazer',
          slug: 'navigation-links/nike/nike-basketball/blazer',
          sort: 5,
        },
        {
          name: 'Foamposite',
          slug: 'navigation-links/nike/nike-basketball/posite',
          sort: 6,
        },
        {
          name: 'Vapormax',
          slug: 'navigation-links/nike/nike-running/vapormax',
          sort: 7,
        },
        {
          name: 'Kobe',
          slug: 'navigation-links/nike/nike-basketball/kobe-bryant',
          sort: 8,
        },
        {
          name: 'LeBron',
          slug: 'navigation-links/nike/nike-basketball/lebron-james',
          sort: 9,
        },
        {
          name: 'Nike New Releases',
          slug: 'navigation-links/nike/new-releases',
          sort: 10,
        },
      ],
    },
    {
      name: 'Adidas',
      slug: 'navigation-links/adidas',
      sort: 4,
      children: [
        {
          name: 'Yeezy',
          slug: 'navigation-links/adidas/adidas-yeezy',
          sort: 1,
        },
        {
          name: 'Pharrell',
          slug: 'navigation-links/adidas/pharrell',
          sort: 2,
        },
        { name: 'NMD', slug: 'navigation-links/adidas/nmd', sort: 3 },
        {
          name: 'Ultra Boost',
          slug: 'navigation-links/adidas/ultra-boost',
          sort: 4,
        },
        {
          name: 'Adidas New Releases',
          slug: 'navigation-links/adidas/new-releases',
          sort: 5,
        },
      ],
    },
    { name: 'Off-White', slug: 'navigation-links/off-white', sort: 5 },
    {
      name: 'New Balance',
      slug: 'navigation-links/new-balance',
      sort: 6,
      children: [
        {
          name: 'New Balance 327',
          slug: 'navigation-links/new-balance/327',
          sort: 1,
        },
        {
          name: 'New Balance 550',
          slug: 'navigation-links/new-balance/550',
          sort: 2,
        },
      ],
    },
    {
      name: 'More Brands',
      slug: 'navigation-links/sneakers',
      sort: 7,
      children: [
        {
          name: 'Asics',
          slug: 'navigation-links/sneakers/asics',
          sort: 1,
        },
        {
          name: 'A Bathing Ape',
          slug: 'navigation-links/sneakers/a-bathing-ape',
          sort: 2,
        },
        {
          name: 'Converse',
          slug: 'navigation-links/sneakers/converse',
          sort: 3,
        },
        {
          name: 'Puma',
          slug: 'navigation-links/sneakers/puma',
          sort: 4,
        },
        {
          name: 'Reebok',
          slug: 'navigation-links/sneakers/reebok',
          sort: 5,
        },
        {
          name: 'Vans',
          slug: 'navigation-links/sneakers/vans',
          sort: 6,
        },
      ],
    },
    {
      name: 'New Releases',
      slug: 'navigation-links/new-releases',
      sort: 8,
    },
    {
      name: 'Price Drops',
      slug: 'navigation-links/price-drops',
      sort: 9,
    },
    {
      name: 'Grails',
      slug: 'navigation-links/collections/grails',
      sort: 10,
    },
    { name: 'Men', slug: 'navigation-links/men', sort: 11 },
    { name: 'Women', slug: 'navigation-links/women', sort: 12 },
    { name: 'Kids', slug: 'navigation-links/youth', sort: 13 },
    { name: 'Apparel', slug: 'navigation-links/apparel', sort: 14 },
  ],
}

export const defaultNavigation: Breadcrumb = {
  name: 'Navigation',
  slug: 'navigation',
  children: [
    {
      name: 'Air Jordans',
      slug: 'navigation/air-jordans',
      sort: 1,
      description:
        'Since 1985, the sneakers with Michael Jordan’s name and world-renowned “Jumpman” silhouette have helped define and shape sneaker culture. It began with a standard \u003ca href=https://www.flightclub.com/nike/nike-dunks/dunk-high\u003eNike high-top\u003c/a\u003e and evolved with daring designs with each iteration. From \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-3\u003egraphic prints\u003c/a\u003e to \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-11\u003epatent leather\u003c/a\u003e to \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-5\u003efighter planes\u003c/a\u003e and \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-14\u003eFerrari-inspired designs\u003c/a\u003e, Jordan sneakers routinely transcend their basketball roots by refreshing their initial offering with new looks and color schemes to remain relevant to each new generation.',
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/049/main/FkuYBuu.png?1570573522',
      algoliaQuery: {
        filters: { brand_name: ['Air Jordan'] },
        indexName: 'product_variants_v2_flight_club',
      },
      children: [
        {
          name: 'Air Jordan 1',
          slug: 'navigation/air-jordans/air-jordan-1',
          sort: 1,
          description:
            "When the Air Jordan 1 arrived in '85, it was with rule-breaking bravado. The \u003ca href=https://www.flightclub.com/air-jordan-1-retro-high-ban-black-varsity-red-white-011413\u003ebanned black-red colorway\u003c/a\u003e violated the league's uniform policy and resulted in a $5,000 fine from the NBA each time Michael Jordan wore sneakers. Thus the legacy began. Jordan's rookie campaign in 1984-85 finished with His Airness averaging 28.2 points per game to earn All-Star and Rookie of the Year honors.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/050/main/FkuYBuu.png?1570573522',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 1'] } },
        },
        {
          name: 'Air Jordan 2',
          slug: 'navigation/air-jordans/air-jordan-2',
          sort: 2,
          description:
            "It was 1986, Michael Jordan’s second season, and Nike aimed to follow \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-1\u003eAir Jordan 1's\u003c/a\u003e success with something eye-catching and more compliant with the NBA’s uniform policy. The Air Jordan 2 was influenced by luxury. The sneaker was made in Italy and designed to resemble high-fashion dress shoes with its faux-lizard skin along the upper. Jordan set an NBA postseason record wearing the Jordan 2 by dropping 63 points on the Celtics in the playoffs.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/051/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 2'] } },
        },
        {
          name: 'Air Jordan 3',
          slug: 'navigation/air-jordans/air-jordan-3',
          sort: 3,
          description:
            "The instantly recognizable Jumpman silhouette made its debut with the Air Jordan 3 during Michael Jordan's 1987-88 NBA season. Legendary designer Tinker Hatfield re-introduced Jordan’s signature line with the first mid-cut basketball sneaker. The tumbled leather and elephant skin print wrapping the toe and heel established the \u003ca href=https://www.flightclub.com/air-jordan-3-retro-88-white-fire-red-cement-grey-black-011693\u003eJordan 3\u003c/a\u003e as a sneaker that could be worn proudly both on and off the hardwood.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/052/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 3'] } },
        },
        {
          name: 'Air Jordan 4',
          slug: 'navigation/air-jordans/air-jordan-4',
          sort: 4,
          description:
            "The Air Jordan 4 debuted in 1989 by building on the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-3\u003eAir Jordan 3\u003c/a\u003e ability to seamlessly blend on-court performance with off-court fashion. Straps framed the eyelets, which provided function for ballers seeking extra support -- or resembled wings on the side of the shoe when loosely laced. Michael Jordan's footwear reached new heights when the \u003ca href=https://www.flightclub.com/air-jordan-4-retro-white-varsity-red-black-011607\u003e\u0026ldquo;Fire Red\u0026rdquo; Jordan 4\u003c/a\u003e was featured in Spike Lee\u0026rsquo;s classic \u003cem\u003eDo the Right Thing\u003c/em\u003e.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/053/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 4'] } },
        },
        {
          name: 'Air Jordan 5',
          slug: 'navigation/air-jordans/air-jordan-5',
          sort: 5,
          description:
            "The Air Jordan 5 hit shelves in 1990. It maintained the mesh side panels \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-4\u003ewith its predecessor\u003c/a\u003e and took another bold step with its look—it included the first appearance of a translucent sole on a Jordan signature sneaker. Tinker Hatfield drew inspiration from World War II era fighter planes when he implemented the infamous “shark-teeth” look into the sneaker's design along the midsole.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/054/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 5'] } },
        },
        {
          name: 'Air Jordan 6',
          slug: 'navigation/air-jordans/air-jordan-6',
          sort: 6,
          description:
            'In 1991, Michael Jordan wore the Air Jordan 6 while completing his second MVP season. He locked in another scoring title and hoisted the NBA championship trophy for the first time in his illustrious career. Tinker Hatfield designed two grip holes into the rubber tongue to help people slip their feet in more easily and included a translucent sole, which contrasted the colored midsole. This proved to be an essential detail once the famous \u003ca href=https://www.flightclub.com/air-jordan-6-retro-white-infrared-black-011235\u003e“Infrared” colorway\u003c/a\u003e hit the streets.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/055/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 6'] } },
        },
        {
          name: 'Air Jordan 7',
          slug: 'navigation/air-jordans/air-jordan-7',
          sort: 7,
          description:
            'The arrival of the Air Jordan 7 in 1992 continued pushing Michael Jordan’s footwear into popular culture. Bugs Bunny replaced Spike Lee’s \u003ca href=https://www.flightclub.com/air-jordan-4-retro-white-varsity-red-black-010418\u003e“Mars Blackmon”\u003c/a\u003e as the pitchman, and for the very first time there were no Nike logos visible on the outside. Jordan won a second consecutive NBA championship in the 7 and made history as part of the \u003ca href=https://www.flightclub.com/air-jordan-7-retro-white-mtllc-gold-obsdn-tr-rd-011596\u003e“Dream Team” during the 1992 Barcelona Olympics\u003c/a\u003e.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/056/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 7'] } },
        },
        {
          name: 'Air Jordan 8',
          slug: 'navigation/air-jordans/air-jordan-8',
          sort: 8,
          description:
            'Michael Jordan strapped on the most unique pair of his signature sneakers during the 1992-93 season. The Air Jordan 8 is the only model to feature crossover straps atop the laces. His Airness scored his 20,000th point in the Jordan 8 while locked in his seventh consecutive scoring title. Jordan also led the Bulls to their first \u003ca href=https://www.flightclub.com/air-jordan-8-retro-blk-vrsty-rd-white-brght-concrd-011773\u003e“Three-Peat” while wearing the 8\u003c/a\u003e, just before he suddenly retired in October 1993.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/057/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 8'] } },
        },
        {
          name: 'Air Jordan 9',
          slug: 'navigation/air-jordans/air-jordan-9',
          sort: 9,
          description:
            'With Michael Jordan’s sudden retirement just before the 1993-94 season, the Air Jordan 9 was the first signature sneaker Jordan never wore during a regular-season game. The sneaker borrowed design cues from the baseball cleats Jordan wore during his brief stint in minor league baseball. Players, such as Mitch Richmond, Kendall Gil, and Penny Hardaway, kept the 9 alive with player exclusives during Jordan’s absence.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/058/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 9'] } },
        },
        {
          name: 'Air Jordan 10',
          slug: 'navigation/air-jordans/air-jordan-10',
          sort: 10,
          description:
            'Michael Jordan retired suddenly before his \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-9\u003eJordan 9\u003c/a\u003e hit the streets, but that didn’t halt any plans to continue his signature line. The lightweight Air Jordan 10 arrived as a tribute to Jordan and was lined with his accomplishments on the sole, and worn around the league by players in other cities, including \u003ca href=https://www.flightclub.com/air-jordan-10-og-black-dark-concord-metallic-silver-010129\u003eSacramento\u003c/a\u003e. The homage proved to be premature, however, as Jordan wasn’t actually done with basketball. Wearing the number 45, he returned to the Bulls in 1995 and was ready to shake off any rustiness to help lead his team into the postseason.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/126/main/FkuYBuu.png?1570573534',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 10'] } },
        },
        {
          name: 'Air Jordan 11',
          slug: 'navigation/air-jordans/air-jordan-11',
          sort: 11,
          description:
            'Tinker Hatfield listened when Michael Jordan said he wanted patent leather on a sneaker--something versatile for basketball and formal occasions. Hatfield responded in 1995 and 1996\u0026rsquo;s Air Jordan 11, often regarded as the most beloved signature silhouette of all. Wrapped in patent leather from toe to heel and topped with ballistic mesh and leather, Jordan wore the 11 to another NBA championship with the Bulls and in \u003ca href=https://www.flightclub.com/air-jordan-11-retro-black-varsity-royal-white-010062\u003e\u003cem\u003eSpace Jam\u003c/em\u003e (1996)\u003c/a\u003e.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/060/main/FkuYBuu.png?1570573523',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 11'] } },
        },
        {
          name: 'Air Jordan 12',
          slug: 'navigation/air-jordans/air-jordan-12',
          sort: 12,
          description:
            'The Jordan brand became official as a Nike subsidiary with the release of the Air Jordan 12. The predominantly leather sneaker returned to the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-2\u003eJordan 2’s\u003c/a\u003e faux-lizard skin for its mudguard and incorporated a design inspired by the Japanese flag’s rising sun. Michael Jordan won his fifth title with the Bulls in the 12, which made the \u003ca href=https://www.flightclub.com/air-jordan-12-og-black-varsity-red-010125\u003eblack/red colorway\u003c/a\u003e legendary for his performance in game 5 of the NBA finals, often referenced as the \u003ca href=https://www.flightclub.com/air-jordan-12-retro-black-varsity-red-011113\u003e“The Flu Game.”\u003c/a\u003e',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/142/main/FkuYBuu.png?1570573537',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 12'] } },
        },
        {
          name: 'Air Jordan 13',
          slug: 'navigation/air-jordans/air-jordan-13',
          sort: 13,
          description:
            'Michael Jordan’s reputation for cat-like reflexes on the court inspired designer Tinker Hatfield to unleash the spirit of the panther for the Air Jordan 13. The sole was modeled after a panther’s paw print and included holograms on the bottom and by the ankle to represent a cat’s glowing eyes. Jordan led the Bulls to another three-peat in the 13 and earned his sixth NBA title. He retired as a champion for the second time in his career.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/143/main/FkuYBuu.png?1570573537',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 13'] } },
        },
        {
          name: 'Air Jordan 14',
          slug: 'navigation/air-jordans/air-jordan-14',
          sort: 14,
          description:
            'Michael Jordan retired for the second time just before the release of the Air Jordan 14, but he still slipped a black/red pair on for his \u003ca href=https://www.flightclub.com/air-jordan-14-retro-black-varsity-red-black-803887\u003e“Last Shot”\u003c/a\u003e and one final NBA title with the Bulls. Designer Tinker Hatfield modeled the sleek 14 after the Ferrari 550M that Jordan drove. The Jumpman appears seven times on both the left and right shoes, which honor the 14th sneaker in Jordan’s signature series.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/061/main/FkuYBuu.png?1570573524',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 14'] } },
        },
        {
          name: 'Air Jordan 15',
          slug: 'navigation/air-jordans/air-jordan-15',
          sort: 15,
          description:
            "Inspired in part by NASA’s X-15 fighter jet, the Jordan 15 arrived as the first official Jordan brand release that the then-retired Michael Jordan wouldn’t wear on the court. Like the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-2\u003eJordan 2\u003c/a\u003e, Tinker Hatfield designed the 15 with premium Italian shoes in mind and included a woven pattern on the upper. He looked to moccasins when designing the 15's high cushioned collar allowing for an easy slip-on.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/144/main/FkuYBuu.png?1570573538',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 15'] } },
        },
        {
          name: 'Air Jordan 16',
          slug: 'navigation/air-jordans/air-jordan-16',
          sort: 16,
          description:
            'The patent leather that helped make the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-11\u003eJordan 11\u003c/a\u003e so popular returned to Michael Jordan’s signature sneaker line with the versatile Jordan 16 in 2001. The translucent sole returned as well, which had been absent since the 11. Presented as two sneakers in one, the 16 came with a removable leather shroud that covered the laces and wrapped around the sole.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/145/main/FkuYBuu.png?1570573538',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 16'] } },
        },
        {
          name: 'Air Jordan 17',
          slug: 'navigation/air-jordans/air-jordan-17',
          sort: 17,
          description:
            'It was back to business for Michael Jordan when his Air Jordan 17, complete with a metal briefcase, dropped in 2002. His Airness returned from retirement yet again to join the talent-starved Washington Wizards. The 17 drew its inspiration from jazz music and the sleek Aston Martin and maintained the lace-shroud from the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-16\u003eJordan 16\u003c/a\u003e. At age 38, Jordan led the Wizards in scoring while wearing the 17 and became the oldest player to drop 50 points in a game.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/146/main/FkuYBuu.png?1570573538',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 17'] } },
        },
        {
          name: 'Air Jordan 18',
          slug: 'navigation/air-jordans/air-jordan-18',
          sort: 18,
          description:
            'Michael Jordan wore the Air Jordan 18 before retiring for the third and final time following the 2002-03 NBA season. Inspiration for the design came from motor sports as a tribute to Jordan’s passion for racing and high-speed vehicles. The 18 incorporated looks reminiscent of traditional race-car driving shoes and also included design elements found in fine Italian dress shoes.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/134/main/FkuYBuu.png?1570573536',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 18'] } },
        },
        {
          name: 'Air Jordan 19',
          slug: 'navigation/air-jordans/air-jordan-19',
          sort: 19,
          description:
            'For the first time, the Air Jordan 19 abandoned laces to introduce a braided sleeve made of high-tech flexible material that locked in the wearer’s foot. With Michael Jordan retired — officially this time — Jordan Brand looked to NBA stars like Jason Kidd, Carmelo Anthony, and Ray Allen, among others, to keep the Jumpman alive on the court.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/127/main/FkuYBuu.png?1570573534',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 19'] } },
        },
        {
          name: 'Air Jordan 20',
          slug: 'navigation/air-jordans/air-jordan-20',
          sort: 20,
          description:
            'No return was in sight for Michael Jordan after his third retirement in 2003, but designer Tinker Hatfield made his comeback to the Jordan brand for 2005’s Air Jordan 20—his first Jordan design since the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-15\u003e15\u003c/a\u003e. Laces also returned with the 20, but were covered with an exquisitely detailed strap, which acknowledged Jordan’s legacy and extensive achievements. ',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/063/main/FkuYBuu.png?1570573524',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 20'] } },
        },
        {
          name: 'Air Jordan 21',
          slug: 'navigation/air-jordans/air-jordan-21',
          sort: 21,
          description:
            'The Air Jordan 21 dropped in 2006 with the intention of highlighting Michael Jordan’s passions. Much like the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-18\u003eJordan 18\u003c/a\u003e, his interest in motor sports and Italian-made dress shoes with premium leather and high-quality suede were at the forefront of the 21’s design. ',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/128/main/FkuYBuu.png?1570573535',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 21'] } },
        },
        {
          name: 'Air Jordan 22',
          slug: 'navigation/air-jordans/air-jordan-22',
          sort: 22,
          description:
            'Finely-tuned machines were often the inspiration for Michael Jordan’s footwear, and 2007’s Air Jordan 22 was no different. While \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-14\u003eprevious silhouettes\u003c/a\u003e honored Jordan’s cars, the Jordan 22 looked to the F-22 Raptor fighter jet for its design cues. Elaborate stitching patterns and reflective 3M provided details to the silhouette.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/064/main/FkuYBuu.png?1570573524',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 22'] } },
        },
        {
          name: 'Air Jordan 23',
          slug: 'navigation/air-jordans/air-jordan-23',
          sort: 23,
          description:
            'The number 23 will forever be synonymous with Michael Jordan. When it was time to create his 23rd signature sneaker in 2008, Tinker Hatfield cooked up another tribute to His Airness and his storied career. Jordan’s signature is embossed on the toe, and there is a stitched pattern on the upper to maintain Jordan’s preference for a dress-shoe appearance. A smoothly polished panel grips the sneakers from toe to heel.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/065/main/FkuYBuu.png?1570573524',
          algoliaQuery: { filters: { silhouette: ['Air Jordan 23'] } },
        },
        {
          name: '6 Rings',
          slug: 'navigation/air-jordans/6-rings',
          sort: 24,
          description:
            'Michael Jordan wore seven different silhouettes of his signature sneaker while winning six NBA championships with the Bulls. The Air Jordan 6 Rings is a tribute to each championship. Popular and easily recognizable design cues from the Air Jordan \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-6\u003e6\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-7\u003e7\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-8\u003e8\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-11\u003e11\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-12\u003e12\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-13\u003e13\u003c/a\u003e, and \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-14\u003e14\u003c/a\u003e were integrated to create the 6 Rings silhouette.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/155/main/FkuYBuu.png?1570573540',
          algoliaQuery: {
            filters: { silhouette: ['Jordan 6 Rings', '6 Rings'] },
          },
        },
        {
          name: "Spiz'ike",
          slug: 'navigation/air-jordans/spizike',
          sort: 25,
          description:
            'Spike Lee was just as critical to the success of Jordan sneakers as Michael Jordan was himself. Spike’s \u003ca href=https://www.flightclub.com/air-jordan-4-retro-white-varsity-red-black-010418\u003e“Mars Blackmon”\u003c/a\u003e character in Jordan advertisements helped cement Jordan’s signature sneakers in popular culture, and the hybrid Spiz’ike is a tribute to the Brooklyn-born filmmaker’s contributions. The Spiz’ike takes most of its design elements from the Air Jordan \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-3\u003e3\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-4\u003e4\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-5\u003e5\u003c/a\u003e, \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-6\u003e6\u003c/a\u003e and \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-9\u003e9\u003c/a\u003e silhouettes. ',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/066/main/FkuYBuu.png?1570573524',
          algoliaQuery: {
            filters: {
              silhouette: ['Spizike', 'Jordan Spizike', "Spiz'ike", "Jordan Spiz'ike"],
            },
          },
        },
        {
          name: 'Jordan Future',
          slug: 'navigation/air-jordans/jordan-future',
          sort: 26,
          description:
            "Introduced in 2014, the Jordan Future adopted the sole (and midsole) from the \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-11\u003eAir Jordan 11\u003c/a\u003e. The woven upper is an entirely new look for the Jordan brand and offers the lightweight comfort expected from something like \u003ca href=https://www.flightclub.com/nike/nike-running/roshe-run\u003eNike's Roshe Run\u003c/a\u003e on a basketball sole. Colored soles provide a unique look for each iteration of this new model.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/129/main/FkuYBuu.png?1570573535',
          algoliaQuery: { filters: { silhouette: ['Jordan Future'] } },
        },
        {
          name: 'Air Jordan New Releases',
          slug: 'navigation/air-jordans/new-releases',
          sort: 28,
          description:
            "Check out all Air Jordan release dates at Flight Club before they drop. We're your number one source for all new and upcoming Jordan sneaker releases.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/130/main/FkuYBuu.png?1570573535',
          algoliaQuery: {
            filters: { brand_name: ['Air Jordan'] },
            indexName: 'product_variants_v2_by_newest_desc_flight_club',
          },
        },
        {
          name: 'High Top Air Jordan',
          slug: 'navigation/air-jordans/air-jordan-hi-top',
          sort: 29,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/062/main/FkuYBuu.png?1570573524',
          algoliaQuery: {
            query: 'High',
            filters: { brand_name: ['Air Jordan'] },
          },
        },
        {
          name: 'Mid Top Air Jordan',
          slug: 'navigation/air-jordans/air-jordan-mids',
          sort: 30,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/147/main/FkuYBuu.png?1570573538',
          algoliaQuery: {
            query: 'Mid',
            filters: { brand_name: ['Air Jordan'] },
          },
        },
        {
          name: 'Low Top Air Jordan',
          slug: 'navigation/air-jordans/air-jordan-lows',
          sort: 31,
          description:
            "Since 1985, the Air Jordan brand has created the most beloved sneakers of all time. Get the low cut versions of all the classic styles when you shop Flight Club's Air Jordan Low Tops collection for everyone—men, women, girls and boys.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/070/main/FkuYBuu.png?1570573524',
          algoliaQuery: {
            query: 'Low',
            filters: { brand_name: ['Air Jordan'] },
          },
        },
      ],
    },
    {
      name: 'Nike',
      slug: 'navigation/nike',
      sort: 2,
      description:
        "In 1964, founders Bill Bowerman and Phil Knight turned their Blue Ribbon Sports venture into the Nike we know today. With roots in the University of Oregon's running team, Knight and Bowerman launched Nike and built the Swoosh into a high-profile brand synonymous with sneakers, athletic apparel, and the sports lifestyle, with help from the \u003ca href=https://www.flightclub.com/air-jordans\u003eAir Jordan\u003c/a\u003e line along the way. From \u003ca href=https://www.flightclub.com/nike/nike-running\u003erunning\u003c/a\u003e to \u003ca href=https://www.flightclub.com/nike/nike-basketball\u003ebasketball\u003c/a\u003e to \u003ca href=https://www.flightclub.com/nike/nike-skateboarding\u003eskating\u003c/a\u003e, some of the world's greatest athletes wear Nike footwear, helping to cement a legacy that has advanced and innovated sneaker culture for decades.",
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/068/main/FkuYBuu.png?1570573524',
      algoliaQuery: { filters: { brand_name: ['Nike'] } },
      children: [
        {
          name: 'Nike Basketball',
          slug: 'navigation/nike/nike-basketball',
          sort: 1,
          description:
            "It began with the \u003ca href=https://www.flightclub.com/nike/nike-basketball/blazer\u003eBlazers\u003c/a\u003e and Bruins in 1972, evolved into the \u003ca href=https://www.flightclub.com/nike/nike-air-force\u003eAir Force 1\u003c/a\u003e, and became the undisputed leader in quality kicks for style and performance after \u003ca href=https://www.flightclub.com/air-jordans\u003eMichael Jordan\u003c/a\u003e made history in the swoosh in 1985. The finest pieces in Nike Basketball are routinely designed for and inspired by NBA’s best from \u003ca href=https://www.flightclub.com/nike/nike-basketball/charles-barkley\u003eCharles Barkley\u003c/a\u003e and \u003chttps://www.flightclub.com/nike/nike-basketball/penny-hardaway\u003ePenny Hardaway\u003c/a\u003e in the '90s to \u003chttps://www.flightclub.com/nike/nike-basketball/lebron-james\u003eLeBron James\u003c/a\u003e and \u003ca href=https://www.flightclub.com/nike/nike-basketball/kevin-durant\u003eKevin Durant\u003c/a\u003e today.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/069/main/FkuYBuu.png?1570573524',
          algoliaQuery: {
            filters: { category: ['Basketball'], brand_name: ['Nike'] },
          },
          children: [
            {
              name: 'Blazer',
              slug: 'navigation/nike/nike-basketball/blazer',
              sort: 1,
              description:
                'Longevity is key. The Nike Blazer launched in 1972 as a basketball sneaker and has since showcased tremendous staying power. The immensely versatile shoe can be dressed in a variety of fabrics from genuine leather to suede and canvas. It has been decorated with graphic prints, offering elaborate artwork that contrasts against the minimalist look. The Blazer has the unique ability of reinventing itself for the times.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/166/main/FkuYBuu.png?1570573542',
              algoliaQuery: { filters: { silhouette: ['Blazer'] } },
            },
            {
              name: 'Charles Barkley',
              slug: 'navigation/nike/nike-basketball/charles-barkley',
              sort: 2,
              description:
                'Sir Charles Barkley declared that he was not a role model in his infamous Nike commercial, but that did nothing to keep the sneakers he wore throughout his NBA career from becoming iconic. From the \u003ca href=https://www.flightclub.com/air-force-180-blk-wht-sport-royal-vrsty-red-800107\u003eAir Force 180\u003c/a\u003e to the \u003ca href=https://www.flightclub.com/air-max2-cb-94-black-white-pure-purple-150779\u003eAir Max2 CB 94\u003c/a\u003e, Barkley’s footwear helped create a lasting legacy as significant as his talent is. Barkley’s popularity is demonstrated in the decades of retro releases and especially with the \u003ca href=https://www.flightclub.com/barkley-posite-max-black-siren-red-041847\u003eBarkley Posite Max\u003c/a\u003e launch that rejuvenated his presence.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/167/main/FkuYBuu.png?1570573542',
              algoliaQuery: {
                filters: {
                  silhouette: ['Barkley Posite Max', 'Air Max CB34', 'Air Max 2 CB 94'],
                },
              },
            },
            {
              name: 'Kevin Durant',
              slug: 'navigation/nike/nike-basketball/kevin-durant',
              sort: 3,
              description:
                'The arrival of the Zoom KD 4 helped make Kevin Durant’s kicks as prolific as his on-court ability. Since 2007, the former NBA Rookie of the Year has been a scoring force, and his footwear has proven to be as versatile as his game has been. His kicks stepped into stride with the KD 4. Designer Leo Chang found inspiration from \u003ca href=https://www.flightclub.com/zoom-kd-4-nerf-cncrd-brght-crmsn-blk-c-nerf-041615\u003eNerf\u003c/a\u003e and \u003ca href=https://www.flightclub.com/kd-6-black-atmc-red-mdm-olv-nbl-rd-041944\u003emeteorology\u003c/a\u003e to create a sneaker that has consistently increased in demand since 2011.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/071/main/FkuYBuu.png?1570573525',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Kevin Durant',
                    'KD 1',
                    'KD 2',
                    'KD 3',
                    'KD 4',
                    'KD 5',
                    'KD 6',
                    'KD 7',
                    'KD 8',
                    'KD 9',
                    'KD 10',
                    'KD 11',
                  ],
                },
              },
            },
            {
              name: 'Kobe Bryant',
              slug: 'navigation/nike/nike-basketball/kobe-bryant',
              sort: 4,
              description:
                'After spending the early part of his career with \u003ca href=https://www.flightclub.com/adidas\u003eadidas\u003c/a\u003e, Kobe Bryant joined \u003ca href=https://www.flightclub.com/nike/nike-basketball\u003eNike Basketball\u003c/a\u003e in 2003. Since then, he has leapt over speeding Aston Martins for Nike and effectively branded himself as the “Black Mamba”. Kobe’s signature kicks often offer a sleek low-top that contrasts against Bryant’s high profile. The designs complement his calculated, \u003ca href=https://www.flightclub.com/kobe-8-system-university-red-sail-noble-red-pearl-grey-041939\u003ereptilian\u003c/a\u003e style.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/148/main/FkuYBuu.png?1570573538',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Zoom Kobe 1',
                    'Zoom Kobe 2',
                    'Zoom Kobe 3',
                    'Zoom Kobe 4',
                    'Zoom Kobe 5',
                    'Zoom Kobe 6',
                    'Zoom Kobe 7',
                    'Zoom Kobe 8',
                    'Kobe 8 System',
                    'Kobe 9',
                    'Kobe 10',
                    'Kobe A.D.',
                  ],
                },
              },
            },
            {
              name: 'Kyrie Irving',
              slug: 'navigation/nike/nike-basketball/kyrie-irving',
              sort: 5,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/149/main/FkuYBuu.png?1570573539',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Kyrie 1',
                    'Kyrie 2',
                    'Kyrie 3',
                    'Kyrie 4',
                    'Kyrie 5',
                    'Kyrie Low',
                    'Kyrie Flytrap',
                    'Kyrie Flytrap 2',
                  ],
                },
              },
            },
            {
              name: 'Lebron James',
              slug: 'navigation/nike/nike-basketball/lebron-james',
              sort: 6,
              description:
                'Since 2003, all eyes have been on LeBron James, and they’ve remained glued there. The King’s reign with Nike Basketball began with the \u003ca href=https://www.flightclub.com/air-zoom-generation-black-white-crimson-040245\u003eAir Zoom Generation\u003c/a\u003e and evolved with each signature sneaker bearing his name. James’s shoes change drastically from year to year, but routinely deliver the most coveted colorways in the culture. From \u003ca href=https://www.flightclub.com/lebron-8-retro-black-pink-flsh-flmnt-grn-041375\u003e“South Beach”\u003c/a\u003e to the electric \u003ca href=https://www.flightclub.com/air-max-lebron-7-black-black-electric-green-041190\u003e“Dunkman”\u003c/a\u003e series, LeBrons reign supreme on and off the court.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/150/main/FkuYBuu.png?1570573539',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'LeBron 1',
                    'LeBron 2',
                    'LeBron 3',
                    'LeBron 4',
                    'LeBron 5',
                    'LeBron 6',
                    'LeBron 7',
                    'LeBron 8',
                    'LeBron 9',
                    'LeBron 10',
                    'LeBron 11',
                    'LeBron 12',
                    'LeBron 13',
                    'LeBron 14',
                    'LeBron 15',
                    'LeBron 16',
                  ],
                },
              },
            },
            {
              name: 'Paul George',
              slug: 'navigation/nike/nike-basketball/paul-george',
              sort: 7,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/131/main/FkuYBuu.png?1570573535',
              algoliaQuery: {
                filters: { silhouette: ['PG', 'PG 2', 'PG 2.5', 'PG 3'] },
              },
            },
            {
              name: 'Penny Hardaway',
              slug: 'navigation/nike/nike-basketball/penny-hardaway',
              sort: 8,
              description:
                'A tall, charismatic point guard in Orlando came closest to challenging Michael Jordan’s sneaker throne in the 90s. Anfernee “Penny” Hardaway’s kicks cracked necks, helped people see beyond the Jumpman, and introduced Foamposite — Nike’s solid but light material — to the streets and the hardwood. His sneaker legacy is so impressive that in 2012, Nike Basketball introduced the Penny 5, making him the first player since Jordan to have his line continued after his playing career.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/151/main/FkuYBuu.png?1570573539',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Penny Hardaway',
                    'Air Penny 1',
                    'Air Penny 2',
                    'Air Penny 3',
                    'Air Penny 4',
                    'Air Penny 5',
                    'Air Penny 6',
                    'Air Max Penny',
                  ],
                },
              },
            },
            {
              name: 'Posite',
              slug: 'navigation/nike/nike-basketball/posite',
              sort: 9,
              description:
                'Foamposite, Nike’s lightweight and durable material, was ahead of its time when it launched in 1997 on \u003ca href=https://www.flightclub.com/nike/nike-basketball/penny-hardaway\u003ePenny Hardaway\u003c/a\u003e’s feet. The material was then adjusted for Tim Duncan \u003ca href=https://www.flightclub.com/total-air-foamposite-max-metallic-silver-black-photo-blue-041571\u003eTotal Air Foamposite Max\u003c/a\u003e in \'98. Sneakerheads eventually embraced it, paying close attention to any Nike Basketball release bearing the "posite" suffix. From the original \u003ca href=https://www.flightclub.com/air-foamposite-one-dk-neon-royal-white-black-041376\u003eOne\u003c/a\u003e and \u003ca href=https://www.flightclub.com/air-foamposite-pro-white-black-true-red-040081\u003ePro\u003c/a\u003e silhouettes to the more modern \u003ca href=https://www.flightclub.com/air-foamposite-one-nrg-galaxy-obsidian-anthracite-black-041660\u003e"Galaxy"\u003c/a\u003e print \u003ca href=https://www.flightclub.com/air-max-hyperposite-crystal-mint-mtlc-coppercoin-041789\u003eHyperposite\u003c/a\u003e, any sneaker bearing the mold warrants instant and well-deserved attention.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/156/main/FkuYBuu.png?1570573540',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Air Flightposite',
                    'Barkley Posite Max',
                    'Air Max Hyperposite',
                    'Air Foamposite',
                    'Air Foamposite One',
                  ],
                },
              },
            },
            {
              name: 'Scottie Pippen',
              slug: 'navigation/nike/nike-basketball/scottie-pippen',
              sort: 10,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/132/main/FkuYBuu.png?1570573535',
              algoliaQuery: {
                filters: {
                  silhouette: ['Scottie Pippen', 'Air Max Tempo', 'Air More Uptempo'],
                },
              },
            },
          ],
        },
        {
          name: 'Nike Skateboarding',
          slug: 'navigation/nike/nike-skateboarding',
          sort: 2,
          description:
            'It was 2002 when Nike introduced its SB line designed entirely for skaters. The line consists of former \u003ca href=https://www.flightclub.com/nike/nike-basketball\u003eNike basketball\u003c/a\u003e silhouettes, reexport constructed with added cushioning and appropriated for skating. The SB’s true strength is in its design and daring color combos. Collaborations with popular skate shops and well-known artists have delivered extremely rare sneakers inspired by everything from \u003ca href=https://www.flightclub.com/dunk-low-pro-sb-aqua-chrome-080091\u003eTiffany jewelry boxes\u003c/a\u003e to Star Wars Jedis.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/133/main/FkuYBuu.png?1570573536',
          algoliaQuery: {
            filters: {
              category: ['skateboarding'],
              brand_name: ['Nike'],
            },
          },
          children: [
            {
              name: 'Blazer SB',
              slug: 'navigation/nike/nike-skateboarding/blazer-sb',
              sort: 1,
              description:
                'Introduced in 1973 as a basketball sneaker, the \u003ca href=https://www.flightclub.com/nike/nike-basketball/blazer\u003eBlazer\u003c/a\u003e evolved into a casually worn sneaker before it was adjusted with more padding for Nike SB. It maintains the minimalist design that has carried it through more than four decades and features a broad swoosh and thick midsole. New designs experiment with colors and graphic prints while they feature diverse fabrics ranging from from canvas to suede and leather.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/139/main/FkuYBuu.png?1570573537',
              algoliaQuery: {
                filters: {
                  category: ['skateboarding'],
                  silhouette: ['Blazer'],
                },
              },
            },
            {
              name: 'Bruin SB',
              slug: 'navigation/nike/nike-skateboarding/bruin-sb',
              sort: 2,
              description:
                'Nike introduced the Bruin in 1972 as a minimalist, low-top basketball sneaker. The swoosh dominated the upper, and the herringbone traction pattern on the sole provided functionality. As basketball technology improved, the Bruin became a staple for casual wear and sportswear before it was reconfigured for skateboarding with Nike SB.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/140/main/FkuYBuu.png?1570573537',
              algoliaQuery: {
                filters: {
                  category: ['skateboarding'],
                  silhouette: ['Bruin'],
                },
              },
            },
            {
              name: 'Janoski',
              slug: 'navigation/nike/nike-skateboarding/janoski',
              sort: 3,
              description:
                'Going lower than the \u003ca href=https://www.flightclub.com/nike/nike-skateboarding/dunk-sb-low\u003eDunk SB Low\u003c/a\u003e, the Stefan Janoski SB line offers a low-profile look while maintaining the creative design found across Nike SB. The sneakers come from Stefan Janoski, a highly regarded skater and all-around artist, whose work revives cherished looks, such as the \u003ca href=https://www.flightclub.com/zoom-stefan-janoski-md-olive-urban-orange-080994\u003eUNDFTD\u003c/a\u003e colorway and \u003ca href=https://www.flightclub.com/zoom-stefan-janoski-sb-net-terra-brown-080801\u003eblood-splatter prints\u003c/a\u003e. The sneaker offers forward-thinking patterns, such as 2013’s digital-floral designs\u003c/a\u003e. ',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/169/main/FkuYBuu.png?1570573542',
              algoliaQuery: {
                filters: {
                  silhouette: ['Stefan Janoski', 'Zoom Janoski', 'Air Max Janoski 2'],
                },
              },
            },
            {
              name: 'Dunk SB',
              slug: 'navigation/nike/nike-skateboarding/sb',
              description:
                'No longer just a dated basketball shoe, the Dunk was reconfigured for skaters rolling with \u003ca href=https://www.flightclub.com/nike/nike-skateboarding\u003eNike SB\u003c/a\u003e. Decorating Dunks with unique color schemes was never a challenge, but their inclusion in skate culture opened the silhouette up to new materials, including \u003ca href=https://www.flightclub.com/nike-dunk-high-pro-sb-tweed-tweed-baroque-brown-080053\u003etweed\u003c/a\u003e, \u003ca href=https://www.flightclub.com/nike-dunk-high-premium-sb-3m-black-light-silver-varsity-red-080903\u003e3M\u003c/a\u003e, and \u003ca href=https://www.flightclub.com/nike-dunk-high-premium-sb-denim-midnight-navy-obsidian-080704\u003edenim\u003c/a\u003e. The Dunk manages to transcend cultures with creative themes for holidays and by honoring icons from De La Soul to Pee Wee Herman.',
            },
          ],
        },
        {
          name: 'Nike Cross-Training',
          slug: 'navigation/nike/nike-cross-training',
          sort: 3,
          description:
            "Nike Training is about Bo Jackson, Ken Griffey Jr.’s majestic swing, Deion Sanders’s prime time showmanship, and Calvin Johnson’s superhuman precision. Nike built its reputation in basketball and running sneakers, but cross-training carried the spirits of athletes whose abilities took them through varying terrains. The \u003ca href=https://www.flightclub.com/nike/nike-running/huarache\u003eHuarache\u003c/a\u003e, Bo's \u003ca href=https://www.flightclub.com/air-trainer-sc-high-og-qs-sail-light-zen-grey-tart-060356\u003eTrainer SC High\u003c/a\u003e, Sanders’s Diamond Turf, and \u003ca href=https://www.flightclub.com/air-griffey-max-1-black-white-fresh-water-varsity-red-060278\u003eGriffey Max 1\u003c/a\u003e went beyond the hardwood and blacktop.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/171/main/FkuYBuu.png?1570573543',
          algoliaQuery: {
            filters: {
              category: ['nike cross training'],
              brand_name: ['Nike'],
            },
          },
          children: [
            {
              name: 'Trainer',
              slug: 'navigation/nike/nike-cross-training/trainer',
              sort: 1,
              description:
                "The Nike Air Trainer and its variations are most synonymous with NFL and MLB great Bo Jackson. During the early '90s, the legendary two-sport athlete rocked the Trainer SC High and the Max '91 to cement a sneaker legacy that still defines standards for Nike Cross-Training.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/158/main/FkuYBuu.png?1570573540',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Air Trainer 1',
                    'Air Trainer 1.2',
                    'Air Trainer 1.3',
                    'Air Trainer 2',
                    'Air Trainer 3',
                    'Air Trainer SC',
                    'Air Trainer SC 2',
                  ],
                },
              },
            },
            {
              name: 'Griffey',
              slug: 'navigation/nike/nike-cross-training/griffey',
              sort: 2,
              description:
                "With a majestic swing and a deafening crack of his bat, Ken Griffey Jr.'s presence on the diamond was as gripping for baseball fans as his footwear was to sneaker enthusiasts. His strapped-up \u003ca href=https://www.flightclub.com/air-griffey-max-1-black-white-fresh-water-varsity-red-060278\u003eGriffey Max 1\u003c/a\u003e has survived through the decades with its bold colors and dominant swoosh, while sneakers like the Griffey Max 2 return in short bursts to tease the market.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/159/main/FkuYBuu.png?1570573540',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Air Griffey Max 1',
                    'Air Griffey Max 2',
                    'Air Max Griffey',
                    'Air Max Griffey Fury',
                  ],
                },
              },
            },
          ],
        },
        {
          name: 'Nike Running',
          slug: 'navigation/nike/nike-running',
          sort: 4,
          description:
            "Running is embedded in Nike's DNA. Phil Knight, a distance runner from the University of Oregon, and Bill Bowerman, a track-and-field coach at the same school, launched Nike with the runner in mind. Silhouettes launched under Nike Running often enjoy two lives: 1) the introduction as a performance model, and 2) the inevitable return as classic kicks for casual purposes. Makes sense, right? If it was good enough to run in, it had to be ideal for walking too.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/093/main/FkuYBuu.png?1570573530',
          algoliaQuery: {
            filters: { category: ['running'], brand_name: ['Nike'] },
          },
          children: [
            {
              name: 'Air Max',
              slug: 'navigation/nike/nike-running/air-max',
              sort: 1,
              description:
                'The revolutionary cushioning technology crafted by aeronautical engineer Frank Rudy has evolved from a performance technology into a culture and lifestyle. Starting with the Air Tailwind in 1979, Nike Air has created classics along its storied lineage, including the \u003ca href=https://www.flightclub.com/nike/nike-running/air-max-1\u003eAir Max 1\u003c/a\u003e, \u003ca href=https://www.flightclub.com/nike/nike-running/air-max-90\u003eAir Max 90\u003c/a\u003e and \u003ca href=https://www.flightclub.com/nike/nike-running/air-max-97\u003eAir Max 97\u003c/a\u003e. Still going strong, Air Max continues to showcase the best of form and function.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/059/main/FkuYBuu.png?1570573523',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Air Max 1',
                    'Air Max 90',
                    'Air Max 95',
                    'Air Max 97',
                    'Air Max 98',
                    'Air Max 180',
                    'Air Max 270',
                    'Air Max 720',
                    'Air Max Deluxe',
                    'Air Max Zero',
                  ],
                },
              },
            },
            {
              name: 'Air Max 1',
              slug: 'navigation/nike/nike-running/air-max-1',
              sort: 2,
              description:
                'The concept of “Air” in Nike soles wasn’t new, but 1987’s Air Max 1 was the first with technology showcasing a visible unit. Designed for runners, the Air Max 1 was created for speed and impact with lightweight and well-cushioned construction. The beloved AM1 remains a Nike sportswear piece, often customized and remodeled with new fabrics and unique color combinations for casual use.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/074/main/FkuYBuu.png?1570573525',
              algoliaQuery: { filters: { silhouette: ['Air Max 1'] } },
            },
            {
              name: 'Air Max 90',
              slug: 'navigation/nike/nike-running/air-max-90',
              sort: 3,
              description:
                'Known for his work with \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-3\u003eMichael Jordan\u003c/a\u003e, designer Tinker Hatfield created 1990’s Air Max 90—formerly known as the Air Max III—with quickness in mind. The shapes and patterns are constructed to convey speed, while remaining aesthetically appealing for daring color combinations. Once the eye-popping \u003ca href=https://www.flightclub.com/air-max-90-og-sail-cool-grey-medium-grey-infrared-051813\u003einfrared\u003c/a\u003e was injected into the AM90, the sneaker became a prime piece for collaborations with Nike.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/080/main/FkuYBuu.png?1570573526',
              algoliaQuery: { filters: { silhouette: ['Air Max 90'] } },
            },
            {
              name: 'Air Max 95',
              slug: 'navigation/nike/nike-running/air-max-95',
              sort: 4,
              description:
                "The Air Max 95 completely shook up the look of the Air Max line. The broad swoosh was replaced by a subtle logo near the ankle, and visible air units were included at the forefoot. It was the layered upper and its gradient effect, however, that made the sneaker so appealing. The neon pop on the \u003ca href=https://www.flightclub.com/air-max-95-og-white-neon-yellow-black-anthracite-051816\u003eoriginal Air Max 95\u003c/a\u003e also caught everyone's attention.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/152/main/FkuYBuu.png?1570573539',
              algoliaQuery: { filters: { silhouette: ['Air Max 95'] } },
            },
            {
              name: 'Air Max 97',
              slug: 'navigation/nike/nike-running/air-max-97',
              sort: 5,
              description:
                'The Air Max 97 was designed with the runner entirely in mind. The full-length visible air unit provided enough cushioning to comfort high-impact runners and keep casual wearers feeling light on their feet. The AM97 maintained the layered look from the \u003ca href=https://www.flightclub.com/nike/nike-running/air-max-95\u003eAM95\u003c/a\u003e, but offered a sleeker take with \u003ca href=https://www.flightclub.com/air-max-97-premium-nrg-metallic-silver-vrsty-red-blk-051759\u003eits metallic coloring\u003c/a\u003e. The 3M stripe wrapping around the shoe gave the sneaker even more flash.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/160/main/FkuYBuu.png?1570573541',
              algoliaQuery: { filters: { silhouette: ['Air Max 97'] } },
            },
            {
              name: 'Air Max 270',
              slug: 'navigation/nike/nike-running/air-max-270',
              sort: 6,
              description:
                'Designed as the first lifestyle Air Max silhouette versus performance, the Air Max 270 takes DNA from the Air Max 93 and Air Max 180 to craft something new. Utilizing different materials on the upper including mesh, leather and nubuck, the large Max Air unit in the heel stands out and offers ample cushioning. Different updates to the 270 give the shoe new life, including the Air Max 270 React, Air Max 270 Flyknit and Air Max 270 Bowfin.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/094/main/FkuYBuu.png?1570573530',
              algoliaQuery: { filters: { silhouette: ['Air Max 270'] } },
            },
            {
              name: 'Flyknit',
              slug: 'navigation/nike/nike-running/flyknit-sockracer',
              sort: 7,
              description:
                "Nike Running stepped away from leather and synthetic uppers and breathable mesh in 2012 when the brand introduced Flyknit. How many materials can be lighter than simple thread? The yarn upper is sewn into and held in place by a lightweight structure. The sneaker was immediately functional for Olympic sprinters and easily desirable for its comfort and aggressive coloring. From the vivid \u003ca href=https://www.flightclub.com/lunar-flyknit-htm-nrg-dark-grey-black-051754\u003eHTM\u003c/a\u003e designs to \u003ca href=https://www.flightclub.com/flyknit-lunar1-supreme-black-black-dark-grey-052073\u003eSupreme's all-black effort\u003c/a\u003e, the Flyknit continues to impress.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/095/main/FkuYBuu.png?1570573530',
              algoliaQuery: {
                filters: {
                  silhouette: ['Flyknit Trainer', 'FLyknit Racer'],
                },
              },
            },
            {
              name: 'Huarache',
              slug: 'navigation/nike/nike-running/huarache',
              sort: 8,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/153/main/FkuYBuu.png?1570573540',
              algoliaQuery: { filters: { silhouette: ['huarache'] } },
            },
            {
              name: 'Presto',
              slug: 'navigation/nike/nike-running/presto',
              sort: 9,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/096/main/FkuYBuu.png?1570573531',
              algoliaQuery: { filters: { silhouette: ['Air Presto'] } },
            },
            {
              name: 'React',
              slug: 'navigation/nike/nike-running/nike-react',
              sort: 10,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/081/main/FkuYBuu.png?1570573526',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'React Element 55',
                    'React Element 87',
                    'Epic React',
                    'React Presto',
                    'Air Max 270 React',
                    'React WR',
                    'React WR',
                    'Jordan Proto React',
                    'Odyssey React',
                    'React Terra Globe',
                    'React Runner',
                    'Presto React',
                  ],
                },
              },
            },
            {
              name: 'Roshe Run',
              slug: 'navigation/nike/nike-running/roshe-run',
              sort: 11,
              description:
                "Much like Nike's \u003ca href=https://www.flightclub.com/nike/nike-running/flyknit-sockracer\u003eFlyknit\u003c/a\u003e, the Roshe Run's design has proven to be highly effective. The Phylon midsole offers padding, while the mesh upper is light and breathable. The sneaker's simplicity allows for bold experimentation with color combinations on the low profile. The show has been adapted into a \u003ca href=https://www.flightclub.com/rosherun-mid-black-black-atomic-red-052000\u003emid-top\u003c/a\u003e, received \u003ca href=https://www.flightclub.com/rosherun-fb-bright-citrus-flash-lime-laser-orange-black-052001\u003eleopard\u003c/a\u003e and \u003ca href=https://www.flightclub.com/rosherun-premium-blitz-blue-black-051978\u003esafari\u003c/a\u003e prints, and become a go-to sneaker for casual comfort.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/082/main/FkuYBuu.png?1570573526',
              algoliaQuery: { filters: { silhouette: ['roshe'] } },
            },
            {
              name: 'Vapormax',
              slug: 'navigation/nike/nike-running/vapormax',
              sort: 12,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/161/main/FkuYBuu.png?1570573541',
              algoliaQuery: {
                filters: {
                  silhouette: [
                    'Air VaporMax',
                    'Air VaporMax Plus',
                    'Air VaporMax 97',
                    'Air VaporMax 95',
                    'Air VaporMax 2019',
                  ],
                },
              },
            },
          ],
        },
        {
          name: 'Nike Air Force',
          slug: 'navigation/nike/nike-air-force',
          sort: 5,
          description:
            'The Air Force line introduced Air technology to Nike. The line offers a cushioning system with broad rubber soles. Since 1982, the tried-and-true basketball shoe has been integral in shaping sneaker culture. The sneaker has evolved with the times and embraced new materials and eccentric designs. It stands out with its remarkable ability for customization.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/163/main/FkuYBuu.png?1570573541',
          algoliaQuery: {
            filters: {
              brand_name: ['Nike'],
              silhouette: ['Air Force 1', 'Air Force 2', 'Air Force Max', 'Air Force 180'],
            },
          },
          children: [
            {
              name: 'Air Force 1 Low',
              slug: 'navigation/nike/nike-air-force/air-force-1-low',
              sort: 1,
              description:
                'Nike’s Air Force 1 Low is international: a sneaker without borders that connects with audiences spanning all types of cultures. First seen in 1982 as a basketball sneaker, the Air Force 1 Low remains a go-to casual sneaker that’s often customized to feature different cultures and regions, as well as PlayStation consoles, record labels, and collaborations with world-renowned artists.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/097/main/FkuYBuu.png?1570573531',
              algoliaQuery: {
                query: 'Low',
                filters: {
                  brand_name: ['Nike'],
                  silhouette: ['Air Force 1'],
                },
              },
            },
            {
              name: 'Air Force 1 Mid',
              slug: 'navigation/nike/nike-air-force/nike-force-1-mid',
              sort: 2,
              description:
                'Addressing the need for a sneaker with support somewhere between the \u003ca href=https://www.flightclub.com/nike/nike-air-force/air-force-1-low\u003eLow\u003c/a\u003e and the \u003ca href=https://www.flightclub.com/nike/nike-air-force/air-force-1-high\u003eHigh\u003c/a\u003e, the Air Force 1 Mid came with an ankle strap for added support while maintaining the once-revolutionary Nike Air technology in the sole. While not as popular as its Low counterpart, the Mid is equally suitable for casual wear.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/098/main/FkuYBuu.png?1570573531',
              algoliaQuery: {
                query: 'Mid',
                filters: {
                  brand_name: ['Nike'],
                  silhouette: ['Air Force 1'],
                },
              },
            },
            {
              name: 'Air Force 1 High',
              slug: 'navigation/nike/nike-air-force/air-force-1-high',
              sort: 3,
              description:
                'Former NBA great Rasheed Wallace kept the spirit of the Air Force 1 alive throughout his career and well after the sneaker was adopted for casual wear. The High was designed with a functional strap for ankle support that became decorative once the sneaker became fashionable. Premium materials, such as patent leathers and suede, were included in exclusive and limited releases, helping many releases achieve “grail” status for collectors.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/075/main/FkuYBuu.png?1570573525',
              algoliaQuery: {
                query: 'High',
                filters: {
                  brand_name: ['Nike'],
                  silhouette: ['Air Force 1'],
                },
              },
            },
            {
              name: 'Special Field',
              slug: 'navigation/nike/nike-air-force/special-field',
              sort: 4,
              algoliaQuery: {
                filters: { silhouette: ['Special Field Boot'] },
              },
            },
          ],
        },
        {
          name: 'Nike Dunks',
          slug: 'navigation/nike/nike-dunks',
          sort: 6,
          description:
            'The Nike Dunk hit the hardwood for the first time in 1985 and was designed with basketball performance in mind. The silhouette expanded on the success of the \u003ca href=https://www.flightclub.com/nike/nike-air-force\u003eAir Force 1\u003c/a\u003e by showcasing the design’s versatility with color combinations, especially for college programs. It returned to the market in 1998 as a casual sneaker and was eventually converted into a skater’s shoe with added padding for the \u003ca href=https://www.flightclub.com/nike/nike-skateboarding\u003eNike SB\u003c/a\u003e line in 2002.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/084/main/FkuYBuu.png?1570573527',
          algoliaQuery: {
            filters: { brand_name: ['Nike'], silhouette: ['Dunk'] },
          },
          children: [
            {
              name: 'Dunk Low',
              slug: 'navigation/nike/nike-dunks/dunk-low',
              sort: 1,
              description:
                'Much like its SB counterpart, the Nike Dunk Low is a low-profile sneaker that launched as a basketball shoe before becoming a go-to sneaker for casual wearers and skaters. Cherished for its ability to be customized, the Dunk Low has been an effective canvas for the most coveted kicks in the culture, including collaborations with legendary graffiti artists Haze, Stash, and Futura.',
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/099/main/FkuYBuu.png?1570573531',
              algoliaQuery: {
                query: 'Low',
                filters: { brand_name: ['Nike'], silhouette: ['Dunk'] },
              },
            },
            {
              name: 'Dunk Mid',
              slug: 'navigation/nike/nike-dunks/dunk-mid',
              sort: 2,
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/085/main/FkuYBuu.png?1570573527',
              algoliaQuery: {
                query: 'Mid',
                filters: { brand_name: ['Nike'], silhouette: ['Dunk'] },
              },
            },
            {
              name: 'Dunk High',
              slug: 'navigation/nike/nike-dunks/dunk-high',
              sort: 3,
              description:
                "The Nike Dunk High arrived in 1985 and was worn on both college and NBA courts while Michael Jordan was rewriting history in his similarly looking \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-1\u003eAir Jordan 1\u003c/a\u003e. The Nike Dunk High's reputation as a sneaker that can be easily adjusted to match specific colors delivered the Dunk to a new generation in 1998, when customization, graphics, and experimentation with fabrics became more prevalent.",
              pictureUrl:
                'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/086/main/FkuYBuu.png?1570573528',
              algoliaQuery: {
                query: 'High',
                filters: { brand_name: ['Nike'], silhouette: ['Dunk'] },
              },
            },
          ],
        },
        {
          name: 'Nike Off-White',
          slug: 'navigation/nike/nike-off-white',
          sort: 7,
          algoliaQuery: {
            filters: {
              indexName: 'product_variants_v2_flight_club',
              collection_slugs: ['nike-off-white'],
            },
          },
          urlOverride: 'collections/nike-off-white',
        },
        {
          name: 'Nike New Releases',
          slug: 'navigation/nike/new-releases',
          sort: 9,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/089/main/FkuYBuu.png?1570573529',
          algoliaQuery: {
            filters: { brand_name: ['Nike'] },
            indexName: 'product_variants_v2_by_newest_desc_flight_club',
          },
        },
      ],
    },
    {
      name: 'Adidas',
      slug: 'navigation/adidas',
      sort: 3,
      description:
        'Named for founder Adolf Dassler, the German-engineered adidas brand launched in 1948 with intentions to make an immediate impact in sports. From Olympic sprinting to soccer to basketball, the famous three stripes were designed for sneaker support before becoming the instantly recognizable adidas brand logo. After proving itself in sports, adidas made its presence felt in lifestyle with help from international \u003ca href=https://www.flightclub.com/superstar-80s-my-adidas-runwht-black1-runwht-200668\u003ehip-hop legends Run DMC\u003c/a\u003e.',
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/102/main/FkuYBuu.png?1570573531',
      algoliaQuery: { filters: { brand_name: ['adidas'] } },
      children: [
        {
          name: 'Ultra Boost',
          slug: 'navigation/adidas/ultra-boost',
          sort: 1,
          description:
            'The adidas Ultra Boost, released in February 2015, uses revolutionary BOOST and ARAMIS 3D technology to create a fully adaptable running experience. adidas BOOST technology provides superior support and adaptive comfort in any condition. Its stretch web outer sole adjusts to any type of foot movement and allows energy to be harnessed. Its newly constructed heel provides optimal stability to the Achilles tendon.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/076/main/FkuYBuu.png?1570573525',
          algoliaQuery: { filters: { silhouette: ['UltraBoost'] } },
        },
        {
          name: 'Yeezy',
          slug: 'navigation/adidas/adidas-yeezy',
          sort: 2,
          description:
            'Chicago-native Kanye West broke new ground in 2015 when he delved into the worlds of fashion and sneakers. Having worked with Nike, Louis Vuitton and A Bathing Ape, West linked with adidas to create the Yeezy line. Staples such as the \u003ca href=https://www.flightclub.com/yeezy/boost-350\u003eYeezy 350\u003c/a\u003e, \u003ca href=https://www.flightclub.com/yeezy/boost-500\u003eYeezy 500\u003c/a\u003e, and \u003ca href=https://www.flightclub.com/yeezy/boost-700\u003eYeezy 700\u003c/a\u003e have become cult favorites, as West continues to push the boundaries of sneaker design.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/104/main/FkuYBuu.png?1570573531',
          algoliaQuery: {
            filters: {
              brand_name: ['adidas'],
              silhouette: [
                'Yeezy Boost 350',
                'Yeezy Boost 380',
                'Yeezy 500',
                'Yeezy Boost 700',
                'Yeezy Boost 750',
                'Yeezy Powerphase',
              ],
            },
          },
        },
        {
          name: 'NMD',
          slug: 'navigation/adidas/nmd',
          sort: 3,
          description:
            'The adidas NMD is a newly developed sneaker style by adidas Originals that integrates traditional design sensibility with innovative technology. The adidas NMD contains references to the adidas Micro Pacer, Rising Star, and Boston Super while incorporating new technologies such as Primeknit and Boost cushioning. Intended for the nomadic lifestyle, the adidas NMD materials presents a sock like feel that is light, durable, and shock-resistant.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/105/main/FkuYBuu.png?1570573531',
          algoliaQuery: {
            filters: {
              silhouette: [
                'NMD',
                'NMD Runner',
                'NMD Human Race',
                'NMD XR1',
                'NMD City Sock',
                'NMD Human Race Trail',
                'NMD Chukka',
                'NMD Trainer',
                'NMD STLT',
                'NMD 360',
              ],
            },
          },
        },
        {
          name: 'Pharrell',
          slug: 'navigation/adidas/pharrell',
          sort: 4,
          description:
            'Pharrell Williams celebrates the diversity of humanity in his partnership with \u003ca href=https://www.flightclub.com/adidas\u003eadidas\u003c/a\u003e, which started back in 2014. Through an exploration of color, passion, love, and energy, the adidas Originals and Pharrell sneaker collaboration is only one part of this expansive project where each product carries the same underlying message: we are all part of the Human Race.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/106/main/FkuYBuu.png?1570573532',
          algoliaQuery: {
            filters: {
              designer: ['Pharrell Williams', 'Nic Galway'],
              silhouette: [
                'Board Flip 2',
                'Tennis Hu',
                'NMD Human Race',
                'NMD Human Race Trail',
                'pw human race nmd',
              ],
            },
          },
        },
        {
          name: 'ZX/EQT Series',
          slug: 'navigation/adidas/zx-eqt-series',
          sort: 5,
          description:
            'In 1984, adidas was looking toward the future when they created the ZX--a sneaker designed for runners while offering all the cushioning that makes the low-top ideal for casual comfort. It was constructed with seams to unite various fabrics for comfort and breathability. Recently, the ZX line received a jolt with an inspired \u003ca href=https://www.flightclub.com/zx-5000-undftdxbape-tecgol-whtvap-gum-200856\u003eUNDFTD x BAPE\u003c/a\u003e collaboration, as well as the menacing, blacked-out \u003ca href=https://www.flightclub.com/zx-500-mastermind-black1-black1-black1-200883\u003e"Mastermind" ZX 500\u003c/a\u003e.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/100/main/FkuYBuu.png?1570573531',
          algoliaQuery: {
            filters: {
              silhouette: [
                'EQT Support',
                'EQT ADV',
                'ZX Flux',
                'ZX 450',
                'ZX 500',
                'ZX 600',
                'ZX 700',
                'ZX 930 EQT',
                'ZX 4000',
                'ZX 8000',
                'ZX 9000',
                'ZX 10000',
              ],
            },
          },
        },
        {
          name: 'Stan Smith',
          slug: 'navigation/adidas/stan-smith',
          sort: 6,
          description:
            "Tennis player Stan Smith joined adidas in 1973, and the adidas Stan Smith became his official sneaker in 1978. Similar to the adidas Rod Laver, the Stan Smith was revered for its minimalism and low-profile design. adidas's Three Stripes appeared on this shoe in creative fashion as three columns with three perforated holes. It remains a minimalist sneaker, but also appeared as a mid-cut for a collaboration with streetwear brand \u003ca href=https://www.flightclub.com/adidas-stan-smith-mid-chrome-wht-iron-200621\u003e10.Deep\u003c/a\u003e.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/107/main/FkuYBuu.png?1570573531',
          algoliaQuery: { filters: { silhouette: ['Stan Smith'] } },
        },
        {
          name: 'Tubular',
          slug: 'navigation/adidas/tubular',
          sort: 7,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/090/main/FkuYBuu.png?1570573529',
          algoliaQuery: { filters: { silhouette: ['Tubular'] } },
        },
        {
          name: 'Superstar',
          slug: 'navigation/adidas/superstar',
          sort: 8,
          description:
            'Laces or no laces? The adidas Superstar has crossed generations and maintained its presence in sneaker culture since 1969 when it launched as a basketball sneaker. Once \u003ca href=https://www.flightclub.com/superstar-80s-my-adidas-runwht-black1-runwht-200668\u003eRun DMC slipped their feet in Superstars\u003c/a\u003e, the "shell-toe" reached new heights and became synonymous with the legendary Queens rap group and hip-hop culture. When most people think of adidas, it\'s the Superstar that comes to mind first.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/110/main/FkuYBuu.png?1570573532',
          algoliaQuery: { filters: { silhouette: ['Superstar'] } },
        },
        {
          name: 'Gazelle',
          slug: 'navigation/adidas/gazelle',
          sort: 9,
          description:
            "Whether its smooth suede or supple leather against the thick rubber midsole on the adidas Gazelle, this sneaker has been a staple for the laid-back and fashionable types since 1968. The Gazelle's minimalist look and low profile makes it ideal for a wide variety of color combinations from vibrant and eccentric to casual with low-key tones.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/111/main/FkuYBuu.png?1570573532',
          algoliaQuery: { filters: { silhouette: ['Gazelle'] } },
        },
        {
          name: 'Campus',
          slug: 'navigation/adidas/campus',
          sort: 10,
          description:
            'Introduced in the 80s as a low-cut basketball sneaker, the adidas Campus became a go-to casual shoe with help from the Beastie Boys. Since becoming a lifestyle sneaker, the Campus has been tweaked with and without the stripes and experimented on with new materials and fabrics. The Campus received a new shot of life in 2013 with the \u003ca href=https://www.flightclub.com/campus-80s-undftdxbape-shioli-shioli-chalk2-200859\u003eUNDFTD x BAPE\u003c/a\u003e collaboration.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/164/main/FkuYBuu.png?1570573541',
          algoliaQuery: { filters: { silhouette: ['Campus'] } },
        },
        {
          name: 'I-5923',
          slug: 'navigation/adidas/i-5923',
          sort: 11,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/108/main/FkuYBuu.png?1570573531',
          algoliaQuery: { filters: { silhouette: ['I-5923'] } },
        },
        {
          name: 'Adidas New Releases',
          slug: 'navigation/adidas/new-releases',
          sort: 13,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/103/main/FkuYBuu.png?1570573531',
          algoliaQuery: {
            filters: { brand_name: ['adidas'] },
            indexName: 'product_variants_v2_by_newest_desc_flight_club',
          },
        },
        {
          name: 'Other Brands',
          slug: 'navigation/adidas/other-brands',
          description:
            "Sneakers are worn worldwide, manufactured everywhere, and accommodate a wide array of lifestyles and eclectic fashion senses. It's not always a \u003ca href=https://www.flightclub.com/nike/nike-basketball\u003eNike Basketball\u003c/a\u003e shoe or a \u003ca href=https://www.flightclub.com/footwear/reebok\u003eReebok Pump\u003c/a\u003e or a \u003ca href=https://www.flightclub.com/air-jordans\u003eJordan\u003c/a\u003e. Sometimes it's a superstar collaboration with a high-end luxury brand or a fresh take on a classic Puma sneaker. Other times it's a reinvigoration of for ASICS, designed to compete with the casual corner controlled by \u003ca href=https://www.flightclub.com/footwear/new-balance\u003eNew Balance\u003c/a\u003e and classic \u003ca href=https://www.flightclub.com/nike/nike-running\u003eNike runners\u003c/a\u003e. The point is that you'll find them right here.",
        },
      ],
    },
    {
      name: 'Yeezy',
      slug: 'navigation/yeezy',
      sort: 4,
      description:
        'When news broke that culture-transcending icon Kanye West would be joining the roster of celebrity designers at adidas, the sneaker world stood still in anticipation of what was to come. Created in 2015, the adidas Yeezy Boost line has since yielded instantly-recognizable and classic silhouettes that boast the same high level of taste and refinement that we’ve come to expect from his music.',
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/112/main/FkuYBuu.png?1570573532',
      algoliaQuery: {
        filters: {
          silhouette: [
            'Yeezy Boost 350',
            'Yeezy Boost 380',
            'Yeezy 500',
            'Yeezy Boost 700',
            'Yeezy Boost 750',
            'Yeezy Powerphase',
          ],
        },
      },
      urlOverride: 'adidas/adidas-yeezy',
      children: [
        {
          name: 'Yeezy Boost 350',
          slug: 'navigation/yeezy/boost-350',
          sort: 1,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/154/main/FkuYBuu.png?1570573540',
          algoliaQuery: { filters: { silhouette: ['Yeezy Boost 350'] } },
        },
        {
          name: 'Yeezy Boost 380',
          slug: 'navigation/yeezy/boost-380',
          sort: 2,
          algoliaQuery: { filters: { silhouette: ['Yeezy Boost 380'] } },
        },
        {
          name: 'Yeezy Boost 500',
          slug: 'navigation/yeezy/boost-500',
          sort: 3,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/113/main/FkuYBuu.png?1570573532',
          algoliaQuery: { filters: { silhouette: ['Yeezy 500'] } },
        },
        {
          name: 'Yeezy Boost 700',
          slug: 'navigation/yeezy/boost-700',
          sort: 4,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/077/main/FkuYBuu.png?1570573525',
          algoliaQuery: { filters: { silhouette: ['Yeezy Boost 700'] } },
        },
        {
          name: 'Yeezy Boost 750',
          slug: 'navigation/yeezy/boost-750',
          sort: 5,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/091/main/FkuYBuu.png?1570573530',
          algoliaQuery: { filters: { silhouette: ['Yeezy Boost 750'] } },
        },
        {
          name: 'Yeezy Powerphase',
          slug: 'navigation/yeezy/yeezy-powerphase',
          sort: 6,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/116/main/FkuYBuu.png?1570573533',
          algoliaQuery: {
            filters: { silhouette: ['Yeezy Powerphase'] },
          },
        },
        {
          name: 'Nike Yeezy',
          slug: 'navigation/yeezy/nike-yeezy',
          sort: 7,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/101/main/FkuYBuu.png?1570573531',
          algoliaQuery: {
            filters: { designer: ['Kanye West'], brand_name: ['Nike'] },
          },
        },
        {
          name: 'Other Yeezy',
          slug: 'navigation/yeezy/other-yeezy',
          sort: 8,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/117/main/FkuYBuu.png?1570573533',
          algoliaQuery: {
            filters: { designer: ['Kanye West'], brand_name: ['Yeezy'] },
          },
        },
      ],
    },
    {
      name: 'New Balance',
      slug: 'navigation/new-balance',
      sort: 5,
      description:
        'Since 1906, New Balance has maintained its low-profile approach to making high-quality, hand-crafted footwear. Its modest objective to maintain quality builds for comfort and arch support created a legacy that has continued for generations. The Boston-bred sneaker brand takes pride in maintaining six American factories, responsible for constructing its most popular kicks, such as the 574 and 999. The minimalist silhouettes allow for unique colorways and creative collaborations.',
      algoliaQuery: { filters: { brand_name: ['New Balance'] } },
      children: [
        {
          name: 'New Balance 327',
          slug: 'navigation/new-balance/327',
          sort: 1,
          algoliaQuery: { filters: { silhouette: ['327'] } },
        },
        {
          name: 'New Balance 550',
          slug: 'navigation/new-balance/550',
          sort: 2,
          algoliaQuery: { filters: { silhouette: ['550'] } },
        },
        {
          name: 'New Balance 990',
          slug: 'navigation/new-balance/990',
          sort: 3,
          algoliaQuery: { filters: { silhouette: ['990'] } },
        },
      ],
    },
    {
      name: 'More Sneakers',
      slug: 'navigation/sneakers',
      sort: 6,
      description:
        "\u003ca href=https://www.flightclub.com/air-jordans\u003eAir Jordan\u003c/a\u003e, \u003ca href=https://www.flightclub.com/nike\u003eNike\u003c/a\u003e, and \u003ca href=https://www.flightclub.com/adidas/adidas-yeezy\u003eYeezy\u003c/a\u003e aren't always the first brands that come to mind when people are looking for sneakers. And those aren't the only items we carry at Flight Club. Not even close. Whether you're looking for \u003ca href=https://www.flightclub.com/sneakers/new-balance\u003eNew Balance\u003c/a\u003e, \u003ca href=https://www.flightclub.com/sneakers/reebok\u003eReebok\u003c/a\u003e, \u003ca href=https://www.flightclub.com/sneakers/converse\u003eConverse\u003c/a\u003e, \u003ca href=https://www.flightclub.com/sneakers/asics\u003eASICS\u003c/a\u003e, or more, we carry a wide selection of the kicks you seek.",
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/125/main/FkuYBuu.png?1570573534',
      algoliaQuery: {
        filters: {},
        breadcrumb_slug: 'navigation/sneakers',
      },
      children: [
        {
          name: 'Vans',
          slug: 'navigation/sneakers/vans',
          sort: 1,
          description:
            "Long before \u003ca href=https://www.flightclub.com/nike/nike-skateboarding\u003eNike Skateboarding\u003c/a\u003e tweaked old basketball sneakers into new skate shoes, Vans was the go-to brand for athletes who maneuvered on top of wheels. Founded in 1966 by brothers Paul and James Van Doren, the Vans sneaker line launched in Anaheim, California with a unique made-to-order approach. Starting in the '70s, cyclists and skaters built Vans to where it is today: an international footwear and apparel company focused on action sports.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/165/main/FkuYBuu.png?1570573541',
          algoliaQuery: { filters: { brand_name: ['Vans'] } },
        },
        {
          name: 'Off-White',
          slug: 'navigation/sneakers/off-white',
          sort: 2,
          description:
            "Virgil Abloh created Off-White as a fashion house with a streetwear mentality. Critiquing contemporary culture and always looking for his next inspiration, Abloh serves up a unique design language with Off-White, leaving his mark on classic \u003ca href=https://www.flightclub.com/air-jordans\u003eAir Jordan\u003c/a\u003e, \u003ca href=https://www.flightclub.com/sneakers/converse\u003eConverse\u003c/a\u003e, and \u003ca href=https://www.flightclub.com/nike\u003eNike\u003c/a\u003e silhouettes through the brand's coveted sneaker collaborations.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/114/main/FkuYBuu.png?1570573532',
          algoliaQuery: {
            filters: { collection_slugs: ['off-white'] },
          },
          urlOverride: 'off-white',
        },
        {
          name: 'Reebok',
          slug: 'navigation/sneakers/reebok',
          sort: 3,
          description:
            "The Reebok brand started as J.W. Foster and Sons in the U.K. in the late 19th century. The brand was renamed in 1958 once it was introduced to America. Reebok expanded beyond running sneakers to gain traction in football, basketball, and other sports. With top endorsers throughout the '90s, including NBA stars \u003ca href=https://www.flightclub.com/kamikaze-2-mid-black-white-racing-green-red-991734\u003eShawn Kemp\u003c/a\u003e, Allen Iverson, and \u003ca href=https://www.flightclub.com/shaqnosis-og-black-white-black-white-991757\u003eShaquille O'Neal\u003c/a\u003e, and a convincing marketing strategy for Pump technology, Reebok became the prime alternative for people who wanted more than a \u003ca href=https://www.flightclub.com/nike\u003eswoosh\u003c/a\u003e on their footwear.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/168/main/FkuYBuu.png?1570573542',
          algoliaQuery: { filters: { brand_name: ['Reebok'] } },
          children: [
            {
              name: 'Other Reebok',
              slug: 'navigation/sneakers/reebok/other-reebok',
              description:
                'From DMX runners to cross-training kicks worn by Cowboys legendary running back Emmitt Smith to the beloved Classic, Reebok maintains the foundation for a lasting presence that extends beyond its high-powered NBA endorsers. That even includes the Rbk effort that allowed hip-hop superstar Jay-Z the opportunity to create his signature sneaker, the S. Carter, in 2003.',
            },
          ],
        },
        {
          name: 'Puma',
          slug: 'navigation/sneakers/puma',
          sort: 4,
          description:
            'The sibling rivalry between Adolf and Rudolf Dassler produced two of the most popular brands in sneaker culture. While Adolf built \u003ca href=https://www.flightclub.com/adidas\u003eadidas\u003c/a\u003e, Rudolf focused on creating Puma. With support from soccer players, including legendary Brazilian footballer Pelé, Puma became an international phenomenon. When Knicks basketball star Walt "Clyde" Frazier wore the Puma Clyde, the shoes became a classic.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/135/main/FkuYBuu.png?1570573536',
          algoliaQuery: { filters: { brand_name: ['Puma'] } },
        },
        {
          name: 'Asics',
          slug: 'navigation/sneakers/asics',
          sort: 5,
          description:
            "ASICS started in 1949 with basketball sneakers made in founder Kihachiro Onitsuka's living room in Japan before making their way to the United States in 1977. In 1986 ASICS introduced its comfort-first Gel technology, designed to enhance cushioning, provide soft landings for the foot, and compete with Nike's Air efforts. Today kicks, such as the GT-II and Gel-Lyte 3, are enjoying a refresh as ASICS collaborates with \u003ca href=https://www.flightclub.com/gel-lyte-3-charcoal-gold-991770\u003eboutique sneaker shops\u003c/a\u003e and prominent names in streetwear.",
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/121/main/FkuYBuu.png?1570573533',
          algoliaQuery: { filters: { brand_name: ['ASICS'] } },
        },
        {
          name: 'Converse',
          slug: 'navigation/sneakers/converse',
          sort: 6,
          description:
            'Converse has been in the sneaker conversation since 1908. The brand has lasted through generations because of its inherently classic appearance and universal appeal, coupled with an ability to adapt to the times with subtle adjustments and updates. From the introduction of the All-Star in 1917, renamed after Chuck Taylor in 1921 for the prominent basketball player, to the Jack Purcell of the 1970s, Converse has experienced its ups and downs without ever compromising its core offerings.',
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/122/main/FkuYBuu.png?1570573534',
          algoliaQuery: { filters: { brand_name: ['Converse'] } },
        },
        {
          name: 'Bape',
          slug: 'navigation/sneakers/a-bathing-ape',
          sort: 7,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/078/main/FkuYBuu.png?1570573525',
          algoliaQuery: { filters: { brand_name: ['BAPE'] } },
        },
        {
          name: 'Saucony',
          slug: 'navigation/sneakers/saucony',
          sort: 8,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/123/main/FkuYBuu.png?1570573534',
          algoliaQuery: { filters: { brand_name: ['Saucony'] } },
        },
        {
          name: 'Under Armour',
          slug: 'navigation/sneakers/under-armour',
          sort: 9,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/109/main/FkuYBuu.png?1570573532',
          algoliaQuery: { filters: { brand_name: ['Under Armour'] } },
        },
        {
          name: 'Men',
          slug: 'navigation/sneakers/men',
          sort: 10,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/136/main/FkuYBuu.png?1570573537',
          algoliaQuery: { filters: { single_gender: ['men'] } },
          urlOverride: 'men',
        },
        {
          name: 'Women',
          slug: 'navigation/sneakers/women',
          sort: 11,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/137/main/FkuYBuu.png?1570573537',
          algoliaQuery: { filters: { single_gender: ['women'] } },
          urlOverride: 'women',
        },
        {
          name: 'Kids',
          slug: 'navigation/sneakers/youth',
          sort: 12,
          pictureUrl:
            'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/138/main/FkuYBuu.png?1570573537',
          algoliaQuery: { filters: { single_gender: ['youth'] } },
          urlOverride: 'youth',
        },
      ],
    },
    {
      name: 'New Releases',
      slug: 'navigation/new-releases',
      sort: 7,
      description:
        "Looking for the latest and greatest sneakers to arrive at Flight Club? They're all here. New sizes and models at our New York, Los Angeles, and Miami locations are added daily.",
      pictureUrl:
        'https://image-preprod.foodsworth.com/attachments/breadcrumb_pictures/images/002/673/115/main/FkuYBuu.png?1570573532',
      algoliaQuery: {
        filters: { collection_slugs: ['new-releases'] },
        indexName: 'product_variants_v2_by_newest_desc_flight_club',
      },
    },
    {
      name: 'Apparel',
      slug: 'navigation/apparel',
      sort: 8,
      description:
        "We have the pieces you need to pair with your freshest \u003ca href='https://www.flightclub.com/air-jordans'\u003eJordans\u003c/a\u003e.",
      algoliaQuery: {
        filters: {
          indexName: 'product_variants_v2_flight_club',
          product_category: ['clothing', 'bags', 'accessories'],
        },
      },
    },
    {
      name: 'Price Drops',
      slug: 'navigation/price-drops',
      sort: 9,
      description:
        'Shop the latest price drops on \u003ca href=https://www.flightclub.com/air-jordans\u003eAir Jordan\u003c/a\u003e, \u003ca href=https://www.flightclub.com/adidas/adidas-yeezy\u003eYeezy\u003c/a\u003e and more, including \u003ca href=https://www.flightclub.com/air-jordans/air-jordan-1\u003eAir Jordan 1s\u003c/a\u003e, \u003ca href=https://www.flightclub.com/yeezy/boost-350\u003eYeezy Boost 350s\u003c/a\u003e, \u003ca href=https://www.flightclub.com/nike/nike-running/air-max\u003eAir Max\u003c/a\u003e, Vans Sk8-Hi and \u003ca href=https://www.flightclub.com/nike/nike-air-force/air-force-1-low\u003eAir Force 1s\u003c/a\u003e.',
      algoliaQuery: {
        filters: {
          indexName: 'product_variants_v2_flight_club',
          collection_slugs: ['price-drops'],
        },
      },
    },
  ],
}

export const defaultBreadcrumbs: Breadcrumb[] = [
  defaultFooter,
  defaultNavigationLinks,
  defaultNavigation,
]
