import styled from 'styled-components'

const H1 = styled.h1`
  font-family: ${props => props.theme.fonts.ivar};
  font-size: 60px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 72px;
  margin: 0;
`

export default H1
