import NextImage, { ImageProps } from 'next/image'
import React, { ReactElement } from "react"
import styled from "styled-components"

const Image = (props: ImageProps): ReactElement => {
  const {
    alt,
    className,
    src = "missing.png",
    width,
    height,
  } = props
  

  const goImageLoader = ({src, width = 750}: {src: string, width: number}) => {
    const isMissing = /missing.png/.test(src)
    if (isMissing) {
      const placeholderSrc = src.substring(0, src.indexOf('missing')) + `placeholders/dash.png?w=${width}`
      return placeholderSrc
    }
    return `${src}?w=${width}`
  }

  return (
    <Image.StyledImage
      {...props}
      width={width}
      loader={goImageLoader}
      alt={alt}
      className={className}
      src={src}
      fill={(width && height) ? false: true}
    />
  )
}

// images use objectFit contain
// to support responsive / client sizes
// that differ from the delivered image
Image.StyledImage = styled(NextImage)`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

export default Image
