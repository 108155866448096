import styled, { css } from 'styled-components'
import { ChangeEvent } from 'react'
import { Field, FieldProps } from 'formik'
import { colors } from 'shared/theme'
import RedX from 'shared/icons/RedX'
import { camelCase } from 'lodash'

export interface TextInputProps {
  className?: string
  dataQa?: string
  label: string
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  type?: 'email' | 'number' | 'text'
}

export const TextInput = ({
  className,
  dataQa,
  label,
  name,
  onChange,
  type = 'text',
}: TextInputProps) => {
  const fieldId = dataQa || `${camelCase(label)}TextInput`
  const getTextInputDataQa = (postFix = '') => `${fieldId}${postFix}`
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const error = meta.touched && meta.error
        const hasError = !!error
        return (
          <Container className={`${className} ${hasError && 'hasError'}`}>
            <Label data-qa={getTextInputDataQa('Label')} htmlFor={fieldId}>
              {label}
            </Label>
            <Input
              data-qa={getTextInputDataQa()}
              id={fieldId}
              type={type}
              {...field}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e)
                if (onChange) {
                  onChange(e)
                }
              }}
            />
            {hasError && (
              <Error data-qa={getTextInputDataQa('Error')}>
                <ErrorX />
                {error}
              </Error>
            )}
          </Container>
        )
      }}
    </Field>
  )
}

const Container = styled.div`
  width: 100%;
`

/* stylelint-disable */
const Input = styled.input`
  background-color: ${colors.offBlack};
  border: 1px solid ${colors.lightGray};
  border-radius: 2px;
  color: ${colors.white};
  font-size: 16px;
  line-height: 18px;
  outline: 0;
  padding: 20px;
  height: 50px;
  transition: border-color 250ms;
  width: 100%;

  &:focus {
    border-color: ${colors.white};
  }

  &:invalid {
    box-shadow: none;
  }

  &::selection {
    color: ${colors.black};
    background-color: ${colors.lighterGray};
  }

  .hasError & {
    border-color: ${colors.red};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${colors.white};
    -webkit-box-shadow: 0 0 0px 1000px ${colors.offBlack} inset;
    background-color: ${colors.black} !important;
    background-clip: content-box !important;
  }
`
/* stylelint-enable */

export const labelStyles = css`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

const Label = styled.label`
  ${labelStyles}
  color: ${colors.lightGray};
  margin-bottom: 10px;
`

const ErrorX = styled(RedX)`
  position: relative;
  top: 1px;
  margin-right: 7px;
`
const Error = styled.div`
  ${labelStyles}
  color: ${colors.red};
  margin-top: 8px;
`
