import { App } from 'shared/types/App'

/**
 * [appHeaders]
 * Determine app specific headers to return for communication with GOAT apps
 * Defaults requests to 'sneakers' app if not or incorrectly specified
 */

type AppHeaders = {
  app: App
  isClient: boolean
  token?: string
}

export const appHeaders = ({ app, isClient, token }: AppHeaders): Record<string, string> => {
  switch (app) {
    case 'cms':
      return {
        'x-goat-app': 'cms-service',
      }
    case 'peas':
      return {
        'X-CSRF-TOKEN': token || '',
        'x-goat-app': 'payments-availability',
      }
    case 'product-template-lists': {
      if (isClient) {
        return {
          'X-CSRF-TOKEN': token || '',
          'x-goat-app': 'product-template-lists',
        }
      }
      return {
        Authorization: token ? `Token token="${token}"` : '',
        'x-goat-app': 'product-template-lists',
      }
    }
    default:
      if (isClient) {
        return {
          'X-CSRF-TOKEN': token || '',
          'x-goat-app': 'sneakers',
        }
      }
      return {
        Authorization: token ? `Token token="${token}"` : '',
        'x-goat-app': 'sneakers',
      }
  }
}
