import { ForwardedRef, ReactNode, forwardRef, useRef, TouchEvent } from 'react'
import styled from 'styled-components'

import { colors, media } from 'shared/lib'
import { sizes } from 'shared/lib/media'

type SlideOutPanel = {
  children: ReactNode
  className: string
  onAnimationStart: () => void
}

export const SlideOutPanel = forwardRef(
  ({ children, className, onAnimationStart }: SlideOutPanel, ref: ForwardedRef<HTMLDivElement>) => {
    const lastY = useRef(0)

    const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
      lastY.current = event.touches[0].clientY
    }

    const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
      const top = event.touches[0].clientY

      const scrollTop = event.currentTarget.scrollTop
      const maxScrollTop = event.currentTarget.scrollHeight - event.currentTarget.offsetHeight
      const direction = lastY.current - top < 0 ? 'up' : 'down'

      if (
        event.cancelable &&
        ((scrollTop <= 0 && direction === 'up') ||
          (scrollTop >= maxScrollTop && direction === 'down'))
      ) {
        event.preventDefault()
      }
      lastY.current = top
    }

    return (
      <Wrapper
        data-qa="SlideOutPanelWrapper"
        ref={ref}
        className={className}
        onAnimationStart={() => onAnimationStart()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {children}
      </Wrapper>
    )
  },
)

SlideOutPanel.displayName = 'SlideOutPanel'

const Wrapper = styled.div`
  background-color: ${colors.FC2_WHITE};

  ${media.large`
  height: 100%;
  width: 545px;
  display: flex;
  flex-direction: column;
    &.enter {
        animation: 0.4s slide-out;
        animation-timing-function:cubic-bezier(0.64, 0, 0.32, 1);
        @keyframes slide-out {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
        }
    }

    &.exit {
        animation: 0.4s slide-in;
        animation-timing-function:cubic-bezier(0.64, 0, 0.32, 1);
        @keyframes slide-in {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
        }
    }
  `}

  @media (max-width: ${sizes.large / 16}em) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;

    &.enter {
      animation: 0.4s slide-up;
      animation-timing-function: cubic-bezier(0.64, 0, 0.32, 1);

      @keyframes slide-up {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }

    &.exit {
      animation: 0.4s slide-down;
      animation-timing-function: cubic-bezier(0.64, 0, 0.32, 1);
      @keyframes slide-down {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(100%);
        }
      }
    }
  }
`
