import styled from 'styled-components'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  &:checked + label:after {
    background: #222;
  }
`

export default HiddenCheckbox
