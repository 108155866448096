import flow from 'lodash/fp/flow'
import toUpper from 'lodash/fp/toUpper'
import snakeCase from 'lodash/fp/snakeCase'

export const toColorConst = (str: string) => flow(snakeCase, toUpper)(str)

const FC2_BLACK = '#000000'
const FC2_DARK_GREY = '#303030'
const FC2_GREY = '#818181'
const FC2_LIGHT_GREY = '#999999'
const FC2_LIGHTEST_GREY = '#e7e7e7'
const FC2_MEDIUM_GREY = '#8F8F8F'
const FC2_OFF_WHITE = '#f9f9f9'
const FC2_WHITE = '#ffffff'
const FC2_GREEN = '#4ca80d'
const FC2_RED = '#e91C23'

export default {
  FC2_BLACK,
  FC2_DARK_GREY,
  FC2_GREY,
  FC2_LIGHT_GREY,
  FC2_LIGHTEST_GREY,
  FC2_MEDIUM_GREY,
  FC2_OFF_WHITE,
  FC2_WHITE,
  FC2_GREEN,
  FC2_RED,
}
