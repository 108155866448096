import { FC } from 'react'
import PanelHeader from './PanelHeader'
import PanelBox from './PanelBox'
import Divider from '../Divider'

interface IPanelProps {
  title: JSX.Element | string
  isEditable?: boolean
  onEdit?: () => void
  subtitle?: string
  children?: React.ReactNode
  qaAttr?: string
  className?: string
  withCloseButton?: boolean
  handleClose?: () => void
  withDivider?: boolean
}

const Panel: FC<IPanelProps> = ({
  title,
  subtitle,
  children,
  onEdit,
  isEditable = false,
  qaAttr,
  className,
  withCloseButton = false,
  handleClose,
  withDivider = true,
}: IPanelProps) => {
  return (
    <PanelBox className={className} data-qa={`${qaAttr}Panel`}>
      <PanelHeader
        title={title}
        qaAttr={qaAttr}
        isEditable={isEditable}
        onEdit={onEdit}
        subtitle={subtitle}
        withCloseButton={withCloseButton}
        handleClose={handleClose}
      />
      {withDivider && <Divider />}
      {children}
    </PanelBox>
  )
}

export default Panel
