const themeColors = {
  black: '#000000',
  darkGray: '#303030',
  lightGray: '#a3a3a3',
  lighterGray: '#afafaf',
  mediumGray: '#8f8f8f',
  glass: 'rgba(200, 200, 200, 0.25)',
  offBlack: '#0d0d0d',
  offWhite: '#eaeaea',
  red: '#e91C23',
  transparent: 'transparent',
  white: '#ffffff',
} as const

export type ThemeColor = typeof themeColors[keyof typeof themeColors]

export default themeColors
