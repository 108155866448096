import camelCase from 'lodash/camelCase'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { FC } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import styled from 'styled-components'

import { SearchFiltersHumanizedRefinement } from 'search'
import { IRefinementListProps } from 'search/components/SearchFilters/Filters/IRefinementListProps'
import { colorFilterOption } from 'search/enums/search'
import { Button } from 'shared/components/Button'
import { Card } from 'shared/components/CardDeprecated'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'

enum FilterColor {
  black = '#000000',
  blue = '#0000FF',
  brown = '#5F452B',
  copper = '#B87333',
  cream = '#F8F8D9',
  gold = '#E6C200',
  grey = '#808080',
  green = '#4ca80D',
  orange = '#ffa500',
  pink = '#ffc0c8',
  purple = '#663399',
  red = '#E91C23',
  silver = '#C0C0C0',
  tan = '#D2B48C',
  teal = '#00B3B3',
  white = '#FFFFFF',
  yellow = '#FFFF00',
  multi = '',
}

const ColorFilter: FC<IRefinementListProps> = ({ items, refine, attribute }) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value[value.length - 1],
    })
    refine(value)
  }
  return items.length > 0 ? (
    <Card title={SearchFiltersHumanizedRefinement()[attribute]} qaAttr={SearchQA.FilterColor}>
      <ColorCardContent>
        {items.map(({ label, value, isRefined }) => (
          <ColorOptionButton
            buttonType="secondary"
            $fill
            key={label}
            onClick={() => {
              handleRefinement(value)
            }}
            isSelected={isRefined}
            qaAttr={SearchQA.FilterColorLabel}
          >
            {label.toLowerCase() === 'multi-color' ? (
              <MultiColorCircle color={FilterColor[label.toLowerCase()]} />
            ) : (
              <Circle color={FilterColor[label.toLowerCase()]} />
            )}
            {colorFilterOption()[camelCase(label)] || label}
          </ColorOptionButton>
        ))}
      </ColorCardContent>
    </Card>
  ) : null
}

const BaseCircle = styled.div`
  border-radius: 50%;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  height: 14px;
  margin-right: 10px;
  width: 14px;
`
const Circle = styled(BaseCircle)<{ color: string }>`
  background-color: ${({ color }) => color};
`

const MultiColorCircle = styled(BaseCircle)`
  background-image: linear-gradient(to left, #fff40c, #4a8a00 31%, #00489c 63%, #d53736);
`

const ColorCardContent = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
`

const ColorOptionButton = styled(Button)<{ isSelected: boolean }>`
  align-items: center;
  border-color: ${({ isSelected }) =>
    isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY} !important;
  display: flex;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: normal !important;
  padding-left: 15px;
  text-align: left;
  text-transform: capitalize !important;
`

export default connectRefinementList(ColorFilter)
